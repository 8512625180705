// libraries
import React, { useState, useLayoutEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import _ from "lodash";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
// constant
import CONSTANT from "common/constant";
// routes
import pathnames from "routes/pathnames";
// api
import api from "services/api";
// common
import { sanitizeError } from "common/utilities";
// components
import AppBreadcrumb from "components/app-breadcrumb";
import AppPagesLayout from "components/app-pages-layout";
import AppImageUpload from "components/app-image-upload";
import AppInputDate from "components/app-input-date";
import AppDropdown from "components/app-dropdown";
import AppStatus from "components/app-status";
import AppButton from "components/app-button";
import AppTextArea from "components/app-text-area";
import AppInputWithLabel from "components/app-input-with-label";
import appToast from "components/app-toast";
import AppModalConfirmation from "components/app-modal-confirmation";
import AppCheckbox from "components/app-checkbox";
// redux
import { updateProfile } from "redux/slices/auth-slice";

const currentDate = moment();
const currentDateAfterFormat = moment(new Date()).format("DD/MM/YYYY");

// new employee

const newEmployeeInitialValues = {
    image: "", // in multipart format
    joinedDate: "",
    probationEnd: "",
    position: "",
    contractType: "",
    reportingManager: "",
    role: "",
    staffId: "",
    name: "",
    userName: "",
    typeOfId: "",
    nric: "",
    passport: "",
    dob: "",
    phoneNumber: "",
    email: "",
    personalEmail: "",
    address: "",
};

const newEmployeeValidationSchema = yup.object().shape({
    joinedDate: yup.string().required("Joined date is required").nullable(true),
    probationEnd: yup.string().required("Probation end date is required").nullable(true),
    position: yup.string().required("Position is required"),
    contractType: yup.string().required("Contract type is required"),
    // reportingManager: yup.string().required("Reporting manager is required"),
    role: yup.string().required("Access type is required"),
    name: yup.string().required("Full name is required"),
    userName: yup.string().required("Username is required"),
    typeOfId: yup.string(),
    nric: yup.string().when(["typeOfId"], (typeOfId, schema) => {
        if (typeOfId && typeOfId.toLowerCase() === "ic no.") {
            return yup
                .string()
                .required("IC number is required")
                .test("", "Invalid IC number", (value) => {
                    let valueWithoutDash = value?.split("-")?.join("");

                    if (value && valueWithoutDash.length === 12) {
                        if (valueWithoutDash.length >= 8 && valueWithoutDash.length <= 12) {
                            let date = value.substring(0, 6);
                            let dateChecking = moment(date, "YYMMDD");
                            let location = valueWithoutDash.substring(6, 8);
                            let locationChecking = CONSTANT.STATE_NUMBER.includes(location);
                            if (!dateChecking._isValid || !locationChecking) {
                                return false;
                            } else {
                                return true;
                            }
                        } else {
                            return true;
                        }
                    } else {
                        return false;
                    }
                });
        }
    }),
    passport: yup.string().when(["typeOfId"], (typeOfId, schema) => {
        if (typeOfId === "passport") {
            return yup
                .string()
                .required("Passport no. is required")
                .test("passport", "Only 9 characters are allowed", (value) => value && value.toString().length === 9)
                .matches(/^[a-z0-9]+$/i, "Invalid passport number");
        }
    }),
    dob: yup
        .string()
        .required("Date of birth is required")
        .test("dob", "Invalid date", (value) => {
            if (value) {
                let valueWithoutSpaces = value.replace(/\s/g, "");
                return "Invaliddate" !== valueWithoutSpaces;
            }
        }),
    // phoneNumber: yup.string().required("Mobile number is required").matches(CONSTANT.REGEX.mobileNo, "Invalid mobile number"),
    email: yup.string().email("Invalid work email").required("Working email is required"),
    // personalEmail: yup.string().email("Invalid personal email").required("Personal email is required"),
    // address: yup.string().required("Address is required"),
});

const newEmployeeInformationFields = [
    { name: "joinedDate", placeholder: "Joined Date", type: "date" },
    { name: "probationEnd", placeholder: "Probation End Date", type: "date" },
    { name: "position", placeholder: "Position", type: "dropdown" },
    { name: "contractType", placeholder: "Contract Type", type: "dropdown" },
    { name: "reportingManager", placeholder: "Reporting Manager", type: "dropdown" },
];

const newEmployeePersonalInformationFields = [
    { name: "staffId", placeholder: "Employee ID", type: "staffId" },
    { name: "name", placeholder: "Full Name" },
    { name: "userName", placeholder: "Username" },
    { name: "typeOfId", placeholder: "Identification Type", type: "dropdown" },
    { name: "nric", placeholder: "IC No.", type: "ic" },
    { name: "passport", placeholder: "Passport No." },
    { name: "dob", placeholder: "Date of Birth", type: "date" },
    { name: "phoneNumber", placeholder: "Mobile Number", type: "mobile" },
    { name: "email", placeholder: "Work Email" },
    { name: "personalEmail", placeholder: "Personal Email" },
    { name: "address", placeholder: "Address", type: "textarea" },
];

// edit employee

const editEmployeeInformationFields = [
    { name: "joinedDate", placeholder: "Joined Date", type: "date" },
    { name: "probationEnd", placeholder: "Probation End Date", type: "date" },
    { name: "promotionDate", placeholder: "Latest Promotion Date", type: "date" },
    { name: "latestCoachSession", placeholder: "Latest Coaching", type: "date" },
    { name: "lastEmpDate", placeholder: "Last Employment", type: "date" },
    { name: "staffStatus", placeholder: "Employment End Status", type: "dropdown" },
    { name: "position", placeholder: "Position", type: "dropdown" },
    { name: "contractType", placeholder: "Contract Type", type: "dropdown" },
    { name: "reportingManager", placeholder: "Reporting Manager", type: "dropdown" },
];

const editEmployeePersonalInformationFields = [
    { name: "staffID", placeholder: "Employee ID", disabled: true },
    { name: "name", placeholder: "Full Name" },
    { name: "userName", placeholder: "Username" },
    { name: "typeOfId", placeholder: "Identification Type", type: "dropdown" },
    { name: "nric", placeholder: "IC No.", type: "ic" },
    { name: "passport", placeholder: "Passport No." },
    { name: "dob", placeholder: "Date of Birth", type: "date" },
    { name: "phoneNumber", placeholder: "Mobile Number", type: "mobile" },
    { name: "email", placeholder: "Work Email" },
    { name: "personalEmail", placeholder: "Personal Email" },
    { name: "address", placeholder: "Address", type: "textarea" },
];

const editEmployeeValidationSchema = yup.object().shape({
    staffStatus: yup
        .string()
        .when(["lastEmpDate"], (lastEmpDate) => {
            if (lastEmpDate) {
                return yup.string().required("Employment end status is required");
            }
        })
        .nullable(),
    position: yup.string().required("Position is required"),
    contractType: yup.string().required("Contract type is required"),
    // reportingManager: yup.string().required("Reporting manager is required"),
    role: yup.string().required("Access type is required"),
    name: yup.string().required("Full name is required"),
    userName: yup.string().required("Username is required"),
    typeOfId: yup.string(),
    nric: yup.string().when(["typeOfId"], (typeOfId, schema) => {
        if (typeOfId && typeOfId.toLowerCase() === "ic no.") {
            return yup
                .string()
                .required("IC number is required")
                .test("", "Invalid IC number", (value) => {
                    let valueWithoutDash = value && value.split("-").join("");
                    if (value && valueWithoutDash.length === 12) {
                        if (value && valueWithoutDash.length >= 8 && valueWithoutDash.length <= 12) {
                            let date = value.substring(0, 6);
                            let dateChecking = moment(date, "YYMMDD");
                            let location = valueWithoutDash.substring(6, 8);
                            let locationChecking = CONSTANT.STATE_NUMBER.includes(location);
                            if (!dateChecking._isValid || !locationChecking) {
                                return false;
                            } else {
                                return true;
                            }
                        } else {
                            return true;
                        }
                    } else {
                        return false;
                    }
                });
        }
    }),
    passport: yup.string().when(["typeOfId"], (typeOfId, schema) => {
        if (typeOfId === "passport") {
            return yup
                .string()
                .required("Passport no. is required")
                .test("passport", "Only 9 characters are allowed", (value) => value && value.toString().length === 9)
                .matches(/^[a-z0-9]+$/i, "Invalid passport number");
        }
    }),
    dob: yup
        .string()
        .required("Date of birth is required")
        .test("", "Invalid date", (value) => {
            if (value) {
                let valueWithoutSpaces = value.replace(/\s/g, "");
                return "Invaliddate" !== valueWithoutSpaces;
            }
        })
        .nullable(true),
    // phoneNumber: yup.string().required("Mobile number is required").matches(CONSTANT.REGEX.mobileNo, "Invalid mobile number"),
    email: yup.string().email("Invalid work email").required("Working email is required"),
    // personalEmail: yup.string().email("Invalid personal email").required("Personal email is required"),
    // address: yup.string().required("Address is required"),
});

const typeOfIdDropdownOption = [
    { label: "IC No.", value: "ic no." },
    { label: "Passport No.", value: "passport" },
];

const employmentEndStatusOptions = [
    { label: "Resigned", value: 1 },
    { label: "Dismissal", value: 2 },
    { label: "Termination", value: 3 },
];

const PageEmployee = () => {
    const [pageContentState, setPageContentState] = useState({});
    const [pageDataValues, setPageDataValues] = useState({});
    const [button, setButton] = useState({});
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [dropdownOptionsBE, setDropdownOptionsBE] = useState({
        position: [],
        reportingManager: [],
    });
    const history = useHistory();
    const location = useLocation();
    const { username } = useParams();
    const [dateErrors, setDateErrors] = useState({
        joinedDate: "",
        probationEnd: "",
        promotionDate: "",
        latestCoachSession: "",
        lastEmpDate: "",
    });
    const dispatch = useDispatch();
    const profile = useSelector((state) => state.auth);
    const staffId = profile.staffID;

    useLayoutEffect(() => {
        if (location.pathname === pathnames.pageNewEmployee) {
            setPageContentState({
                breadcrumbLabel: "New Employee",
                initialValues: newEmployeeInitialValues,
                validationSchema: newEmployeeValidationSchema,
                employeeInformationFields: newEmployeeInformationFields,
                personalInformationFields: newEmployeePersonalInformationFields,
                modalConfirmDetails: "Confirm to create?",
                status: "Probation",
            });
            setButton({
                label: "Confirm",
                disabled: false,
            });
        } else {
            const apiGetUsername = async () => {
                try {
                    let pathUsernameValue = username.replace("value_", "");
                    let payload = {
                        staffId: pathUsernameValue,
                    };
                    const response = await api.get.getUsernameDetails(payload);
                    const result = response.data.result[0];
                    setPageContentState({
                        breadcrumbLabel: "Edit Employee",
                        initialValues: {
                            image: result.image, // in multipart format
                            joinedDate: result.joinedDate,
                            probationEnd: result.probationEnd,
                            promotionDate: result.promotionDate,
                            latestCoachSession: result.latestCoachSession,
                            lastEmpDate: result.lastEmpDate,
                            staffStatus: result.status,
                            position: result.staffPosition,
                            contractType: result.contractType,
                            reportingManager: result.reportingManager,
                            staffID: result.staffID,
                            name: result.staffName,
                            userName: result.staffUserName,
                            nric: result.staffNRIC,
                            dob: result.staffDOB,
                            phoneNumber: result.staffPhoneNumber,
                            email: result.staffEmail,
                            personalEmail: result.staffPersonalEmail,
                            address: result.staffAddress,
                            status: result.status,
                            passport: result.staffNRIC,
                            typeOfId: result.typeOfId.toLowerCase(),
                            role: result.staffRole.toLowerCase(),
                        },
                        status: result.status,
                        validationSchema: editEmployeeValidationSchema,
                        employeeInformationFields: editEmployeeInformationFields,
                        personalInformationFields: editEmployeePersonalInformationFields,
                        modalConfirmDetails: "Confirm to save?",
                        staffRole: result.staffRole.toLowerCase(),
                    });
                    setButton({
                        label: "Confirm",
                        disabled: false,
                    });
                } catch (error) {
                    const errorMessage = sanitizeError(error);
                    appToast(errorMessage, false, 300);
                    history.push(pathnames.pageUserManagement);
                }
            };
            apiGetUsername();
        }
        const getStaffNamePosition = async () => {
            try {
                const response = await api.get.getStaffNamePosition();
                const labelUsername = response.data.result.staffNameList.map((o) => {
                    return {
                        ...o,
                        label: o.userName,
                    };
                });
                setDropdownOptionsBE({
                    position: [...response.data.result.positionList],
                    // reportingManager: labelUsername,
                    reportingManager: [{ id: 0, value: "", value2: "", userName: "none", label: "none" }, ...labelUsername],
                });
            } catch (error) {
                const sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
            }
        };
        getStaffNamePosition();
    }, [location, history, username]);

    const breadcrumb = [
        {
            label: "User Management",
            pathname: pathnames.pageUserManagement,
        },
        {
            label: pageContentState.breadcrumbLabel,
        },
    ];

    const buttonDisable = (touched, button) => {
        if (location.pathname === pathnames.pageNewEmployee) {
            if (button.disabled) {
                return true;
            } else {
                return false;
            }
        } else {
            if (_.isEmpty(touched)) {
                return true;
            } else {
                if (button.disabled) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    };

    const onHandleSubmit = (values) => {
        setConfirmModalOpen(true);
        setPageDataValues(values);
    };

    const updateConfirmed = async (location, pathnames, pageDataValues) => {
        const { status, image, ...dataExcludeImgStatus } = pageDataValues;
        let inputData;
        let { passport, nric, ...dataExcludeIdentification } = dataExcludeImgStatus;
        if (dataExcludeImgStatus.typeOfId && dataExcludeImgStatus.typeOfId.toLowerCase() === "ic no.") {
            inputData = {
                ...dataExcludeIdentification,
                nricOrPassport: nric,
            };
        } else if (dataExcludeImgStatus.typeOfId === "passport") {
            inputData = {
                ...dataExcludeIdentification,
                nricOrPassport: passport,
            };
        }
        if (location.pathname === pathnames.pageNewEmployee) {
            setButton({
                label: "Creating",
                disabled: true,
            });
            setPageContentState({
                ...pageContentState,
                modalConfirmDetails: "Creating...",
            });
            try {
                const formData = new FormData();
                const dataBlob = new Blob([JSON.stringify(inputData)], { type: "application/json" });
                let newImage = image;
                if (newImage === "") {
                    newImage = new Blob([""]);
                }
                formData.append("data", dataBlob);
                formData.append("image", newImage);
                await api.post.createNewEmployee(formData);

                appToast("Employee has been created successfully.", true, 300);
                history.push(pathnames.pageUserManagement);
            } catch (error) {
                const sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);

                setPageContentState({
                    ...pageContentState,
                    modalConfirmDetails: "Confirm to create?",
                });
                setButton({
                    label: "Create",
                    disabled: false,
                });
            }
        } else {
            setButton({
                label: "Saving",
                disabled: true,
            });
            setPageContentState({
                ...pageContentState,
                modalConfirmDetails: "Saving..",
            });
            try {
                inputData = {
                    ...inputData,
                    adminId: pageContentState.staffRole.toLowerCase() === "employee" ? staffId : null,
                };
                const formData = new FormData();
                const dataBlob = new Blob([JSON.stringify(inputData)], { type: "application/json" });
                // let imageBlob;
                let newImage;
                if (typeof image === "object") {
                    // upload new image
                    // imageBlob = new Blob([image]);
                    newImage = image;
                } else {
                    // remain old image
                    newImage = new Blob([""]);
                }
                if (newImage === null) {
                    newImage = new Blob([""]);
                }
                formData.append("data", dataBlob);
                formData.append("image", newImage);
                const response = await api.post.updateStaffDetails(formData);
                const result = response.data.result;
                if (result.staffID === profile.staffID) {
                    dispatch(
                        updateProfile({
                            ...profile,
                            staffName: result.staffName,
                            staffUserName: result.staffUserName,
                        })
                    );
                }
                appToast("Employee details has been updated successfully.", true, 300);
                history.push(pathnames.pageUserManagement);
            } catch (error) {
                const sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);

                setPageContentState({
                    ...pageContentState,
                    modalConfirmDetails: "Confirm to save?",
                });
                setButton({
                    label: "Save",
                    disabled: false,
                });
            }
        }
    };

    const dateValidation = (name, values, value) => {
        if (value) {
            if (value === "Invalid date") {
                setDateErrors({
                    ...dateErrors,
                    [name]: "Invalid date",
                });
            } else {
                if (name === "joinedDate") {
                    const joinedDateMoment = moment(value, "DD/MM/YYYY");
                    const probationDateMoment = moment(values.probationEnd, "DD/MM/YYYY");
                    const lastEmpDateMoment = moment(values.lastEmpDate, "DD/MM/YYYY");
                    if (moment(joinedDateMoment).isAfter(lastEmpDateMoment)) {
                        setDateErrors({
                            ...dateErrors,
                            joinedDate: "Date is required to be before last employment date",
                        });
                        return;
                    } else if (moment(joinedDateMoment).isAfter(probationDateMoment)) {
                        setDateErrors({
                            ...dateErrors,
                            joinedDate: "Date is required to be before probation date",
                        });
                        return;
                    } else {
                        setDateErrors({
                            ...dateErrors,
                            joinedDate: "",
                        });
                        return;
                    }
                }
                if (name === "probationEnd") {
                    const probationDateMoment = moment(value, "DD/MM/YYYY");
                    const joinedDateMoment = moment(values.joinedDate, "DD/MM/YYYY");
                    if (moment(probationDateMoment).isBefore(joinedDateMoment)) {
                        setDateErrors({
                            ...dateErrors,
                            probationEnd: "Date is required to be after joined date",
                        });
                    } else {
                        setDateErrors({
                            ...dateErrors,
                            probationEnd: "",
                        });
                    }
                }
            }
        } else {
            setDateErrors({
                ...dateErrors,
                [name]: "Date is required",
            });
        }
        if (value === "Invalid date") {
            setDateErrors({
                ...dateErrors,
                [name]: "Invalid date",
            });
        } else {
            if (name === "promotionDate") {
                const promotionDateMoment = moment(value, "DD/MM/YYYY");
                const joinedDateMoment = moment(values.joinedDate, "DD/MM/YYYY");
                const lastEmpDateMoment = moment(values.lastEmpDate, "DD/MM/YYYY");
                if (moment(promotionDateMoment).isBefore(joinedDateMoment)) {
                    setDateErrors({
                        ...dateErrors,
                        promotionDate: "Date is required to be after joined date",
                    });
                    return;
                } else if (moment(promotionDateMoment).isAfter(lastEmpDateMoment)) {
                    setDateErrors({
                        ...dateErrors,
                        promotionDate: "Date is required to be before last employment date",
                    });
                    return;
                } else {
                    setDateErrors({
                        ...dateErrors,
                        promotionDate: "",
                    });
                    return;
                }
            }
            if (name === "latestCoachSession") {
                const latestCoachSessionMoment = moment(value, "DD/MM/YYYY");
                const joinedDateMoment = moment(values.joinedDate, "DD/MM/YYYY");
                const lastEmpDateMoment = moment(values.lastEmpDate, "DD/MM/YYYY");
                if (moment(latestCoachSessionMoment).isBefore(joinedDateMoment)) {
                    setDateErrors({
                        ...dateErrors,
                        latestCoachSession: "Date is required to be after joined date",
                    });
                    return;
                } else if (moment(latestCoachSessionMoment).isAfter(lastEmpDateMoment)) {
                    setDateErrors({
                        ...dateErrors,
                        latestCoachSession: "Date is required to be before last employment date",
                    });
                    return;
                } else {
                    setDateErrors({
                        ...dateErrors,
                        latestCoachSession: "",
                    });
                    return;
                }
            }
            if (name === "lastEmpDate") {
                const lastEmpDateMoment = moment(value, "DD/MM/YYYY");
                const joinedDateMoment = moment(values.joinedDate, "DD/MM/YYYY");
                if (moment(lastEmpDateMoment).isBefore(joinedDateMoment)) {
                    setDateErrors({
                        ...dateErrors,
                        lastEmpDate: "Date is required to be after joined date",
                    });
                } else {
                    setDateErrors({
                        ...dateErrors,
                        lastEmpDate: "",
                    });
                }
            }
        }
    };

    const setEmployeeStatus = (setFieldValue, values, lastEmpDate, probationEnd, joinedDate, status) => {
        const lastEmpDateMoment = moment(lastEmpDate, "DD/MM/YYYY");
        if (status === "Permanent" || status === "permanent") {
            if (moment(lastEmpDateMoment).isBefore(currentDate)) {
                setPageContentState({
                    ...pageContentState,
                    status: "Resigned",
                });
                setFieldValue("status", "Resigned");
                setFieldValue("contractType", "Resigned");
                return;
            }
        } else {
            if (moment(lastEmpDateMoment).isBefore(currentDate)) {
                setPageContentState({
                    ...pageContentState,
                    status: "Resigned",
                });
                setFieldValue("status", "Resigned");
                setFieldValue("contractType", "Resigned");
                return;
            } else {
                if (moment(lastEmpDateMoment).isAfter(currentDate)) {
                    if (values.contractType === "Resigned") {
                        setFieldValue("contractType", "");
                    }
                }
                setPageContentState({
                    ...pageContentState,
                    status: "Probation",
                });
                return;
            }
        }
    };

    const adminCheckbox = (setFieldTouched, setFieldValue, values) => {
        setFieldTouched("role");
        if (values.role.toLowerCase() === "employee") {
            setFieldValue("role", "admin owner");
        } else if (values.role.toLowerCase() === "admin") {
            setFieldValue("role", "");
        } else if (values.role.toLowerCase() === "admin owner") {
            setFieldValue("role", "employee");
        } else {
            setFieldValue("role", "admin");
        }
    };

    const employeeCheckbox = (setFieldTouched, setFieldValue, values) => {
        setFieldTouched("role");
        if (values.role.toLowerCase() === "admin") {
            setFieldValue("role", "admin owner");
        } else if (values.role.toLowerCase() === "employee") {
            setFieldValue("role", "");
        } else if (values.role.toLowerCase() === "admin owner") {
            setFieldValue("role", "admin");
        } else {
            setFieldValue("role", "employee");
        }
    };

    const statusColorChange = (status) => {
        if (status) {
            if (status === "permanent") {
                return "blue";
            } else if (status === "resigned" || status === "dismissal" || status === "termination") {
                return "red";
            } else return "lightBlue";
        }
    };

    if (!_.isEmpty(pageContentState) && pageContentState.breadcrumbLabel && pageContentState.initialValues && pageContentState.validationSchema && !_.isEmpty(button)) {
        return (
            <Formik initialValues={pageContentState.initialValues} onSubmit={onHandleSubmit} validationSchema={pageContentState.validationSchema}>
                {({ handleSubmit, values, setFieldValue, errors, touched, setFieldTouched }) => {
                    return (
                        <AppPagesLayout>
                            <div className="page-employee">
                                <AppModalConfirmation
                                    isOpenModal={confirmModalOpen}
                                    onRequestClose={() => {
                                        if (!button.disabled) {
                                            setConfirmModalOpen(false);
                                        }
                                    }}
                                    details={pageContentState.modalConfirmDetails}
                                    onClick={() => updateConfirmed(location, pathnames, pageDataValues)}
                                    cancelDisabled={button.disabled}
                                    buttonDisabled={button.disabled}
                                    buttonLabel={button.label}
                                />
                                <div className="page-employee__breadcrumb">
                                    <AppBreadcrumb list={breadcrumb} />
                                </div>
                                <div className="page-employee__content">
                                    <div className="page-employee__left">
                                        <div className="page-employee__left-content">
                                            <div className="page-employee__image-upload-wrapper">
                                                <AppImageUpload
                                                    onChange={(value) => {
                                                        setFieldTouched("image");
                                                        setFieldValue("image", value[0]);
                                                    }}
                                                    value={values.image}
                                                />
                                            </div>
                                            <div className="page-employee__title">Employment Information</div>
                                            {pageContentState.employeeInformationFields.map((item, index) => {
                                                if (item.type === "date") {
                                                    const errorMessage = (touched, validationSchemaErrors, manualErrorChecking) => {
                                                        if (touched) {
                                                            if (validationSchemaErrors) {
                                                                return validationSchemaErrors;
                                                            } else {
                                                                if (manualErrorChecking) {
                                                                    return manualErrorChecking;
                                                                } else {
                                                                    return null;
                                                                }
                                                            }
                                                        } else {
                                                            return null;
                                                        }
                                                    };
                                                    return (
                                                        <div className="page-employee__input-wrapper" key={index}>
                                                            <AppInputDate
                                                                placeholder={item.placeholder}
                                                                onChange={(value) => {
                                                                    setFieldTouched(item.name);
                                                                    dateValidation(item.name, values, value);
                                                                    if (value === "") {
                                                                        if (item.name === "lastEmpDate") {
                                                                            setFieldValue("staffStatus", "");
                                                                        }
                                                                        setFieldValue(item.name, null);
                                                                        if (values.contractType === "Resigned") {
                                                                            setFieldValue("contractType", "");
                                                                        }
                                                                    } else {
                                                                        setFieldValue(item.name, value, true);
                                                                        if (item.name === "probationEnd") {
                                                                            setEmployeeStatus(setFieldValue, values, values.lastEmpDate, value, values.joinedDate, values.status);
                                                                        } else if (item.name === "lastEmpDate") {
                                                                            setEmployeeStatus(setFieldValue, values, value, values.probationEnd, values.joinedDate, values.status);
                                                                            return;
                                                                        } else if (item.name === "joinedDate") {
                                                                            setEmployeeStatus(setFieldValue, values, value.lastEmpDate, values.probationEnd, value, values.status);
                                                                        } else {
                                                                            return;
                                                                        }
                                                                    }
                                                                }}
                                                                maxDate={item.name === "latestCoachSession" || item.name === "promotionDate" ? "today" : null}
                                                                error={errorMessage(touched[item.name], errors[item.name], dateErrors[item.name])}
                                                                value={values[item.name]}
                                                            />
                                                        </div>
                                                    );
                                                } else if (item.type === "dropdown") {
                                                    if (item.name === "position" && dropdownOptionsBE.position.length) {
                                                        return (
                                                            <div className="page-employee__input-wrapper" key={index}>
                                                                <AppDropdown
                                                                    placeholder={item.placeholder}
                                                                    onChange={(selected) => {
                                                                        setFieldTouched(item.name);
                                                                        setFieldValue(item.name, selected.label);
                                                                    }}
                                                                    dropdownOptions={dropdownOptionsBE.position}
                                                                    error={touched[item.name] && errors[item.name]}
                                                                    value={values[item.name]}
                                                                />
                                                            </div>
                                                        );
                                                    } else if (item.name === "contractType") {
                                                        let contractTypeDropdownOptions;
                                                        if (moment(moment(values.lastEmpDate, "DD/MM/YYYY")).isBefore(currentDate)) {
                                                            contractTypeDropdownOptions = [{ label: "Resigned", id: 4 }];
                                                        } else {
                                                            contractTypeDropdownOptions = [
                                                                { label: "Full-Time Employee", id: 0 },
                                                                { label: "Part-Time Employee", id: 1 },
                                                                { label: "Contract Staff", id: 2 },
                                                                { label: "Intern", id: 3 },
                                                            ];
                                                        }
                                                        let currentContractType;
                                                        if (values.contractType) {
                                                            currentContractType = contractTypeDropdownOptions.filter((ele) => ele.label === values.contractType)[0];
                                                        } else {
                                                            currentContractType = null;
                                                        }
                                                        return (
                                                            <div className="page-employee__input-wrapper" key={index}>
                                                                <AppDropdown
                                                                    placeholder={item.placeholder}
                                                                    onChange={(selected) => {
                                                                        setFieldTouched(item.name);
                                                                        setFieldValue(item.name, selected.label);
                                                                        if (selected.label === "Resigned") {
                                                                            setFieldValue("lastEmpDate", currentDateAfterFormat);
                                                                        }
                                                                    }}
                                                                    dropdownOptions={contractTypeDropdownOptions}
                                                                    error={touched[item.name] && errors[item.name]}
                                                                    value={values[item.name]}
                                                                    currentInputValue={currentContractType}
                                                                    isDisabled={moment(moment(values.lastEmpDate, "DD/MM/YYYY")).isBefore(currentDate) ? true : false}
                                                                />
                                                            </div>
                                                        );
                                                    } else if (item.name === "reportingManager" && dropdownOptionsBE.reportingManager.length) {
                                                        return (
                                                            <div className="page-employee__input-wrapper" key={index}>
                                                                <AppDropdown
                                                                    placeholder={item.placeholder}
                                                                    onChange={(selected) => {
                                                                        setFieldTouched(item.name);
                                                                        setFieldValue(item.name, selected && selected.userName ? selected.userName : "");
                                                                    }}
                                                                    dropdownOptions={dropdownOptionsBE.reportingManager}
                                                                    error={touched[item.name] && errors[item.name]}
                                                                    value={values[item.name]}
                                                                    currentInputValue={dropdownOptionsBE.reportingManager.filter((ele) => ele.userName === values[item.name])[0]}
                                                                    isClearable={true}
                                                                />
                                                            </div>
                                                        );
                                                    } else if (item.name === "staffStatus" && values.lastEmpDate) {
                                                        return (
                                                            <div className="page-employee__input-wrapper" key={index}>
                                                                <AppDropdown
                                                                    placeholder={item.placeholder}
                                                                    onChange={(selected) => {
                                                                        setFieldTouched(item.name);
                                                                        setFieldValue(item.name, selected.label);
                                                                    }}
                                                                    dropdownOptions={employmentEndStatusOptions}
                                                                    currentInputValue={employmentEndStatusOptions.filter((ele) => ele.label === values[item.name])[0]}
                                                                    error={touched[item.name] && errors[item.name]}
                                                                    value={employmentEndStatusOptions.filter((ele) => ele.label === values[item.name]).length ? employmentEndStatusOptions.filter((ele) => ele.label === values[item.name])[0].label : ""}
                                                                />
                                                            </div>
                                                        );
                                                    } else return null;
                                                } else {
                                                    return null;
                                                }
                                            })}
                                            <div className="page-employee__access-type">
                                                <div className="page-employee__access-type-title">Access Type</div>
                                                <div className="page-employee__selection">
                                                    <div className="page-employee__checkbox-wrapper">
                                                        <AppCheckbox
                                                            error={touched.role && errors.role}
                                                            onChange={() => adminCheckbox(setFieldTouched, setFieldValue, values)}
                                                            checked={values.role.toLowerCase() === "admin" || values.role.toLowerCase() === "admin owner"}
                                                        />
                                                    </div>
                                                    <span className="page-employee__selection-label" onClick={() => adminCheckbox(setFieldTouched, setFieldValue, values)}>
                                                        Admin
                                                    </span>
                                                </div>
                                                <div className="page-employee__selection">
                                                    <div className="page-employee__checkbox-wrapper">
                                                        <AppCheckbox
                                                            error={touched.role && errors.role}
                                                            onChange={() => employeeCheckbox(setFieldTouched, setFieldValue, values)}
                                                            checked={values.role.toLowerCase() === "employee" || values.role.toLowerCase() === "admin owner"}
                                                        />
                                                    </div>
                                                    <span className="page-employee__selection-label" onClick={() => employeeCheckbox(setFieldTouched, setFieldValue, values)}>
                                                        Employee
                                                    </span>
                                                </div>
                                                {touched.role && errors.role ? <div className="page-employee__error-message">{errors.role}</div> : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="page-employee__right">
                                        <div className="page-employee__right-content">
                                            {pageContentState.status ? (
                                                <div className="page-employee__app-status-wrapper">
                                                    <AppStatus
                                                        color={statusColorChange(pageContentState.status.toLowerCase())}
                                                        label={pageContentState.status ? pageContentState.status.charAt(0).toUpperCase() + pageContentState.status.slice(1) : ""}
                                                    />
                                                </div>
                                            ) : null}
                                            <div className="page-employee__title">Personal Information</div>

                                            {pageContentState.personalInformationFields.map((item, index) => {
                                                if (item.type === "date") {
                                                    return (
                                                        <div key={index} className="page-employee__input-wrapper">
                                                            <AppInputDate
                                                                placeholder="Date of Birth"
                                                                onChange={(value) => {
                                                                    setFieldTouched(item.name);
                                                                    if (value === "") {
                                                                        setFieldValue(item.name, "");
                                                                    } else {
                                                                        setFieldValue(item.name, value);
                                                                    }
                                                                }}
                                                                maxDate="yesterday"
                                                                error={touched[item.name] && errors[item.name]}
                                                                value={values[item.name]}
                                                                disabled={item.name === "dob" && values.typeOfId === "passport" ? false : true}
                                                            />
                                                        </div>
                                                    );
                                                } else if (item.type === "dropdown") {
                                                    return (
                                                        <div key={index} className="page-employee__input-wrapper">
                                                            <AppDropdown
                                                                placeholder={item.placeholder}
                                                                onChange={(selected) => {
                                                                    setFieldTouched(item.name);
                                                                    setFieldValue(item.name, selected.value);
                                                                    setFieldValue("nric", "");
                                                                    setFieldValue("passport", "");
                                                                    setFieldValue("dob", "");
                                                                }}
                                                                dropdownOptions={typeOfIdDropdownOption}
                                                                error={touched[item.name] && !values[item.name] ? `${item.placeholder} is required` : ""}
                                                                value={
                                                                    values[item.name] && typeOfIdDropdownOption.filter((ele) => ele.value === values[item.name].toLowerCase())[0]
                                                                        ? typeOfIdDropdownOption.filter((ele) => ele.value === values[item.name].toLowerCase())[0].label
                                                                        : null
                                                                }
                                                                currentInputValue={values[item.name] ? typeOfIdDropdownOption.filter((ele) => ele.value === values[item.name].toLowerCase())[0] : null}
                                                            />
                                                        </div>
                                                    );
                                                } else if (item.type === "ic") {
                                                    if (values.typeOfId.toLowerCase() === "ic no.") {
                                                        return (
                                                            <div key={index} className="page-employee__input-wrapper">
                                                                <AppInputWithLabel
                                                                    placeholder={item.placeholder}
                                                                    onChange={(e) => {
                                                                        setFieldTouched(item.name);
                                                                        setFieldValue(item.name, e.target.value);
                                                                    }}
                                                                    error={touched[item.name] && errors[item.name]}
                                                                    value={values[item.name] ? values[item.name] : ""}
                                                                    onBlur={() => {
                                                                        let removedDashValue = values.nric.split("-").join("");
                                                                        if (removedDashValue.length >= 12) {
                                                                            let withDashesValue = removedDashValue.substring(0, 6) + "-" + removedDashValue.substring(6, 8) + "-" + removedDashValue.substring(8);
                                                                            setFieldValue(item.name, withDashesValue);
                                                                            let year;
                                                                            let currentYear = currentDate.format("YY");
                                                                            if (parseInt(withDashesValue.substring(0, 2)) < parseInt(currentYear)) {
                                                                                year = `20${withDashesValue.substring(0, 2)}`;
                                                                            } else {
                                                                                year = `19${withDashesValue.substring(0, 2)}`;
                                                                            }
                                                                            let date = `${withDashesValue.substring(4, 6)}/${withDashesValue.substring(2, 4)}/${year}`;
                                                                            setFieldValue("dob", date);
                                                                        } else {
                                                                            setFieldValue("dob", "");
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        );
                                                    } else return null;
                                                } else if (item.type === "mobile") {
                                                    return (
                                                        <div key={index} className="page-employee__input-wrapper">
                                                            <AppInputWithLabel
                                                                placeholder={item.placeholder}
                                                                onChange={(e) => {
                                                                    setFieldTouched(item.name);
                                                                    setFieldValue(item.name, e.target.value);
                                                                }}
                                                                error={touched[item.name] && errors[item.name]}
                                                                value={values[item.name]}
                                                                onBlur={() => {
                                                                    let removedDashValue = values.phoneNumber.split("-").join("");
                                                                    if (removedDashValue >= 10) {
                                                                        let withDashValue = removedDashValue.substring(0, 3) + "-" + removedDashValue.substring(3);
                                                                        setFieldValue(item.name, withDashValue);
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    );
                                                } else if (item.type === "textarea") {
                                                    return (
                                                        <div key={index} className="page-employee__input-wrapper">
                                                            <AppTextArea
                                                                placeholder={item.placeholder}
                                                                onChange={(e) => {
                                                                    setFieldTouched(item.name);
                                                                    setFieldValue(item.name, e.target.value);
                                                                }}
                                                                error={touched[item.name] && errors[item.name]}
                                                                value={values[item.name]}
                                                            />
                                                        </div>
                                                    );
                                                } else if (item.type === "staffId") {
                                                    return (
                                                        <div key={index} className="page-employee__input-wrapper">
                                                            <AppInputWithLabel
                                                                onKeyPress={(event) => {
                                                                    if (!/[0-9]/.test(event.key) || values[item.name].length >= 3) {
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                                placeholder={item.placeholder}
                                                                onChange={(e) => {
                                                                    setFieldTouched(item.name);
                                                                    setFieldValue(item.name, e.target.value);
                                                                }}
                                                                error={touched[item.name] && errors[item.name]}
                                                                value={values[item.name]}
                                                                disabled={item.disabled}
                                                            />
                                                        </div>
                                                    );
                                                } else if (item.name === "name" || item.name === "userName") {
                                                    return (
                                                        <div key={index} className="page-employee__input-wrapper">
                                                            <AppInputWithLabel
                                                                placeholder={item.placeholder}
                                                                onChange={(e) => {
                                                                    setFieldTouched(item.name);
                                                                    setFieldValue(
                                                                        item.name,
                                                                        e.target.value
                                                                            .toLowerCase()
                                                                            .split(" ")
                                                                            .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
                                                                            .join(" ")
                                                                    );
                                                                }}
                                                                error={touched[item.name] && errors[item.name]}
                                                                value={values[item.name]}
                                                                disabled={item.disabled}
                                                            />
                                                        </div>
                                                    );
                                                } else {
                                                    if (item.name === "passport" && values.typeOfId !== "passport") {
                                                        return null;
                                                    } else {
                                                        return (
                                                            <div key={index} className="page-employee__input-wrapper">
                                                                <AppInputWithLabel
                                                                    placeholder={item.placeholder}
                                                                    onChange={(e) => {
                                                                        setFieldTouched(item.name);
                                                                        setFieldValue(item.name, e.target.value);
                                                                    }}
                                                                    error={touched[item.name] && errors[item.name]}
                                                                    value={values[item.name]}
                                                                    disabled={item.disabled}
                                                                />
                                                            </div>
                                                        );
                                                    }
                                                }
                                            })}

                                            <div className="page-employee__button-row">
                                                <div className="page-employee__button-wrapper">
                                                    <AppButton label="Cancel" buttonType="outline" size="l" onClick={() => history.push(pathnames.pageUserManagement)} />
                                                </div>
                                                <div className="page-employee__button-wrapper">
                                                    <AppButton disabled={buttonDisable(touched, button)} onClick={handleSubmit} label={button.label} size="l" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </AppPagesLayout>
                    );
                }}
            </Formik>
        );
    } else {
        return null;
    }
};

export default PageEmployee;
