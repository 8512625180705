import { toast, Slide } from "react-toastify";

const toastConfig = {
    transition: Slide,
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: false,
    draggable: false,
    pauseOnHover: true,
};

let toastId = null;

const appToast = (message, condition, delay) => {
    if (delay) {
        setTimeout(() => {
             toastId = toast(message, { ...toastConfig, className: condition ? "app-toast__success" : "app-toast__fail" });
        }, [delay]);
    } else {
        toastId = toast(message, { ...toastConfig, className: condition ? "app-toast__success" : "app-toast__fail" });
    };

};

export default appToast;


export const appToastDismiss = () => {
    if (toastId) {
        toast.dismiss();
        toastId = null;
    }
}