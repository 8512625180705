// libraries
import React from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

const getAppDropdownMenuClassNames = (tableDottedButton) => {
    const classNames = ["app-dropdown-mini__dropdown-menu"];
    if (tableDottedButton) classNames.push("app-dropdown-mini__dropdown-menu-positioning");

    return classNames.join(" ");
};

const AppDropdownMini = ({ label, toggle, isOpen, dropdownItem, tableDottedButton, outContentsSelected }) => {
    return (
        <Dropdown className="app-dropdown-mini__dropdown" toggle={toggle} isOpen={isOpen}>
            <DropdownToggle className="app-dropdown-mini__dropdown-toggle">{label}</DropdownToggle>
            <DropdownMenu container="body" className={getAppDropdownMenuClassNames(tableDottedButton)}>
                {dropdownItem.map((item, index) => (
                    <DropdownItem key={index} className="app-dropdown-mini__dropdown-item" onClick={item.onClick ? () => item.onClick(outContentsSelected ? outContentsSelected : null) : null}>
                        {item.item}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
};

export default AppDropdownMini;
