import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    staffID: "",
    staffName: "",
    staffUserName: "",
    staffRole: "",
    extra: "",
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        updateProfile: (state, action) => {
            return {
                ...state,
                ...action.payload,
            }
        },
        resetProfile: () => initialState
    }
})

export const { updateProfile, resetProfile } = authSlice.actions

export default authSlice.reducer;