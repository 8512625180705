// libraries
import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";
// components
import AppPagesLayout from "components/app-pages-layout";
import AppBreadcrumb from "components/app-breadcrumb";
import AppTabLink from "components/app-tab-link";
import AppInput from "components/app-input";
import AppInputWithLabel from "components/app-input-with-label";
import AppDropdown from "components/app-dropdown";
import AppInputDate from "components/app-input-date";
import AppButton from "components/app-button";
import AppReactTable from "components/app-react-table";
import AppPaginate from "components/app-paginate";
import AppDropdownMini from "components/app-dropdown-mini";
import appToast from "components/app-toast";
import AppModal from "components/app-modal";
import AppTextArea from "components/app-text-area";
import AppModalConfirmation from "components/app-modal-confirmation";
import AppDualColumnsModal from "components/app-dual-columns-modal";
import AppAttachmentImage from "components/app-attachment-image";
// common
import { sanitizeError } from "common/utilities";
// service
import api from "services/api";
// hooks
import usePrevious from "hooks/use-previous";
// routes
import pathnames from "routes/pathnames";
// assets
import iconSearch from "assets/images/components/app-input/icon-search.svg";
import iconSetting from "assets/images/icon-setting.svg";
import iconTailedArrowUp from "assets/images/icon-tailed-arrow-up.svg";
import iconDots from "assets/images/icon-vertical-dots.svg";

const breadcrumb = [
    {
        label: "Claim Management",
    },
    {
        label: "Pending Approval",
    },
];

const appTabLink = [
    {
        label: "Claim Directory",
        pathname: pathnames.pageClaimDirectory,
    },
    {
        label: "Pending Approval",
        pathname: pathnames.pageClaimPendingApproval,
    },
    {
        label: "Pending Payment",
        pathname: pathnames.pageClaimPendingPayment,
    },
];

const advanceSearch = [
    {
        name: "claimType",
        placeholder: "Claim Type",
        type: "dropdown",
    },
    {
        name: "submitDate",
        placeholder: "Submit Date",
        type: "date",
    },
    {
        name: "amount",
        placeholder: "Amount (RM)",
        type: "input",
    },
];

const rejectDropdownOptions = [
    { label: "Duplicated claim", id: 1 },
    { label: "Not covered by HR Policy", id: 2 },
    { label: "Late submission", id: 3 },
    { label: "Others", id: 4 },
];

const pendingClaimDetailsInitialValues = {
    amount: "",
    approveAmount: "",
    attachment: "",
    claimType: "",
    currentBalance: "",
    desc: "",
    id: "",
    rejectReason: "",
    remark: "",
    staffId: "",
    status: "",
    submitDate: "",
    userName: "",
    fileType: "",
};

const attachmentSrcInitialState = {
    file: "",
    type: "",
};

const PageClaimPendingApproval = () => {
    const profile = useSelector((state) => state.auth);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [detailSearch, setDetailSearch] = useState({
        claimType: "",
        submitDate: "",
        amount: "",
    });
    const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
    const [tableFilter, setTableFilter] = useState({
        pageSize: 5,
        pageNo: 0,
        sortBy: "id",
        sortDir: "asc",
    });
    const [dropdownOpen, setDropdownOpen] = useState(null);
    const [tableData, setTableData] = useState({
        content: [],
        last: null,
        pageNo: 0,
        pageSize: 5,
        totalElements: null,
        totalPages: 1,
    });
    const prevValueSortHeader = usePrevious({
        sortBy: tableFilter.sortBy,
        sortDir: tableFilter.sortDir,
    });
    const [rejectValue, setRejectValue] = useState({
        reason: {
            value: "",
            error: "",
        },
        remark: {
            value: "",
        },
    });
    const [confirmModal, setConfirmModal] = useState({
        details: "",
        confirmDisable: false,
        claimStatus: "",
    });
    const [singleIdSelected, setSingleIdSelected] = useState();
    const searchKeywordInputRef = useRef(null);
    const [claimTypeDropdown, setClaimTypeDropdown] = useState([]);
    const [modalIsOpenArrangement, setModalIsOpenArrangement] = useState([]);
    const [pendingClaimDetails, setPendingClaimDetails] = useState(pendingClaimDetailsInitialValues);
    const [claimDetailsApprovedAmount, setClaimDetailsApprovedAmount] = useState({
        value: null,
        error: "",
    });
    const [attachmentSrc, setAttachmentSrc] = useState(attachmentSrcInitialState);
    const [keyInApprovedAmount, setKeyInApproveAmount] = useState("");

    const tableDataDropdownOptions = [
        {
            item: "Approve",
            onClick: (item) => {
                setSingleIdSelected(item);
                setModalIsOpenArrangement(["APPROVE_CONFIRMATION"]);
                setConfirmModal({
                    details: "Confirm to approve?",
                    claimStatus: "approve",
                });
            },
        },
        {
            item: "Reject",
            onClick: (item) => {
                setModalIsOpenArrangement(["REJECT_MODAL"]);
                setSingleIdSelected(item);
            },
        },
    ];

    const getDefaultClaimType = async () => {
        try {
            const response = await api.get.claimManagementClaimType();
            const claimTypeArray = response.data.result.map((e, i) => {
                return {
                    label: e,
                    value: i + 1,
                };
            });
            setClaimTypeDropdown(claimTypeArray);
        } catch (error) {
            let sanitizedError = sanitizeError(error);
            appToast(sanitizedError, false);
        }
    };

    const getTableData = async (payload) => {
        try {
            const response = await api.get.claimManagementClaimApproval(payload ? payload : null);
            setTableData(response.data.result);
        } catch (error) {
            let sanitizedError = sanitizeError(error);
            appToast(sanitizedError, false);
        }
    };

    useEffect(() => {
        getDefaultClaimType();
        getTableData();
    }, []);

    const sortSearchApply = async (tableFilterPayload) => {
        let payload = {
            searchValue: searchKeyword,
            ...tableFilterPayload,
            ...detailSearch,
        };
        getTableData(payload);
    };

    const searchKeywordTyped = async (value) => {
        setSearchKeyword(value);
        setTableFilter({
            pageNo: 0,
            pageSize: 5,
            sortBy: "",
            sortDir: "asc",
        });
        let payload = {
            searchValue: value,
            pageNo: 0,
            pageSize: 5,
            sortBy: "",
            sortDir: "asc",
        };
        getTableData(payload);
    };

    const applySearchKeywordSearch = async () => {
        let payload = {
            searchValue: "",
            ...tableFilter,
        };
        getTableData(payload);
    };

    const searchBarOnChange = (e) => {
        searchKeywordTyped(e.target.value);
    };

    const handleKeypress = (e) => {
        if (e.code === "Enter") {
            searchKeywordTyped(e.target.value);
        }
    };

    const applyAdvanceSearch = async () => {
        let payload = {
            ...detailSearch,
            ...tableFilter,
        };
        getTableData(payload);
    };

    const applyResetSearch = async () => {
        let payload = {
            ...tableFilter,
        };
        getTableData(payload);
    };

    const headerArrowRotate = (active) => {
        const classNames = ["react-table__arrow-icon"];
        if (active && tableFilter.sortDir === "desc") classNames.push("react-table__arrow-icon-down");
        if (active) classNames.push("react-table__arrow-icon--active");

        return classNames.join(" ");
    };

    const headerFilterClick = (header) => {
        let tableFilterPayload;
        let column = header.column;
        if (prevValueSortHeader && prevValueSortHeader.sortBy === column.searchFilterValue) {
            if (prevValueSortHeader.sortDir === "asc") {
                tableFilterPayload = {
                    ...tableFilter,
                    sortBy: column.searchFilterValue,
                    sortDir: "desc",
                    pageNo: 0,
                };
                setTableFilter(tableFilterPayload);
                sortSearchApply(tableFilterPayload);
            } else {
                tableFilterPayload = {
                    ...tableFilter,
                    sortBy: column.searchFilterValue,
                    sortDir: "asc",
                    pageNo: 0,
                };
                setTableFilter(tableFilterPayload);
                sortSearchApply(tableFilterPayload);
            }
        } else {
            tableFilterPayload = {
                ...tableFilter,
                sortBy: column.searchFilterValue,
                sortDir: "asc",
                pageNo: 0,
            };
            setTableFilter(tableFilterPayload);
            sortSearchApply(tableFilterPayload);
        }
    };

    const rejectReasonConfirmed = () => {
        if (!rejectValue.reason.value) {
            setRejectValue({
                ...rejectValue,
                reason: {
                    ...rejectValue.reason,
                    error: "Reason is required",
                },
            });
        } else {
            setModalIsOpenArrangement([...modalIsOpenArrangement, "REJECT_CONFIRMATION"]);
            setConfirmModal({
                ...confirmModal,
                details: "Confirm to reject?",
                claimStatus: "reject",
            });
        }
    };

    const onPageChange = (event) => {
        let tableFilterPayload = {
            ...tableFilter,
            pageNo: event.selected,
        };
        setTableFilter(tableFilterPayload);
        let payload = {
            searchValue: searchKeyword,
            ...tableFilterPayload,
            ...detailSearch,
        };
        getTableData(payload);
    };

    const confirmationModalConfirmed = async () => {
        setConfirmModal({
            ...confirmModal,
            confirmDisable: true,
        });
        if (confirmModal.claimStatus === "approve") {
            try {
                setModalIsOpenArrangement([]);
                const response = await api.get.claimManagementPendingClaimDetails(singleIdSelected);
                let billAmount = response.data.result.amount;
                let balanceAmount = response.data.result.currentBalance;
                try {
                    let payload = {
                        adminStaffId: profile.staffID,
                        approvedAmount: Math.min(billAmount, balanceAmount),
                        claimId: singleIdSelected,
                    };
                    await api.get.claimManagementApproveClaim(payload);
                    setModalIsOpenArrangement([]);
                    setConfirmModal({
                        details: "",
                        confirmDisable: false,
                        claimStatus: "",
                    });
                    appToast("Claim has been approved successfully.", true);
                    getTableData();
                } catch (error) {
                    let sanitizedError = sanitizeError(error);
                    appToast(sanitizedError, false);
                    setConfirmModal({
                        ...confirmModal,
                        confirmDisable: false,
                    });
                }
            } catch (error) {
                appToast(sanitizeError(error), false);
            }
        } else if (confirmModal.claimStatus === "modal_approve") {
            try {
                let payload = {
                    adminStaffId: profile.staffID,
                    approvedAmount: claimDetailsApprovedAmount.value,
                    claimId: singleIdSelected,
                };
                await api.get.claimManagementApproveClaim(payload);
                setModalIsOpenArrangement([]);
                setConfirmModal({
                    details: "",
                    confirmDisable: false,
                    claimStatus: "",
                });
                appToast("Claim has been approved successfully.", true);
                getTableData();
            } catch (error) {
                let sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
                setConfirmModal({
                    ...confirmModal,
                    confirmDisable: false,
                });
            }
        } else if (confirmModal.claimStatus === "reject") {
            try {
                const payload = {
                    adminStaffId: profile.staffID,
                    claimId: singleIdSelected,
                    rejectReason: rejectValue.reason.value,
                    remarks: rejectValue.remark.value,
                };
                await api.get.claimManagementRejectClaim(payload);
                setRejectValue({
                    reason: {
                        value: "",
                        error: "",
                    },
                    remark: {
                        value: "",
                    },
                });
                setModalIsOpenArrangement([]);
                setConfirmModal({
                    details: "",
                    confirmDisable: false,
                    claimStatus: "",
                });
                appToast("Claim has been rejected successfully.", true);
                getTableData();
            } catch (error) {
                let sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
                setConfirmModal({
                    ...confirmModal,
                    confirmDisable: false,
                });
            }
        }
    };

    const tableColumn = [
        {
            id: "staffUserName",
            Header: () => {
                return (
                    <span className="react-table__userName-header">
                        Username
                    </span>
                );
            },
            Cell: (row) => {
                return <div className="react-table__userName-data">{row.row.original.staffUserName}</div>;
            },
        },
        {
            id: "claimType",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Claim Type
                        <img className={headerArrowRotate(tableFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            accessor: "claimType",
            maxWidth: 80,
            searchFilterValue: "claim_type",
        },
        {
            id: "submitDate",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Submit Date
                        <img className={headerArrowRotate(tableFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            Cell: (row) => {
                return <span>{moment(row.row.original.submitDate).format("DD/MM/YYYY")}</span>;
            },
            maxWidth: 120,
            searchFilterValue: "submit_date",
        },
        {
            id: "claim_amount",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Amount (RM)
                        <img className={headerArrowRotate(tableFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            accessor: "amount",
            Cell: (row) => {
                return (
                    <div className="react-table__amount-row">
                        <span>{row.row.original.amount.toFixed(2)}</span>
                    </div>
                );
            },
            searchFilterValue: "claim_amount",
            maxWidth: 120,
        },
        {
            id: "button",
            Cell: (row) => {
                return (
                    <div onClick={(e) => e.stopPropagation()}>
                        <AppDropdownMini
                            page="page-claim-pending-approval"
                            label={<img className="react-table__edit-icon" src={iconDots} alt="" />}
                            toggle={() => {
                                if (dropdownOpen) {
                                    setDropdownOpen(null);
                                } else {
                                    setDropdownOpen(row.row.original.id);
                                }
                            }}
                            isOpen={row.row.original.id === dropdownOpen}
                            dropdownItem={tableDataDropdownOptions}
                            outContentsSelected={dropdownOpen}
                            tableDottedButton={true}
                        />
                    </div>
                );
            },
            maxWidth: 25,
        },
    ];

    const claimDetailModalApprove = () => {
        setModalIsOpenArrangement([...modalIsOpenArrangement, "APPROVE_CONFIRMATION"]);
        setConfirmModal({
            ...confirmModal,
            details: "Confirm to approve?",
            claimStatus: "modal_approve",
        });
    };

    const tableRowClicked = async (row) => {
        try {
            const response = await api.get.claimManagementPendingClaimDetails(row.id);
            let result = response.data.result;
            const fileSplitted = result.attachment.split(".");
            const fileType = fileSplitted[fileSplitted.length - 1];
            setPendingClaimDetails({ ...result, fileType: fileType });
            setSingleIdSelected(row.id);
            setClaimDetailsApprovedAmount({
                ...claimDetailsApprovedAmount,
                value: Math.min(result.amount, result.currentBalance),
            });
            setKeyInApproveAmount(Math.min(result.amount, result.currentBalance).toString());
            setModalIsOpenArrangement(["MODAL_CLAIM_DETAILS"]);
        } catch (error) {
            appToast(sanitizeError(error), false);
        }
    };

    return (
        <AppPagesLayout>
            <div className="page-claim-pending-approval">
                <AppDualColumnsModal
                    title="Claim Details"
                    isOpenModal={modalIsOpenArrangement.slice(-1)[0] === "MODAL_CLAIM_DETAILS"}
                    onRequestClose={() => {
                        setClaimDetailsApprovedAmount({
                            value: "",
                            error: "",
                        });
                        setModalIsOpenArrangement(modalIsOpenArrangement.slice(0, -1));
                        setPendingClaimDetails(pendingClaimDetailsInitialValues);
                    }}
                    status="Pending"
                    statusColor="orange"
                    childrenLeft={
                        <div>
                            <div className="page-pending-claim__modal-input-wrapper">
                                <AppInputWithLabel placeholder="Username" value={pendingClaimDetails.userName} disabled={true} />
                            </div>
                            <div className="page-pending-claim__modal-input-wrapper">
                                <AppInputWithLabel placeholder="Claim Type" value={pendingClaimDetails.claimType} disabled={true} />
                            </div>
                            {pendingClaimDetails.attachment ? (
                                <div
                                    className="page-pending-claim__modal-input-wrapper page-pending-claim__modal-input-wrapper--hover"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        setAttachmentSrc({
                                            file: pendingClaimDetails.attachment,
                                            type: pendingClaimDetails.fileType,
                                        });
                                        setModalIsOpenArrangement([...modalIsOpenArrangement, "MODAL_ATTACHMENT"]);
                                    }}>
                                    <span className="page-pending-claim__modal-icon-wrapper">
                                        <AppAttachmentImage fileType={pendingClaimDetails.fileType} />
                                    </span>
                                    <AppInputWithLabel
                                        placeholder="Attachment"
                                        value={`        ${pendingClaimDetails.attachment.split("/")[pendingClaimDetails.attachment.split("/").length - 1]}`}
                                        disabled={true}
                                        onClick={() => {
                                            setAttachmentSrc({
                                                file: pendingClaimDetails.attachment,
                                                type: pendingClaimDetails.fileType,
                                            });
                                            setModalIsOpenArrangement([...modalIsOpenArrangement, "MODAL_ATTACHMENT"]);
                                        }}
                                    />
                                </div>
                            ) : null}
                            <div className="page-pending-claim__modal-input-wrapper">
                                <AppTextArea placeholder="Description" value={pendingClaimDetails.desc ? pendingClaimDetails.desc : ""} disabled={true} />
                            </div>
                        </div>
                    }
                    childrenRight={
                        <div>
                            <div className="page-pending-claim__modal-input-wrapper">
                                <AppInputWithLabel placeholder="Employee ID" value={pendingClaimDetails.staffId} disabled={true} />
                            </div>
                            <div className="page-pending-claim__modal-input-wrapper">
                                <AppInputWithLabel placeholder="Bill Amount (RM)" value={pendingClaimDetails.amount} disabled={true} />
                            </div>
                            <div className="page-pending-claim__modal-input-wrapper">
                                <AppInputWithLabel
                                    placeholder="Approved Amount (RM)"
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key) && event.key !== ".") {
                                            event.preventDefault();
                                        }
                                    }}
                                    onChange={(e) => {
                                        setKeyInApproveAmount(e.target.value);
                                        setClaimDetailsApprovedAmount({
                                            ...claimDetailsApprovedAmount,
                                            error: "",
                                        });
                                    }}
                                    onBlur={() => {
                                        const keyInApprovedAmountFloat = parseFloat(parseFloat(keyInApprovedAmount).toFixed(2));
                                        if (!keyInApprovedAmountFloat) {
                                            setKeyInApproveAmount("0");
                                            setClaimDetailsApprovedAmount({
                                                value: 0,
                                                error: "",
                                            });
                                            return;
                                        }
                                        if (keyInApprovedAmountFloat > parseFloat(pendingClaimDetails.currentBalance) && pendingClaimDetails.claimType.toLowerCase() !== "others") {
                                            setClaimDetailsApprovedAmount({
                                                value: keyInApprovedAmountFloat,
                                                error: "Amount exceeded balance amount.",
                                            });
                                        } else if (keyInApprovedAmountFloat > parseFloat(pendingClaimDetails.amount)) {
                                            setClaimDetailsApprovedAmount({
                                                value: keyInApprovedAmountFloat,
                                                error: "Amount exceeded bill amount.",
                                            });
                                        } else {
                                            setClaimDetailsApprovedAmount({
                                                value: keyInApprovedAmountFloat,
                                                error: "",
                                            });
                                        }
                                        setKeyInApproveAmount(keyInApprovedAmountFloat.toString());
                                    }}
                                    error={claimDetailsApprovedAmount.error}
                                    value={keyInApprovedAmount}
                                />
                            </div>
                            <div className="page-pending-claim__modal-input-wrapper">
                                <AppInputWithLabel placeholder="Submit Date" value={pendingClaimDetails.submitDate} disabled={true} />
                            </div>
                        </div>
                    }
                    childrenBottom={
                        <div className="page-pending-claim__button-row">
                            <div className="page-pending-claim__button-wrapper">
                                <AppButton
                                    label="Reject"
                                    buttonType="outline"
                                    size="l"
                                    onClick={() => {
                                        setModalIsOpenArrangement([...modalIsOpenArrangement, "REJECT_MODAL"]);
                                    }}
                                />
                            </div>
                            <div className="page-pending-claim__button-wrapper">
                                <AppButton label="Approve" size="l" onClick={claimDetailModalApprove} disabled={claimDetailsApprovedAmount.error} />
                            </div>
                        </div>
                    }
                />
                <AppModal
                    isOpenModal={modalIsOpenArrangement.slice(-1)[0] === "MODAL_ATTACHMENT"}
                    onRequestClose={() => {
                        setModalIsOpenArrangement(modalIsOpenArrangement.slice(0, -1));
                        setTimeout(() => {
                            setAttachmentSrc(attachmentSrcInitialState);
                        }, [250]);
                    }}
                    emptyModal={true}>
                    {attachmentSrc.type === "pdf" ? (
                        <div className="page-pending-claim__modal-attachment">
                            <object width="100%" height="100%" data={attachmentSrc.file} type="application/pdf">
                                {" "}
                            </object>
                        </div>
                    ) : (
                        <img className="page-pending-claim__modal-image" alt="" src={attachmentSrc.file} />
                    )}
                </AppModal>
                <AppModal
                    title="Reject Reason"
                    isOpenModal={modalIsOpenArrangement.slice(-1)[0] === "REJECT_MODAL"}
                    onRequestClose={() => {
                        setRejectValue({
                            reason: {
                                value: "",
                                error: "",
                            },
                            remark: {
                                value: "",
                            },
                        });
                        setModalIsOpenArrangement(modalIsOpenArrangement.slice(0, -1));
                    }}>
                    <div className="page-claim-pending-approval__modal">
                        <div className="page-claim-pending-approval__modal-subtitle">The reject reason will apply to all selected leave.</div>
                        <div className="page-claim-pending-approval__modal-input">
                            <AppDropdown
                                placeholder="Reject Reason"
                                dropdownOptions={rejectDropdownOptions}
                                onChange={(selected) => {
                                    setRejectValue({
                                        ...rejectValue,
                                        reason: {
                                            value: selected.label,
                                            error: "",
                                        },
                                    });
                                }}
                                value={rejectValue.reason.value}
                                currentInputValue={rejectValue.reason.value ? rejectDropdownOptions.filter((ele) => ele.label === rejectValue.reason.value)[0] : null}
                                error={rejectValue.reason.error}
                            />
                        </div>
                        <div className="page-claim-pending-approval__modal-input">
                            <AppTextArea
                                placeholder="Remark (Optional)"
                                onChange={(e) => {
                                    setRejectValue({
                                        ...rejectValue,
                                        remark: {
                                            value: e.target.value,
                                        },
                                    });
                                }}
                                value={rejectValue.remark.value}
                            />
                        </div>
                        <div className="page-claim-pending-approval__modal-button-row">
                            <div className="page-claim-pending-approval__modal-button">
                                <AppButton
                                    label="Cancel"
                                    buttonType="outline"
                                    size="l"
                                    onClick={() => {
                                        setRejectValue({
                                            reason: {
                                                value: "",
                                                error: "",
                                            },
                                            remark: {
                                                value: "",
                                            },
                                        });
                                        setModalIsOpenArrangement(modalIsOpenArrangement.slice(0, -1));
                                    }}
                                />
                            </div>
                            <div className="page-claim-pending-approval__modal-button">
                                <AppButton label="Confirm" size="l" onClick={() => rejectReasonConfirmed()} />
                            </div>
                        </div>
                    </div>
                </AppModal>
                <AppModalConfirmation
                    isOpenModal={modalIsOpenArrangement.slice(-1)[0] === "REJECT_CONFIRMATION" || modalIsOpenArrangement.slice(-1)[0] === "APPROVE_CONFIRMATION"}
                    onRequestClose={() => {
                        setConfirmModal({
                            details: "",
                            confirmDisable: false,
                            claimStatus: "",
                            removeAmount: "",
                        });
                        setModalIsOpenArrangement(modalIsOpenArrangement.slice(0, -1));
                    }}
                    details={confirmModal.details}
                    onClick={confirmationModalConfirmed}
                    buttonDisabled={confirmModal.confirmDisable}
                    buttonLabel="Confirm"
                />
                <div className="page-claim-pending-approval__breadcrumb">
                    <AppBreadcrumb list={breadcrumb} />
                </div>
                <div className="page-claim-pending-approval__tab-wrapper">
                    <AppTabLink tabList={appTabLink} />
                </div>
                <div className="page-claim-pending-approval__searchBar-wrapper">
                    <AppInput
                        leftSrc={iconSearch}
                        rightSrc={iconSetting}
                        rightSrcOnClick={() => {
                            setShowAdvanceSearch(!showAdvanceSearch);
                            searchKeywordInputRef.current.value = "";
                            searchKeywordTyped("");
                        }}
                        onFocus={() => {
                            if (showAdvanceSearch) {
                                setShowAdvanceSearch(false);
                                searchKeywordInputRef.current.value = "";
                                searchKeywordTyped("");
                                applySearchKeywordSearch();
                            }
                        }}
                        ref={searchKeywordInputRef}
                        placeholder="Search keyword"
                        onChange={_.debounce((e) => searchBarOnChange(e), 1000)}
                        onKeyPress={handleKeypress}
                    />
                </div>

                {showAdvanceSearch ? (
                    <div className="page-claim-pending-approval__search-content">
                        <div className="page-claim-pending-approval__input">
                            {advanceSearch.map((item, index) => {
                                if (item.type === "input") {
                                    return (
                                        <div key={index} className="page-claim-pending-approval__input-wrapper">
                                            <AppInputWithLabel
                                                placeholder={item.placeholder}
                                                onChange={(e) =>
                                                    setDetailSearch({
                                                        ...detailSearch,
                                                        [item.name]: e.target.value,
                                                    })
                                                }
                                                value={detailSearch[item.name]}
                                            />
                                        </div>
                                    );
                                } else if (item.type === "dropdown") {
                                    return (
                                        <div key={index} className="page-claim-pending-approval__input-wrapper">
                                            <AppDropdown
                                                placeholder={item.placeholder}
                                                dropdownOptions={claimTypeDropdown}
                                                onChange={(selected) =>
                                                    setDetailSearch({
                                                        ...detailSearch,
                                                        [item.name]: selected.label,
                                                    })
                                                }
                                                currentInputValue={detailSearch[item.name] ? claimTypeDropdown.filter((e) => e.label === detailSearch[item.name])[0] : ""}
                                            />
                                        </div>
                                    );
                                } else if (item.type === "date") {
                                    return (
                                        <div key={index} className="page-claim-pending-approval__input-wrapper">
                                            <AppInputDate
                                                placeholder={item.placeholder}
                                                onChange={(value) => {
                                                    if (value === "") {
                                                        setDetailSearch({
                                                            ...detailSearch,
                                                            [item.name]: null,
                                                        });
                                                    } else {
                                                        setDetailSearch({
                                                            ...detailSearch,
                                                            [item.name]: moment(value, "DD/MM/YYYY").format("YYYY-MM-DD"),
                                                        });
                                                    }
                                                }}
                                                value={moment(detailSearch[item.name], "YYYY-MM-DD").format("DD/MM/YYYY")}
                                            />
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </div>
                        <div className="page-claim-pending-approval__button-row">
                            <div className="page-claim-pending-approval__button-wrapper">
                                <AppButton
                                    label="Reset"
                                    buttonType="outline"
                                    size="s"
                                    onClick={() => {
                                        setDetailSearch({
                                            claimType: "",
                                            submitDate: "",
                                            amount: "",
                                        });
                                        applyResetSearch();
                                    }}
                                />
                            </div>
                            <div className="page-claim-pending-approval__button-wrapper">
                                <AppButton label="Apply" size="s" onClick={applyAdvanceSearch} />
                            </div>
                        </div>
                    </div>
                ) : null}

                <div className="page-claim-pending-approval__table-wrapper">
                    <AppReactTable columns={tableColumn} data={tableData.content} searchKeyword={searchKeyword || !Object.values(detailSearch).every((e) => e === "")} onClickRow={tableRowClicked} />
                </div>

                <div className="page-claim-pending-approval__paginate-wrapper">
                    <AppPaginate onPageChange={onPageChange} pageCount={tableData.totalPages} forcePage={tableData.pageNo} />
                </div>
            </div>
        </AppPagesLayout>
    );
};

export default PageClaimPendingApproval;
