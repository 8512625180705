// libraries
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
// components
import AppPagesLayout from "components/app-pages-layout";
import AppBreadcrumb from "components/app-breadcrumb";
import AppTabLink from "components/app-tab-link";
import AppReactTable from "components/app-react-table";
import AppButton from "components/app-button";
import AppTextArea from "components/app-text-area";
import appToast from "components/app-toast";
import AppDualColumnsModal from "components/app-dual-columns-modal";
import AppInputWithLabel from "components/app-input-with-label";
// service
import api from "services/api";
// common
import { sanitizeError } from "common/utilities";
// routes
import employeePathnames from "routes/employee-pathnames";
// assets
import iconCalendar from "assets/images/components/app-button/icon-calendar.svg";
// new leave
import AppEmployeeCreateNewLeave from "./new-leave";

const breadcrumb = [
    {
        label: "Leave Management",
    },
    {
        label: "Pending Approval",
    },
];

const PageEmployeePendingLeave = () => {
    const profile = useSelector(state => state.auth)
    const staffId = profile ? profile.staffID : "";
    const [tableData, setTableData] = useState([]);
    const [leaveDetails, setLeaveDetails] = useState(null);
    const [largeModalIsOpen, setLargeModalIsOpen] = useState(false);
    const createLeaveModal = useRef(null);

    const appTabLink = [
        {
            label: "Leave Calendar",
            pathname: employeePathnames.pageEmployeeLeaveCalendar,
        },
        {
            label: "Leave Summary",
            pathname: employeePathnames.pageEmployeeLeaveSummary,
        },
        {
            label: "Leave History",
            pathname: employeePathnames.pageEmployeeLeaveHistory,
        },
        {
            label: "Pending Approval",
            pathname: employeePathnames.pageEmployeePendingLeave,
        },
    ];

    const getTableData = async (staffId) => {
        try {
            const response = await api.get.leaveManagementPendingLeave(staffId);
            setTableData(response.data.result);
        } catch (error) {
            let sanitizedError = sanitizeError(error);
            appToast(sanitizedError, false);
        }
    };

    useEffect(() => {
        getTableData(staffId);
    }, [staffId]);

    const tableColumn = [
        {
            id: "leaveType",
            Header: () => {
                return <span className="react-table__leave-type-header">Leave Type</span>;
            },
            Cell: (row) => {
                return <span className="react-table__leave-type-data">{row.row.original.leaveType}</span>;
            },
        },
        {
            id: "leaveDays",
            Header: () => {
                return <span>Days</span>;
            },
            maxWidth: 60,
            Cell: (row) => {
                return <span className="react-table__days-data">{row.row.original.leaveDays}</span>;
            },
        },
        {
            id: "startDate",
            Header: () => {
                return <span>Start Date</span>;
            },
            accessor: "startDate",
            maxWidth: 100,
        },
        {
            id: "endDate",
            Header: () => {
                return <span>End Date</span>;
            },
            accessor: "endDate",
            maxWidth: 100,
        },
        {
            id: "timeOff",
            Header: () => {
                return <span>Time Off</span>;
            },
            accessor: "timeOff",
            maxWidth: 120,
        },
    ];

    const tableRowClicked = async (item) => {
        try {
            const response = await api.get.leaveManagementLeaveHistoryDetail(item.id);
            setLeaveDetails(response.data.result);
            setLargeModalIsOpen(true);
        } catch (error) {
            let sanitizedError = sanitizeError(error);
            appToast(sanitizedError, false);
        }
    };

    return (
        <AppPagesLayout>
            <div className="page-pending-leave">
                {leaveDetails ? (
                    <AppDualColumnsModal
                        title="Leave Details"
                        isOpenModal={largeModalIsOpen}
                        onRequestClose={() => setLargeModalIsOpen(false)}
                        status={leaveDetails.status}
                        statusColor="orange"
                        childrenLeft={
                            <div>
                                <div className="page-pending-leave__modal-input-wrapper">
                                    <AppInputWithLabel placeholder="Username" value={leaveDetails.userName} disabled={true} />
                                </div>
                                <div className="page-pending-leave__modal-input-wrapper">
                                    <AppInputWithLabel placeholder="Leave Type" value={leaveDetails.leaveType} disabled={true} />
                                </div>
                                <div className="page-pending-leave__modal-input-wrapper">
                                    <AppInputWithLabel placeholder="Start Date" value={leaveDetails.startDate} disabled={true} />
                                </div>
                                <div className="page-pending-leave__modal-input-wrapper">
                                    <AppInputWithLabel placeholder="Submit Date" value={leaveDetails.submitDate} disabled={true} />
                                </div>
                            </div>
                        }
                        childrenRight={
                            <div>
                                <div className="page-pending-leave__modal-input-wrapper">
                                    <AppInputWithLabel placeholder="Employee ID" value={leaveDetails.staffID} disabled={true} />
                                </div>
                                <div className="page-pending-leave__modal-input-wrapper">
                                    <AppInputWithLabel placeholder="Time Off" value={leaveDetails.timeOff} disabled={true} />
                                </div>
                                <div className="page-pending-leave__modal-input-wrapper">
                                    <AppInputWithLabel placeholder="End Date" value={leaveDetails.endDate} disabled={true} />
                                </div>
                                {leaveDetails.rejectReason ? (
                                    <div className="page-pending-leave__modal-input-wrapper">
                                        <AppInputWithLabel placeholder="Reject Reason" value={leaveDetails.rejectReason} disabled={true} />
                                    </div>
                                ) : null}
                                {leaveDetails.remark ? (
                                    <div className="page-pending-leave__modal-input-wrapper">
                                        <AppTextArea placeholder="Remark" value={leaveDetails.remark} disabled={true} />
                                    </div>
                                ) : null}
                            </div>
                        }
                    />
                ) : null}

                <div className="page-pending-leave__breadcrumb">
                    <AppBreadcrumb list={breadcrumb} />
                </div>
                <AppEmployeeCreateNewLeave ref={createLeaveModal} onSuccess={() => getTableData(staffId)}/>
                <div className="page-leave-summary__button-row">
                    <div className="page-leave-summary__new-button">
                        <AppButton buttonIcon={<img src={iconCalendar} alt="" className="page-leave-summary__calendar-icon" />} size="s" label="New Leave" onClick={() => createLeaveModal.current.createLeaveModalIsOpen()} />
                    </div>
                </div>
                <div className="page-pending-leave__tab-wrapper">
                    <AppTabLink tabList={appTabLink} />
                </div>
                <div className="page-pending-leave__table-wrapper">
                    <AppReactTable
                        columns={tableColumn}
                        data={tableData}
                        onClickRow={tableRowClicked}
                    />
                </div>
            </div>
        </AppPagesLayout>
    );
};

export default PageEmployeePendingLeave;
