const CONSTANT = {
    ENDPOINT_PATH: {
        LOGIN: "/staff/login",
        STAFF_LOGIN: "/staff/staffLogin",
        REFRESH_TOKEN: "/staff/refreshToken",
        FORGOT_PASSWORD: "/staff/forgotPassword",
        VALIDATE_RESET_PASSWORD_TOKEN: "/staff/validateToken",
        RESET_PASSWORD: "/staff/resetPassword",
        ADMIN_CHANGE_PASSWORD: "/staff/changePassword",

        CREATE_NEW_EMPLOYEE: "/userMgmt/createStaff",
        USER_MANAGEMENT_TABLE: "/userMgmt/allStaff",
        USER_MANAGEMENT_TABLE_SEARCH: "/userMgmt/searchStaff",
        GET_USERNAME_DETAILS: "/userMgmt/getStaff",
        GET_STAFF_NAME_POSITION: "/userMgmt/listStaffPosition",
        UPDATE_STAFF_DETAILS: "/userMgmt/updateStaff",
        EMPLOYEE_BULK_UPLOAD: "/userMgmt/bulkUpload",
        EMPLOYEE_EXPORT: "/userMgmt/bulkExport",
        USER_MANAGEMENT_UPLOAD_TEMPLATE_VERSION: "/userMgmt/templateVersion",
        USER_MANAGEMENT_DOWNLOAD_TEMPLATE: "/userMgmt/downloadTemplate",

        CREATE_BENEFIT: "/companyBenf/createBenefit",
        COMPANY_BENEFIT_BENEFIT_TABLE: "/companyBenf/allBenefits",
        COMPANY_BENEFIT_LIST_ALL_BENEFIT: "/companyBenf/listAllBenefit",
        COMPANY_BENEFIT_CREATE_POSITION: "/companyBenf/createPosition",
        COMPANY_BENEFIT_LIST_ALL_POSITION: "/companyBenf/listAllPosition",
        COMPANY_BENEFIT_LIST_SEARCHED_BENEFIT: "/companyBenf/searchBenefits",
        COMPANY_BENEFIT_LIST_SEARCHED_POSITION: "/companyBenf/searchPosition",
        COMPANY_BENEFIT_GET_POSITION_BY_ID: "/companyBenf/getPosition",
        COMPANY_BENEFIT_UPDATE_POSITION: "/companyBenf/updatePosition",
        COMPANY_BENEFIT_GET_BENEFIT: "/companyBenf/getBenefit",
        COMPANY_BENEFIT_UPDATE_BENEFIT: "/companyBenf/updateBenefit",
        COMPANY_BENEFIT_REMOVE_BENEFIT: "/companyBenf/removeBenefit",
        COMPANY_BENEFIT_ENTITLED_BENEFIT: "/companyBenf/entitledBenefits",

        LEAVE_MANAGEMENT_LIST_SEARCHED_LEAVE_DIRECTORY: "/leaveMgmt/searchLeaveDirectory",
        LEAVE_MANAGEMENT_GET_LEAVE_SUMMARY: "/leaveMgmt/getLeaveSummary",
        LEAVE_MANAGEMENT_UPCOMING_LEAVE: "/leaveMgmt/upcomingLeave",
        LEAVE_MANAGEMENT_LEAVE_HISTORY: "/leaveMgmt/leaveHistory",
        LEAVE_MANAGEMENT_PENDING_LEAVE: "/leaveMgmt/getPendingLeave",
        LEAVE_MANAGEMENT_PENDING_APPROVAL: "/leaveMgmt/searchLeaveApproval",
        LEAVE_MANAGEMENT_LEAVE_HISTORY_DETAIL: "/leaveMgmt/getLeaveHistoryDetail",
        LEAVE_MANAGEMENT_LEAVE_STAFF_TO_CALENDAR: "/leaveMgmt/getLeaveStaffToCalender",
        LEAVE_MANAGEMENT_PUBLIC_HOLIDAY_CALENDAR: "/leaveMgmt/getPublicHolidayToCalender",
        LEAVE_MANAGEMENT_UPDATE_LEAVE: "/leaveMgmt/updateLeave",
        LEAVE_MANAGEMENT_LIST_LEAVE_TYPE: "/leaveMgmt/listLeaveType",
        LEAVE_MANAGEMENT_WORKING_STATUS: "/leaveMgmt/workingStatus",
        LEAVE_MANAGEMENT_CREATE_LEAVE: "/leaveMgmt/createLeave",
        LEAVE_MANAGEMENT_CANCEL_LEAVE: "/leaveMgmt/cancelLeave",

        CLAIM_MANAGEMENT_CLAIM_DIRECTORY: "/claimMgmt/searchClaimDirectory",
        CLAIM_MANAGEMENT_SEARCH_CLAIM_APPROVAL: "/claimMgmt/searchClaimApproval",
        CLAIM_MANAGEMENT_CLAIM_SUMMARY: "/claimMgmt/getClaimSummary",
        CLAIM_MANAGEMENT_APPROVE_CLAIM: "/claimMgmt/approveClaim",
        CLAIM_MANAGEMENT_REJECT_CLAIM: "/claimMgmt/rejectClaim",
        CLAIM_MANAGEMENT_CLAIM_HISTORY: "/claimMgmt/listClaimHistory",
        CLAIM_MANAGEMENT_CLAIM_DETAILS: "/claimMgmt/getClaimHistoryDetail",
        CLAIM_MANAGEMENT_CLAIM_TYPE: "/claimMgmt/listClaimType",
        CLAIM_MANAGEMENT_PENDING_CLAIM: "/claimMgmt/searchPendingClaim",
        CLAIM_MANAGEMENT_PENDING_CLAIM_DETAIL: "/claimMgmt/getClaimDetail",
        CLAIM_MANAGEMENT_CREATE_CLAIM: "/claimMgmt/createClaim",
        CLAIM_MANAGEMENT_SEARCH_PAYMENT_PENDING: "/claimMgmt/searchPaymentPending",
        CLAIM_MANAGEMENT_ISSUE_PAID_STATUS: "/claimMgmt/issuePaidStatus",
        CLAIM_MANAGEMENT_CLAIM_PENDING_PAYMENT_DETAIL: "/claimMgmt/getClaimPendingDetail",

        TIMESHEET_MANAGEMENT_LIST_TIMESHEET: "/timesheetMgmt/listTimesheet",
        TIMESHEET_MANAGEMENT_SAVE_TIMESHEET: "/timesheetMgmt/saveTimesheet",
        TIMESHEET_MANAGEMENT_EXPORT: "/timesheetMgmt/timesheetExport",

        PAYSLIP_SEARCH_EMPLOYEE: "/payslipMgmt/payslipUsers",
        PAYSLIP_GET_STAFF_ID_SEARCH_PAYSLIP: "/payslipMgmt/getPayslip",
        PAYSLIP_DOWNLOAD: "/payslipMgmt/downloadPayslip",
        PAYSLIP_ZIP_DOWNLOAD: "/payslipMgmt/downloadAllPayslip",
        PAYSLIP_VALIDATE_FILENAME: "/payslipMgmt/bulkUploadPaySlip",
        PAYSLIP_BULKUPLOAD: "/payslipMgmt/confirmBulkUpload",

        EMPLOYEE_CONFIRMATION_GET_PDF_DETAILS: "/empConfirmation/getConfirmPdfDetail",
        EMPLOYEE_CONFIRMATION_SEARCH_EMPLOYEE: "/empConfirmation/listEmployee",
        EMPLOYEE_CONFIRMATION_SAVE_PDF: "/empConfirmation/savePdfDetail",
        EMPLOYEE_CONFIRMATION_PDF_TO_EMAIL: "/empConfirmation/pdfToEmail",

        COACHING_SESSION_UPCOMING_LIST: "/coachingMgmt/upcomingSessions",
        COACHING_SESSION_COACHING_HISTORY: "/coachingMgmt/coachingHistory",
        COACHING_SESSION_SCHEDULE_SESSION: "/coachingMgmt/scheduleCoachingSession",
        COACHING_SESSION_SESSION_DETAIL: "/coachingMgmt/getSessionDetails",
        COACHING_SESSION_EDIT_SESSION: "/coachingMgmt/editCoachingSession",
        COACHING_SESSION_UPCOMING_LIST_ADVANCE_SEARCH: "/coachingMgmt/upcomingSessionsADVSearch",

        DASHBOARD_CREATE_ANNOUNCEMENT: "/dashboardMgmt/createAnnouncement",
        DASHBOARD_GET_ON_LEAVE: "/dashboardMgmt/getOnLeave",
        DASHBOARD_GET_TOTAL_EMPLOYEE: "/dashboardMgmt/getTotalEmployee",
        DASHBOARD_GET_EMPLOYEE_BIRTHDAY: "/dashboardMgmt/getEmployeeBirthday",
        DASHBOARD_GET_PENDING_CLAIM: "/dashboardMgmt/getPendingClaim",
        DASHBOARD_GET_PENDING_LEAVE: "/dashboardMgmt/getPendingLeave",
        DASHBOARD_UPCOMING_ANNOUNCEMENT: "/dashboardMgmt/listUpcomingAnnouncement",
        DASHBOARD_CHANGE_PIN_STATUS: "/dashboardMgmt/changePinnedStatus",
        DASHBOARD_DELETE_ANNOUNCEMENT: "/dashboardMgmt/archiveAnnouncement",
        DASHBOARD_POSTED_ANNOUNCEMENT: "/dashboardMgmt/listActiveAnnouncement",
        DASHBOARD_GET_PUBLIC_HOLIDAY: "/dashboardMgmt/getPublicHolidays",
        DASHBOARD_GET_NEW_EMPLOYEE: "/dashboardMgmt/countNewEmployee",
        DASHBOARD_GET_TURNOVER_EMPLOYEE: "/dashboardMgmt/countTurnover",
        DASHBOARD_GET_EMPLOYEE_UPCOMING_LEAVE: "/dashboardMgmt/EmployeePortal/getUpcomingLeave",
        DASHBOARD_GET_EMPLOYEE_BALANCE_DAY: "/dashboardMgmt/EmployeePortal/getBalanceDays",
        DASHBOARD_GET_EMPLOYEE_UPCOMING_COACHING: "/dashboardMgmt/EmployeePortal/dashboardUpcomingCoaching",
        DASHBOARD_GET_EMPLOYEE_PENDING_CLAIM: "/dashboardMgmt/EmployeePortal/staffPendingClaim",
        DASHBOARD_GET_EMPLOYEE_PENDING_LEAVE: "/dashboardMgmt/EmployeePortal/staffPendingLeave",
        DASHBOARD_GET_EMPLOYEE_ALL_ANNOUNCEMENT: "/dashboardMgmt/listAllAnnouncement",

        NOTIFICATION_ADMIN: "/notification/adminNotification",
        NOTIFICATION_EMPLOYEE: "/notification/employeeNotification",
        NOTIFICATION_ADMIN_READ: "/notification/admin/updateReadStatus",
        NOTIFICATION_EMPLOYEE_READ: "/notification/employee/updateReadStatus",
    },
    REGEX: {
        atLeastOneNumber: /.*[0-9].*/,
        atLeastOneAlphabet: /[a-z]/i,
        spaces: /\s/g,
        icNo: /^\d{0,6}[-]\d{2}[-]\d{4}$/,
        mobileNo: /^(01)\d{1}[-]\d{7,8}$/,
    },
    BE_STATUS: {
        CREATE_ANNOUNCEMENT: {
            PIN: "pin",
            REMOVE_PIN: "unpin",
        },
        PAYSLIP: {
            DUPLICATE_PAYSLIP: "this payslip is duplicate",
        },
        LEAVE_TYPE: {
            ANNUAL_LEAVE: "Annual Leave",
            COMPASSIONATE_LEAVE: "Compassionate Leave",
            MEDICAL_LEAVE: "Medical Leave",
            PATERNITY_LEAVE: "Maternity Leave",
            CARRY_FORWARD_LEAVE: "Carry Forward Leave",
        },
        CLAIM: {
            CLAIM_TYPE: {
                MEDICAL_CLAIM: "Medical Claim",
                OTHERS: "Others",
                BODY_CHECK_OPTICAL_DENTAL: "Body Check/ Optical/ Dental",
            },
        },
        TIMEOFF: {
            FULL_DAY: "Full Day",
            AM: "Half Day AM",
            PM: "Half Day PM",
        },
    },
    STATE_NUMBER: [
        "01",
        "21",
        "22",
        "23",
        "24",
        "02",
        "25",
        "26",
        "27",
        "03",
        "28",
        "29",
        "04",
        "30",
        "05",
        "31",
        "59",
        "06",
        "32",
        "33",
        "07",
        "34",
        "35",
        "08",
        "36",
        "37",
        "38",
        "39",
        "09",
        "40",
        "10",
        "41",
        "42",
        "43",
        "44",
        "11",
        "45",
        "46",
        "12",
        "47",
        "48",
        "49",
        "13",
        "50",
        "51",
        "52",
        "53",
        "14",
        "54",
        "55",
        "56",
        "57",
        "15",
        "58",
        "16",
        "74",
        "82",
    ],
};

export default CONSTANT;
