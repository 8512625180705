import React from "react";

const AppStatus = ({ size, color, label }) => {
    const getAppStatusClassNames = (size, color) => {
        const classNames = ["app-status"];
        if (size === "s") {
            classNames.push("app-status--small");
        } else {
            classNames.push("app-status--large");
        }

        switch (color) {
            case "green":
                classNames.push("app-status--green");
                break;
            case "red":
                classNames.push("app-status--red");
                break;
            case "lightBlue":
                classNames.push("app-status--blue");
                break;
            case "orange":
                classNames.push("app-status--orange");
                break;
            case "blue":
                classNames.push("app-status__confirmed");
                break;
            default:
                break;
        }
        return classNames.join(" ");
    };
    return <div className={getAppStatusClassNames(size, color)}>{label}</div>;
};

export default AppStatus;
