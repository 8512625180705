// staff pathnames
import employeePathnames from "./employee-pathnames";
// dashboard
import PageEmployeeDashboard from "pages/employee/home/dashboard";
import PageEmployeeProfileAndSettings from "pages/employee/home/profile-and-settings";
// user management
import PageEmployeeUserManagement from "pages/employee/user-management/user-management";
import PageEmployeeDetails from "pages/employee/user-management/employee-details";
// leave management
import PageEmployeeLeaveCalendar from 'pages/employee/leave-management/leave-calendar';
import PageEmployeeLeaveSummary from "pages/employee/leave-management/leave-summary";
import PageEmployeeLeaveHistory from "pages/employee/leave-management/leave-history";
import PageEmployeePendingLeave from "pages/employee/leave-management/pending-leave";
// claim management
import PageEmployeeClaimSummary from "pages/employee/claim-management/claim-summary";
import PageEmployeeClaimHistory from "pages/employee/claim-management/claim-history";
import PageEmployeePendingClaim from "pages/employee/claim-management/pending-claim";
// payslip
import PageEmployeePortalPayslip from "pages/employee/payslip/payslip";
// coaching session
import PageEmployeeUpcomingSchedule from "pages/employee/coaching-session/upcoming-schedule";
import PageEmployeeCoachingHistory from "pages/employee/coaching-session/coaching-history";

const employeeRoutes = [
    {
        path: employeePathnames.pageEmployeeDashboard,
        component: PageEmployeeDashboard,
        title: "Dashboard",
        authentication: true,
    },
    {
        path: employeePathnames.pageEmployeeDashboardProfileAndSettings,
        component: PageEmployeeProfileAndSettings,
        title: "Dashboard",
        authentication: true,
    },
    // {
    //     path: employeePathnames.pageEmployeeUserManagement,
    //     component: PageEmployeeUserManagement,
    //     title: "User Management",
    //     authentication: true,
    // },
    // {
    //     path: employeePathnames.pageEmployeeDetails,
    //     component: PageEmployeeDetails,
    //     title: "User Management",
    //     authentication: true,
    // },
    {
        path: employeePathnames.pageEmployeeLeaveCalendar,
        component: PageEmployeeLeaveCalendar,
        title: "Leave Management",
        authentication: true,
    },
    {
        path: employeePathnames.pageEmployeeLeaveSummary,
        component: PageEmployeeLeaveSummary,
        title: "Leave Management",
        authentication: true,
    },
    {
        path: employeePathnames.pageEmployeeLeaveHistory,
        component: PageEmployeeLeaveHistory,
        title: "Leave Management",
        authentication: true,
    },
    {
        path: employeePathnames.pageEmployeePendingLeave,
        component: PageEmployeePendingLeave,
        title: "Leave Management",
        authentication: true,
    },
    {
        path: employeePathnames.pageEmployeeClaimSummary,
        component: PageEmployeeClaimSummary,
        title: "Claim Management",
        authentication: true,
    },
    {
        path: employeePathnames.pageEmployeeClaimHistory,
        component: PageEmployeeClaimHistory,
        title: "Claim Management",
        authentication: true,
    },
    {
        path: employeePathnames.pageEmployeePendingClaim,
        component: PageEmployeePendingClaim,
        title: "Claim Management",
        authentication: true,
    },
    {
        path: employeePathnames.pageEmployeePayslip,
        component: PageEmployeePortalPayslip,
        title: "Payslip",
        authentication: true,
    },
    {
        path: employeePathnames.pageEmployeeUpcomingSchedule,
        component: PageEmployeeUpcomingSchedule,
        title: "Coaching Session",
        authentication: true,
    },
    {
        path: employeePathnames.pageEmployeeCoachingHistory,
        component: PageEmployeeCoachingHistory,
        title: "Coaching Session",
        authentication: true,
    }
];

export default employeeRoutes;