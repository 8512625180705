// libraries
import React, { useState, useLayoutEffect, Fragment, createContext, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";
import _ from "lodash";
// components
import AppPagesLayout from "components/app-pages-layout";
import AppBreadcrumb from "components/app-breadcrumb";
import AppButton from "components/app-button";
import AppDropdown from "components/app-dropdown";
import AppModalConfirmation from "components/app-modal-confirmation";
import AppModal from "components/app-modal";
import AppInputResponsive from "components/app-input-responsive";
import appToast from "components/app-toast";
// assets
import iconEye from "assets/images/icon-eye-black.svg";
import iconClose from "assets/images/icon-close-black.svg";
// routes
import pathnames from "routes/pathnames";
// letter template default
import AppLetterDefaultTemplate from "./letter-default-template";
import AppLetterWithIncrementTemplate from "./letter-with-increment-template";
import AppLetterExtendProbationTemplate from "./letter-extend-probation-template";
// letter template pdf
import letterDefaultPdf from "./letter-default-pdf";
import letterWithIncrementPdf from "./letter-with-increment-pdf";
import letterExtendProbationPdf from "./letter-extend-probation-pdf";
// hooks
import useIsMount from "hooks/use-is-mount";
// common
import { sanitizeError } from "common/utilities";
import api from "services/api";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const getUsernameColumnClassNames = (active) => {
    const classNames = ["page-send-letter__username"];
    if (active) classNames.push("page-send-letter__username--active");
    return classNames.join(" ");
};

const templateTypeOptions = [
    {
        label: "Default",
        value: "CONFIRMATION_LETTER",
    },
    {
        label: "With Increment",
        value: "CONFIRMATION_LETTER_WITH_INCREMENT",
    },
];

const letterInputInitialValues = {
    staffId: "",
    templateType: "",
    date: "",
    name: "",
    address1: "",
    address2: "",
    address3: "",
    address4: "",
    dearName: "",
    position: "",
    probationEnd: "",
    probationMonthExtend: "",
    startDate: "",
    probationExtendEnd: "",
    lastOfferLetter: "",
    currentSalary: "",
    newSalary: "",
    salaryIncrementDate: "",
};

let staffIdSelected = [];
let selectedStaffDetails = [];

export const AppLetterInputContext = createContext(null);

const addressInput = (address, start, end) => {
    const splittedAddress = address.split(", ").join("$").split(",").join("$").split("$");
    let startValue = splittedAddress[start] ? splittedAddress[start] : "";
    let endValue = "";
    if (end && end === "last" && splittedAddress.length > start + 1) {
        for (let i = start + 1; i < splittedAddress.length; i++) {
            let othersValue = splittedAddress[i] ? ", " + splittedAddress[i] : "";
            endValue = endValue + othersValue;
        }
    } else if (end) {
        endValue = splittedAddress[end] ? splittedAddress[end] + ", " : "";
    } else endValue = "";

    return startValue + endValue;
};

function addWeekdays(date, inputFormat, outputFormat, days) {
    date = moment(date, inputFormat); // use a clone
    while (days > 0) {
        date = date.add(1, "days");
        // decrease "days" only if it's a weekday.
        if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
            days -= 1;
        }
    }
    return moment(date).format(outputFormat);
}

const PageSendConfirmationLetter = () => {
    const location = useLocation();
    const history = useHistory();
    const [letterInput, setLetterInput] = useState(letterInputInitialValues);
    const [confirmationModal, setConfirmationModal] = useState({
        isOpen: false,
        buttonDisable: false,
    });
    const todaysDate = moment().format("D MMMM YYYY");
    const [staffLetterSave, setStaffLetterSave] = useState([]);
    const [pdfModalOpen, setPdfModalOpen] = useState(false);
    const [previewUrl, setPreviewUrl] = useState("");
    const isMount = useIsMount();
    const [pageContents, setPageContents] = useState({
        breadcrumb: "",
        headerTitle: "",
        letter: "",
        templateOptions: [],
    });
    const breadcrumb = [
        {
            label: "Employee Confirmation",
            pathname: pathnames.pageEmployeeConfirmation,
        },
        {
            label: pageContents.breadcrumb,
        },
    ];
    const selectedUserDetails = location.state.selectedUserDetails;

    const usernameOnClick = (staffId, staffLetterSave, location) => {
        const currentTemplate = staffLetterSave.find((e) => e.staffId === staffId);
        const templateCheckingAndUpdate = (savedTemplateType, location) => {
            const templateStatePassed = location.state.page;
            if (!_.isEmpty(templateTypeOptions.find((o) => o.value === templateStatePassed)) && !_.isEmpty(templateTypeOptions.find((o) => o.value === currentTemplate.templateType))) {
                return savedTemplateType;
            } else {
                return templateStatePassed;
            }
        };

        setLetterInput((prevState) => {
            return {
                ...prevState,
                staffId: staffId,
                templateType: templateCheckingAndUpdate(currentTemplate.templateType, location),
                date: currentTemplate.date,
                name: currentTemplate.name,
                address1: currentTemplate.address1,
                address2: currentTemplate.address2,
                address3: currentTemplate.address3,
                address4: currentTemplate.address4,
                dearName: currentTemplate.dearName,
                position: currentTemplate.position,
                probationEnd: currentTemplate.probationEnd,
                probationMonthExtend: currentTemplate.probationMonthExtend,
                startDate: currentTemplate.startDate,
                probationExtendEnd: currentTemplate.probationExtendEnd,
                lastOfferLetter: currentTemplate.lastOfferLetter,
                currentSalary: currentTemplate.currentSalary,
                newSalary: currentTemplate.newSalary,
                salaryIncrementDate: currentTemplate.salaryIncrementDate,
            };
        });
    };

    useLayoutEffect(() => {
        if (!location.state) {
            history.push(pathnames.pageEmployeeConfirmation);
        }
        if (location.state && location.state.page) {
            if (location.state.page === "CONFIRMATION_LETTER") {
                setPageContents({
                    breadcrumb: "Send Confirmation Letter",
                    headerTitle: "Confirmation Letter",
                    letter: "confirmationLetter",
                });
            } else if (location.state.page === "PROBATION_LETTER") {
                setPageContents({
                    breadcrumb: "Extend Probation Date",
                    headerTitle: "Extend Probation Letter",
                    letter: "probationLetter",
                });
            }
        }
        if (location.state && selectedUserDetails.length) {
            selectedStaffDetails = selectedUserDetails;
            const allStaffId = selectedStaffDetails.map((o) => o.staffID);
            const getPdfDetails = async (id) => {
                try {
                    let payload = {
                        staffId: allStaffId.join(","),
                    };
                    const response = await api.get.employeeConfirmationPdfDetail(payload);
                    const newStaffLetterSave = response.data.result.map((savedData, index) => {
                        if (savedData.staffId) {
                            return savedData;
                        } else {
                            return {
                                staffId: selectedStaffDetails[index].staffID,
                                templateType: location.state.page,
                                date: todaysDate,
                                name: selectedStaffDetails[index].staffName.toUpperCase(),
                                address1: addressInput(selectedStaffDetails[index].staffAddress, 0, 1),
                                address2: addressInput(selectedStaffDetails[index].staffAddress, 2),
                                address3: addressInput(selectedStaffDetails[index].staffAddress, 3),
                                address4: addressInput(selectedStaffDetails[index].staffAddress, 4, "last"),
                                dearName: selectedStaffDetails[index].staffName.split(" ")[0].charAt(0).toUpperCase() + selectedStaffDetails[index].staffName.split(" ")[0].slice(1).toLowerCase(),
                                position: selectedStaffDetails[index].staffPosition,
                                probationEnd: moment(selectedStaffDetails[index].probationEnd, "DD/MM/YYYY").format("D MMMM YYYY"),
                                probationMonthExtend: "1",
                                startDate: addWeekdays(selectedStaffDetails[index].probationEnd, "DD/MM/YYYY", "D MMMM YYYY", 1),
                                probationExtendEnd: moment(selectedStaffDetails[index].probationEnd, "DD/MM/YYYY").add(1, "day").add(1, "month").format("D MMMM YYYY"),
                                lastOfferLetter: moment(selectedStaffDetails[index].joinedDate, "DD/MM/YYYY").format("D MMMM YYYY"),
                                currentSalary: "0.00",
                                newSalary: "0.00",
                                salaryIncrementDate: "",
                            };
                        }
                    });
                    setStaffLetterSave(newStaffLetterSave);
                    staffIdSelected = selectedStaffDetails.map((o) => o.staffID);
                    usernameOnClick(staffIdSelected[0], newStaffLetterSave, location);
                } catch (error) {
                    appToast(sanitizeError(error), false);
                }
            };
            getPdfDetails(allStaffId);
        } else {
            history.push(pathnames.pageEmployeeConfirmation);
        }
    }, [location, history, todaysDate, selectedUserDetails]);

    useEffect(() => {
        setStaffLetterSave((state) => {
            return state.map((letter) => {
                if (letter.staffId === letterInput.staffId) {
                    return letterInput;
                } else {
                    return letter;
                }
            });
        });
    }, [letterInput]);

    const pdfPreview = () => {
        if (letterInput.templateType === "CONFIRMATION_LETTER") {
            pdfMake.createPdf(letterDefaultPdf(letterInput)).getDataUrl((dataUrl) => {
                setPreviewUrl(dataUrl);
                setPdfModalOpen(true);
            });
        } else if (letterInput.templateType === "CONFIRMATION_LETTER_WITH_INCREMENT") {
            pdfMake.createPdf(letterWithIncrementPdf(letterInput)).getDataUrl((dataUrl) => {
                setPreviewUrl(dataUrl);
                setPdfModalOpen(true);
            });
        } else if (letterInput.templateType === "PROBATION_LETTER") {
            pdfMake.createPdf(letterExtendProbationPdf(letterInput)).getDataUrl((dataUrl) => {
                setPreviewUrl(dataUrl);
                setPdfModalOpen(true);
            });
        }
    };

    const confirmationModalConfirmed = async () => {
        setConfirmationModal({
            ...confirmationModal,
            buttonDisable: true,
        });
        const formData = new FormData();
        const promisesFunction = [];
        let letterType;
        const letterTypeSaveFunction = (o) => {
            return new Promise((resolve) => {
                if (o.templateType === "CONFIRMATION_LETTER") {
                    pdfMake.createPdf(letterDefaultPdf(o)).getBlob(async (blob) => {
                        resolve(formData.append("letter", blob, `${o.staffId}_ConfirmationLetter.pdf`));
                    });
                } else if (o.templateType === "CONFIRMATION_LETTER_WITH_INCREMENT") {
                    pdfMake.createPdf(letterWithIncrementPdf(o)).getBlob((blob) => {
                        resolve(formData.append("letter", blob, `${o.staffId}_ConfirmationLetter.pdf`));
                    });
                } else if (letterInput.templateType === "PROBATION_LETTER") {
                    pdfMake.createPdf(letterExtendProbationPdf(o)).getBlob((blob) => {
                        resolve(formData.append("letter", blob, `${o.staffId}_ExtendProbation.pdf`));
                    });
                }
            });
        };
        staffLetterSave.forEach((o, i) => {
            if (o.templateType && i === 0) {
                letterType = o.templateType;
            }
            promisesFunction.push(letterTypeSaveFunction(o));
        });
        Promise.all(promisesFunction).then(async () => {
            try {
                await api.post.employeeConfirmationPdfToEmail(formData);
                setConfirmationModal({
                    ...confirmationModal,
                    buttonDisable: false,
                });
                if (letterType === "CONFIRMATION_LETTER" || letterType === "CONFIRMATION_LETTER_WITH_INCREMENT") {
                    appToast("Confirmation letter has been successfully sent.", true, 300);
                } else if (letterType === "PROBATION_LETTER") {
                    appToast("Extend probation letter has been successfully sent.", true, 300);
                } else {
                    appToast("Letter has been successfully sent.", true, 300);
                }
                history.push(pathnames.pageEmployeeConfirmation);
            } catch (error) {
                appToast(sanitizeError(error), false);
                setConfirmationModal({
                    ...confirmationModal,
                    buttonDisable: false,
                });
            }
        });
    };

    const confirmAndSave = async (letterInput) => {
        try {
            let payload = letterInput;
            await api.post.employeeConfirmationSavePdfDetails(payload);
            appToast("Current selected letter had been saved successfully.", true);
        } catch (error) {
            appToast(sanitizeError(error), false);
        }
    };

    return (
        <AppPagesLayout>
            <div className="page-send-letter">
                <AppModalConfirmation
                    isOpenModal={confirmationModal.isOpen}
                    onRequestClose={() => {
                        if (!confirmationModal.buttonDisable) {
                            setConfirmationModal({
                                ...confirmationModal,
                                isOpen: false,
                            });
                        }
                    }}
                    details={confirmationModal.buttonDisable ? "Sending.." : "Confirm to send?"}
                    onClick={() => confirmationModalConfirmed()}
                    cancelDisabled={confirmationModal.buttonDisable}
                    buttonDisabled={confirmationModal.buttonDisable}
                    buttonLabel="Confirm"
                />

                <AppModal isOpenModal={pdfModalOpen} onRequestClose={() => setPdfModalOpen(false)} emptyModal={true}>
                    <div className="page-send-letter__modal-attachment">
                        <object width="100%" height="100%" data={previewUrl} type="application/pdf">
                            {" "}
                        </object>
                    </div>
                </AppModal>
                <div className="page-send-letter__breadcrumb">
                    <AppBreadcrumb list={breadcrumb} />
                </div>
                <div className="page-send-letter__contents">
                    <div className="page-send-letter__header">
                        <div className="page-send-letter__header-username">Username</div>
                        <div className="page-send-letter__header-confirmation">
                            <div>{pageContents.headerTitle}</div>
                            <div className="page-send-letter__preview-button">
                                <AppButton size="s" buttonIcon={<img className="page-send-letter__icon-eye" src={iconEye} alt="" />} label="Preview" buttonType="outline" onClick={pdfPreview} />
                            </div>
                        </div>
                    </div>
                    <div className="page-send-letter__body">
                        <div className="page-send-letter__username-column">
                            {staffLetterSave.map((o, i) => (
                                <div key={i} className={getUsernameColumnClassNames(o.staffId === letterInput.staffId)} onClick={() => usernameOnClick(o.staffId, staffLetterSave, location)}>
                                    <span>{selectedUserDetails.find((item) => item.staffID === o.staffId).staffUserName}</span>
                                    <div onClick={(e) => e.stopPropagation()} style={{ display: "flex" }}>
                                        <img
                                            className="page-send-letter__close-icon"
                                            src={iconClose}
                                            alt=""
                                            onClick={() => {
                                                setStaffLetterSave(staffLetterSave.filter((item) => item.staffId.toString() !== o.staffId.toString()));
                                                if (staffLetterSave.length <= 1) {
                                                    history.push(pathnames.pageEmployeeConfirmation);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="page-send-letter__letter-content">
                            {pageContents.letter === "confirmationLetter" ? (
                                <div className="page-send-letter__dropdown-row">
                                    <div className="page-send-letter__dropdown-wrapper">
                                        <AppDropdown
                                            placeholder="Template"
                                            dropdownOptions={templateTypeOptions}
                                            onChange={(selected) => {
                                                setLetterInput({
                                                    ...letterInput,
                                                    templateType: selected.value,
                                                });
                                            }}
                                            value={templateTypeOptions.find((o) => o.value === letterInput.templateType) ? templateTypeOptions.find((o) => o.value === letterInput.templateType).label : templateTypeOptions[0].label}
                                            currentInputValue={templateTypeOptions.find((o) => o.value === letterInput.templateType) ? templateTypeOptions.find((o) => o.value === letterInput.templateType) : templateTypeOptions[0]}
                                        />
                                    </div>
                                </div>
                            ) : null}
                            <div style={{ padding: 24, width: 499 }}>
                                <div style={{ fontSize: 11 }}>
                                    <div style={{ fontWeight: 700 }}>PRIVATE & CONFIDENTIAL</div>
                                    <br />
                                    {!isMount ? (
                                        <Fragment>
                                            <div>
                                                [
                                                <AppInputResponsive
                                                    className="page-send-letter__input"
                                                    value={letterInput.date}
                                                    onChange={(e) =>
                                                        setLetterInput({
                                                            ...letterInput,
                                                            date: e.target.value,
                                                        })
                                                    }
                                                />
                                                ]
                                            </div>
                                            <br />
                                            <div style={{ fontWeight: 700 }}>
                                                [
                                                <AppInputResponsive
                                                    className="page-send-letter__input page-send-letter__input--bold"
                                                    value={letterInput.name}
                                                    onChange={(e) =>
                                                        setLetterInput({
                                                            ...letterInput,
                                                            name: e.target.value,
                                                        })
                                                    }
                                                />
                                                ]
                                            </div>
                                            <div>
                                                [
                                                <AppInputResponsive
                                                    className="page-send-letter__input"
                                                    value={letterInput.address1}
                                                    onChange={(e) =>
                                                        setLetterInput({
                                                            ...letterInput,
                                                            address1: e.target.value,
                                                        })
                                                    }
                                                />
                                                ]
                                            </div>
                                            <div>
                                                [
                                                <AppInputResponsive
                                                    className="page-send-letter__input"
                                                    value={letterInput.address2}
                                                    onChange={(e) =>
                                                        setLetterInput({
                                                            ...letterInput,
                                                            address2: e.target.value,
                                                        })
                                                    }
                                                />
                                                ]
                                            </div>
                                            <div>
                                                [
                                                <AppInputResponsive
                                                    className="page-send-letter__input"
                                                    value={letterInput.address3}
                                                    onChange={(e) =>
                                                        setLetterInput({
                                                            ...letterInput,
                                                            address3: e.target.value,
                                                        })
                                                    }
                                                />
                                                ]
                                            </div>
                                            <div>
                                                [
                                                <AppInputResponsive
                                                    className="page-send-letter__input"
                                                    value={letterInput.address4}
                                                    onChange={(e) =>
                                                        setLetterInput({
                                                            ...letterInput,
                                                            address4: e.target.value,
                                                        })
                                                    }
                                                />
                                                ]
                                            </div>
                                            <br />
                                            <div>
                                                Dear [
                                                <AppInputResponsive
                                                    className="page-send-letter__input"
                                                    value={letterInput.dearName}
                                                    onChange={(e) =>
                                                        setLetterInput({
                                                            ...letterInput,
                                                            dearName: e.target.value,
                                                        })
                                                    }
                                                />
                                                ],
                                            </div>
                                        </Fragment>
                                    ) : null}
                                    <br />
                                    <br />
                                    <AppLetterInputContext.Provider value={{ letterInput, setLetterInput }}>
                                        {letterInput.templateType === "CONFIRMATION_LETTER" ? (
                                            <AppLetterDefaultTemplate />
                                        ) : letterInput.templateType === "CONFIRMATION_LETTER_WITH_INCREMENT" ? (
                                            <AppLetterWithIncrementTemplate />
                                        ) : letterInput.templateType === "PROBATION_LETTER" ? (
                                            <AppLetterExtendProbationTemplate />
                                        ) : null}
                                    </AppLetterInputContext.Provider>
                                </div>
                            </div>
                            <div className="page-send-letter__save-button-row">
                                <div className="page-send-letter__save-button-wrapper">
                                    <AppButton
                                        label="Save & Confirm"
                                        buttonType="outline"
                                        size="s"
                                        onClick={() => {
                                            const newStaffLetterSave = staffLetterSave.map((o) => {
                                                if (o.staffId === letterInput.staffId) {
                                                    return letterInput;
                                                } else return o;
                                            });
                                            setStaffLetterSave(newStaffLetterSave);
                                            confirmAndSave(letterInput);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-send-letter__send-button-row">
                    <div className="page-send-letter__send-button-wrapper">
                        <AppButton
                            label="Confirm & Send"
                            size="s"
                            onClick={() => {
                                setConfirmationModal({
                                    ...confirmationModal,
                                    isOpen: true,
                                });
                            }}
                        />
                    </div>
                </div>
            </div>
        </AppPagesLayout>
    );
};

export default PageSendConfirmationLetter;

// tested my staffId able to send, need to test multiple and complete the table

// email isnt pdf

// WITH INCREMENT HAVE ISSUE
