// libraries
import React from "react";
import { useTable, useFlexLayout } from "react-table";

const getTableHeaderRowClassNames = (idSelectAll) => {
    const classNames = ["header__row"];
    if (idSelectAll) classNames.push("header__selected-all");
    return classNames.join(" ");
};

const getTableRowClassNames = (onClickRow, checkedIdArray, currentRowId) => {
    const classNames = ["body__row"];
    if (onClickRow) classNames.push("body__row-hover");
    if (checkedIdArray) {
        if (checkedIdArray.includes(currentRowId)) classNames.push("body__row-selected");
    }
    return classNames.join(" ");
};

const AppReactTable = ({ columns, data, onClickRow, checkedIdArray, idSelectAll, totalItems, headerSelectAllChildren, searchKeyword, bodyStyle, customEmptyListMessage }) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data,
        },
        useFlexLayout
    );

    const tableHeader = (column, index, idSelectAll, totalItems) => {
        if (idSelectAll) {
            if (index === 0) {
                return column.render("Header");
            } else if (index === 1) {
                return <div>{idSelectAll} records selected</div>;
            } else {
                return null;
            }
        } else {
            return column.render("Header");
        }
    };

    return (
        <div className="app-react-table">
            <div className="app-react-table__content">
                <div {...getTableProps()} className="app-react-table__table-wrapper">
                    <div className="header">
                        {idSelectAll && headerSelectAllChildren ? (
                            <div className="header__row-header-buttons">
                                <div className="header__row-header-divider" />
                                <div>{headerSelectAllChildren}</div>
                            </div>
                        ) : null}
                        {headerGroups.map((headerGroup) => (
                            <div className={getTableHeaderRowClassNames(idSelectAll)} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column, index) => (
                                    <div className="header__row-header" {...column.getHeaderProps()}>
                                        {tableHeader(column, index, idSelectAll, totalItems)}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                    {rows.length ? (
                        <div {...getTableBodyProps()} className="body">
                            <div style={bodyStyle} className="body-scrollbar">
                            {rows.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <div {...row.getRowProps()} className={getTableRowClassNames(onClickRow, checkedIdArray, row.original.id)} onClick={() => (onClickRow ? onClickRow(row.original) : null)}>
                                        {row.cells.map((cell) => {
                                            return (
                                                <div className="body__row-data" {...cell.getCellProps()}>
                                                    {cell.render("Cell")}
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}

                            </div>
                        </div>
                    ) : (
                        <div className="app-react-table__empty-body">
                            <div className="app-react-table__empty-body-row">
                                <div className="app-react-table__empty-body-detail-1">No records found</div>
                                <div className="app-react-table__empty-body-detail-2">{customEmptyListMessage ? customEmptyListMessage : searchKeyword ? `Keyword does not match with the records` : `You don't have any records yet`}</div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AppReactTable;
