// path
import pathnames from "./pathnames";
// authentication
import PageLogin from "pages/authentication/login";
import PageResetPassword from "pages/authentication/reset-password";
import PageResetPasswordCheckEmail from "pages/authentication/reset-password-check-email";
import PageNewPassword from "pages/authentication/new-password";
// others
import Page404 from "pages/404";
// routes
import adminRoutes from "./admin-routes";
import employeeRoutes from "./employee-routes";

const routes = [
    {
        path: pathnames.page404,
        component: Page404,
        title: "404",
        authentication: false,
        role: "auth",
    },
    {
        path: pathnames.pageLogin,
        component: PageLogin,
        title: "Login",
        authentication: false,
        role: "auth",
    },
    {
        path: pathnames.pageResetPassword,
        component: PageResetPassword,
        title: "Reset Password",
        authentication: false,
        role: "auth",
    },
    {
        path: pathnames.pageResetPasswordCheckEmail,
        component: PageResetPasswordCheckEmail,
        title: "Reset Password Check Email",
        authentication: false,
        role: "auth",
    },
    {
        path: pathnames.pageNewPassword,
        component: PageNewPassword,
        title: "New Password",
        authentication: false,
        role: "auth",
    },
    ...adminRoutes.map((i) => ({ ...i, role: "admin", key: i.path })),
    ...employeeRoutes.map((i) => ({ ...i, role: "employee", key: i.path })),
];

export default routes;
