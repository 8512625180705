// libraries
import React, { useState, useEffect, Fragment, useRef } from "react";
import { BiFilterAlt } from "react-icons/bi";
import moment from "moment";
import { useSelector } from "react-redux";
// components
import AppPagesLayout from "components/app-pages-layout";
import AppBreadcrumb from "components/app-breadcrumb";
import AppTabLink from "components/app-tab-link";
import AppReactTable from "components/app-react-table";
import AppPaginate from "components/app-paginate";
import AppDropdownCheckbox from "components/app-dropdown-checkbox";
import AppDualColumnsModal from "components/app-dual-columns-modal";
import AppInputWithLabel from "components/app-input-with-label";
import AppTextArea from "components/app-text-area";
import appToast from "components/app-toast";
import AppModal from "components/app-modal";
import AppButton from "components/app-button";
import AppAttachmentImage from "components/app-attachment-image";
// common
import { sanitizeError, statusFormat } from "common/utilities";
// service
import api from "services/api";
// hooks
import usePrevious from "hooks/use-previous";
// routes
import employeePathnames from "routes/employee-pathnames";
// assets
import iconTailedArrowUp from "assets/images/icon-tailed-arrow-up.svg";
import iconCalendar from "assets/images/components/app-button/icon-calendar.svg";
// new claim
import AppEmployeeCreateNewClaim from "./new-claim";

const breadcrumb = [
    {
        label: "Claim Management",
    },
    {
        label: "Claim History",
    },
];

const getTableStatusClassNames = (status) => {
    const classNames = ["react-table__status-data"];
    if (status.toLowerCase() === "approved" || status.toLowerCase() === "approve" || status.toLowerCase() === "paid") classNames.push("react-table__status--green");
    if (status.toLowerCase() === "rejected" || status.toLowerCase() === "reject") classNames.push("react-table__status--red");

    return classNames.join(" ");
};

const getStatusColor = (status) => {
    if (status === "reject" || status === "rejected") {
        return "red";
    } else if (status === "approve" || status === "approved") {
        return "green";
    } else if (status === "pending") {
        return "orange";
    } else {
        return "";
    }
};

const tableStatusFilter = [
    { label: "Approved", name: "approved", value: "approve" },
    { label: "Rejected", name: "rejected", value: "reject" },
];

const attachmentSrcInitialState = {
    file: "",
    type: "",
};

let statusToFilter = [];

const selectedClaimInitialValues = {
    amount: "",
    approveAmount: "",
    attachment: "",
    claimType: "",
    desc: "",
    filePath: "",
    id: "",
    rejectReason: "",
    remark: "",
    staffId: "",
    status: "",
    submitDate: "",
    userName: "",
    fileType: "",
};

const PageEmployeeClaimHistory = () => {
    const [statusFilterOpen, setStatusFilterOpen] = useState(false);
    const profile = useSelector((state) => state.auth);
    const staffId = profile ? profile.staffID : "";
    const [tableSearchFilter, setTableSearchFilter] = useState({
        pageNo: 0,
        pageSize: 5,
        sortBy: "",
        sortDir: "asc",
        claimStatus: "",
        filter: "",
        staffId: staffId,
    });
    const [tableData, setTableData] = useState({
        content: [],
        last: null,
        pageNo: 0,
        pageSize: 5,
        totalElements: null,
        totalPages: 1,
    });
    const prevValueSortHeader = usePrevious({
        sortBy: tableSearchFilter.sortBy,
        sortDir: tableSearchFilter.sortDir,
    });
    const [statusFilter, setStatusFilter] = useState({
        approved: false,
        rejected: false,
    });
    const [selectedClaim, setSelectedClaim] = useState(selectedClaimInitialValues);
    const createClaimModal = useRef(null);
    const [modalIsOpenSequence, setModalIsOpenSequence] = useState([]);
    const [attachmentSrc, setAttachmentSrc] = useState(attachmentSrcInitialState);

    const appTabLink = [
        {
            label: "Claim Summary",
            pathname: employeePathnames.pageEmployeeClaimSummary,
        },
        {
            label: "Claim History",
            pathname: employeePathnames.pageEmployeeClaimHistory,
        },
        {
            label: "Pending Claim",
            pathname: employeePathnames.pageEmployeePendingClaim,
        },
    ];

    const getClaimHistory = async (tableSearchFilter) => {
        try {
            const response = await api.get.claimManagementClaimHistory(tableSearchFilter);
            setTableData(response.data.result);
        } catch (error) {
            let sanitizedError = sanitizeError(error);
            appToast(sanitizedError, false);
        }
    };

    useEffect(() => {
        getClaimHistory(tableSearchFilter);
    }, [tableSearchFilter]);

    const headerArrowRotate = (active) => {
        const classNames = ["react-table__arrow-icon"];
        if (active && tableSearchFilter.sortDir === "desc") classNames.push("react-table__arrow-icon-down");
        if (active) classNames.push("react-table__arrow-icon--active");

        return classNames.join(" ");
    };

    const headerFilterClick = (header) => {
        let column = header.column;
        if (prevValueSortHeader.sortBy === column.searchFilterValue) {
            if (prevValueSortHeader.sortDir === "asc") {
                setTableSearchFilter({
                    ...tableSearchFilter,
                    sortBy: column.searchFilterValue,
                    sortDir: "desc",
                    pageNo: 0,
                });
            } else {
                setTableSearchFilter({
                    ...tableSearchFilter,
                    sortBy: column.searchFilterValue,
                    sortDir: "asc",
                    pageNo: 0,
                });
            }
        } else {
            setTableSearchFilter({
                ...tableSearchFilter,
                sortBy: column.searchFilterValue,
                sortDir: "asc",
                pageNo: 0,
            });
        }
    };

    const tableColumn = [
        {
            id: "claimType",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__claim-type-header react-table__header">
                        Claim Type
                        <img className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            Cell: (row) => {
                return <span className="react-table__claim-type-row">{row.row.original.claimType}</span>;
            },
            searchFilterValue: "claim_type",
        },
        {
            id: "submitDate",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Submit Date
                        <img className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            maxWidth: 60,
            Cell: (row) => {
                return <span>{moment(row.row.original.submitDate).format("DD/MM/YYYY")}</span>;
            },
            searchFilterValue: "submit_date",
        },
        {
            id: "amount",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Approved amount (RM)
                        <img className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            Cell: (row) => {
                return (
                    <div className="react-table__amount">
                        <div>{row.row.original.amount.toFixed(2)}</div>
                    </div>
                );
            },
            maxWidth: 100,
            searchFilterValue: "approve_amount",
        },
        {
            id: "filePath",
            Header: () => {
                return <span className="react-table__header react-table__header--cursor-default">Attachment</span>;
            },
            Cell: (row) => {
                if (row.row.original.filePath) {
                    const fileSplitted = row.row.original.filePath.split(".");
                    const fileType = fileSplitted[fileSplitted.length - 1];
                    return (
                        <div className="react-table__attachment" onClick={(e) => e.stopPropagation()}>
                            <AppAttachmentImage
                                fileType={fileType}
                                onClick={() => {
                                    setAttachmentSrc({
                                        file: row.row.original.filePath,
                                        type: fileType,
                                    });
                                    setModalIsOpenSequence(["MODAL_PDF"]);
                                }}
                            />
                        </div>
                    );
                } else {
                    return null;
                }
            },
            maxWidth: 70,
        },
        {
            id: "claimStatus",
            Header: () => {
                const getStatusClassNames = (statusFilterOpen) => {
                    const classNames = ["react-table__icon-filter"];
                    if (statusFilterOpen) classNames.push("react-table__icon-filter--active");
                    return classNames.join(" ");
                };
                return (
                    <div onClick={(e) => e.stopPropagation()}>
                        <span className="react-table__status-header" onClick={() => setStatusFilterOpen(!statusFilterOpen)}>
                            Status
                            <BiFilterAlt className={getStatusClassNames(statusFilterOpen)} />
                        </span>
                        {statusFilterOpen ? (
                            <div className="react-table__dropdown">
                                <AppDropdownCheckbox
                                    dropdownList={tableStatusFilter}
                                    onChange={(item, value) => {
                                        setStatusFilter({
                                            ...statusFilter,
                                            [item.name]: value,
                                        });
                                        if (value) {
                                            statusToFilter.push(item.value);
                                        } else {
                                            statusToFilter = statusToFilter.filter((e) => e !== item.value);
                                        }
                                        if (statusToFilter.length === 1) {
                                            setTableSearchFilter({
                                                ...tableSearchFilter,
                                                pageNo: 0,
                                                filter: statusToFilter[0],
                                            });
                                        } else {
                                            setTableSearchFilter({
                                                ...tableSearchFilter,
                                                pageNo: 0,
                                                filter: "",
                                            });
                                        }
                                    }}
                                    state={statusFilter}
                                />
                            </div>
                        ) : null}
                    </div>
                );
            },
            Cell: (row) => {
                return (
                    <div className={getTableStatusClassNames(row.row.original.claimStatus)}>
                        <span>{statusFormat(row.row.original.claimStatus)}</span>
                    </div>
                );
            },
            searchFilterValue: "status",
            maxWidth: 80,
        },
    ];

    const onPageChange = (e) => {
        setTableSearchFilter({
            ...tableSearchFilter,
            pageNo: e.selected,
        });
    };

    const tableRowSelected = async (row) => {
        try {
            const response = await api.get.claimManagementClaimDetails(row.id);
            const fileSplitted = response.data.result.filePath.split(".");
            const fileType = fileSplitted[fileSplitted.length - 1];
            setSelectedClaim({ ...response.data.result, fileType: fileType });
            setModalIsOpenSequence(["MODAL_DETAILS"]);
        } catch (error) {
            let sanitizedError = sanitizeError(error);
            appToast(sanitizedError, false);
        }
    };

    return (
        <AppPagesLayout>
            <div className="page-claim-history" onClick={() => setStatusFilterOpen(false)}>
                <AppEmployeeCreateNewClaim ref={createClaimModal} onSuccess={() => getClaimHistory(tableSearchFilter)} />
                <AppDualColumnsModal
                    title="Claim Details"
                    isOpenModal={modalIsOpenSequence.slice(-1)[0] === "MODAL_DETAILS"}
                    onRequestClose={() => setModalIsOpenSequence(modalIsOpenSequence.slice(0, -1))}
                    status={statusFormat(selectedClaim.status)}
                    statusColor={getStatusColor(selectedClaim.status ? selectedClaim.status.toLowerCase() : "")}
                    childrenLeft={
                        <div>
                            <div className="page-claim-history__modal-input-wrapper">
                                <AppInputWithLabel placeholder="Username" value={selectedClaim.userName} disabled={true} />
                            </div>
                            <div className="page-claim-history__modal-input-wrapper">
                                <AppInputWithLabel placeholder="Claim Type" value={selectedClaim.claimType} disabled={true} />
                            </div>
                            <div className="page-claim-history__modal-input-wrapper">
                                <span className="page-claim-history__modal-icon-wrapper">
                                    <AppAttachmentImage
                                        fileType={selectedClaim.fileType}
                                        onClick={() => {
                                            setAttachmentSrc({
                                                file: selectedClaim.filePath,
                                                type: selectedClaim.fileType,
                                            });
                                            setModalIsOpenSequence([...modalIsOpenSequence, "MODAL_PDF"]);
                                        }}
                                    />
                                </span>
                                <AppInputWithLabel
                                    placeholder="Attachment"
                                    value={`        ${selectedClaim.attachment}`}
                                    disabled={true}
                                    onClick={() => {
                                        setAttachmentSrc({
                                            file: selectedClaim.filePath,
                                            type: selectedClaim.fileType,
                                        });
                                        setModalIsOpenSequence([...modalIsOpenSequence, "MODAL_PDF"]);
                                    }}
                                />
                            </div>
                            <div className="page-claim-history__modal-input-wrapper">
                                <AppTextArea placeholder="Description" value={selectedClaim.desc} disabled={true} />
                            </div>
                        </div>
                    }
                    childrenRight={
                        <div>
                            <div className="page-claim-history__modal-input-wrapper">
                                <AppInputWithLabel placeholder="Employee ID" value={selectedClaim.staffId} disabled={true} />
                            </div>
                            <div className="page-claim-history__modal-input-wrapper">
                                <AppInputWithLabel placeholder="Bill Amount (RM)" value={selectedClaim.amount} disabled={true} />
                            </div>
                            {statusFormat(selectedClaim.status) === "Approved" ? (
                                <div className="page-claim-history__modal-input-wrapper">
                                    <AppInputWithLabel placeholder="Approved Amount (RM)" value={selectedClaim.approveAmount} disabled={true} />
                                </div>
                            ) : null}
                            <div className="page-claim-history__modal-input-wrapper">
                                <AppInputWithLabel placeholder="Submit Date" value={selectedClaim.submitDate} disabled={true} />
                            </div>
                            {statusFormat(selectedClaim.status) === "Rejected" ? (
                                <Fragment>
                                    <div className="page-claim-history__modal-input-wrapper">
                                        <AppInputWithLabel placeholder="Reject Reason" value={selectedClaim.rejectReason} disabled={true} />
                                    </div>
                                    <div className="page-claim-history__modal-input-wrapper">
                                        <AppTextArea placeholder="Remark" value={selectedClaim.remark} disabled={true} />
                                    </div>
                                </Fragment>
                            ) : null}
                        </div>
                    }
                />
                <AppModal
                    isOpenModal={modalIsOpenSequence.slice(-1)[0] === "MODAL_PDF"}
                    onRequestClose={() => {
                        setModalIsOpenSequence(modalIsOpenSequence.slice(0, -1));
                        setTimeout(() => {
                            setAttachmentSrc(attachmentSrcInitialState);
                        }, 200);
                    }}
                    emptyModal={true}>
                    {attachmentSrc.type === "pdf" ? (
                        <div className="page-claim-history__modal-attachment">
                            <object width="100%" height="100%" data={attachmentSrc.file} type="application/pdf">
                                {" "}
                            </object>
                        </div>
                    ) : (
                        <img className="page-claim-history__modal-image" alt="" src={attachmentSrc.file} />
                    )}
                </AppModal>
                <div className="page-claim-history__breadcrumb">
                    <AppBreadcrumb list={breadcrumb} />
                </div>

                <div className="page-claim-history__button-row">
                    <div className="page-claim-history__new-button">
                        <AppButton buttonIcon={<img src={iconCalendar} alt="" className="page-claim-history__calendar-icon" />} size="s" label="New Claim" onClick={() => createClaimModal.current.createClaimModalIsOpen()} />
                    </div>
                </div>

                <div className="page-claim-history__tab-wrapper">
                    <AppTabLink tabList={appTabLink} />
                </div>

                <div className="page-claim-history__table-wrapper">
                    <AppReactTable columns={tableColumn} data={tableData.content} onClickRow={(row) => tableRowSelected(row)} />
                </div>
                <div className="page-claim-history__pagination">
                    <AppPaginate onPageChange={onPageChange} pageCount={tableData.totalPages} forcePage={tableSearchFilter.pageNo} />
                </div>
            </div>
        </AppPagesLayout>
    );
};

export default PageEmployeeClaimHistory;
