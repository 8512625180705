// libraries
import React, { useState, useEffect } from "react";
import _ from "lodash";
import { BiFilterAlt } from "react-icons/bi";
import { useHistory } from "react-router-dom";
// components
import AppPagesLayout from "components/app-pages-layout";
import AppBreadcrumb from "components/app-breadcrumb";
import AppTabLink from "components/app-tab-link";
import AppInput from "components/app-input";
import AppReactTable from "components/app-react-table";
import AppPaginate from "components/app-paginate";
import AppDropdownCheckbox from "components/app-dropdown-checkbox";
import appToast from "components/app-toast";
// hooks
import usePrevious from "hooks/use-previous";
// service
import api from "services/api";
// common
import { sanitizeError } from "common/utilities";
// routes
import pathnames from "routes/pathnames";
// assets
import iconSearch from "assets/images/components/app-input/icon-search.svg";
import iconTailedArrowUp from "assets/images/icon-tailed-arrow-up.svg";

const breadcrumb = [
    {
        label: "Leave Management",
    },
    {
        label: "Leave Directory",
    },
];

const appTabLink = [
    {
        label: "Leave Calendar",
        pathname: pathnames.pageLeaveManagementCalendar,
    },
    {
        label: "Leave Directory",
        pathname: pathnames.pageLeaveDirectory,
    },
    {
        label: "Pending Approval",
        pathname: pathnames.pageLeavePendingApproval,
    },
];

const tableStatusFilter = [
    { label: "Working", name: "working", value: "working" },
    { label: "On Leave", name: "onLeave", value: "on leave" },
];

const getTableStatusClassNames = (status) => {
    const classNames = ["react-table__status-data"];
    if (status.toLowerCase() === "working") classNames.push("react-table__status-working");
    if (status.toLowerCase() === "on leave") classNames.push("react-table__status-on-leave");

    return classNames.join(" ");
};

let statusToFilter = [];

const PageLeaveDirectory = () => {
    const [statusFilterOpen, setStatusFilterOpen] = useState(false);
    const history = useHistory();
    const [tableSearchFilter, setTableSearchFilter] = useState({
        pageNo: 0,
        pageSize: 5,
        searchValue: "",
        sortBy: "",
        sortDir: "asc",
        filter: "",
    });
    const [tableData, setTableData] = useState({
        leaveDirList: [],
        last: null,
        pageNo: 0,
        pageSize: 5,
        totalElements: null,
        totalPages: 1,
    });
    const prevValueSortHeader = usePrevious({
        sortBy: tableSearchFilter.sortBy,
        sortDir: tableSearchFilter.sortDir,
    });
    const [statusFilter, setStatusFilter] = useState({
        working: false,
        onLeave: false,
    });

    useEffect(() => {
        const getTableData = async () => {
            try {
                const response = await api.get.leaveManagementListSearchedLeaveDirectory(tableSearchFilter);
                setTableData(response.data.result);
            } catch (error) {
                let sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
            }
        };
        getTableData();
    }, [tableSearchFilter]);

    const headerArrowRotate = (active) => {
        const classNames = ["react-table__arrow-icon"];
        if (active && tableSearchFilter.sortDir === "desc") classNames.push("react-table__arrow-icon-down");
        if (active) classNames.push("react-table__arrow-icon--active");

        return classNames.join(" ");
    };

    const headerFilterClick = (header) => {
        let column = header.column;
        if (prevValueSortHeader.sortBy === column.searchFilterValue) {
            if (prevValueSortHeader.sortDir === "asc") {
                setTableSearchFilter({
                    ...tableSearchFilter,
                    sortBy: column.searchFilterValue,
                    sortDir: "desc",
                    pageNo: 0,
                });
            } else {
                setTableSearchFilter({
                    ...tableSearchFilter,
                    sortBy: column.searchFilterValue,
                    sortDir: "asc",
                    pageNo: 0,
                });
            }
        } else {
            setTableSearchFilter({
                ...tableSearchFilter,
                sortBy: column.searchFilterValue,
                sortDir: "asc",
                pageNo: 0,
            });
        }
    };

    const tableColumn = [
        {
            id: "staffID",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__employee-id-header  react-table__header">
                        Employee ID
                        <img className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            Cell: (row) => {
                return <span className="react-table__employee-id-data">{row.row.original.staffID}</span>;
            },
            searchFilterValue: "staff_id",
            maxWidth: 80,
        },
        {
            id: "staffUserName",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Username <img className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />{" "}
                    </span>
                );
            },
            searchFilterValue: "user_name",
            accessor: "staffUserName",
        },
        {
            id: "leaveStatus",
            Header: () => {
                const getStatusClassNames = (statusFilterOpen) => {
                    const classNames = ["react-table__icon-filter"];
                    if (statusFilterOpen) classNames.push("react-table__icon-filter--active");
                    return classNames.join(" ");
                };
                return (
                    <div onClick={(e) => e.stopPropagation()}>
                        <span className="react-table__status-header" onClick={() => setStatusFilterOpen(!statusFilterOpen)}>
                            Status
                            <BiFilterAlt className={getStatusClassNames(statusFilterOpen)} />
                        </span>
                        {statusFilterOpen ? (
                            <div className="react-table__dropdown">
                                <AppDropdownCheckbox
                                    dropdownList={tableStatusFilter}
                                    onChange={(item, value) => {
                                        setStatusFilter({
                                            ...statusFilter,
                                            [item.name]: value,
                                        });
                                        if (value) {
                                            statusToFilter.push(item.value);
                                        } else {
                                            statusToFilter = statusToFilter.filter((e) => e !== item.value);
                                        }
                                        if (statusToFilter.length === 1) {
                                            setTableSearchFilter({
                                                ...tableSearchFilter,
                                                filter: statusToFilter[0],
                                                pageNo: 0,
                                            });
                                        } else {
                                            setTableSearchFilter({
                                                ...tableSearchFilter,
                                                filter: "",
                                                pageNo: 0,
                                            });
                                        }
                                    }}
                                    state={statusFilter}
                                />
                            </div>
                        ) : null}
                    </div>
                );
            },
            Cell: (row) => {
                let capitalizedStatus = row.row.original.leaveStatus
                    .split(" ")
                    .map((e) => {
                        return e.charAt(0).toUpperCase() + e.substring(1);
                    })
                    .join(" ");
                return (
                    <div className={getTableStatusClassNames(row.row.original.leaveStatus)}>
                        <span>{capitalizedStatus}</span>
                    </div>
                );
            },
            searchFilterValue: "status",
            maxWidth: 80,
        },
    ];

    const searchKeywordTyped = (value) => {
        setTableSearchFilter({
            pageNo: 0,
            pageSize: 5,
            searchValue: value,
            sortBy: "",
            sortDir: "asc",
            filter: "",
        });
    };

    const searchBarOnChange = (e) => {
        searchKeywordTyped(e.target.value);
    };

    const handleKeypress = (e) => {
        if (e.code === "Enter") {
            searchKeywordTyped(e.target.value);
        }
    };

    const onPageChange = (e) => {
        setTableSearchFilter({
            ...tableSearchFilter,
            pageNo: e.selected,
        });
    };

    const selectedRow = (selectedRow) => {
        history.push(pathnames.pageLeaveSummary.replace(":pathStaffId", "value_" + selectedRow.staffID));
    };

    return (
        <AppPagesLayout>
            <div className="page-leave-directory" onClick={() => setStatusFilterOpen(false)}>
                <div className="page-leave-directory__breadcrumb">
                    <AppBreadcrumb list={breadcrumb} />
                </div>
                <div className="page-leave-directory__tab-wrapper">
                    <AppTabLink tabList={appTabLink} />
                </div>

                <div className="page-leave-directory__searchBar-wrapper">
                    <AppInput leftSrc={iconSearch} placeholder="Search keyword" onChange={_.debounce((e) => searchBarOnChange(e), 1000)} onKeyPress={handleKeypress} />
                </div>

                <div className="page-leave-directory__table-wrapper">
                    <AppReactTable onClickRow={selectedRow} columns={tableColumn} data={tableData.leaveDirList} searchKeyword={tableSearchFilter.searchValue} />
                </div>

                <div className="page-leave-directory__pagination">
                    <AppPaginate onPageChange={onPageChange} pageCount={tableData.totalPages} forcePage={tableSearchFilter.pageNo} />
                </div>
            </div>
        </AppPagesLayout>
    );
};

export default PageLeaveDirectory;
