// libraries
import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";
// components
import AppPagesLayout from "components/app-pages-layout";
import AppBreadcrumb from "components/app-breadcrumb";
import AppTabLink from "components/app-tab-link";
import AppInput from "components/app-input";
import AppInputWithLabel from "components/app-input-with-label";
import AppDropdown from "components/app-dropdown";
import AppInputDate from "components/app-input-date";
import AppButton from "components/app-button";
import AppReactTable from "components/app-react-table";
import AppCheckbox from "components/app-checkbox";
import AppPaginate from "components/app-paginate";
import AppDropdownMini from "components/app-dropdown-mini";
import appToast from "components/app-toast";
import AppModalConfirmation from "components/app-modal-confirmation";
import AppModal from "components/app-modal";
import AppTextArea from "components/app-text-area";
import AppDualColumnsModal from "components/app-dual-columns-modal";
// routes
import pathnames from "routes/pathnames";
import { sanitizeError } from "common/utilities";
// hooks
import usePrevious from "hooks/use-previous";
// service
import api from "services/api";
// assets
import iconSearch from "assets/images/components/app-input/icon-search.svg";
import iconSetting from "assets/images/icon-setting.svg";
import iconTailedArrowUp from "assets/images/icon-tailed-arrow-up.svg";
import iconDots from "assets/images/icon-vertical-dots.svg";

const breadcrumb = [
    {
        label: "Leave Management",
    },
    {
        label: "Pending Approval",
    },
];

const appTabLink = [
    {
        label: "Leave Calendar",
        pathname: pathnames.pageLeaveManagementCalendar,
    },
    {
        label: "Leave Directory",
        pathname: pathnames.pageLeaveDirectory,
    },
    {
        label: "Pending Approval",
        pathname: pathnames.pageLeavePendingApproval,
    },
];

const rejectDropdownOptions = [{ label: "Others", id: 1 }];

const searchInitialState = {
    approvalStatus: "",
    empName: "",
    endDate: "",
    leaveType: "",
    pageNo: 0,
    pageSize: 5,
    searchValue: "",
    sortBy: "",
    sortDir: "",
    startDate: "",
    submitDate: "",
};

const PageLeavePendingApproval = () => {
    const profile = useSelector((state) => state.auth);
    const staffId = profile ? profile.staffID : "";
    const [searchKeyword, setSearchKeyword] = useState("");
    const [detailSearch, setDetailSearch] = useState({
        empName: "",
        endDate: "",
        leaveType: "",
        startDate: "",
        submitDate: "",
    });
    const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
    const [idRowSelected, setIdRowSelected] = useState([]);
    const [tableData, setTableData] = useState({
        leaveDirList: [],
        last: null,
        pageNo: 0,
        pageSize: 5,
        totalElements: null,
        totalPages: 1,
    });
    const [tableSearch, setTableSearch] = useState(searchInitialState);
    const [dropdownOpen, setDropdownOpen] = useState(null);
    const prevValueSortHeader = usePrevious({
        sortBy: tableSearch.sortBy,
        sortDir: tableSearch.sortDir,
    });
    const [leaveTypeDropdown, setLeaveTypeDropdown] = useState([]);
    const [allId, setAllId] = useState([]);
    const [confirmModal, setConfirmModal] = useState({
        details: "",
        confirmDisable: false,
        leaveStatus: "",
        removeAmount: "",
    });
    const [singleIdSelected, setSingleIdSelected] = useState();
    const [rejectValue, setRejectValue] = useState({
        reason: {
            value: "",
            error: "",
        },
        remark: {
            value: "",
        },
    });
    const [leaveDetails, setLeaveDetails] = useState(null);
    const [modalIsOpenSequence, setModalIsOpenSequence] = useState([]);

    const advanceSearch = [
        {
            name: "empName",
            placeholder: "Employee Name",
            type: "input",
        },
        {
            name: "leaveType",
            placeholder: "Leave Type",
            type: "dropdown",
            dropdownOptions: leaveTypeDropdown,
        },
        {
            name: "startDate",
            placeholder: "Start Date",
            type: "date",
        },
        {
            name: "endDate",
            placeholder: "End Date",
            type: "date",
        },
        {
            name: "submitDate",
            placeholder: "Submit Date",
            type: "date",
        },
    ];

    const getTableData = async (tableSearch) => {
        try {
            const response = await api.get.leaveManagementPendingApproval(tableSearch);
            setTableData(response.data.result);
            setAllId(response.data.result.ids);
        } catch (error) {
            appToast(sanitizeError(error), false);
        }
    };

    useEffect(() => {
        getTableData(tableSearch);
    }, [tableSearch]);

    useEffect(() => {
        const getDefaultLeaveType = async () => {
            try {
                const response = await api.get.leaveManagementListLeaveType();
                const leaveTypeArray = response.data.result.map((e, i) => {
                    return {
                        label: e,
                        value: i + 1,
                    };
                });
                setLeaveTypeDropdown(leaveTypeArray);
            } catch (error) {
                let sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
            }
        };
        getDefaultLeaveType();
    }, []);

    const applyAdvanceSearch = async () => {
        setTableSearch((state) => {
            return {
                ...state,
                ...detailSearch,
            };
        });
    };

    const searchKeywordTyped = async (value) => {
        setTableSearch(() => {
            return {
                ...searchInitialState,
                searchValue: value,
            };
        });
        setSearchKeyword(value);
    };

    const searchBarOnChange = (e) => {
        searchKeywordTyped(e.target.value);
    };

    const handleKeypress = (e) => {
        if (e.code === "Enter") {
            searchKeywordTyped(e.target.value);
        }
    };

    const selectRowFunction = (rowData) => {
        if (idRowSelected.includes(rowData.id)) {
            setIdRowSelected([...idRowSelected.filter((ele) => ele !== rowData.id)]);
        } else {
            const sortedIdRowSelected = [...idRowSelected, rowData.id];
            setIdRowSelected(sortedIdRowSelected.sort());
        }
    };

    const selectAllFunction = (allId) => {
        if (_.isEqual(idRowSelected, allId)) {
            setIdRowSelected([]);
        } else {
            setIdRowSelected(allId);
        }
    };

    const headerArrowRotate = (active) => {
        const classNames = ["react-table__arrow-icon"];
        if (active && tableSearch.sortDir === "desc") classNames.push("react-table__arrow-icon-down");
        if (active) classNames.push("react-table__arrow-icon--active");

        return classNames.join(" ");
    };

    const headerFilterClick = (header) => {
        let column = header.column;
        if (prevValueSortHeader && prevValueSortHeader.sortBy === column.searchFilterValue) {
            if (prevValueSortHeader.sortDir === "asc") {
                setTableSearch((state) => {
                    return {
                        ...state,
                        sortBy: column.searchFilterValue,
                        sortDir: "desc",
                        pageNo: 0,
                    };
                });
            } else {
                setTableSearch((state) => {
                    return {
                        ...state,
                        sortBy: column.searchFilterValue,
                        sortDir: "asc",
                        pageNo: 0,
                    };
                });
            }
        } else {
            setTableSearch((state) => {
                return {
                    ...state,
                    sortBy: column.searchFilterValue,
                    sortDir: "asc",
                    pageNo: 0,
                };
            });
        }
    };

    const tableDataDropdownOptions = [
        {
            item: "Approve",
            onClick: (item) => {
                setSingleIdSelected(item);
                setModalIsOpenSequence(["MODAL_CONFIRMATION"]);
                setConfirmModal({
                    details: "Confirm to approve?",
                    leaveStatus: "approve",
                    removeAmount: "single",
                });
            },
        },
        {
            item: "Reject",
            onClick: (item) => {
                setSingleIdSelected(item);
                setConfirmModal({
                    ...confirmModal,
                    removeAmount: "single",
                });
                setModalIsOpenSequence(["MODAL_REJECT"]);
            },
        },
    ];

    const tableColumn = [
        {
            id: "Id",
            Header: (header) => {
                if (header.data.length) {
                    return (
                        <div className="react-table__header-checkbox-wrapper">
                            <AppCheckbox type="selectAll" onChange={() => selectAllFunction(allId)} checked={_.isEqual(idRowSelected, allId)} />
                        </div>
                    );
                } else {
                    return null;
                }
            },
            Cell: (row) => {
                return (
                    <div className="react-table__row-checkbox-wrapper" onClick={(e) => e.stopPropagation()}>
                        <AppCheckbox onChange={() => selectRowFunction(row.row.original)} checked={idRowSelected.includes(row.row.original.id)} />
                    </div>
                );
            },
            maxWidth: 32,
        },
        {
            id: "staffUserName",
            Header: () => {
                return (
                    <span className="react-table__userName-header">
                        Username
                    </span>
                );
            },
            Cell: (row) => {
                return <span className="react-table__userName-data">{row.row.original.staffUserName}</span>;
            },
        },
        {
            id: "leaveType",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Leave Type
                        <img className={headerArrowRotate(tableSearch.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            accessor: "leaveType",
            width: 100,
            searchFilterValue: "leave_type",
        },
        {
            id: "submitDate",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Submit Date
                        <img className={headerArrowRotate(tableSearch.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            Cell: (row) => {
                return <span className="react-table__userName-data">{moment(row.row.original.submitDate).format("DD/MM/YYYY")}</span>;
            },
            searchFilterValue: "submit_date",
        },
        {
            id: "button",
            Cell: (row) => {
                return (
                    <div onClick={(e) => e.stopPropagation()}>
                        <AppDropdownMini
                            page="page-leave-pending-approval"
                            label={<img className="react-table__edit-icon" src={iconDots} alt="" />}
                            toggle={() => {
                                if (dropdownOpen) {
                                    setDropdownOpen(null);
                                } else {
                                    setDropdownOpen(row.row.original.id);
                                }
                            }}
                            isOpen={row.row.original.id === dropdownOpen}
                            dropdownItem={tableDataDropdownOptions}
                            outContentsSelected={dropdownOpen}
                            tableDottedButton={true}
                        />
                    </div>
                );
            },
            maxWidth: 25,
        },
    ];

    const rejectReasonConfirmed = () => {
        if (!rejectValue.reason.value) {
            setRejectValue({
                ...rejectValue,
                reason: {
                    ...rejectValue.reason,
                    error: "Reason is required",
                },
            });
        } else {
            setModalIsOpenSequence([...modalIsOpenSequence, "MODAL_CONFIRMATION"]);
            setConfirmModal({
                ...confirmModal,
                details: "Confirm to reject?",
                leaveStatus: "reject",
            });
        }
    };

    const onPageChange = async (event) => {
        setTableSearch((state) => {
            return {
                ...state,
                pageNo: event.selected,
            };
        });
    };

    const searchKeywordInputRef = useRef(null);

    const confirmationModalConfirmed = async () => {
        if (confirmModal.leaveStatus === "approve") {
            setConfirmModal({
                ...confirmModal,
                confirmDisable: true,
            });
            try {
                const payload = {
                    adminStaffId: staffId,
                    leaveDetailId: confirmModal.removeAmount === "multiple" ? idRowSelected : [singleIdSelected],
                    leaveStatus: "Approve",
                };
                await api.post.leaveManagementUpdateLeave(payload);
                setConfirmModal({
                    details: "",
                    confirmDisable: false,
                    leaveStatus: "",
                    removeAmount: "",
                });
                setModalIsOpenSequence([]);
                setIdRowSelected([]);
                appToast("Leave has been approved successfully.", true);
                getTableData(tableSearch);
            } catch (error) {
                let sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
                setConfirmModal({
                    ...confirmModal,
                    confirmDisable: false,
                });
            }
        } else if (confirmModal.leaveStatus === "reject") {
            setConfirmModal({
                ...confirmModal,
                confirmDisable: true,
            });
            try {
                const payload = {
                    adminStaffId: staffId,
                    leaveDetailId: confirmModal.removeAmount === "multiple" ? idRowSelected : [singleIdSelected],
                    leaveStatus: "Reject",
                    rejectReason: rejectValue.reason.value,
                    remark: rejectValue.remark.value,
                };
                await api.post.leaveManagementUpdateLeave(payload);
                setConfirmModal({
                    details: "",
                    confirmDisable: false,
                    leaveStatus: "",
                    removeAmount: "",
                });
                setRejectValue({
                    reason: {
                        value: "",
                        error: "",
                    },
                    remark: {
                        value: "",
                    },
                });
                setModalIsOpenSequence([]);
                setIdRowSelected([]);
                appToast("Leave has been rejected successfully.", true);
                getTableData(tableSearch);
            } catch (error) {
                let sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
                setConfirmModal({
                    ...confirmModal,
                    confirmDisable: false,
                });
            }
        }
    };

    const tableRowClicked = async (item) => {
        try {
            const response = await api.get.leaveManagementLeaveHistoryDetail(item.id);
            setLeaveDetails(response.data.result);
            setSingleIdSelected(response.data.result.id);
            setModalIsOpenSequence(["MODAL_DETAILS"]);
        } catch (error) {
            let sanitizedError = sanitizeError(error);
            appToast(sanitizedError, false);
        }
    };

    return (
        <AppPagesLayout>
            <div className="page-leave-pending-approval">
                {leaveDetails ? (
                    <AppDualColumnsModal
                        title="Leave Details"
                        isOpenModal={modalIsOpenSequence.slice(-1)[0] === "MODAL_DETAILS"}
                        onRequestClose={() => {
                            setModalIsOpenSequence(modalIsOpenSequence.slice(0, -1));
                            setSingleIdSelected(null);
                        }}
                        status={leaveDetails.status}
                        statusColor="orange"
                        childrenLeft={
                            <div>
                                <div className="page-pending-leave__modal-input-wrapper">
                                    <AppInputWithLabel placeholder="Username" value={leaveDetails.userName} disabled={true} />
                                </div>
                                <div className="page-pending-leave__modal-input-wrapper">
                                    <AppInputWithLabel placeholder="Leave Type" value={leaveDetails.leaveType} disabled={true} />
                                </div>
                                <div className="page-pending-leave__modal-input-wrapper">
                                    <AppInputWithLabel placeholder="Start Date" value={leaveDetails.startDate} disabled={true} />
                                </div>
                                <div className="page-pending-leave__modal-input-wrapper">
                                    <AppInputWithLabel placeholder="Submit Date" value={leaveDetails.submitDate} disabled={true} />
                                </div>
                            </div>
                        }
                        childrenRight={
                            <div>
                                <div className="page-pending-leave__modal-input-wrapper">
                                    <AppInputWithLabel placeholder="Employee ID" value={leaveDetails.staffID} disabled={true} />
                                </div>
                                <div className="page-pending-leave__modal-input-wrapper">
                                    <AppInputWithLabel placeholder="Time Off" value={leaveDetails.timeOff} disabled={true} />
                                </div>
                                <div className="page-pending-leave__modal-input-wrapper">
                                    <AppInputWithLabel placeholder="End Date" value={leaveDetails.endDate} disabled={true} />
                                </div>
                                {leaveDetails.rejectReason ? (
                                    <div className="page-pending-leave__modal-input-wrapper">
                                        <AppInputWithLabel placeholder="Reject Reason" value={leaveDetails.rejectReason} disabled={true} />
                                    </div>
                                ) : null}
                                {leaveDetails.remark ? (
                                    <div className="page-pending-leave__modal-input-wrapper">
                                        <AppTextArea placeholder="Remark" value={leaveDetails.remark} disabled={true} />
                                    </div>
                                ) : null}
                            </div>
                        }
                        childrenBottom={
                            <div className="page-pending-leave__modal-input-button-row">
                                <div className="page-pending-leave__modal-input-button">
                                    <AppButton
                                        label="Reject"
                                        buttonType="outline"
                                        onClick={() => {
                                            setModalIsOpenSequence([...modalIsOpenSequence, "MODAL_REJECT"]);
                                        }}
                                    />
                                </div>
                                <div className="page-pending-leave__modal-input-button">
                                    <AppButton
                                        label="Approve"
                                        onClick={() => {
                                            setConfirmModal({
                                                details: "Confirm to approve?",
                                                leaveStatus: "approve",
                                                removeAmount: "single",
                                            });
                                            setModalIsOpenSequence([...modalIsOpenSequence, "MODAL_CONFIRMATION"]);
                                        }}
                                    />
                                </div>
                            </div>
                        }
                    />
                ) : null}
                <AppModal
                    title="Reject Reason"
                    isOpenModal={modalIsOpenSequence.slice(-1)[0] === "MODAL_REJECT"}
                    onRequestClose={() => {
                        setRejectValue({
                            reason: {
                                value: "",
                                error: "",
                            },
                            remark: {
                                value: "",
                            },
                        });
                        setModalIsOpenSequence(modalIsOpenSequence.slice(0, -1));
                    }}>
                    <div className="page-leave-pending-approval__modal">
                        <div className="page-leave-pending-approval__modal-subtitle">The reject reason will apply to all selected leave.</div>
                        <div className="page-leave-pending-approval__modal-input">
                            <AppDropdown
                                placeholder="Reject Reason"
                                dropdownOptions={rejectDropdownOptions}
                                onChange={(selected) => {
                                    setRejectValue({
                                        ...rejectValue,
                                        reason: {
                                            value: selected.label,
                                            error: "",
                                        },
                                    });
                                }}
                                value={rejectValue.reason.value}
                                currentInputValue={rejectValue.reason.value ? rejectDropdownOptions.filter((ele) => ele.label === rejectValue.reason.value)[0] : null}
                                error={rejectValue.reason.error}
                            />
                        </div>
                        <div className="page-leave-pending-approval__modal-input">
                            <AppTextArea
                                placeholder="Remark (Optional)"
                                onChange={(e) => {
                                    setRejectValue({
                                        ...rejectValue,
                                        remark: {
                                            value: e.target.value,
                                        },
                                    });
                                }}
                                value={rejectValue.remark.value}
                            />
                        </div>
                        <div className="page-leave-pending-approval__modal-button-row">
                            <div className="page-leave-pending-approval__modal-button">
                                <AppButton
                                    label="Cancel"
                                    buttonType="outline"
                                    size="l"
                                    onClick={() => {
                                        setRejectValue({
                                            reason: {
                                                value: "",
                                                error: "",
                                            },
                                            remark: {
                                                value: "",
                                            },
                                        });
                                        setModalIsOpenSequence(modalIsOpenSequence.slice(0, -1));
                                    }}
                                />
                            </div>
                            <div className="page-leave-pending-approval__modal-button">
                                <AppButton label="Confirm" size="l" onClick={() => rejectReasonConfirmed()} />
                            </div>
                        </div>
                    </div>
                </AppModal>
                <AppModalConfirmation
                    isOpenModal={modalIsOpenSequence.slice(-1)[0] === "MODAL_CONFIRMATION"}
                    onRequestClose={() => {
                        setModalIsOpenSequence(modalIsOpenSequence.slice(0, -1));
                    }}
                    details={confirmModal.details}
                    onClick={confirmationModalConfirmed}
                    buttonDisabled={confirmModal.confirmDisable}
                    buttonLabel="Confirm"
                />
                <div className="page-leave-pending-approval__breadcrumb">
                    <AppBreadcrumb list={breadcrumb} />
                </div>
                <div className="page-leave-pending-approval__tab-wrapper">
                    <AppTabLink tabList={appTabLink} />
                </div>
                <div className="page-leave-pending-approval__searchBar-wrapper">
                    <AppInput
                        leftSrc={iconSearch}
                        rightSrc={iconSetting}
                        rightSrcOnClick={() => {
                            setShowAdvanceSearch(!showAdvanceSearch);
                            searchKeywordInputRef.current.value = "";
                            searchKeywordTyped("");
                        }}
                        onFocus={() => {
                            if (showAdvanceSearch) {
                                setShowAdvanceSearch(false);
                                searchKeywordInputRef.current.value = "";
                                searchKeywordTyped("");
                            }
                        }}
                        placeholder="Search keyword"
                        onChange={_.debounce((e) => searchBarOnChange(e), 1000)}
                        onKeyPress={handleKeypress}
                        ref={searchKeywordInputRef}
                    />
                </div>

                {showAdvanceSearch ? (
                    <div className="page-leave-pending-approval__search-content">
                        <div className="page-leave-pending-approval__input">
                            {advanceSearch.map((item, index) => {
                                if (item.type === "input") {
                                    return (
                                        <div key={index} className="page-leave-pending-approval__input-wrapper">
                                            <AppInputWithLabel
                                                placeholder={item.placeholder}
                                                onChange={(e) => {
                                                    setDetailSearch({
                                                        ...detailSearch,
                                                        [item.name]: e.target.value,
                                                    });
                                                }}
                                                value={detailSearch[item.name]}
                                            />
                                        </div>
                                    );
                                } else if (item.type === "dropdown" && leaveTypeDropdown.length) {
                                    return (
                                        <div key={index} className="page-leave-pending-approval__input-wrapper">
                                            <AppDropdown
                                                placeholder={item.placeholder}
                                                dropdownOptions={item.dropdownOptions}
                                                onChange={(selected) =>
                                                    setDetailSearch({
                                                        ...detailSearch,
                                                        [item.name]: selected.label,
                                                    })
                                                }
                                                currentInputValue={detailSearch[item.name] ? leaveTypeDropdown.find((o) => o.label === detailSearch[item.name]) : ""}
                                            />
                                        </div>
                                    );
                                } else if (item.type === "date") {
                                    return (
                                        <div key={index} className="page-leave-pending-approval__input-wrapper">
                                            <AppInputDate
                                                placeholder={item.placeholder}
                                                onChange={(value) => {
                                                    if (value === "") {
                                                        setDetailSearch({
                                                            ...detailSearch,
                                                            [item.name]: null,
                                                        });
                                                    } else {
                                                        setDetailSearch({
                                                            ...detailSearch,
                                                            [item.name]: moment(value, "DD/MM/YYYY").format("YYYY-MM-DD"),
                                                        });
                                                    }
                                                }}
                                                value={moment(detailSearch[item.name], "YYYY-MM-DD").format("DD/MM/YYYY")}
                                            />
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </div>
                        <div className="page-leave-pending-approval__button-row">
                            <div className="page-leave-pending-approval__button-wrapper">
                                <AppButton
                                    label="Reset"
                                    buttonType="outline"
                                    size="s"
                                    onClick={() => {
                                        setDetailSearch({
                                            empName: "",
                                            endDate: "",
                                            leaveType: "",
                                            startDate: "",
                                            submitDate: "",
                                        });
                                        setTableSearch(searchInitialState);
                                    }}
                                />
                            </div>
                            <div className="page-leave-pending-approval__button-wrapper">
                                <AppButton label="Apply" size="s" onClick={() => applyAdvanceSearch()} />
                            </div>
                        </div>
                    </div>
                ) : null}

                <div className="page-leave-pending-approval__table-wrapper">
                    <AppReactTable
                        headerSelectAllChildren={
                            <div className="page-leave-pending-approval__table-header-row">
                                <div className="page-leave-pending-approval__table-header-button-wrapper">
                                    <AppButton
                                        label="Approve Leave"
                                        size="s"
                                        onClick={() => {
                                            setConfirmModal({
                                                ...confirmModal,
                                                details: "Confirm to approve?",
                                                leaveStatus: "approve",
                                                removeAmount: "multiple",
                                            });
                                            setModalIsOpenSequence(["MODAL_CONFIRMATION"]);
                                        }}
                                    />
                                </div>
                                <div className="page-leave-pending-approval__table-header-button-wrapper">
                                    <AppButton
                                        label="Reject Leave"
                                        buttonType="outline"
                                        size="s"
                                        onClick={() => {
                                            setConfirmModal({
                                                ...confirmModal,
                                                removeAmount: "multiple",
                                            });
                                            setModalIsOpenSequence(["MODAL_REJECT"]);
                                        }}
                                    />
                                </div>
                            </div>
                        }
                        columns={tableColumn}
                        data={tableData.leaveDirList}
                        idSelectAll={idRowSelected.length}
                        totalItems={allId.length}
                        searchKeyword={searchKeyword || !Object.values(detailSearch).every((e) => e === "")}
                        onClickRow={tableRowClicked}
                    />
                </div>

                <div className="page-leave-pending-approval__paginate-wrapper">
                    <AppPaginate onPageChange={onPageChange} pageCount={tableData.totalPages} forcePage={tableSearch.pageNo} />
                </div>
            </div>
        </AppPagesLayout>
    );
};

export default PageLeavePendingApproval;
