// libraries
import React, { useState } from "react";
// assets
import iconCamera from "assets/images/components/app-image-upload/icon-camera.svg";

const AppImageUpload = ({ onChange, error, onBlur, value }) => {
    const [imageURLs, setImageURLs] = useState([]);

    const onImageChange = (e) => {
        let imageBlob = [URL.createObjectURL([...e.target.files][0])];
        setImageURLs(imageBlob);
        onChange([...e.target.files]);
    };

    return (
        <div className="app-image-upload">
            <div className="app-image-upload__wrapper">
                <label className="app-image-upload__label">
                    <input onBlur={onBlur} className="app-image-upload__input" type="file" accept="image/png, image/jpeg" alt="" onChange={onImageChange} />
                    {imageURLs.length || value ? (
                        <div className="app-image-upload__image-wrapper">
                            <img className="app-image-upload__image" src={imageURLs[0] ? imageURLs[0] : value} alt="" />
                            <div className="app-image-upload__image-camera-wrapper">
                                <img className="app-image-upload__camera-icon" alt="" src={iconCamera} />
                            </div>
                        </div>
                    ) : (
                        <div className="app-image-upload__placeholder-image">
                            <img className="app-image-upload__camera-icon" alt="" src={iconCamera} />
                        </div>
                    )}
                </label>
            </div>
            {error ? <div className="app-image-upload__error-message">{error}</div> : null}
        </div>
    );
};

export default AppImageUpload;
