// libraries
import React, { Fragment, useState } from "react";
import { Formik } from "formik";
import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { updateProfile } from "redux/slices/auth-slice";
// api
import api from "services/api";
// common
import { sanitizeError } from "common/utilities";
// components
import AppButton from "components/app-button";
import AppInput from "components/app-input";
import AppAuthLayout from "components/app-auth-layout";
// assets
import logoAtf from "assets/images/logo-atf.svg";
// routes
import pathnames from "routes/pathnames";
import employeePathnames from "routes/employee-pathnames";

const initialValues = {
    employeeId: "",
    password: "",
};

const validationSchema = yup.object().shape({
    employeeId: yup.string().required("Employee ID / Email is required"),
    password: yup.string().required("Password is required"),
});

const PageLogin = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState({
        id: "",
        password: "",
    });
    const [button, setButton] = useState({
        admin: {
            label: "Administrator",
            disabled: false,
        },
        employee: {
            label: "Employee",
            disabled: false,
        },
    });

    const onHandleLogin = async (values) => {
        if (values.type === "administrator") {
            setButton({
                admin: {
                    label: "Logging in",
                    disabled: true,
                },
                employee: {
                    ...button.employee,
                    disabled: true,
                },
            });
            try {
                const payload = {
                    userIDOrEmail: values.employeeId,
                    password: values.password,
                };
                const response = await api.post.login(payload);
                dispatch(updateProfile(response.data.result));
                localStorage.setItem("@storage_token", response.data.result.jwtToken);
                history.push(pathnames.pageDashboard);
            } catch (error) {
                const sanitizeErrorMessage = sanitizeError(error);
                setErrorMessage({
                    id: sanitizeErrorMessage,
                    password: sanitizeErrorMessage,
                });
                setButton({
                    admin: {
                        label: "Administrator",
                        disabled: false,
                    },
                    employee: {
                        ...button.employee,
                        disabled: false,
                    },
                });
            }
        } else if (values.type === "employee") {
            setButton({
                admin: {
                    ...button.admin,
                    disabled: true,
                },
                employee: {
                    label: "Logging in",
                    disabled: true,
                },
            });
            try {
                const payload = {
                    userIDOrEmail: values.employeeId,
                    password: values.password,
                };
                const response = await api.post.staffLogin(payload);
                dispatch(updateProfile(response.data.result));
                localStorage.setItem("@storage_token", response.data.result.jwtToken);
                history.push(employeePathnames.pageEmployeeDashboard);
            } catch (error) {
                const sanitizeErrorMessage = sanitizeError(error);
                setErrorMessage({
                    id: sanitizeErrorMessage,
                    password: sanitizeErrorMessage,
                });
                setButton({
                    admin: {
                        ...button.admin,
                        disabled: false,
                    },
                    employee: {
                        label: "Employee",
                        disabled: false,
                    },
                });
            }
        }
    };

    const onFocusId = () => {
        setErrorMessage({
            ...errorMessage,
            id: "",
        });
    };

    const onFocusPassword = () => {
        setErrorMessage({
            ...errorMessage,
            password: "",
        });
    };

    return (
        <AppAuthLayout>
            <div className="page-login">
                <img className="page-login__logo" src={logoAtf} alt="" />
                <div className="page-login__title">Welcome Back!</div>
                <div className="page-login__subtitle">Human Resource Platform</div>
                <Formik initialValues={initialValues} onSubmit={onHandleLogin} validationSchema={validationSchema}>
                    {({ handleSubmit, handleChange, values, setFieldValue, errors, setFieldTouched, touched }) => {
                        return (
                            <Fragment>
                                <div className="page-login__input-wrapper">
                                    <AppInput
                                        placeholder="Employee ID / Email"
                                        error={errorMessage.id ? errorMessage.id : touched.employeeId && errors.employeeId ? errors.employeeId : ""}
                                        onFocus={onFocusId}
                                        onChange={(e) => handleChange("employeeId")(e.target.value)}
                                        value={values.employeeId}
                                        onBlur={() => setFieldTouched("employeeId")}
                                    />
                                </div>
                                <div className="page-login__input-wrapper">
                                    <AppInput
                                        placeholder="Password"
                                        error={errorMessage.password ? errorMessage.password : touched.password && errors.password ? errors.password : ""}
                                        onFocus={onFocusPassword}
                                        type="password"
                                        onChange={(e) => handleChange("password")(e.target.value)}
                                        value={values.password}
                                        onBlur={() => setFieldTouched("password")}
                                    />
                                </div>
                                <div className="page-login__forgot-password-wrapper">
                                    <Link to="/reset-password" className="page-login__forgot-password">
                                        Forgot Password?
                                    </Link>
                                </div>
                                <div className="page-login__buttons-wrapper">
                                    <div className="page-login__button">
                                        <AppButton
                                            label={button.admin.label}
                                            buttonType="outline"
                                            size="l"
                                            disabled={button.admin.disabled}
                                            onClick={() => {
                                                setFieldValue("type", "administrator");
                                                handleSubmit();
                                            }}
                                        />
                                    </div>
                                    <div className="page-login__button">
                                        <AppButton
                                            label={button.employee.label}
                                            buttonType="solid"
                                            size="l"
                                            disabled={button.employee.disabled}
                                            onClick={() => {
                                                setFieldValue("type", "employee");
                                                handleSubmit();
                                            }}
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        );
                    }}
                </Formik>
            </div>
        </AppAuthLayout>
    );
};

export default PageLogin;
