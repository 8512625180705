// libraries
import React, { useState, useEffect } from "react";
// assets
import iconArrowUp from "assets/images/icon-solid-up.svg";

const getInputNumberClassNames = (onFocus, error) => {
    const classNames = ["app-input-number"];
    if (onFocus) classNames.push("app-input-number--focus");
    if (!onFocus) classNames.push("app-input-number--unfocus");
    if (error) classNames.push("app-input-number--error");
    return classNames.join(" ");
};

const AppInputNumber = ({ placeholder, value, onChange, showButtons = true, disabled, error }) => {
    const [inputStringValue, setInputStringValue] = useState("");
    const [inputNumberValue, setInputNumberValue] = useState(0);
    const [inputFocus, setInputFocus] = useState(false);
    useEffect(() => {
        setInputStringValue(value);
        if (value) {
            if (parseInt(value) || parseInt(value) === 0) {
                setInputNumberValue(parseInt(value));
            }
        }
    }, [value]);

    useEffect(() => {
        if (inputNumberValue) {
            setInputStringValue(inputNumberValue.toString());
        }
    }, [inputNumberValue]);

    const onChangeHandler = (e) => {
        let stringNumber = e.target.value.replace(/[^\d]/g, "");
        setInputStringValue(stringNumber);
        onChange(stringNumber);
    };

    const onFocusHandler = () => {
        setInputFocus(true);
    };

    const onBlurHandler = () => {
        setInputFocus(false);
        let numberValue = parseInt(inputStringValue);
        setInputNumberValue(numberValue);
    };

    const arrowUpClicked = () => {
        let inputNumberValueIncrement = inputNumberValue + 1;
        setInputNumberValue(inputNumberValueIncrement);
        onChange(inputNumberValueIncrement.toString());
    };

    const arrowDownClicked = () => {
        if (inputNumberValue > 0) {
            let inputNumberValueDecrement = inputNumberValue - 1;
            setInputNumberValue(inputNumberValueDecrement);
            onChange(inputNumberValueDecrement.toString());
        }
    };

    return (
        <div>
            <div className={getInputNumberClassNames(inputFocus, error)}>
                <input disabled={disabled} className="app-input-number__input" placeholder={placeholder} onFocus={onFocusHandler} onBlur={onBlurHandler} onChange={onChangeHandler} value={inputStringValue} />

                {showButtons ? (
                    <div className="app-input-number__button-wrapper">
                        <button className="app-input-number__arrow-up" onClick={arrowUpClicked}>
                            <img src={iconArrowUp} alt="" />
                        </button>
                        <button className="app-input-number__arrow-down" onClick={arrowDownClicked}>
                            <img src={iconArrowUp} alt="" className="app-input-number__arrow-down-color" />
                        </button>
                    </div>
                ) : null}
            </div>
            {error ? <div className="app-input-number__error-message">{error}</div> : null}
        </div>
    );
};

export default AppInputNumber;
