import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { LineChart, Line, Tooltip, PieChart, Pie, Cell } from "recharts";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
// components
import AppPagesLayout from "components/app-pages-layout";
import AppButton from "components/app-button";
import AppModal from "components/app-modal";
import AppModalConfirmation from "components/app-modal-confirmation";
import appToast from "components/app-toast";
import AppInputWithLabel from "components/app-input-with-label";
import AppTextArea from "components/app-text-area";
import AppInputDate from "components/app-input-date";
import AppTimePicker from "components/app-time-picker";
import AppInputUploadAttachment from "components/app-input-upload-attachment";
import AppDropdown from "components/app-dropdown";
// assets
import iconArrowUp from "assets/images/icon-tailed-arrow-up.svg";
import iconArrowRight from "assets/images/icon-arrow-right.svg";
import iconHoliday from "assets/images/icon-holiday.svg";
import iconAnnouncement from "assets/images/icon-announcement.svg";
import iconDelete from "assets/images/icon-delete.svg";
import iconPinned from "assets/images/icon-pinned.svg";
import iconPin from "assets/images/icon-pin.svg";
import iconFilePdf from "assets/images/icon-file-pdf.svg";
// pathnames
import pathnames from "routes/pathnames";
// common
import CONSTANT from "common/constant";
// utilities
import { sanitizeError } from "common/utilities";
// services
import api from "services/api";
import defaultProfileImage from "assets/images/default_profile_image.jpg";

const getNewEmployeePercentageClassNames = (direction) => {
    const classNames = ["employee-graph-card__percentage-increased"];
    if (direction === "down") classNames.push("employee-graph-card__percentage-decrease");
    return classNames.join(" ");
};

const getNewEmployeeArrowClassNames = (direction) => {
    const classNames = ["employee-graph-card__arrow-green"];
    if (direction === "down") classNames.push("employee-graph-card__arrow-red");
    return classNames.join(" ");
};

const getListItemInfoClassNames = (color) => {
    const classNames = ["list-item__info"];
    if (color === "blue") classNames.push("list-item__info--blue");
    if (color === "red") classNames.push("list-item__info--red");
    return classNames.join(" ");
};

const ListItem = ({ imgUrl, details, info, infoColor, style, imgSrc }) => {
    return (
        <div className="list-item" style={style}>
            <div className="list-item__details-wrapper">
                <img alt="" src={imgUrl === "null" || !imgUrl ? defaultProfileImage : imgUrl} className="employee-list-item__details-img" />
                <div className="list-item__details">{details}</div>
            </div>
            <div className={getListItemInfoClassNames(infoColor)}>{info}</div>
        </div>
    );
};

const pieColorLabel = [
    { name: "fullTimeEmp", label: "Permanent", color: "#1A6ED3" },
    { name: "partTimeEmp", label: "Probation", color: "#facb57" },
    { name: "contractEmp", label: "Contract", color: "#A13030" },
    { name: "internEmp", label: "Intern", color: "#216237" },
];

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, ...props }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 1.8;
    const colorFill = props.payload.fill;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} className="total-employee__label-text" fill={colorFill} textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
            {props.payload.payload.value}
        </text>
    );
};

const CustomToolTip = (props) => {
    if (props.active && props.payload && props.payload.length && props.payload[0].payload.date && props.payload[0].payload) {
        return (
            <div className="graph-tooltip">
                <div>Date: {props.payload[0].payload.date}</div>
                <div>Employee number: {props.payload[0].payload.employee}</div>
            </div>
        );
    }
    return null;
};

const confirmModalInitialState = {
    details: "",
    confirmDisable: false,
    values: "",
};

const schedulePostDropdown = [
    { label: "Immediately", value: "immediate" },
    { label: "Future", value: "schedule" },
    { label: "Evergreen", value: "evergreen" },
];

const createAnnouncementFields = [
    { name: "title", placeholder: "Title", type: "text", maxLength: 100 },
    { name: "description", placeholder: "Description", type: "textarea", maxLength: 250 },
    { name: "attachment", placeholder: "Attachment (Optional)", type: "upload" },
    { name: "announcementType", placeholder: "Schedule Post", type: "dropdown" },
    { name: "startDate", placeholder: "Start Date", type: "date" },
    { name: "startTime", placeholder: "Start Time", type: "time" },
    { name: "endDate", placeholder: "End Date", type: "date" },
    { name: "endTime", placeholder: "End Time", type: "time" },
];

const createAnnouncementInitialValues = {
    title: "",
    description: "",
    attachment: null,
    announcementType: "evergreen",
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
};

const createAnnouncementValidationSchema = yup.object().shape({
    title: yup.string().required("Title is required."),
    description: yup.string().required("Description is required."),
    announcementType: yup.string().required("Schedule Post is required."),
    startDate: yup.string().when(["announcementType"], (announcementType, schema) => {
        if (announcementType === "evergreen" || announcementType === "schedule") {
            return yup
                .string()
                .required("Start Date is required.")
                .test("startDate", "Invalid date", (value) => {
                    if (value) {
                        let valueWithoutspaces = value.toLowerCase().replace(/\s/g, "");
                        return "invaliddate" !== valueWithoutspaces;
                    }
                });
        }
    }),
    startTime: yup.string().when(["announcementType", "startDate"], (announcementType, startDate, schema) => {
        if (announcementType === "evergreen" || announcementType === "schedule") {
            return yup
                .string()
                .required("Start time is required.")
                .test({
                    test: (startTime) => {
                        if (startDate) {
                            if (moment(startDate + startTime, "DD/MM/YYYYhh:mm A").isBefore(moment().add(5, "minutes"))) {
                                return false;
                            } else return true;
                        } else {
                            if (moment(startTime, "hh:mm A").isBefore(moment().add(5, "minutes"))) {
                                return false;
                            } else return true;
                        }
                    },
                    message: () => {
                        if (announcementType === "evergreen") {
                            return "Evergreen require start time of 5 minutes from now.";
                        } else if (announcementType === "schedule") {
                            return "Future require start time of 5 minutes from now.";
                        } else {
                            return "Require start time of 5 minutes from now.";
                        }
                    },
                });
        }
    }),
    endDate: yup.string().when(["announcementType"], (announcementType, schema) => {
        if (announcementType === "immediate" || announcementType === "schedule") {
            return yup
                .string()
                .required("End date is required.")
                .test("endDate", "Invalid date", (value) => {
                    if (value) {
                        let valueWithoutspaces = value.toLowerCase().replace(/\s/g, "");
                        return "invaliddate" !== valueWithoutspaces;
                    }
                });
        }
    }),
    endTime: yup.string().when(["announcementType"], (announcementType, schema) => {
        if (announcementType === "immediate" || announcementType === "schedule") {
            return yup.string().required("End date is required.");
        }
    }),
    // endDate: yup.string().when(["announcementType", "startDate"], (announcementType, startDate) => {
    // if (announcementType === "immediate") {
    //     return yup.string().required("End Date is required.");
    // }
    // else if (announcementType === "schedule") {
    //     return yup
    //         .string()
    //         .required("End Date is required.")
    //         .test("endDate", "End date is required to be after start date.", (endDate) => {
    //             if (startDate && moment(endDate, "DD/MM/YYYY").isBefore(moment(startDate, "DD/MM/YYYY"))) {
    //                 return false;
    //             } else return true;
    //         });
    // }
    // }),
});

const hideContentClassNames = (currentTab, tabSelected) => {
    const classNames = ["page-dashboad__tab"];
    if (currentTab === tabSelected) {
        classNames.push("page-dashboard__tab--selected");
    } else {
        classNames.push("page-dashboard__tab--unselected");
    }
    return classNames.join(" ");
};

const tabClassNames = (currentTab, tabSelected) => {
    const classNames = ["announcement__tab-selection"];
    if (currentTab === tabSelected) classNames.push("announcement__tab-selected");
    return classNames.join(" ");
};

function compare( a, b ) {
    if ( a.dob < b.dob ){
      return -1;
    }
    if ( a.dob > b.dob ){
      return 1;
    }
    return 0;
  }

const PageDashboard = () => {
    const profile = useSelector((state) => state.auth);
    const history = useHistory();
    const [pdfModalOpen, setPdfModalOpen] = useState(false);
    const [attachmentUrlSelected, setAttachmentUrlSelected] = useState("");
    const [confirmModal, setConfirmModal] = useState(confirmModalInitialState);
    const [timePickerIsOpen, setTimePickerIsOpen] = useState("");
    const [timePickerTouched, setTimePickerTouched] = useState({
        startTime: false,
        endTime: false,
    });
    const [timeError, setTimeError] = useState({
        startDate: "",
        startTime: "",
        endDate: "",
        endTime: "",
    });
    const [pinned, setPinned] = useState(false);
    const [modalIsOpenSequence, setModalIsOpenSequence] = useState([]);
    const [employeeOnLeaveData, setEmployeeOnLeaveData] = useState([]);
    const [totalEmployeeData, setTotalEmployeeData] = useState([]);
    const [pieData, setPieData] = useState([]);
    const [birthdayDate, setBirthdayDate] = useState([]);
    const [publicHolidayDate, setPublicHolidayDate] = useState([]);
    const [pendingClaimData, setPendingClaimData] = useState();
    const [pendingLeaveData, setPendingLeaveData] = useState();
    const [postedAnnouncement, setPostedAnnouncement] = useState([]);
    const [upcomingAnnouncement, setUpcomingAnnouncement] = useState([]);
    const [deleteAnnouncementId, setDeleteAnnouncementId] = useState(null);
    const [newEmployeeData, setNewEmployeeData] = useState({
        employeeCount: 0,
        percentage: "",
        frequency: [],
    });
    const [turnoverEmployeeData, setTurnoverEmployeeData] = useState({
        employeeCount: 0,
        percentage: "",
        frequency: [],
    });
    const [tabSelected, setTabSelected] = useState("posted");
    const [currentTime, setCurrentTime] = useState(moment().format("Do MMMM YYYY | LT"));

    const pendingInfo = [
        {
            title: "Pending Leave",
            value: pendingLeaveData,
            valueColor: "#fa9c57",
            buttonText: "Go to Leave",
            buttonOnClick: () => history.push(pathnames.pageLeavePendingApproval),
        },
        {
            title: "Pending Claim",
            value: pendingClaimData,
            valueColor: "#facb57",
            buttonText: "Go to Claim",
            buttonOnClick: () => history.push(pathnames.pageClaimPendingApproval),
        },
    ];

    useEffect(() => {
        const updateCurrentTime = () => {
            setCurrentTime(moment().format("Do MMMM YYYY | LT"));
        };
        const interval = setInterval(updateCurrentTime, 1000 * 60);
        return () => {
            clearInterval(interval);
        };
    }, []);

    const getNewEmployee = async () => {
        try {
            const response = await api.get.dashboardGetNewEmployee();
            let updateFrequency = [];
            for (let i = 0; i < 7; i++) {
                updateFrequency.unshift({
                    date: moment().subtract(i, "days").format("DD-MM-YYYY"),
                    employee: 0,
                });
            }
            updateFrequency.forEach((updateObj) => {
                response.data.result.frequency.forEach((responseObj) => {
                    if (updateObj.date === responseObj.date) {
                        updateObj.employee = responseObj.employee;
                    }
                });
            });
            const updatedNewEmployeeData = {
                employeeCount: response.data.result.employeeCount,
                percentage: response.data.result.percentage,
                frequency: updateFrequency,
            };
            setNewEmployeeData(updatedNewEmployeeData);
        } catch (error) {
            appToast(sanitizeError(error), false);
        }
    };

    const getTurnOverEmployee = async () => {
        try {
            const response = await api.get.dashboardGetTurnoverEmployee();
            let updateFrequency = [];
            for (let i = 0; i < 7; i++) {
                updateFrequency.unshift({
                    date: moment().subtract(i, "days").format("DD-MM-YYYY"),
                    employee: 0,
                });
            }
            updateFrequency.forEach((updateObj) => {
                response.data.result.frequency.forEach((responseObj) => {
                    if (updateObj.date === responseObj.date) {
                        updateObj.employee = responseObj.employee;
                    }
                });
            });
            const updateTurnoverEmployeeData = {
                employeeCount: response.data.result.employeeCount,
                percentage: response.data.result.percentage,
                frequency: updateFrequency,
            };
            setTurnoverEmployeeData(updateTurnoverEmployeeData);
        } catch (error) {
            appToast(sanitizeError(error), false);
        }
    };

    const getOnLeave = async () => {
        try {
            const response = await api.get.dashboardGetOnLeave();
            setEmployeeOnLeaveData(response.data.result);
        } catch (error) {
            appToast(sanitizeError(error), false);
        }
    };

    const getTotalEmployee = async () => {
        try {
            const response = await api.get.dashboardGetTotalEmployee();
            setTotalEmployeeData(response.data.result);
            let updatePieData = Object.entries(response.data.result)
                .filter((arr) => arr[0] !== "totalEmp")
                .map((arr) => {
                    return {
                        name: arr[0],
                        value: arr[1],
                        label: pieColorLabel.find((e) => e.name === arr[0]).label,
                    };
                });
            setPieData(updatePieData);
        } catch (error) {
            appToast(sanitizeError(error), false);
        }
    };

    const getEmployeeBirthday = async () => {
        try {
            const response = await api.get.dashboardGetEmployeeBirthday();
            const sorting = response.data.result.sort( compare );
            let birthdaySequence = [...sorting.filter((e) => e.dob === "Today"), ...sorting.filter((e) => e.dob !== "Today")];
            setBirthdayDate(birthdaySequence);
        } catch (error) {
            appToast(sanitizeError(error), false);
        }
    };

    const getPendingClaim = async () => {
        try {
            const response = await api.get.dashboardGetPendingClaim();
            setPendingClaimData(response.data.result);
        } catch (error) {
            appToast(sanitizeError(error), false);
        }
    };

    const getPendingLeave = async () => {
        try {
            const response = await api.get.dashboardGetPendingLeave();
            setPendingLeaveData(response.data.result);
        } catch (error) {
            appToast(sanitizeError(error), false);
        }
    };

    const getPostedAnnouncement = async () => {
        try {
            const response = await api.get.dashboardGetPostedAnnouncement();
            setPostedAnnouncement(response.data.result);
        } catch (error) {
            appToast(sanitizeError(error), false);
        }
    };

    const getUpcomingAnnouncement = async () => {
        try {
            const response = await api.get.dashboardGetUpcomingAnnouncement();
            setUpcomingAnnouncement(response.data.result);
        } catch (error) {
            appToast(sanitizeError(error), false);
        }
    };

    const getPublicHoliday = async () => {
        try {
            const response = await api.get.dashboardGetPublicHoliday();
            let publicHolidaySequence = [...response.data.result.filter((e) => e.holidayDate === "Today"), ...response.data.result.filter((e) => e.holidayDate !== "Today")];
            setPublicHolidayDate(publicHolidaySequence);
        } catch (error) {
            appToast(sanitizeError(error), false);
        }
    };

    useEffect(() => {
        getNewEmployee();
        getTurnOverEmployee();
        getOnLeave();
        getTotalEmployee();
        getEmployeeBirthday();
        getPendingClaim();
        getPendingLeave();
        getPostedAnnouncement();
        getUpcomingAnnouncement();
        getPublicHoliday();
    }, []);

    const changePinStatus = async (id, type) => {
        try {
            await api.get.dashboardChangePinnedStatus(id);
            if (type === "posted") {
                getPostedAnnouncement();
            } else if (type === "upcoming") {
                getUpcomingAnnouncement();
            }
        } catch (error) {
            appToast(sanitizeError(error), false);
        }
    };

    const deleteAnnouncementClicked = (announcementId) => {
        setDeleteAnnouncementId(announcementId);
        setConfirmModal({
            ...confirmModal,
            details: "Confirm to delete?",
            confirmDisable: false,
        });
        setModalIsOpenSequence(["DELETE_CONFIRMATION"]);
    };

    const timeValidation = (schedulePost, type, startDate, endDate, startTime, endTime) => {
        if (schedulePost === "schedule" && startDate && endDate && startTime && endTime) {
            let startTimeFormatted = moment(`${startDate} ${startTime}`, "DD/MM/YYYY hh.mm A");
            let endTimeFormatted = moment(`${endDate} ${endTime}`, "DD/MM/YYYY hh.mm A");
            if (startDate.toLowerCase() === "invalid date") {
                setTimeError({
                    ...timeError,
                    startDate: "Invalid date.",
                });
            }
            if (endDate.toLowerCase() === "invalid date") {
                setTimeError({
                    ...timeError,
                    endDate: "Invalid date.",
                });
            }
            if (moment(startTimeFormatted).isAfter(moment(endTimeFormatted)) || startTimeFormatted._i === endTimeFormatted._i) {
                setTimeError({
                    startDate: "Start time and date should be before end time and date.",
                    startTime: "Start time and date should be before end time and date.",
                    endDate: "End Time and date should be after start time and date.",
                    endTime: "End Time and date should be after start time and date.",
                });
            } else {
                setTimeError({
                    startDate: "",
                    startTime: "",
                    endDate: "",
                    endTime: "",
                });
            }
        } else {
            setTimeError({
                startDate: "",
                startTime: "",
                endDate: "",
                endTime: "",
            });
        }
    };

    const onHandleSubmit = (values) => {
        timeValidation(values.announcementType, "", values.startDate, values.endDate, values.startTime, values.endTime);
        if (Object.values(timeError).every((e) => e === "")) {
            setConfirmModal({
                details: "Confirm to create?",
                confirmDisable: false,
                values: values,
            });
            setModalIsOpenSequence([...modalIsOpenSequence, "CREATE_CONFIRMATION"]);
        }
    };

    const resetCreateAnnouncement = (resetForm) => {
        setTimeError({
            startDate: "",
            startTime: "",
            endDate: "",
            endTime: "",
        });
        if (resetForm) {
            resetForm();
        }
        setTimePickerTouched({
            startTime: false,
            endTime: false,
        });
        setModalIsOpenSequence([]);
    };

    const confirmationModalConfirmed = async (resetForm, values) => {
        if (confirmModal.details === "Confirm to delete?") {
            try {
                let payload = deleteAnnouncementId;
                await api.get.dashboardDeleteAnnouncement(payload);
                getPostedAnnouncement();
                getUpcomingAnnouncement();
                setModalIsOpenSequence([]);
                resetCreateAnnouncement();
                appToast("Announcement has been deleted successfully.", true);
                setDeleteAnnouncementId(null);
                setConfirmModal(confirmModalInitialState);
            } catch (error) {
                appToast(sanitizeError(error), false);
            }
        } else if (confirmModal.details === "Confirm to create?") {
            try {
                const payload = {
                    title: values.title,
                    description: values.description,
                    announcementType: values.announcementType,
                    startDate: values.startDate ? moment(values.startDate, "DD/MM/YYYY").format("YYYY-MM-DD") : "",
                    startTime: values.startTime,
                    endDate: values.endDate ? moment(values.endDate, "DD/MM/YYYY").format("YYYY-MM-DD") : "",
                    endTime: values.endTime,
                    pinStatus: pinned ? CONSTANT.BE_STATUS.CREATE_ANNOUNCEMENT.PIN : CONSTANT.BE_STATUS.CREATE_ANNOUNCEMENT.REMOVE_PIN,
                };
                const formData = new FormData();
                const attachment = values.attachment;
                const dataBlob = new Blob([JSON.stringify(payload)], { type: "application/json" });

                formData.append("data", dataBlob);
                formData.append("attachment", attachment);

                await api.post.dashboardCreateAnnouncement(formData);
                getPostedAnnouncement();
                getUpcomingAnnouncement();
                setModalIsOpenSequence([]);
                setPinned(false);
                resetCreateAnnouncement(resetForm);
                appToast("Announcement has been created successfully.", true);
                setConfirmModal(confirmModalInitialState);
            } catch (error) {
                appToast(sanitizeError(error), false);
            }
        } else return null;
    };

    return (
        <AppPagesLayout>
            <Formik initialValues={createAnnouncementInitialValues} validationSchema={createAnnouncementValidationSchema} onSubmit={onHandleSubmit}>
                {({ handleSubmit, values, setFieldValue, errors, setFieldTouched, touched, resetForm }) => (
                    <div className="page-dashboard">
                        <AppModalConfirmation
                            isOpenModal={modalIsOpenSequence.slice(-1)[0] === "DELETE_CONFIRMATION" || modalIsOpenSequence.slice(-1)[0] === "CREATE_CONFIRMATION"}
                            onRequestClose={() => {
                                setModalIsOpenSequence(modalIsOpenSequence.slice(0, -1));
                                setConfirmModal(confirmModalInitialState);
                            }}
                            details={confirmModal.details}
                            onClick={() => confirmationModalConfirmed(resetForm, values)}
                            buttonDisabled={confirmModal.confirmDisable}
                            buttonLabel="Confirm"
                        />
                        <AppModal isOpenModal={pdfModalOpen} onRequestClose={() => setPdfModalOpen(false)} emptyModal={true}>
                            <div className="page-employee-confirmation__modal-attachment">
                                <object width="100%" height="100%" data={attachmentUrlSelected} type="application/pdf">
                                    {" "}
                                </object>
                            </div>
                        </AppModal>
                        <AppModal
                            title="Create Announcement"
                            headerRight={
                                <div onClick={() => setPinned(!pinned)} className="page-dashboard__create-announcement-pin">
                                    {pinned ? <img alt="" src={iconPinned} /> : <img alt="" src={iconPin} />}
                                </div>
                            }
                            isOpenModal={modalIsOpenSequence.slice(-1)[0] === "CREATE_MODAL"}
                            onClick={() => {
                                setTimePickerIsOpen("");
                                timeValidation(values.announcementType, "endTime", values.startDate, values.endDate, values.startTime, values.endTime);
                            }}
                            onRequestClose={() => {
                                setModalIsOpenSequence(modalIsOpenSequence.slice(0, -1));
                                resetCreateAnnouncement(resetForm);
                            }}>
                            <div className="page-dashboard__create-announcement">
                                <div className="create-announcement__row-1">
                                    <div className="create-announcement__column-1">
                                        {createAnnouncementFields.map((o, i) => {
                                            if (i < 3) {
                                                if (o.type === "text") {
                                                    return (
                                                        <div key={i} className="create-announcement__input-wrapper">
                                                            <AppInputWithLabel
                                                                placeholder={o.placeholder}
                                                                value={values[o.name]}
                                                                onChange={(e) => {
                                                                    setFieldTouched(o.name);
                                                                    setFieldValue(o.name, e.target.value);
                                                                }}
                                                                error={touched[o.name] && errors[o.name]}
                                                                maxLength={o.maxLength ? o.maxLength : null}
                                                            />
                                                        </div>
                                                    );
                                                } else if (o.type === "textarea") {
                                                    return (
                                                        <div key={i} className="create-announcement__input-wrapper">
                                                            <AppTextArea
                                                                placeholder={o.placeholder}
                                                                onChange={(e) => {
                                                                    setFieldTouched(o.name);
                                                                    setFieldValue(o.name, e.target.value);
                                                                }}
                                                                error={touched[o.name] && errors[o.name]}
                                                                value={values[o.name]}
                                                                maxLength={o.maxLength ? o.maxLength : null}
                                                            />
                                                        </div>
                                                    );
                                                } else if (o.type === "upload") {
                                                    return (
                                                        <div key={i}>
                                                            <AppInputUploadAttachment
                                                                accept="application/pdf"
                                                                placeholder={o.placeholder}
                                                                onChange={(e) => {
                                                                    setFieldValue(o.name, e.target.files[0]);
                                                                }}
                                                                value={values[o.name] ? values[o.name].name : ""}
                                                            />
                                                        </div>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </div>
                                    <div className="create-announcement__column-2">
                                        {createAnnouncementFields.map((o, i) => {
                                            if (i >= 3) {
                                                if (o.type === "dropdown") {
                                                    return (
                                                        <div key={i} className="create-announcement__input-wrapper">
                                                            <AppDropdown
                                                                placeholder={o.placeholder}
                                                                onChange={(selected) => {
                                                                    setFieldTouched(o.name);
                                                                    setFieldValue(o.name, selected.value);
                                                                    setTimeError({
                                                                        startDate: "",
                                                                        startTime: "",
                                                                        endDate: "",
                                                                        endTime: "",
                                                                    });
                                                                    if (selected.value === "immediate") {
                                                                        setFieldValue("startDate", "");
                                                                        setFieldValue("startTime", "");
                                                                        setTimePickerTouched({
                                                                            ...timePickerTouched,
                                                                            startTime: false,
                                                                        });
                                                                    } else if (selected.value === "evergreen") {
                                                                        setFieldValue("endDate", "");
                                                                        setFieldValue("endTime", "");
                                                                        setTimePickerTouched({
                                                                            ...timePickerTouched,
                                                                            endTime: false,
                                                                        });
                                                                    } else {
                                                                        return;
                                                                    }
                                                                }}
                                                                dropdownOptions={schedulePostDropdown}
                                                                currentInputValue={values[o.name] ? schedulePostDropdown.filter((ele) => ele.value === values[o.name])[0] : ""}
                                                                error={touched[o.name] && errors[o.name]}
                                                                value={values[o.name]}
                                                            />
                                                        </div>
                                                    );
                                                } else if ((o.name === "startDate" || o.name === "startTime") && (values.announcementType === "evergreen" || values.announcementType === "schedule")) {
                                                    if (o.type === "date") {
                                                        return (
                                                            <div key={i} className="create-announcement__input-wrapper">
                                                                <AppInputDate
                                                                    placeholder={o.placeholder}
                                                                    onChange={(value) => {
                                                                        setFieldTouched(o.name);
                                                                        setFieldValue(o.name, value);
                                                                        if (touched[o.name]) {
                                                                            timeValidation(values.announcementType, "startDate", value, values.endDate, values.startTime, values.endTime);
                                                                        }
                                                                    }}
                                                                    minDate="today"
                                                                    error={timeError.startDate ? timeError.startDate : touched[o.name] ? errors[o.name] : ""}
                                                                    value={values[o.name]}
                                                                />
                                                            </div>
                                                        );
                                                    } else if (o.type === "time") {
                                                        return (
                                                            <div key={i} className="create-announcement__input-wrapper" onClick={(e) => e.stopPropagation()}>
                                                                <AppTimePicker
                                                                    placeholder={o.placeholder}
                                                                    onFocus={(e, placeholder) => {
                                                                        setFieldTouched(o.name);
                                                                        setTimePickerTouched({
                                                                            ...timePickerTouched,
                                                                            [o.name]: true,
                                                                        });
                                                                        setTimePickerIsOpen(placeholder);
                                                                    }}
                                                                    timeIsOpenPlaceholder={timePickerIsOpen}
                                                                    startTime={{
                                                                        hours: "10",
                                                                        minutes: "00",
                                                                        ampm: "AM",
                                                                    }}
                                                                    valueFunc={(value) => {
                                                                        let timeFormatted = `${value.hours}.${value.minutes} ${value.ampm}`;
                                                                        if (values[o.name] !== timeFormatted) {
                                                                            setFieldValue(o.name, timeFormatted);
                                                                        }
                                                                    }}
                                                                    touched={timePickerTouched[o.name]}
                                                                    error={timeError.startTime ? timeError.startTime : touched[o.name] ? errors[o.name] : ""}
                                                                />
                                                            </div>
                                                        );
                                                    } else return null;
                                                } else if ((o.name === "endDate" || o.name === "endTime") && (values.announcementType === "immediate" || values.announcementType === "schedule")) {
                                                    if (o.type === "date") {
                                                        return (
                                                            <div key={i} className="create-announcement__input-wrapper">
                                                                <AppInputDate
                                                                    placeholder={o.placeholder}
                                                                    onChange={(value) => {
                                                                        setFieldTouched(o.name);
                                                                        setFieldValue(o.name, value);
                                                                        if (touched[o.name]) {
                                                                            timeValidation(values.announcementType, "endDate", values.startDate, value, values.startTime, values.endTime);
                                                                        }
                                                                    }}
                                                                    minDate="today"
                                                                    error={timeError.endDate ? timeError.endDate : touched[o.name] ? errors[o.name] : ""}
                                                                    value={values[o.name]}
                                                                />
                                                            </div>
                                                        );
                                                    } else if (o.type === "time") {
                                                        return (
                                                            <div key={i} className="create-announcement__input-wrapper" onClick={(e) => e.stopPropagation()}>
                                                                <AppTimePicker
                                                                    placeholder={o.placeholder}
                                                                    onFocus={(e, placeholder) => {
                                                                        setFieldTouched(o.name);
                                                                        setTimePickerTouched({
                                                                            ...timePickerTouched,
                                                                            [o.name]: true,
                                                                        });
                                                                        setTimePickerIsOpen(placeholder);
                                                                    }}
                                                                    timeIsOpenPlaceholder={timePickerIsOpen}
                                                                    startTime={{
                                                                        hours: "11",
                                                                        minutes: "00",
                                                                        ampm: "AM",
                                                                    }}
                                                                    valueFunc={(value) => {
                                                                        let timeFormatted = `${value.hours}.${value.minutes} ${value.ampm}`;
                                                                        if (values[o.name] !== timeFormatted) {
                                                                            setFieldValue(o.name, timeFormatted);
                                                                        }
                                                                    }}
                                                                    touched={timePickerTouched[o.name]}
                                                                    error={timeError.endTime ? timeError.endTime : touched[o.name] ? errors[o.name] : ""}
                                                                />
                                                            </div>
                                                        );
                                                    } else return null;
                                                } else {
                                                    return null;
                                                }
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </div>
                                </div>
                                <div className="create-announcement__row-2">
                                    <div className="create-announcement__button-wrapper">
                                        <AppButton
                                            label="Cancel"
                                            buttonType="outline"
                                            onClick={() => {
                                                setModalIsOpenSequence(modalIsOpenSequence.slice(0, -1));
                                                resetCreateAnnouncement(resetForm);
                                            }}
                                        />
                                    </div>
                                    <div className="create-announcement__button-wrapper">
                                        <AppButton label="Create" onClick={handleSubmit} />
                                    </div>
                                </div>
                            </div>
                        </AppModal>
                        <div className="page-dashboard__header">
                            <div className="page-dashboard__header-welcome">Welcome back{profile && profile.staffUserName ? `, ${profile.staffUserName}` : ""}</div>
                            <div className="page-dashboard__header-datetime">{currentTime}</div>
                        </div>
                        <div className="page-dashboard__contents">
                            <div className="page-dashboard__column-1">
                                <div className="page-dashboard__info-row">
                                    <div className="page-dashboard__card-styling" style={{ width: "50%", height: 145 }}>
                                        <div className="employee-graph-card">
                                            <div style={{ height: "100%" }}>
                                                <div className="page-dashboard__card-contents-title">New Employee</div>
                                                <div className="employee-graph-card__row">
                                                    <div className="employee-graph-card__column">
                                                        <div className="employee-graph-card__numbers-row">
                                                            <div className="employee-graph-card__numbers">{newEmployeeData.employeeCount ? newEmployeeData.employeeCount : "0"}</div>
                                                            <div className={getNewEmployeePercentageClassNames()}>+ {newEmployeeData.percentage ? newEmployeeData.percentage : ""} </div>
                                                            <img alt="" src={iconArrowUp} className={getNewEmployeeArrowClassNames()} />
                                                        </div>
                                                        <div className="employee-graph-card__time-period">(Last 7 days)</div>
                                                    </div>
                                                    <div className="employee-graph-card__column">
                                                        <LineChart width={140} height={100} data={newEmployeeData.frequency ? newEmployeeData.frequency : []}>
                                                            <Tooltip content={<CustomToolTip />} offset={20} />
                                                            <Line type="monotone" dataKey="employee" stroke="#85d19f" strokeWidth={2} dot={false} animationDuration={1000} />
                                                        </LineChart>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="page-dashboard__card-styling" style={{ width: "50%", height: 145 }}>
                                        <div className="employee-graph-card">
                                            <div style={{ height: "100%" }}>
                                                <div className="page-dashboard__card-contents-title">Employee Turnover</div>
                                                <div className="employee-graph-card__row">
                                                    <div className="employee-graph-card__column">
                                                        <div className="employee-graph-card__numbers-row">
                                                            <div className="employee-graph-card__numbers">{turnoverEmployeeData.employeeCount ? turnoverEmployeeData.employeeCount : "0"}</div>
                                                            <div className={getNewEmployeePercentageClassNames("down")}>- {turnoverEmployeeData.percentage} </div>
                                                            <img alt="" src={iconArrowUp} className={getNewEmployeeArrowClassNames("down")} />
                                                        </div>
                                                        <div className="employee-graph-card__time-period">(Last 7 days)</div>
                                                    </div>
                                                    <div className="employee-graph-card__column">
                                                        <LineChart width={140} height={100} data={turnoverEmployeeData.frequency}>
                                                            <Tooltip content={<CustomToolTip />} />
                                                            <Line type="monotone" dataKey="employee" stroke="#ed7f7f" strokeWidth={2} dot={false} animationDuration={1000} />
                                                        </LineChart>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="page-dashboard__info-row">
                                    <div className="page-dashboard__card-styling" style={{ width: "50%", height: 155 }}>
                                        <div className="employee-on-leave">
                                            <div className="employee-on-leave__header">
                                                <div className="page-dashboard__card-contents-title">Employee on Leave</div>
                                                <button className="employee-on-leave__view-all-button" onClick={() => history.push(pathnames.pageLeaveManagementCalendar)}>
                                                    <span className="employee-on-leave__view-all">View All</span> <img alt="" src={iconArrowRight} className="page-dashboard__arrow-right" />
                                                </button>
                                            </div>
                                            <div className="employee-on-leave__body">
                                                {employeeOnLeaveData.length ? (
                                                    employeeOnLeaveData.map((obj, index) => {
                                                        if (index < 3) {
                                                            return <ListItem key={index} imgUrl={obj.userImage} infoColor="red" details={obj.staffUserName} info={obj.leaveType} />;
                                                        } else return null;
                                                    })
                                                ) : (
                                                    <div className="page-dashboard__empty-card employee-on-leave__empty-card">No employee on leave today</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="page-dashboard__card-styling" style={{ width: "50%", height: 155 }}>
                                        <div className="total-employee">
                                            <div className="total-employee__column-1">
                                                <div className="page-dashboard__card-contents-title">Total Employee</div>
                                                <PieChart width={120} height={100}>
                                                    <Pie data={pieData} dataKey="value" nameKey="name" innerRadius={23} outerRadius={32} paddingAngle={1} label={renderCustomizedLabel} maxRadius={50} labelLine={false} animationDuration={750}>
                                                        {pieData.map((entry, index) => {
                                                            return <Cell key={`cell-${index}`} fill={pieColorLabel.find((o) => o.name === entry.name).color} />;
                                                        })}
                                                    </Pie>
                                                </PieChart>
                                            </div>
                                            <div className="total-employee__column-2">
                                                <div className="total-employee__total-value">{totalEmployeeData.totalEmp}</div>
                                                <div>
                                                    {pieColorLabel.map((e, i) => (
                                                        <div key={i} className="total-employee__legend">
                                                            <div className="total-employee__legend-color" style={{ backgroundColor: e.color }} />
                                                            <span className="total-employee__legend-details">{e.label}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="page-dashboard__info-row">
                                    <div className="page-dashboard__card-styling" style={{ width: "100%", height: 340 }}>
                                        <div className="announcement">
                                            <div className="announcement__header">
                                                <div className="announcement__title">Announcements</div>
                                                <div className="announcement__header-button-wrapper">
                                                    <AppButton
                                                        buttonIcon={<img alt="" src={iconAnnouncement} />}
                                                        size="s"
                                                        label="Create Announcement"
                                                        buttonType="outline"
                                                        onClick={() => {
                                                            setModalIsOpenSequence(["CREATE_MODAL"]);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="announcement__body">
                                                <div className="announcement__tab-header-row">
                                                    <div className={tabClassNames("posted", tabSelected)} onClick={() => setTabSelected("posted")}>
                                                        Posted
                                                    </div>
                                                    <div className={tabClassNames("upcoming", tabSelected)} onClick={() => setTabSelected("upcoming")}>
                                                        Upcoming
                                                    </div>
                                                </div>
                                                <div className="announcement__contents">
                                                    <div className={hideContentClassNames("posted", tabSelected)}>
                                                        {postedAnnouncement.length
                                                            ? postedAnnouncement.map((o, i) => (
                                                                  <div key={i} className="announcement__contents-row-wrapper">
                                                                      <div className="announcement__contents-text-column">
                                                                          <div className="announcement__contents-title">{o.title}</div>
                                                                          <div className="announcement__contents-details">{o.description}</div>
                                                                      </div>
                                                                      <div className="announcement__contents-button-column">
                                                                          <div>
                                                                              {o.attachment ? (
                                                                                  <img
                                                                                      className="announcement__pdf-file"
                                                                                      alt=""
                                                                                      src={iconFilePdf}
                                                                                      onClick={() => {
                                                                                          setAttachmentUrlSelected(o.attachment);
                                                                                          setPdfModalOpen(true);
                                                                                      }}
                                                                                  />
                                                                              ) : null}
                                                                              <button onClick={() => changePinStatus(o.id, "posted")} className="announcement__contents-button">
                                                                                  {o.pinnedStatus === "true" ? <img alt="" src={iconPinned} /> : <img alt="" src={iconPin} />}
                                                                              </button>
                                                                              <button
                                                                                  className="announcement__contents-button"
                                                                                  onClick={() => {
                                                                                      deleteAnnouncementClicked(o.id);
                                                                                  }}>
                                                                                  <img alt="" src={iconDelete} className="announcement__delete-icon" />
                                                                              </button>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              ))
                                                            : <div className="announcement__tab-empty">No announcement available</div>}
                                                    </div>
                                                    <div className={hideContentClassNames("upcoming", tabSelected)}>
                                                        {upcomingAnnouncement.length
                                                            ? upcomingAnnouncement.map((o, i) => (
                                                                  <div key={i} className="announcement__contents-row-wrapper">
                                                                      <div className="announcement__contents-text-column">
                                                                          <div className="announcement__contents-title">{o.title}</div>
                                                                          <div className="announcement__contents-details">{o.description}</div>
                                                                      </div>
                                                                      <div className="announcement__contents-button-column">
                                                                          <div>
                                                                              {o.attachment ? (
                                                                                  <img
                                                                                      className="announcement__pdf-file"
                                                                                      alt=""
                                                                                      src={iconFilePdf}
                                                                                      onClick={() => {
                                                                                          setAttachmentUrlSelected(o.attachment);
                                                                                          setPdfModalOpen(true);
                                                                                      }}
                                                                                  />
                                                                              ) : null}
                                                                              <button className="announcement__contents-button" onClick={() => changePinStatus(o.id, "upcoming")}>
                                                                                  {o.pinnedStatus === "true" ? <img alt="" src={iconPinned} /> : <img alt="" src={iconPin} />}
                                                                              </button>
                                                                              <button
                                                                                  className="announcement__contents-button"
                                                                                  onClick={() => {
                                                                                      deleteAnnouncementClicked(o.id);
                                                                                  }}>
                                                                                  <img alt="" src={iconDelete} className="announcement__delete-icon" />
                                                                              </button>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              ))
                                                              : <div className="announcement__tab-empty">No announcement available</div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="page-dashboard__column-2">
                                <div className="page-dashboard__card-styling" style={{ paddingRight: 5 }}>
                                    <div className="celebration-corner">
                                        <div className="page-dashboard__card-contents-title">Celebration Corner</div>
                                        <div className="celebration-corner__contents" style={{ maxHeight: 220, overflow: "scroll" }}>
                                            {birthdayDate.length ? (
                                                birthdayDate.map((o, i) => <ListItem key={i} imgUrl={o.userImage} infoColor="blue" details={o.username} info={o.dob} style={{ paddingRight: 10 }} />)
                                            ) : (
                                                <div className="page-dashboard__empty-card celebration-corner__empty-card">No birthday this month</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="page-dashboard__card-styling" style={{ height: "auto" }}>
                                    <div className="holidy-this-month">
                                        <div className="page-dashboard__card-contents-title">Holiday this Month</div>
                                        <div className="holiday-this-month__contents">
                                            {publicHolidayDate.length ? (
                                                publicHolidayDate.map((o, i) => <ListItem key={i} imgUrl={iconHoliday} infoColor="blue" details={o.holiday} info={o.holidayDate} style={{ paddingRight: 10 }} />)
                                            ) : (
                                                <div className="page-dashboard__empty-card holiday-this-month__empty-card">No holiday this month</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="page-dashboard__card-styling" style={{ height: 158 }}>
                                    <div className="pending">
                                        {pendingInfo.map((o, i) => (
                                            <div key={i} className="pending__contents">
                                                <div>
                                                    <div className="page-dashboard__card-contents-title">{o.title}</div>
                                                    <button className="pending__pending-button" onClick={o.buttonOnClick}>
                                                        {o.buttonText} <img alt="" src={iconArrowRight} className="page-dashboard__arrow-right" />
                                                    </button>
                                                </div>
                                                <div className="pending__pending-value" style={{ color: o.valueColor }}>
                                                    {o.value}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Formik>
        </AppPagesLayout>
    );
};

export default PageDashboard;
