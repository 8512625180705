import React, { useState, useEffect } from "react";

const getWrapperClassNames = (focused, error, disabled) => {
    const classNames = ["app-text-area__wrapper"];
    if (error) classNames.push("app-text-area__error");
    if (focused) classNames.push("app-text-area__focused");
    if (disabled) classNames.push("app-text-area__disabled");
    return classNames.join(" ");
};

const getTextareaClassNames = (error) => {
    const classNames = ["app-text-area__input"];

    return classNames.join(" ");
};

const AppTextArea = ({ onChange, value, placeholder, error, onBlur, label, disabled, maxLength }) => {
    const [focused, setFocused] = useState(false);
    const [showLabel, setShowLabel] = useState(false);

    useEffect(() => {
        if (value && !focused) {
            setShowLabel(true);
        }
    }, [value, focused]);

    const textareaOnFocusHandler = () => {
        setFocused(true);
        setShowLabel(false);
    };

    const textareaOnBlurHandler = () => {
        setFocused(false);
        if (onBlur) {
            onBlur();
        }
        if (value) {
            setShowLabel(true);
        }
    };

    return (
        <div className="app-text-area">
            <div className={getWrapperClassNames(focused, error, disabled)}>
                <textarea disabled={disabled} onBlur={textareaOnBlurHandler} onFocus={textareaOnFocusHandler} placeholder={placeholder} className={getTextareaClassNames(error)} onChange={onChange} value={value} maxLength={maxLength}></textarea>
                {showLabel ? <div className="app-text-area__label">{label ? label : placeholder}</div> : null}
            </div>
            {error ? <span className="app-text-area__error-message">{error}</span> : null}
        </div>
    );
};

export default AppTextArea;
