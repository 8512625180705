// libraries
import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
// components
import AppPagesLayout from "components/app-pages-layout";
import AppBreadcrumb from "components/app-breadcrumb";
import AppTabLink from "components/app-tab-link";
import AppReactTable from "components/app-react-table";
import AppButton from "components/app-button";
import AppModal from "components/app-modal";
import AppTextArea from "components/app-text-area";
import appToast from "components/app-toast";
import AppDualColumnsModal from "components/app-dual-columns-modal";
import AppInputWithLabel from "components/app-input-with-label";
import AppAttachmentImage from "components/app-attachment-image";
// service
import api from "services/api";
// common
import { sanitizeError } from "common/utilities";
// routes
import employeePathnames from "routes/employee-pathnames";
// assets
import iconCalendar from "assets/images/components/app-button/icon-calendar.svg";
// new claim
import AppEmployeeCreateNewClaim from "./new-claim";

const breadcrumb = [
    {
        label: "Claim Management",
    },
    {
        label: "Pending Claim",
    },
];

const attachmentSrcInitialState = {
    file: "",
    type: "",
};

const pendingClaimDetailsInitialValues = {
    amount: "",
    approveAmount: "",
    attachment: "",
    claimType: "",
    currentBalance: "",
    desc: "",
    id: "",
    rejectReason: "",
    remark: "",
    staffId: "",
    status: "",
    submitDate: "",
    userName: "",
    fileType: "",
};

const PageEmployeePendingClaim = () => {
    const profile = useSelector((state) => state.auth);
    const staffId = profile ? profile.staffID : "";
    const [tableData, setTableData] = useState([]);
    const [pendingClaimDetails, setPendingClaimDetails] = useState(pendingClaimDetailsInitialValues);
    const [attachmentSrc, setAttachmentSrc] = useState(attachmentSrcInitialState);
    const createClaimModal = useRef(null);
    const [modalIsOpenSequence, setModalIsOpenSequence] = useState([]);

    const appTabLink = [
        {
            label: "Claim Summary",
            pathname: employeePathnames.pageEmployeeClaimSummary,
        },
        {
            label: "Claim History",
            pathname: employeePathnames.pageEmployeeClaimHistory,
        },
        {
            label: "Pending Claim",
            pathname: employeePathnames.pageEmployeePendingClaim,
        },
    ];

    const getTableData = async (staffId) => {
        try {
            let payload = ("000" + staffId).slice(-3);
            const response = await api.get.claimManagementPendingClaim(payload);
            setTableData(response.data.result.content);
        } catch (error) {
            let sanitizedError = sanitizeError(error);
            appToast(sanitizedError, false);
        }
    };

    useEffect(() => {
        getTableData(staffId);
    }, [staffId]);

    const tableColumn = [
        {
            id: "claimType",
            Header: (header) => {
                return <span className="react-table__claim-type-header">Claim Type</span>;
            },
            Cell: (row) => {
                return <span className="react-table__claim-type-data">{row.row.original.claimType}</span>;
            },
        },
        {
            id: "submitDate",
            Header: () => {
                return <span>Submit Date</span>;
            },
            Cell: (row) => {
                return <span>{moment(row.row.original.submitDate).format("DD/MM/YYYY")}</span>;
            },
            maxWidth: 80,
        },
        {
            id: "amount",
            Header: () => {
                return <span>Bill amount (RM)</span>;
            },
            Cell: (row) => {
                return (
                    <div className="react-table__amount">
                        <div>{row.row.original.amount.toFixed(2)}</div>
                    </div>
                );
            },
            maxWidth: 70,
        },
        {
            id: "attachment",
            Header: () => {
                return <span className="react-table__header">Attachment</span>;
            },
            Cell: (row) => {
                const fileSplitted = row.row.original.attachment.split(".");
                const fileType = fileSplitted[fileSplitted.length - 1];
                return (
                    <div className="react-table__attachment" onClick={(e) => e.stopPropagation()}>
                        <AppAttachmentImage
                            fileType={fileType}
                            onClick={() => {
                                setAttachmentSrc({
                                    file: row.row.original.attachment,
                                    type: fileType,
                                });
                                setModalIsOpenSequence(["MODAL_ATTACHMENT"]);
                            }}
                        />
                    </div>
                );
            },
            accessor: "attachment",
            maxWidth: 60,
            searchFilterValue: "attachment",
        },
    ];

    const tableRowClicked = async (row) => {
        try {
            const response = await api.get.claimManagementPendingClaimDetails(row.id);
            let result = response.data.result;
            const fileSplitted = result.attachment.split(".");
            const fileType = fileSplitted[fileSplitted.length - 1];
            setPendingClaimDetails({ ...result, fileType: fileType });
            setModalIsOpenSequence(["MODAL_CLAIM_DETAILS"]);
        } catch (error) {
            appToast(sanitizeError(error), false);
        }
    };

    return (
        <AppPagesLayout>
            <div className="page-pending-claim">
                <AppDualColumnsModal
                    title="Claim Details"
                    isOpenModal={modalIsOpenSequence.slice(-1)[0] === "MODAL_CLAIM_DETAILS"}
                    onRequestClose={() => {
                        setModalIsOpenSequence(modalIsOpenSequence.slice(0, -1));
                        setPendingClaimDetails(pendingClaimDetailsInitialValues);
                    }}
                    status="Pending"
                    statusColor="orange"
                    childrenLeft={
                        <div>
                            <div className="page-pending-claim__modal-input-wrapper">
                                <AppInputWithLabel placeholder="Username" value={pendingClaimDetails.userName} disabled={true} />
                            </div>
                            <div className="page-pending-claim__modal-input-wrapper">
                                <AppInputWithLabel placeholder="Claim Type" value={pendingClaimDetails.claimType} disabled={true} />
                            </div>
                            {pendingClaimDetails.attachment ? (
                                <div
                                    className="page-pending-claim__modal-input-wrapper page-pending-claim__modal-input-wrapper--hover"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        setAttachmentSrc({
                                            file: pendingClaimDetails.attachment,
                                            type: pendingClaimDetails.fileType,
                                        });
                                        setModalIsOpenSequence([...modalIsOpenSequence, "MODAL_ATTACHMENT"]);
                                    }}>
                                    <span className="page-pending-claim__modal-icon-wrapper">
                                        <AppAttachmentImage fileType={pendingClaimDetails.fileType} />
                                    </span>
                                    <AppInputWithLabel
                                        placeholder="Attachment"
                                        value={`        ${pendingClaimDetails.attachment.split("/")[pendingClaimDetails.attachment.split("/").length - 1]}`}
                                        disabled={true}
                                        onClick={() => {
                                            setAttachmentSrc({
                                                file: pendingClaimDetails.attachment,
                                                type: pendingClaimDetails.fileType,
                                            });
                                            setModalIsOpenSequence([...modalIsOpenSequence, "MODAL_ATTACHMENT"]);
                                        }}
                                    />
                                </div>
                            ) : null}
                            <div className="page-pending-claim__modal-input-wrapper">
                                <AppTextArea placeholder="Description" value={pendingClaimDetails.desc ? pendingClaimDetails.desc : ""} disabled={true} />
                            </div>
                        </div>
                    }
                    childrenRight={
                        <div>
                            <div className="page-pending-claim__modal-input-wrapper">
                                <AppInputWithLabel placeholder="Employee ID" value={pendingClaimDetails.staffId} disabled={true} />
                            </div>
                            <div className="page-pending-claim__modal-input-wrapper">
                                <AppInputWithLabel placeholder="Bill Amount (RM)" value={pendingClaimDetails.amount} disabled={true} />
                            </div>
                            <div className="page-pending-claim__modal-input-wrapper">
                                <AppInputWithLabel placeholder="Submit Date" value={pendingClaimDetails.submitDate} disabled={true} />
                            </div>
                        </div>
                    }
                />
                <AppModal
                    isOpenModal={modalIsOpenSequence.slice(-1)[0] === "MODAL_ATTACHMENT"}
                    onRequestClose={() => {
                        setModalIsOpenSequence(modalIsOpenSequence.slice(0, -1));
                        setTimeout(() => {
                            setAttachmentSrc(attachmentSrcInitialState);
                        }, [250]);
                    }}
                    emptyModal={true}>
                    {attachmentSrc.type === "pdf" ? (
                        <div className="page-pending-claim__modal-attachment">
                            <object width="100%" height="100%" data={attachmentSrc.file} type="application/pdf">
                                {" "}
                            </object>
                        </div>
                    ) : (
                        <img className="page-pending-claim__modal-image" alt="" src={attachmentSrc.file} />
                    )}
                </AppModal>
                <AppEmployeeCreateNewClaim ref={createClaimModal} onSuccess={() => getTableData(staffId)} />

                <div className="page-pending-claim__breadcrumb">
                    <AppBreadcrumb list={breadcrumb} />
                </div>

                <div className="page-pending-claim__button-row">
                    <div className="page-pending-claim__new-button">
                        <AppButton buttonIcon={<img src={iconCalendar} alt="" className="page-pending-claim__calendar-icon" />} size="s" label="New Claim" onClick={() => createClaimModal.current.createClaimModalIsOpen()} />
                    </div>
                </div>

                <div className="page-pending-claim__tab-wrapper">
                    <AppTabLink tabList={appTabLink} />
                </div>
                <div className="page-pending-claim__table-wrapper">
                    <AppReactTable columns={tableColumn} data={tableData} onClickRow={tableRowClicked} />
                </div>
            </div>
        </AppPagesLayout>
    );
};

export default PageEmployeePendingClaim;
