// libraries
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
// components
import AppButton from "components/app-button";
import AppAuthLayout from "components/app-auth-layout";
import AppInput from "components/app-input";
// utilities
import { sanitizeError } from "common/utilities";
// api
import api from "services/api";
// assets
import logoAtf from "assets/images/logo-atf.svg";
// pathnames
import pathnames from "routes/pathnames";

const initialValues = {
    email: "",
};

const validationSchema = yup.object().shape({
    email: yup.string().email("Please enter a valid email").required("Email is required"),
});

const PageResetPassword = () => {
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState("");
    const [submitBtn, setSubmitBtn] = useState({
        label: "Submit",
        disabled: false,
    });

    const onSubmit = async (values) => {
        setSubmitBtn({
            label: "Submitting...",
            disabled: true,
        });
        try {
            const params = {
                email: values.email,
            };
            await api.post.forgotPassword(null, params);
            setSubmitBtn({
                label: "Submit",
                disabled: false,
            });
            history.push(pathnames.pageResetPasswordCheckEmail, { email: values.email });
        } catch (error) {
            const errorMessage = sanitizeError(error);
            setErrorMessage(errorMessage);
            setSubmitBtn({
                label: "Submit",
                disabled: false,
            });
        }
    };

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ handleSubmit, errors, setFieldValue, values }) => (
                <AppAuthLayout>
                    <div className="page-reset-password">
                        <div className="page-reset-password__contents">
                            <img className="page-reset-password__logo" src={logoAtf} alt="" />
                            <div className="page-reset-password__title">Reset Password</div>
                            <div className="page-reset-password__subtitle">Input your email</div>
                            <div className="page-reset-password__input">
                                <AppInput placeholder="Email" onChange={(e) => setFieldValue("email", e.target.value)} value={values.email} error={errorMessage ? errorMessage : errors.email} onFocus={() => setErrorMessage("")} />
                            </div>
                            <div className="page-reset-password__button-wrapper">
                                <div className="page-reset-password__button">
                                    <AppButton label="Back to login" buttonType="outline" size="l" onClick={() => history.push(pathnames.pageLogin)} />
                                </div>
                                <div className="page-reset-password__button">
                                    <AppButton label={submitBtn.label} size="l" onClick={handleSubmit} disabled={submitBtn.disabled} />
                                </div>
                            </div>
                        </div>
                    </div>
                </AppAuthLayout>
            )}
        </Formik>
    );
};

export default PageResetPassword;
