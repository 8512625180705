// libraries
import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import moment from "moment";
import { BsShare } from "react-icons/bs";
// components
import AppPagesLayout from "components/app-pages-layout";
import AppBreadcrumb from "components/app-breadcrumb";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppPaginate from "components/app-paginate";
import AppInputWithLabel from "components/app-input-with-label";
import AppInputDate from "components/app-input-date";
import AppReactTableEditable from "components/app-react-table-editable";
import appToast from "components/app-toast";
import AppCheckbox from "components/app-checkbox";
// service
import api from "services/api";
// common
import { sanitizeError } from "common/utilities";
// hooks
import usePrevious from "hooks/use-previous";
// assets
import iconSearch from "assets/images/components/app-input/icon-search.svg";
import iconTailedArrowUp from "assets/images/icon-tailed-arrow-up.svg";
import iconSetting from "assets/images/icon-setting.svg";
import iconLeft from "assets/images/icon-arrow-left.svg";
import iconRight from "assets/images/icon-arrow-right.svg";

const breadcrumb = [
    {
        label: "Timesheet Management",
    },
];

const advanceSearch = [
    {
        name: "userName",
        placeholder: "Username",
        type: "input",
    },
    {
        name: "staffId",
        placeholder: "Employee ID",
        type: "input",
    },
    {
        name: "startDate",
        placeholder: "Start Date",
        type: "date",
    },
];

const setDetailSearchInitialValues = {
    userName: "",
    staffId: "",
    startDate: "",
};

const tableSearchInitialValues = {
    pageNo: 0,
    pageSize: 5,
    searchValue: "",
    sortBy: "",
    sortDir: "asc",
    startDate: moment().day(1).format("YYYY-MM-DD"),
};

const PageTimesheetManagement = () => {
    const [tableSearchFilter, setTableSearchFilter] = useState(tableSearchInitialValues);
    const [tableConfig, setTableConfig] = useState({
        content: [],
        last: null,
        pageNo: 0,
        pageSize: 5,
        totalElements: null,
        totalPages: 1,
        dateDay: [],
    });
    const prevValueSortHeader = usePrevious({
        sortBy: tableSearchFilter.sortBy,
        sortDir: tableSearchFilter.sortDir,
    });
    const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
    const searchKeywordInputRef = useRef(null);
    const [detailSearch, setDetailSearch] = useState(setDetailSearchInitialValues);
    const [idRowSelected, setIdRowSelected] = useState([]);
    const [allId, setAllId] = useState([]);
    // const [timesheetDataUpdate, setTimesheetDataUpdate] = useState([]);
    const [data, setData] = useState([]);

    const getTableData = async (payload) => {
        try {
            const response = await api.get.timesheetManagementListTimesheet(payload);
            const updateWeekdaysNullTo8 = response.data.result.content.map(o => {
                return {
                    ...o,
                    noOfHours1: o.noOfHours1 === null ? 8 : o.noOfHours1,
                    noOfHours2: o.noOfHours2 === null ? 8 : o.noOfHours2,
                    noOfHours3: o.noOfHours3 === null ? 8 : o.noOfHours3,
                    noOfHours4: o.noOfHours4 === null ? 8 : o.noOfHours4,
                    noOfHours5: o.noOfHours5 === null ? 8 : o.noOfHours5,
                    noOfHours6: o.noOfHours6 === null ? 0 : o.noOfHours6,
                    noOfHours7: o.noOfHours7 === null ? 0 : o.noOfHours7,
                }
            })
            setTableConfig({
                ...response.data.result,
                content: updateWeekdaysNullTo8
            });
            setData(updateWeekdaysNullTo8);
            setAllId(response.data.result.empIdList);
        } catch (error) {
            let sanitizedError = sanitizeError(error);
            appToast(sanitizedError, false);
        }
    };

    useEffect(() => {
        getTableData(tableSearchFilter);
    }, [tableSearchFilter]);

    const headerArrowRotate = (active) => {
        const classNames = ["react-table__arrow-icon"];
        if (active && tableSearchFilter.sortDir === "desc") classNames.push("react-table__arrow-icon-down");
        if (active) classNames.push("react-table__arrow-icon--active");

        return classNames.join(" ");
    };

    const headerFilterClick = (header) => {
        let column = header.column;
        if (prevValueSortHeader && prevValueSortHeader.sortBy === column.searchFilterValue) {
            if (prevValueSortHeader.sortDir === "asc") {
                setTableSearchFilter({
                    ...tableSearchFilter,
                    sortBy: column.searchFilterValue,
                    sortDir: "desc",
                    pageNo: 0,
                });
            } else {
                setTableSearchFilter({
                    ...tableSearchFilter,
                    sortBy: column.searchFilterValue,
                    sortDir: "asc",
                    pageNo: 0,
                });
            }
        } else {
            setTableSearchFilter({
                ...tableSearchFilter,
                sortBy: column.searchFilterValue,
                sortDir: "asc",
                pageNo: 0,
            });
        }
    };

    const selectRowFunction = (rowData) => {
        if (idRowSelected.includes(rowData.staffId)) {
            setIdRowSelected([...idRowSelected.filter((ele) => ele !== rowData.staffId)]);
        } else {
            const sortedIdRowSelected = [...idRowSelected, rowData.staffId];
            setIdRowSelected(sortedIdRowSelected.sort());
        }
    };

    const selectAllFunction = (allId) => {
        if (_.isEqual(idRowSelected, allId)) {
            setIdRowSelected([]);
        } else {
            setIdRowSelected(allId);
        }
    };

    // const onUpdateTableData = (staffId, date, value) => {
    //     let clonedTimesheetDataUpdate = [...timesheetDataUpdate];
    //     let existedStaffIdObj = clonedTimesheetDataUpdate.find((o) => o.staffId === staffId);
    //     let dateFormatted = moment(date, "YYYY-MM-DD dddd").format("DD/MM/YYYY");
    //     let daysFormatted = moment(date, "YYYY-MM-DD dddd").format("dddd");
    //     if (existedStaffIdObj) {
    //         let filterOutUpdatedStaffIdObj = clonedTimesheetDataUpdate.filter((o) => o !== existedStaffIdObj);
    //         const exitedTimesheetBase = existedStaffIdObj.timesheetBase.find((o) => o.date === dateFormatted);
    //         if (exitedTimesheetBase) {
    //             let filterOutUpdatedTimesheetBase = existedStaffIdObj.timesheetBase.filter((o) => o !== exitedTimesheetBase);
    //             let timesheetBasePayload = {
    //                 date: dateFormatted,
    //                 days: daysFormatted,
    //                 numberHours: parseInt(value) ? parseInt(value) : 0,
    //             };
    //             let newObj = {
    //                 staffId: staffId,
    //                 timesheetBase: [...filterOutUpdatedTimesheetBase, timesheetBasePayload],
    //             };
    //             setTimesheetDataUpdate([...filterOutUpdatedStaffIdObj, newObj]);
    //         } else {
    //             let timesheetBasePayload = {
    //                 date: dateFormatted,
    //                 days: daysFormatted,
    //                 numberHours: parseInt(value) ? parseInt(value) : 0,
    //             };
    //             let newObj = {
    //                 staffId: staffId,
    //                 timesheetBase: [...existedStaffIdObj.timesheetBase, timesheetBasePayload],
    //             };
    //             setTimesheetDataUpdate([...filterOutUpdatedStaffIdObj, newObj]);
    //         }
    //     } else {
    //         let payload = {
    //             staffId: staffId,
    //             timesheetBase: [
    //                 {
    //                     date: dateFormatted,
    //                     days: daysFormatted,
    //                     numberHours: parseInt(value) ? parseInt(value) : 0,
    //                 },
    //             ],
    //         };
    //         setTimesheetDataUpdate([...clonedTimesheetDataUpdate, payload]);
    //     }
    // };

    const updateMyData = (rowIndex, columnId, value) => {
        // onUpdateTableData(data[rowIndex].staffId, tableConfig.dateDay[parseInt(columnId.slice(-1)) - 1], value);
        setData((old) =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    };
                }
                return row;
            })
        );
    };

    const onSaveClicked = async () => {
        const date1 = moment(tableConfig.dateDay[0].split(" ")[0], "YYYY-MM-DD").format("DD/MM/YYYY");
        const day1 = tableConfig.dateDay[0].split(" ")[1];
        const date2 = moment(tableConfig.dateDay[1].split(" ")[0], "YYYY-MM-DD").format("DD/MM/YYYY");
        const day2 = tableConfig.dateDay[1].split(" ")[1];
        const date3 =  moment(tableConfig.dateDay[2].split(" ")[0], "YYYY-MM-DD").format("DD/MM/YYYY");
        const day3 = tableConfig.dateDay[2].split(" ")[1];
        const date4 = moment(tableConfig.dateDay[3].split(" ")[0], "YYYY-MM-DD").format("DD/MM/YYYY");
        const day4 = tableConfig.dateDay[3].split(" ")[1];
        const date5 = moment(tableConfig.dateDay[4].split(" ")[0], "YYYY-MM-DD").format("DD/MM/YYYY");
        const day5 = tableConfig.dateDay[4].split(" ")[1];
        const date6 = moment(tableConfig.dateDay[5].split(" ")[0], "YYYY-MM-DD").format("DD/MM/YYYY");
        const day6 = tableConfig.dateDay[5].split(" ")[1];
        const date7 = moment(tableConfig.dateDay[6].split(" ")[0], "YYYY-MM-DD").format("DD/MM/YYYY");
        const day7 = tableConfig.dateDay[6].split(" ")[1];
        const timesheetAllUpdated = data.map(o => {
            return {
                staffId: o.staffId,
                timesheetBase: [
                    {
                        date: date1,
                        days: day1,
                        numberHours: o.noOfHours1
                    },
                    {
                        date: date2,
                        days: day2,
                        numberHours: o.noOfHours2
                    },
                    {
                        date: date3,
                        days: day3,
                        numberHours: o.noOfHours3
                    },
                    {
                        date: date4,
                        days: day4,
                        numberHours: o.noOfHours4
                    },
                    {
                        date: date5,
                        days: day5,
                        numberHours: o.noOfHours5
                    },
                    {
                        date: date6,
                        days: day6,
                        numberHours: o.noOfHours6
                    },
                    {
                        date: date7,
                        days: day7,
                        numberHours: o.noOfHours7
                    },
                ]
            }
        })
        try {
            await api.post.timesheetManagementSaveTimesheet(timesheetAllUpdated);
            appToast("Timesheet had been successfully updated.", true);
        } catch (error) {
            let sanitizedError = sanitizeError(error);
            appToast(sanitizedError, false);
        }
    };

    const onClickWeekChange = (move) => {
        if (move === "forward") {
            setTableSearchFilter(state => {
                return {
                    ...state,
                    startDate: moment(state.startDate, "YYYY-MM-DD").add(7, "d").format("YYYY-MM-DD"),
                }
            })
        } else if (move === "back") {
            setTableSearchFilter(state => {
                return {
                    ...state,
                    startDate: moment(state.startDate, "YYYY-MM-DD").subtract(7, "d").format("YYYY-MM-DD"),
                }
            })
        }
    };

    const tableColumn = [
        {
            id: "Id",
            Header: (header) => {
                if (header.data.length) {
                    return (
                        <div className="react-table__header-checkbox-wrapper">
                            <AppCheckbox type="selectAll" onChange={() => selectAllFunction(allId)} checked={_.isEqual(idRowSelected, allId)} />
                        </div>
                    );
                } else {
                    return null;
                }
            },
            Cell: (row) => {
                return (
                    <div className="react-table__row-checkbox-wrapper" onClick={(e) => e.stopPropagation()}>
                        <AppCheckbox onChange={() => selectRowFunction(row.row.original)} checked={idRowSelected.includes(row.row.original.staffId)} />
                    </div>
                );
            },
            maxWidth: 34,
        },
        {
            id: "staffUserName",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__username-header react-table__header">
                        Username
                        <img className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            Cell: (row) => {
                return <span className="react-table__username-data">{row.row.original.staffUserName}</span>;
            },
            maxWidth: 140,
            searchFilterValue: "user_name",
        },
        {
            id: "noOfHours1",
            Header: () => {
                return <span>{tableConfig.dateDay[0] ? moment(tableConfig.dateDay[0], "YYYY-MM-DD dddd").format("ddd, DD MMM") : ""}</span>;
            },
            width: 80,
            accessor: "noOfHours1",
        },
        {
            id: "noOfHours2",
            Header: () => {
                return <span>{tableConfig.dateDay[1] ? moment(tableConfig.dateDay[1], "YYYY-MM-DD dddd").format("ddd, DD MMM") : ""}</span>;
            },
            width: 80,
            accessor: "noOfHours2",
        },
        {
            id: "noOfHours3",
            Header: () => {
                return <span>{tableConfig.dateDay[2] ? moment(tableConfig.dateDay[2], "YYYY-MM-DD dddd").format("ddd, DD MMM") : ""}</span>;
            },
            width: 80,
            accessor: "noOfHours3",
        },
        {
            id: "noOfHours4",
            Header: () => {
                return <span>{tableConfig.dateDay[3] ? moment(tableConfig.dateDay[3], "YYYY-MM-DD dddd").format("ddd, DD MMM") : ""}</span>;
            },
            width: 80,
            accessor: "noOfHours4",
        },
        {
            id: "noOfHours5",
            Header: () => {
                return <span>{tableConfig.dateDay[4] ? moment(tableConfig.dateDay[4], "YYYY-MM-DD dddd").format("ddd, DD MMM") : ""}</span>;
            },
            width: 80,
            accessor: "noOfHours5",
        },
        {
            id: "noOfHours6",
            Header: () => {
                return <span>{tableConfig.dateDay[5] ? moment(tableConfig.dateDay[5], "YYYY-MM-DD dddd").format("ddd, DD MMM") : ""}</span>;
            },
            width: 80,
            accessor: "noOfHours6",
        },
        {
            id: "noOfHours7",
            Header: () => {
                return <span>{tableConfig.dateDay[6] ? moment(tableConfig.dateDay[6], "YYYY-MM-DD dddd").format("ddd, DD MMM") : ""}</span>;
            },
            width: 80,
            accessor: "noOfHours7",
        },
    ];

    const searchKeywordTyped = (value) => {
        setTableSearchFilter({
            ...tableSearchInitialValues,
            searchValue: value,
        });
    };

    const searchBarOnChange = (e) => {
        searchKeywordTyped(e.target.value);
    };

    const handleKeypress = (e) => {
        if (e.code === "Enter") {
            searchKeywordTyped(e.target.value);
        }
    };

    const onPageChange = (e) => {
        let payload = {
            ...tableSearchFilter,
            pageNo: e.selected,
        };
        setTableSearchFilter(payload);
        getTableData(payload);
    };

    const applySearchKeywordSearch = async () => {
        let payload = {
            searchValue: "",
            ...tableSearchFilter,
        };
        getTableData(payload);
    };

    const applyResetSearch = async () => {
        setTableSearchFilter(tableSearchInitialValues)
    };

    const applyAdvanceSearch = () => {
        setTableSearchFilter(state => {
            return {
                ...state,
                ...detailSearch,
                startDate: detailSearch.startDate ? detailSearch.startDate : moment(tableConfig.dateDay[0], "YYYY-MM-DD dddd").format("YYYY-MM-DD")
            }
        })
    };

    const handleOnExport = async () => {
        try {
            let payload = {
                month: moment(tableSearchFilter.startDate, "YYYY-MM-DD").format("MM"),
                staffId: idRowSelected.join(","),
                year: moment(tableSearchFilter.startDate, "YYYY-MM-DD").format("YYYY"),
            };
            const response = await api.post.timesheetManagementExport(payload, "arraybuffer");
            // create a download anchor tag
            var downloadLink = document.createElement("a");
            downloadLink.target = "_blank";
            downloadLink.download = "timesheet-management.xlsx";
            // convert downloaded data to a Blob
            var blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            // create an object URL from the Blob
            var URL = window.URL || window.webkitURL;
            var downloadUrl = URL.createObjectURL(blob);
            // set object URL as the anchor's href
            downloadLink.href = downloadUrl;
            // append the anchor to document body
            document.body.appendChild(downloadLink);
            // fire a click event on the anchor
            downloadLink.click();
            // cleanup: remove element and revoke object URL
            document.body.removeChild(downloadLink);
            URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            appToast(sanitizeError(error), false);
        }
    };

    return (
        <AppPagesLayout>
            <div className="page-timesheet-management">
                <div className="page-timesheet-management__breadcrumb">
                    <AppBreadcrumb list={breadcrumb} />
                </div>

                <div className="page-timesheet-management__row">
                    <div className="page-timesheet-management__searchBar-wrapper">
                        <AppInput
                            leftSrc={iconSearch}
                            placeholder="Search keyword"
                            onChange={_.debounce((e) => searchBarOnChange(e), 1000)}
                            onKeyPress={handleKeypress}
                            rightSrc={iconSetting}
                            rightSrcOnClick={() => {
                                setShowAdvanceSearch(!showAdvanceSearch);
                                searchKeywordInputRef.current.value = "";
                                searchKeywordTyped("");
                            }}
                            onFocus={() => {
                                if (showAdvanceSearch) {
                                    setShowAdvanceSearch(false);
                                    searchKeywordInputRef.current.value = "";
                                    searchKeywordTyped("");
                                    applySearchKeywordSearch();
                                }
                            }}
                            ref={searchKeywordInputRef}
                        />
                    </div>
                    <div className="page-timesheet-management__button-wrapper">
                        <AppButton size="s" buttonType="outline" label="Export" buttonIcon={<BsShare />} disabled={!idRowSelected.length} onClick={handleOnExport} />
                    </div>
                </div>

                {showAdvanceSearch ? (
                    <div className="page-timesheet-management__search-content">
                        <div className="page-timesheet-management__input">
                            {advanceSearch.map((item, index) => {
                                if (item.type === "input") {
                                    return (
                                        <div key={index} className="page-timesheet-management__input-wrapper">
                                            <AppInputWithLabel
                                                placeholder={item.placeholder}
                                                onChange={(e) =>
                                                    setDetailSearch({
                                                        ...detailSearch,
                                                        [item.name]: e.target.value,
                                                    })
                                                }
                                                value={detailSearch[item.name]}
                                            />
                                        </div>
                                    );
                                } else if (item.type === "date") {
                                    return (
                                        <div key={index} className="page-timesheet-management__input-wrapper">
                                            <AppInputDate
                                                placeholder={item.placeholder}
                                                onChange={(value) => {
                                                    if (value === "" || value === "Invalid date") {
                                                        setDetailSearch({
                                                            ...detailSearch,
                                                            [item.name]: null,
                                                        });
                                                    } else {
                                                        setDetailSearch({
                                                            ...detailSearch,
                                                            [item.name]: moment(value, "DD/MM/YYYY").format("YYYY-MM-DD"),
                                                        });
                                                    }
                                                }}
                                                value={moment(detailSearch[item.name], "YYYY-MM-DD").format("DD/MM/YYYY")}
                                            />
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </div>
                        <div className="page-timesheet-management__advance-button-row">
                            <div className="page-timesheet-management__advance-button-wrapper">
                                <AppButton
                                    label="Reset"
                                    buttonType="outline"
                                    size="s"
                                    onClick={() => {
                                        setDetailSearch(setDetailSearchInitialValues);
                                        applyResetSearch();
                                    }}
                                />
                            </div>
                            <div className="page-timesheet-management__advance-button-wrapper">
                                <AppButton label="Apply" size="s" onClick={applyAdvanceSearch} />
                            </div>
                        </div>
                    </div>
                ) : null}

                <div className="page-timesheet-management__table-header-row">
                    <div className="page-timesheet-management__table-header-save-button">
                        <AppButton label="Save" size="s" onClick={onSaveClicked} />
                    </div>
                    <div className="page-timesheet-management__table-header-button-wrapper">
                        <button onClick={() => onClickWeekChange("back")} className="page-timesheet-management__table-header-arrow-button-left">
                            <img src={iconLeft} alt="" className="page-timesheet-management__table-header-arrow" />
                        </button>
                        <button onClick={() => onClickWeekChange("forward")} className="page-timesheet-management__table-header-arrow-button-right">
                            <img src={iconRight} alt="" className="page-timesheet-management__table-header-arrow" />
                        </button>
                    </div>
                </div>

                <div className="page-timesheet-management__table-wrapper">
                    <AppReactTableEditable columns={tableColumn} data={data} searchKeyword={tableSearchFilter.searchValue || !Object.values(detailSearch).every((e) => e === "")} updateMyData={updateMyData} />
                </div>
                <div className="page-timesheet-management__pagination">
                    <AppPaginate onPageChange={onPageChange} pageCount={tableConfig.totalPages} forcePage={tableSearchFilter.pageNo} />
                </div>
            </div>
        </AppPagesLayout>
    );
};

export default PageTimesheetManagement;
