import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
// components
import AppPagesLayout from "components/app-pages-layout";
import AppModal from "components/app-modal";
import appToast from "components/app-toast";
// assets
import iconArrowRight from "assets/images/icon-arrow-right.svg";
import iconHoliday from "assets/images/icon-holiday.svg";
import iconFilePdf from "assets/images/icon-file-pdf.svg";
// pathnames
import employeePathnames from "routes/employee-pathnames";
// utilities
import { sanitizeError } from "common/utilities";
// services
import api from "services/api";
import defaultProfileImage from "assets/images/default_profile_image.jpg";

const getListItemInfoClassNames = (color) => {
    const classNames = ["employee-list-item__info"];
    if (color === "blue") classNames.push("employee-list-item__info--blue");
    if (color === "red") classNames.push("employee-list-item__info--red");
    return classNames.join(" ");
};

const ListItem = ({ imgUrl, details, info, infoColor, style, hideImg }) => {
    return (
        <div className="employee-list-item" style={style}>
            <div className="employee-list-item__details-wrapper">
                {hideImg ? null : (
                    <img alt="" src={imgUrl === "null" || !imgUrl ? defaultProfileImage : imgUrl} className="employee-list-item__details-img" />
                )}
                <div className="employee-list-item__details">{details}</div>
            </div>
            <div className={getListItemInfoClassNames(infoColor)}>{info}</div>
        </div>
    );
};

function compare( a, b ) {
    if ( a.dob < b.dob ){
      return -1;
    }
    if ( a.dob > b.dob ){
      return 1;
    }
    return 0;
  }

const PageEmployeeDashboard = () => {
    const profile = useSelector((state) => state.auth);
    const staffId = profile ? profile.staffID : "";
    const history = useHistory();
    const [pdfModalOpen, setPdfModalOpen] = useState(false);
    const [attachmentUrlSelected, setAttachmentUrlSelected] = useState("");
    const [employeeOnLeaveData, setEmployeeOnLeaveData] = useState([]);
    const [birthdayDate, setBirthdayDate] = useState([]);
    const [publicHolidayDate, setPublicHolidayDate] = useState([]);
    const [allAnnouncement, setAllAnnouncement] = useState([]);
    const [employeeUpcoming, setEmployeeUpcoming] = useState([]);
    const [balanceDaysData, setBalanceDaysData] = useState([]);
    const [pendingClaimNumber, setPendingClaimNumber] = useState(0);
    const [pendingLeaveNumber, setPendingLeaveNumber] = useState(0);
    const [upcomingCoaching, setUpcomingCoaching] = useState([]);
    const [currentTime, setCurrentTime] = useState(moment().format("Do MMMM YYYY | LT"));

    const pendingInfo = [
        {
            title: "Pending Leave",
            value: pendingLeaveNumber,
            valueColor: "#fa9c57",
            buttonText: "Go to Leave",
            buttonOnClick: () => history.push(employeePathnames.pageEmployeePendingLeave),
        },
        {
            title: "Pending Claim",
            value: pendingClaimNumber,
            valueColor: "#facb57",
            buttonText: "Go to Claim",
            buttonOnClick: () => history.push(employeePathnames.pageEmployeePendingClaim),
        },
    ];

    const getOnLeave = async () => {
        try {
            const response = await api.get.dashboardGetOnLeave();
            setEmployeeOnLeaveData(response.data.result);
        } catch (error) {
            appToast(sanitizeError(error), false);
        }
    };

    const getEmployeeBirthday = async () => {
        try {
            const response = await api.get.dashboardGetEmployeeBirthday();
            const sorting = response.data.result.sort( compare );
            let birthdaySequence = [...sorting.filter((e) => e.dob === "Today"), ...sorting.filter((e) => e.dob !== "Today")];
            setBirthdayDate(birthdaySequence);
        } catch (error) {
            appToast(sanitizeError(error), false);
        }
    };

    const getAllAnnouncement = async () => {
        try {
            const response = await api.get.dashboardGetEmployeeAllAnnouncement();
            setAllAnnouncement(response.data.result);
        } catch (error) {
            appToast(sanitizeError(error), false);
        }
    };

    const getPublicHoliday = async () => {
        try {
            const response = await api.get.dashboardGetPublicHoliday();
            let publicHolidaySequence = [...response.data.result.filter((e) => e.holidayDate === "Today"), ...response.data.result.filter((e) => e.holidayDate !== "Today")];
            setPublicHolidayDate(publicHolidaySequence);
        } catch (error) {
            appToast(sanitizeError(error), false);
        }
    };

    const getEmployeeUpcomingLeave = async (staffId) => {
        try {
            const response = await api.get.dashboardGetEmployeeUpcomingLeave(staffId);
            setEmployeeUpcoming(response.data.result);
        } catch (error) {
            appToast(sanitizeError(error), false);
        }
    };

    const getEmployeeBalanceDays = async (staffId) => {
        try {
            const response = await api.get.dashboardGetEmployeeBalanceDays(staffId);
            setBalanceDaysData(response.data.result);
        } catch (error) {
            appToast(sanitizeError(error), false);
        }
    };

    const getEmployeePendingClaim = async (staffId) => {
        try {
            const response = await api.get.dashboardGetEmployeePendingClaim(staffId);
            setPendingClaimNumber(response.data.result);
        } catch (error) {
            appToast(sanitizeError(error), false);
        }
    };

    const getEmployeePendingLeave = async (staffId) => {
        try {
            const response = await api.get.dashboardGetEmployeePendingLeave(staffId);
            setPendingLeaveNumber(response.data.result);
        } catch (error) {
            appToast(sanitizeError(error), false);
        }
    };

    const getUpcomingCoaching = async (staffId) => {
        try {
            const response = await api.get.dashboardGetEmployeeUpcomingCoaching(staffId);
            const upcomingCoachingUpdate = response.data.result.map((o) => {
                return {
                    ...o,
                    coachDate: moment().format("DD/MM/YYYY") === o.coachDate ? "Today" : o.coachDate,
                };
            });
            setUpcomingCoaching(upcomingCoachingUpdate);
        } catch (error) {
            appToast(sanitizeError(error), false);
        }
    };

    useEffect(() => {
        getOnLeave();
        getEmployeeBirthday();
        getAllAnnouncement();
        getPublicHoliday();
        getEmployeeUpcomingLeave(staffId);
        getEmployeeBalanceDays(staffId);
        getEmployeePendingClaim(staffId);
        getEmployeePendingLeave(staffId);
        getUpcomingCoaching(staffId);
    }, [staffId]);

    useEffect(() => {
        const updateCurrentTime = () => {
            setCurrentTime(moment().format("Do MMMM YYYY | LT"));
        };
        const interval = setInterval(updateCurrentTime, 1000 * 30);
        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <AppPagesLayout>
            <div className="page-employee-dashboard">
                <AppModal isOpenModal={pdfModalOpen} onRequestClose={() => setPdfModalOpen(false)} emptyModal={true}>
                    <div className="page-employee-confirmation__modal-attachment">
                        <object width="100%" height="100%" data={attachmentUrlSelected} type="application/pdf">
                            {" "}
                        </object>
                    </div>
                </AppModal>
                <div className="page-employee-dashboard__header">
                    <div className="page-employee-dashboard__header-welcome">Welcome back{profile && profile.staffUserName ? `, ${profile.staffUserName}` : ""}</div>
                    <div className="page-employee-dashboard__header-datetime">{currentTime}</div>
                </div>
                <div className="page-employee-dashboard__contents">
                    <div className="page-employee-dashboard__column-1">
                        <div className="page-employee-dashboard__info-row">
                            <div className="page-employee-dashboard__card-styling" style={{ width: "100%", height: 360, paddingRight: 8 }}>
                                <div className="announcement">
                                    <div className="announcement__header">
                                        <div className="announcement__title">Announcements</div>
                                    </div>
                                    <div className="announcement__body">
                                        <div className="announcement__contents">
                                            <div className="page-dashboad__tab" style={{ height: "100%" }}>
                                                {allAnnouncement.length
                                                    ? allAnnouncement.map((o, i) => (
                                                          <div key={i} className="announcement__contents-row-wrapper">
                                                              <div className="announcement__contents-text-column">
                                                                  <div className="announcement__contents-title">{o.title}</div>
                                                                  <div className="announcement__contents-details">{o.description}</div>
                                                              </div>
                                                              <div className="announcement__contents-button-column">
                                                                  <div>
                                                                      {o.attachment ? (
                                                                          <img
                                                                              className="announcement__pdf-file"
                                                                              alt=""
                                                                              src={iconFilePdf}
                                                                              onClick={() => {
                                                                                  setAttachmentUrlSelected(o.attachment);
                                                                                  setPdfModalOpen(true);
                                                                              }}
                                                                          />
                                                                      ) : null}
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      ))
                                                    : <div style={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", color: "#999999", fontSize: 14  }}><div>No Announcement Available</div></div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="page-employee-dashboard__info-row">
                            <div className="page-employee-dashboard__card-styling" style={{ width: "50%", minHeight: 155 }}>
                                <div className="medium-card">
                                    <div className="medium-card__header">
                                        <div className="page-employee-dashboard__card-contents-title">Upcoming Leave</div>
                                        <button className="medium-card__view-all-button" onClick={() => history.push(employeePathnames.pageEmployeeLeaveSummary)}>
                                            <span className="medium-card__view-all">View All</span> <img alt="" src={iconArrowRight} className="page-employee-dashboard__arrow-right" />
                                        </button>
                                    </div>
                                    <div className="medium-card__body">
                                        {employeeUpcoming.length ? (
                                            employeeUpcoming.map((obj, index) => {
                                                if (index < 4) {
                                                    return (
                                                        <ListItem
                                                            hideImg={true}
                                                            key={index}
                                                            style={{ padding: 0, marginTop: 0, marginBottom: 4 }}
                                                            infoColor="red"
                                                            details={`${obj.leaveType}${obj.timeOff ? " - " + obj.timeOff : ""}`}
                                                            info={`${moment(obj.startDate).format("Do")}${moment(obj.endDate).format("Do") !== "Invalid date" ? " - " + moment(obj.endDate).format("Do") : ""}`}
                                                        />
                                                    );
                                                } else return null;
                                            })
                                        ) : (
                                            <div className="page-employee-dashboard__empty-card medium-card__empty-card">No upcoming leave</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="page-employee-dashboard__card-styling" style={{ width: "50%", minHeight: 155 }}>
                                <div className="medium-card">
                                    <div className="medium-card__header">
                                        <div className="page-employee-dashboard__card-contents-title">Leave Balance</div>
                                        <button className="medium-card__view-all-button" onClick={() => history.push(employeePathnames.pageEmployeeLeaveSummary)}>
                                            <span className="medium-card__view-all">View All</span> <img alt="" src={iconArrowRight} className="page-employee-dashboard__arrow-right" />
                                        </button>
                                    </div>
                                    <div className="medium-card__body">
                                        {balanceDaysData.length ? (
                                            balanceDaysData.map((obj, index) => {
                                                if (index < 4) {
                                                    return <ListItem hideImg={true} key={index} style={{ padding: 0, marginTop: 0, marginBottom: 4 }} infoColor="red" details={obj.benefitName} info={`${parseInt(obj.leaveDays)} days`} />;
                                                }
                                                return null;
                                            })
                                        ) : (
                                            <div className="page-employee-dashboard__empty-card medium-card__empty-card">No leave balance data</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="page-employee-dashboard__info-row">
                            <div className="page-employee-dashboard__card-styling" style={{ width: "50%", minHeight: 155 }}>
                                <div className="medium-card">
                                    <div className="medium-card__header">
                                        <div className="page-employee-dashboard__card-contents-title">Employee on Leave</div>
                                        <button className="medium-card__view-all-button" onClick={() => history.push(employeePathnames.pageEmployeeLeaveCalendar)}>
                                            <span className="medium-card__view-all">View All</span> <img alt="" src={iconArrowRight} className="page-employee-dashboard__arrow-right" />
                                        </button>
                                    </div>
                                    <div className="medium-card__body">
                                        {employeeOnLeaveData.length ? (
                                            employeeOnLeaveData.map((obj, index) => {
                                                if (index < 3) {
                                                    return <ListItem key={index} imgUrl={obj.userImage} infoColor="red" details={obj.staffUserName} info={obj.leaveType}/>;
                                                }
                                                return null;
                                            })
                                        ) : (
                                            <div className="page-employee-dashboard__empty-card medium-card__empty-card">No employee on leave today</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="page-employee-dashboard__card-styling" style={{ width: "50%", minHeight: 155 }}>
                                <div className="medium-card">
                                    <div className="medium-card__header">
                                        <div className="page-employee-dashboard__card-contents-title" style={{ fontSize: 15 }}>
                                            Upcoming Coaching Session
                                        </div>
                                        <button className="medium-card__view-all-button" onClick={() => history.push(employeePathnames.pageEmployeeUpcomingSchedule)}>
                                            <span className="medium-card__view-all">View All</span> <img alt="" src={iconArrowRight} className="page-employee-dashboard__arrow-right" />
                                        </button>
                                    </div>
                                    <div className="medium-card__body">
                                        {upcomingCoaching.length ? (
                                            upcomingCoaching.map((obj, index) => {
                                                if (index < 4) {
                                                    return <ListItem hideImg={true} key={index} infoColor="red" details={obj.coachName} info={`${obj.coachDate} ${obj.startTime} - ${obj.endTime}`} style={{ padding: 0, marginTop: 0, marginBottom: 4 }} />;
                                                }
                                                return null;
                                            })
                                        ) : (
                                            <div className="page-employee-dashboard__empty-card medium-card__empty-card">No upcoming coaching session</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-employee-dashboard__column-2">
                        <div className="page-employee-dashboard__card-styling" style={{ paddingRight: 5 }}>
                            <div className="celebration-corner">
                                <div className="page-employee-dashboard__card-contents-title">Celebration Corner</div>
                                <div className="celebration-corner__contents" style={{ maxHeight: 220, overflow: "scroll" }}>
                                    {birthdayDate.length ? (
                                        birthdayDate.map((o, i) => <ListItem key={i} imgUrl={o.userImage} infoColor="blue" details={o.username} info={o.dob} style={{ paddingRight: 10 }} />)
                                    ) : (
                                        <div className="page-employee-dashboard__empty-card celebration-corner__empty-card">No birthday this month</div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="page-employee-dashboard__card-styling" style={{ height: "auto" }}>
                            <div className="holidy-this-month">
                                <div className="page-employee-dashboard__card-contents-title">Holiday this Month</div>
                                <div className="holiday-this-month__contents">
                                    {publicHolidayDate.length ? (
                                        publicHolidayDate.map((o, i) => <ListItem key={i} imgUrl={iconHoliday} infoColor="blue" details={o.holiday} info={o.holidayDate} style={{ paddingRight: 10 }} />)
                                    ) : (
                                        <div className="page-employee-dashboard__empty-card holiday-this-month__empty-card">No holiday this month</div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="page-employee-dashboard__card-styling" style={{ height: 158 }}>
                            <div className="pending">
                                {pendingInfo.map((o, i) => (
                                    <div key={i} className="pending__contents">
                                        <div>
                                            <div className="page-employee-dashboard__card-contents-title">{o.title}</div>
                                            <button className="pending__pending-button" onClick={o.buttonOnClick}>
                                                {o.buttonText} <img alt="" src={iconArrowRight} className="page-employee-dashboard__arrow-right" />
                                            </button>
                                        </div>
                                        <div className="pending__pending-value" style={{ color: o.valueColor }}>
                                            {o.value}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppPagesLayout>
    );
};

export default PageEmployeeDashboard;
