export const isDevelopmentMode = () => !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export const sanitizeError = (originalError) => {
    let error = originalError;
    if (typeof originalError === "object") {
        if (originalError.response) {
            if (originalError.response.data) {
                error = originalError.response.data;
            } else {
                error = originalError.response;
            }
        } else if (originalError.data) {
            error = originalError.data;
        }
        if (error.error) {
            return error.error;
        } else {
            return error.message;
        }
    } else {
        error = originalError;
        return error;
    }
};

export const statusFormat = (status) => {
    if (status.toLowerCase() === "reject") {
        return "Rejected";
    } else if (status.toLowerCase() === "approve") {
        return "Approved";
    } else if (status.toLowerCase() === "cancel") {
        return "Cancelled";
    } else {
        return status.charAt(0).toUpperCase() + status.substring(1);
    }
};

export const statusColor = (status) => {
    if (status.toLowerCase() === "upcoming") {
        return "orange";
    } else if (status.toLowerCase() === "overdue") {
        return "red";
    } else if (status.toLowerCase() === "completed") {
        return "green";
    } else if (status.toLowerCase() === "cancelled") {
        return "red";
    } else {
        return "";
    }
};
