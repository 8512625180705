// libraries
import React, { useState, useEffect, Fragment } from "react";
import moment from "moment";
// components
import AppCheckbox from "components/app-checkbox";
import AppDropdown from "components/app-dropdown";
import AppTextArea from "components/app-text-area";
import AppInputDate from "components/app-input-date";
import AppTimePicker from "components/app-time-picker";
import AppButton from "components/app-button";
import AppModal from "components/app-modal";
import AppModalConfirmation from "components/app-modal-confirmation";
import appToast from "components/app-toast";
// common
import { sanitizeError } from "common/utilities";
// api
import api from "services/api";

const initialValues = {
    endTime: {
        placeholder: "End time",
        value: "",
        error: "",
    },
    mentorId: {
        placeholder: "Mentor ID",
        value: "",
        error: "",
    },
    mentorName: {
        placeholder: "Mentor name",
        value: "",
        error: "",
    },
    remark: {
        placeholder: "Remark",
        value: "",
        error: "",
        optional: true,
    },
    scheduleDate: {
        placeholder: "Schedule date",
        value: "",
        error: "",
    },
    staffId: {
        placeholder: "Staff ID",
        value: "",
        error: "",
    },
    staffName: {
        placeholder: "Staff name",
        value: "",
        error: "",
    },
    startTime: {
        placeholder: "Start time",
        value: "",
        error: "",
    },
    status: {
        placeholder: "",
        value: "",
        error: "",
        optional: true,
    },
};

const PageCreateCoachingSession = ({ largeModalIsOpen, onRequestClose, onRequestOpen, postSuccess }) => {
    const [timeIsOpen, setTimeIsOpen] = useState("");
    const [value, setValue] = useState(initialValues);
    const [confirmModal, setConfirmModal] = useState({
        isOpen: false,
        confirmDisable: false,
    });
    const [staffNameList, setStaffNameList] = useState([]);
    const [timePickerTouched, setTimePickerTouched] = useState({
        startTime: false,
        endTime: false,
    });

    useEffect(() => {
        const getStaffNamePosition = async () => {
            try {
                const response = await api.get.getStaffNamePosition();
                const staffList = response.data.result.staffNameList.map((o) => {
                    return { value: o.userName, id: o.value2 };
                });
                setStaffNameList(staffList);
            } catch (error) {
                const sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
            }
        };
        getStaffNamePosition();
    }, []);

    const confirmationModalConfirmed = async () => {
        const { mentorName, staffName, ...others } = value;
        let payload = {};
        for (const [k, v] of Object.entries(others)) {
            payload = {
                ...payload,
                [k]: v.value,
            };
        }
        try {
            await api.post.coachingSessionScheduleSession(payload);
            setConfirmModal({
                isOpen: false,
                confirmDisable: false,
            });
            setValue(initialValues);
            appToast("Coaching session has been scheduled successfully", true);
            postSuccess();
        } catch (error) {
            let sanitizedError = sanitizeError(error);
            appToast(sanitizedError, false);
        }
    };

    const createSession = () => {
        let clonedValue = { ...value };
        // check time
        let endTime = moment(clonedValue.endTime.value, "hh:mm A");
        let startTime = moment(clonedValue.startTime.value, "hh:mm A");
        if (endTime.isBefore(startTime) || endTime._i === startTime._i) {
            clonedValue = {
                ...clonedValue,
                endTime: {
                    ...clonedValue.endTime,
                    error: "End time is required to be after start time.",
                },
                startTime: {
                    ...clonedValue.startTime,
                    error: "Start time is required to be before end time.",
                },
            };
        }
        // check required
        for (const key in clonedValue) {
            if (!clonedValue[key].value && !clonedValue[key].optional) {
                clonedValue = {
                    ...clonedValue,
                    [key]: {
                        ...clonedValue[key],
                        error: `${clonedValue[key].placeholder} is required.`,
                    },
                };
            } else if (clonedValue[key].value === "Invalid date") {
                clonedValue = {
                    ...clonedValue,
                    [key]: {
                        ...clonedValue[key],
                        error: `${clonedValue[key].placeholder} is invalid.`,
                    },
                };
            } else {
                clonedValue = {
                    ...clonedValue,
                    [key]: {
                        ...clonedValue[key],
                        error: "",
                    },
                };
            }
        }
        if (clonedValue.mentorId.value === clonedValue.staffId.value) {
            clonedValue = {
                ...clonedValue,
                mentorId: {
                    ...clonedValue.mentorId,
                    error: "Mentor and mentee need to be different.",
                },
                mentorName: {
                    ...clonedValue.mentorName,
                    error: "Mentor and mentee need to be different.",
                },
                staffId: {
                    ...clonedValue.staffId,
                    error: "Mentor and mentee need to be different.",
                },
                staffName: {
                    ...clonedValue.staffName,
                    error: "Mentor and mentee need to be different.",
                },
            };
        }
        setValue(clonedValue);
        if (Object.values(clonedValue).every((e) => e.error === "")) {
            onRequestClose();
            setConfirmModal({
                isOpen: true,
                confirmDisable: false,
            });
        }
    };

    return (
        <Fragment>
            <AppModalConfirmation
                isOpenModal={confirmModal.isOpen}
                onRequestClose={() => {
                    setConfirmModal({
                        isOpen: false,
                        confirmDisable: false,
                    });
                    onRequestOpen();
                }}
                details="Confirm to create"
                onClick={confirmationModalConfirmed}
                buttonDisabled={confirmModal.confirmDisable}
                buttonLabel="Confirm"
            />
            <AppModal
                title="Schedule a Session"
                isOpenModal={largeModalIsOpen}
                onRequestClose={() => {
                    onRequestClose();
                    setValue(initialValues);
                }}
                onClick={() => {
                    setTimeIsOpen("");
                }}>
                <div className="page-create-coaching-session">
                    <div>
                        <div className="page-create-coaching-session__column-modal-left">
                            <div className="column-modal-left__checkbox-message">Please tick the checkbox in order to select backdate.</div>
                            <div className="column-modal-left__checkbox-gap" />
                            <div className="column-modal-left__checkbox-wrapper">
                                <AppCheckbox
                                    checked={value.status.value.toLowerCase() === "completed"}
                                    onChange={() => {
                                        if (value.status.value.toLowerCase() === "completed") {
                                            setValue({
                                                ...value,
                                                scheduleDate: {
                                                    ...value.scheduleDate,
                                                    value: "",
                                                },
                                                status: {
                                                    ...value.status,
                                                    value: "",
                                                },
                                            });
                                        } else {
                                            setValue({
                                                ...value,
                                                scheduleDate: {
                                                    ...value.scheduleDate,
                                                    value: "",
                                                },
                                                status: {
                                                    ...value.status,
                                                    value: "Completed",
                                                },
                                            });
                                        }
                                    }}
                                />
                                <span className="column-modal-left__checkbox-label">Mark as Completed</span>
                            </div>
                            <div className="column-modal-left__input-wrapper">
                                <AppDropdown
                                    placeholder="Employee Username"
                                    dropdownOptions={staffNameList}
                                    onChange={(selected) => {
                                        setValue({
                                            ...value,
                                            staffName: {
                                                ...value.staffName,
                                                value: selected.label,
                                                error: "",
                                            },
                                            staffId: {
                                                ...value.staffId,
                                                value: selected.value,
                                                error: "",
                                            },
                                            mentorName: {
                                                ...value.mentorName,
                                                error: "",
                                            },
                                            mentorId: {
                                                ...value.mentorId,
                                                error: "",
                                            },
                                        });
                                    }}
                                    value={value.staffName.value}
                                    currentInputValue={value.staffName.value ? staffNameList.filter((ele) => ele.label === value.staffName.value)[0] : null}
                                    error={value.staffName.error}
                                />
                            </div>
                            <div className="column-modal-left__input-wrapper">
                                <AppDropdown
                                    placeholder="Mentor Username"
                                    dropdownOptions={staffNameList}
                                    onChange={(selected) => {
                                        setValue({
                                            ...value,
                                            mentorName: {
                                                ...value.mentorName,
                                                value: selected.label,
                                                error: "",
                                            },
                                            mentorId: {
                                                ...value.mentorId,
                                                value: selected.value,
                                                error: "",
                                            },
                                            staffName: {
                                                ...value.staffName,
                                                error: "",
                                            },
                                            staffId: {
                                                ...value.staffId,
                                                error: "",
                                            },
                                        });
                                    }}
                                    value={value.mentorName.value}
                                    currentInputValue={value.mentorName.value ? staffNameList.filter((ele) => ele.label === value.mentorName.value)[0] : null}
                                    error={value.mentorName.error}
                                />
                            </div>
                            <div className="column-modal-left__input-wrapper">
                                <AppTextArea
                                    placeholder="Remark (Optional)"
                                    onChange={(e) =>
                                        setValue({
                                            ...value,
                                            remark: {
                                                ...value.remark,
                                                value: e.target.value,
                                                error: "",
                                            },
                                        })
                                    }
                                    value={value.remark.value}
                                    error={value.remark.error}
                                />
                            </div>
                        </div>
                        <div className="page-create-coaching-session__column-modal-right">
                            <div className="column-modal-right__input-date-wrapper">
                                <AppInputDate
                                    placeholder="Date"
                                    onChange={(v) =>
                                        setValue({
                                            ...value,
                                            scheduleDate: {
                                                ...value.scheduleDate,
                                                value: v,
                                                error: "",
                                            },
                                        })
                                    }
                                    maxDate={value.status.value.toLowerCase() === "completed" ? "yesterday" : null}
                                    minDate={value.status.value.toLowerCase() !== "completed" ? "today" : null}
                                    value={value.scheduleDate.value}
                                    error={value.scheduleDate.error}
                                />
                            </div>
                            <div onClick={(e) => e.stopPropagation()} className="column-modal-right__time-wrapper">
                                <AppTimePicker
                                    placeholder="Start Time"
                                    onFocus={(e, placeholder) => {
                                        setTimeIsOpen(placeholder);
                                        setTimePickerTouched({
                                            ...timePickerTouched,
                                            startTime: true,
                                        });
                                    }}
                                    timeIsOpenPlaceholder={timeIsOpen}
                                    startTime={{
                                        hours: "10",
                                        minutes: "00",
                                        ampm: "am",
                                    }}
                                    touched={timePickerTouched.startTime}
                                    valueFunc={(v) => {
                                        let time = `${v.hours}:${v.minutes} ${v.ampm}`;
                                        if (value.startTime.value !== time) {
                                            setValue({
                                                ...value,
                                                startTime: {
                                                    ...value.startTime,
                                                    value: time,
                                                    error: "",
                                                },
                                            });
                                        }
                                    }}
                                    error={value.startTime.error}
                                />
                            </div>

                            <div onClick={(e) => e.stopPropagation()} className="column-modal-right__time-wrapper">
                                <AppTimePicker
                                    placeholder="End Time"
                                    onFocus={(e, placeholder) => {
                                        setTimeIsOpen(placeholder);
                                        setTimePickerTouched({
                                            ...timePickerTouched,
                                            endTime: true,
                                        });
                                    }}
                                    timeIsOpenPlaceholder={timeIsOpen}
                                    startTime={{
                                        hours: "11",
                                        minutes: "00",
                                        ampm: "am",
                                    }}
                                    touched={timePickerTouched.endTime}
                                    valueFunc={(v) => {
                                        let time = `${v.hours}:${v.minutes} ${v.ampm}`;
                                        if (value.endTime.value !== time) {
                                            setValue({
                                                ...value,
                                                endTime: {
                                                    ...value.endTime,
                                                    value: time,
                                                    error: "",
                                                },
                                            });
                                        }
                                    }}
                                    error={value.endTime.error}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="page-create-coaching-session__column-modal-bottom">
                        <div className="column-modal-bottom__modal-input-button">
                            <AppButton
                                label="Cancel"
                                size="l"
                                buttonType="outline"
                                onClick={() => {
                                    onRequestClose();
                                    setValue(initialValues);
                                }}
                            />
                        </div>
                        <div className="column-modal-bottom__modal-input-button">
                            <AppButton label="Create" size="l" onClick={createSession} />
                        </div>
                    </div>
                </div>
            </AppModal>
        </Fragment>
    );
};

export default PageCreateCoachingSession;
