// libraries
import React, { useState, useEffect, useRef } from "react";
import { BiFilterAlt } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
// components
import AppPagesLayout from "components/app-pages-layout";
import AppBreadcrumb from "components/app-breadcrumb";
import AppTabLink from "components/app-tab-link";
import AppReactTable from "components/app-react-table";
import AppPaginate from "components/app-paginate";
import AppDropdownCheckbox from "components/app-dropdown-checkbox";
import AppDualColumnsModal from "components/app-dual-columns-modal";
import AppInputWithLabel from "components/app-input-with-label";
import AppTextArea from "components/app-text-area";
import appToast from "components/app-toast";
import AppButton from "components/app-button";
// common
import { sanitizeError, statusFormat } from "common/utilities";
// service
import api from "services/api";
// hooks
import usePrevious from "hooks/use-previous";
// routes
import employeePathnames from "routes/employee-pathnames";
// assets
import iconTailedArrowUp from "assets/images/icon-tailed-arrow-up.svg";
import iconCalendar from "assets/images/components/app-button/icon-calendar.svg";
// new leave
import AppEmployeeCreateNewLeave from "./new-leave";

const breadcrumb = [
    {
        label: "Leave Management",
    },
    {
        label: "Leave History",
    },
];

const tableStatusFilter = [
    { label: "Approved", name: "approved", value: "approved" },
    { label: "Rejected", name: "rejected", value: "rejected" },
    { label: "Cancelled", name: "cancelled", value: "cancelled" },
];

const getStatusClassNames = (status) => {
    const classNames = ["page-leave-history__user-status"];
    if (status.toLowerCase() === "working" || status.toLowerCase() === "approved" || status.toLowerCase() === "approve") classNames.push("page-leave-history__user-status--green");
    if (status.toLowerCase() === "on leave" || status.toLowerCase() === "rejected" || status.toLowerCase() === "reject"  || status.toLowerCase() === "cancelled") classNames.push("page-leave-history__user-status--red");

    return classNames.join(" ");
};

let statusToFilter = [];

const PageEmployeeLeaveHistory = () => {
    const [statusFilterOpen, setStatusFilterOpen] = useState(false);
    const profile = useSelector(state => state.auth);
    const staffId = profile ? profile.staffID : "";
    const history = useHistory();
    const [tableSearchFilter, setTableSearchFilter] = useState({
        pageNo: 0,
        pageSize: 5,
        sortBy: "",
        sortDir: "asc",
        leaveStatus: "",
        staffId: staffId,
    });
    const [tableData, setTableData] = useState({
        leaveDirList: [],
        last: null,
        pageNo: 0,
        pageSize: 5,
        totalElements: null,
        totalPages: 1,
    });
    const [largeModalIsOpen, setLargeModalIsOpen] = useState(false);
    const prevValueSortHeader = usePrevious({
        sortBy: tableSearchFilter.sortBy,
        sortDir: tableSearchFilter.sortDir,
    });
    const [statusFilter, setStatusFilter] = useState({
        approved: false,
        rejected: false,
        cancelled: false,
    });
    const [leaveDetails, setLeaveDetails] = useState(null);
    const createLeaveModal = useRef(null);

    const appTabLink = [
        {
            label: "Leave Calendar",
            pathname: employeePathnames.pageEmployeeLeaveCalendar,
        },
        {
            label: "Leave Summary",
            pathname: employeePathnames.pageEmployeeLeaveSummary,
        },
        {
            label: "Leave History",
            pathname: employeePathnames.pageEmployeeLeaveHistory,
        },
        {
            label: "Pending Approval",
            pathname: employeePathnames.pageEmployeePendingLeave,
        },
    ];

    const getLeaveManagementLeaveHistory = async (tableSearchFilter, history) => {
        try {
            const response = await api.get.leaveManagementListLeaveHistory(tableSearchFilter);
            setTableData(response.data.result);
        } catch (error) {
            let sanitizedError = sanitizeError(error);
            appToast(sanitizedError, false);
            history.goBack();
        }
    };

    useEffect(() => {
        getLeaveManagementLeaveHistory(tableSearchFilter, history);
    }, [history, tableSearchFilter]);

    const headerArrowRotate = (active) => {
        const classNames = ["react-table__arrow-icon"];
        if (active && tableSearchFilter.sortDir === "desc") classNames.push("react-table__arrow-icon-down");
        if (active) classNames.push("react-table__arrow-icon--active");

        return classNames.join(" ");
    };

    const headerFilterClick = (header) => {
        let column = header.column;
        if (prevValueSortHeader.sortBy === column.searchFilterValue) {
            if (prevValueSortHeader.sortDir === "asc") {
                setTableSearchFilter({
                    ...tableSearchFilter,
                    sortBy: column.searchFilterValue,
                    sortDir: "desc",
                    pageNo: 0,
                });
            } else {
                setTableSearchFilter({
                    ...tableSearchFilter,
                    sortBy: column.searchFilterValue,
                    sortDir: "asc",
                    pageNo: 0,
                });
            }
        } else {
            setTableSearchFilter({
                ...tableSearchFilter,
                sortBy: column.searchFilterValue,
                sortDir: "asc",
                pageNo: 0,
            });
        }
    };

    const tableColumn = [
        {
            id: "leaveType",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__leave-type-header react-table__header">
                        Leave Type
                        <img className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            Cell: (row) => {
                return <div className="react-table__leave-type-row">{row.row.original.leaveType}</div>;
            },
            searchFilterValue: "leave_type",
        },
        {
            id: "leaveDays",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__days-header react-table__header">
                        Days
                        <img className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            maxWidth: 60,
            Cell: (row) => {
                return <span className="react-table__days-row">{row.row.original.leaveDays}</span>;
            },
            searchFilterValue: "leave_days",
        },
        {
            id: "startDate",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Start Date
                        <img className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            Cell: (row) => {
                return <span>{moment(row.row.original.startDate).format("DD/MM/YYYY")}</span>;
            },
            maxWidth: 110,
            searchFilterValue: "start_date",
        },
        {
            id: "endDate",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        End Date
                        <img className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            Cell: (row) => {
                return <span>{moment(row.row.original.endDate).format("DD/MM/YYYY")}</span>;
            },
            maxWidth: 110,
            searchFilterValue: "end_date",
        },
        {
            id: "timeOff",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Time Off
                        <img className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            accessor: "timeOff",
            maxWidth: 110,
            searchFilterValue: "time_off",
        },
        {
            id: "leaveStatus",
            Header: () => {
                const getStatusClassNames = (statusFilterOpen) => {
                    const classNames = ["react-table__icon-filter"];
                    if (statusFilterOpen) classNames.push("react-table__icon-filter--active");
                    return classNames.join(" ");
                };
                return (
                    <div onClick={(e) => e.stopPropagation()}>
                        <span className="react-table__status-header" onClick={() => setStatusFilterOpen(!statusFilterOpen)}>
                            Status
                            <BiFilterAlt className={getStatusClassNames(statusFilterOpen)} />
                        </span>
                        {statusFilterOpen ? (
                            <div className="react-table__dropdown">
                                <AppDropdownCheckbox
                                    dropdownList={tableStatusFilter}
                                    onChange={(item, value) => {
                                        setStatusFilter({
                                            ...statusFilter,
                                            [item.name]: value,
                                        });
                                        if (value) {
                                            statusToFilter.push(item.value);
                                        } else {
                                            statusToFilter = statusToFilter.filter((e) => e !== item.value);
                                        }
                                        setTableSearchFilter({
                                            ...tableSearchFilter,
                                            pageNo: 0,
                                            leaveStatus: statusToFilter.join(""),
                                        });
                                    }}
                                    state={statusFilter}
                                />
                            </div>
                        ) : null}
                    </div>
                );
            },
            Cell: (row) => {
                return (
                    <span className={getStatusClassNames(row.row.original.leaveStatus)}>
                        <span>{statusFormat(row.row.original.leaveStatus)}</span>
                    </span>
                );
            },
            maxWidth: 110,
        },
    ];

    const onPageChange = (e) => {
        setTableSearchFilter({
            ...tableSearchFilter,
            pageNo: e.selected,
        });
    };

    const selectedRow = async (selectedRow) => {
        try {
            const response = await api.get.leaveManagementLeaveHistoryDetail(selectedRow.id);
            setLeaveDetails(response.data.result);
            setLargeModalIsOpen(true);
        } catch (error) {
            let sanitizedError = sanitizeError(error);
            appToast(sanitizedError, false);
        }
    };

    const modalStatusColor = (status) => {
        if (status === "approved" || status === "approve") {
            return "green";
        } else if (status === "reject" || status === "rejected" || status === "cancelled" || status === "cancel") {
            return "red";
        } else {
            return null;
        }
    };

    return (
        <AppPagesLayout>
            <div className="page-leave-history" onClick={() => setStatusFilterOpen(false)}>
                <AppEmployeeCreateNewLeave ref={createLeaveModal} onSuccess={() => getLeaveManagementLeaveHistory(tableSearchFilter, history)}/>
                {leaveDetails ? (
                    <AppDualColumnsModal
                        title="Leave Details"
                        isOpenModal={largeModalIsOpen}
                        onRequestClose={() => setLargeModalIsOpen(false)}
                        status={statusFormat(leaveDetails.status)}
                        statusColor={modalStatusColor(leaveDetails.status.toLowerCase())}
                        childrenLeft={
                            <div>
                                <div className="page-leave-history__modal-input-wrapper">
                                    <AppInputWithLabel placeholder="Username" value={leaveDetails.userName} disabled={true} />
                                </div>
                                <div className="page-leave-history__modal-input-wrapper">
                                    <AppInputWithLabel placeholder="Leave Type" value={leaveDetails.leaveType} disabled={true} />
                                </div>
                                <div className="page-leave-history__modal-input-wrapper">
                                    <AppInputWithLabel placeholder="Start Date" value={leaveDetails.startDate} disabled={true} />
                                </div>
                                <div className="page-leave-history__modal-input-wrapper">
                                    <AppInputWithLabel placeholder="Submit Date" value={leaveDetails.submitDate} disabled={true} />
                                </div>
                            </div>
                        }
                        childrenRight={
                            <div>
                                <div className="page-leave-history__modal-input-wrapper">
                                    <AppInputWithLabel placeholder="Employee ID" value={leaveDetails.staffID} disabled={true} />
                                </div>
                                <div className="page-leave-history__modal-input-wrapper">
                                    <AppInputWithLabel placeholder="Time Off" value={leaveDetails.timeOff} disabled={true} />
                                </div>
                                <div className="page-leave-history__modal-input-wrapper">
                                    <AppInputWithLabel placeholder="End Date" value={leaveDetails.endDate} disabled={true} />
                                </div>
                                {leaveDetails.rejectReason ? (
                                    <div className="page-leave-history__modal-input-wrapper">
                                        <AppInputWithLabel placeholder="Reject Reason" value={leaveDetails.rejectReason} disabled={true} />
                                    </div>
                                ) : null}
                                {leaveDetails.remark ? (
                                    <div className="page-leave-history__modal-input-wrapper">
                                        <AppTextArea placeholder="Remark" value={leaveDetails.remark} disabled={true} />
                                    </div>
                                ) : null}
                            </div>
                        }
                    />
                ) : null}
                <div className="page-leave-history__breadcrumb">
                    <AppBreadcrumb list={breadcrumb} />
                </div>

                <div className="page-leave-history__button-row">
                    <div className="page-leave-history__new-button">
                        <AppButton buttonIcon={<img src={iconCalendar} alt="" className="page-leave-history__calendar-icon" />} size="s" label="New Leave" onClick={() => createLeaveModal.current.createLeaveModalIsOpen()} />
                    </div>
                </div>

                <div className="page-leave-history__tab-wrapper">
                    <AppTabLink tabList={appTabLink} />
                </div>

                <div className="page-leave-history__table-wrapper">
                    <AppReactTable columns={tableColumn} data={tableData.leaveDirList} onClickRow={selectedRow} />
                </div>
                <div className="page-leave-history__pagination">
                    <AppPaginate onPageChange={onPageChange} pageCount={tableData.totalPages} forcePage={tableSearchFilter.pageNo} />
                </div>
            </div>
        </AppPagesLayout>
    );
};

export default PageEmployeeLeaveHistory;
