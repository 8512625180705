
// dashboard
import PageDashboard from "pages/admin/home/dashboard";
// user management
import PageUserManagement from "pages/admin/user-management/user-management";
import PageEmployee from "pages/admin/user-management/employee";
// company benefits
import PageCompanyBenefitsPosition from "pages/admin/company-benefits/position";
import PageCompanyBenefitsBenefit from "pages/admin/company-benefits/benefit";
import PageCreateBenefitPackage from "pages/admin/company-benefits/create-edit-benefit-package";
// leave management
import PageLeaveCalendar from "pages/admin/leave-management/leave-calendar";
import PageLeaveDirectory from "pages/admin/leave-management/leave-directory";
import PageLeaveSummary from "pages/admin/leave-management/leave-summary";
import PageLeaveHistory from "pages/admin/leave-management/leave-history";
import PagePendingLeave from "pages/admin/leave-management/pending-leave";
import PageLeavePendingApproval from "pages/admin/leave-management/leave-pending-approval";
// claim management
import PageClaimDirectory from "pages/admin/claim-management/claim-directory";
import PageClaimPendingApproval from "pages/admin/claim-management/claim-pending-approval";
import PageClaimPendingPayment from "pages/admin/claim-management/claim-pending-payment";
import PageClaimSummary from "pages/admin/claim-management/claim-summary";
import PageClaimHistory from "pages/admin/claim-management/claim-history";
import PagePendingClaim from "pages/admin/claim-management/pending-claim";
// timesheet
import PageTimesheetManagement from "pages/admin/timesheet-management/timesheet-management";
// payslip
import PagePayslip from "pages/admin/payslip/payslip";
import PageEmployeePayslip from "pages/admin/payslip/employee-payslip";
// employee confirmation
import PageEmployeeConfirmation from "pages/admin/employee-confirmation/employee-confirmation";
import PageSendConfirmationLetter from "pages/admin/employee-confirmation/send-letter";
// coaching session
import PageCoachingUpcomingSession from "pages/admin/coaching-session/upcoming-schedule";
import PageCoachingHistory from "pages/admin/coaching-session/coaching-history";
// path
import pathnames from "./pathnames";

const adminRoutes = [
    {
        path: pathnames.pageDashboard,
        component: PageDashboard,
        title: "Dashboard",
        authentication: true,
    },
    {
        path: pathnames.pageUserManagement,
        component: PageUserManagement,
        title: "User Management",
        authentication: true,
    },
    {
        path: pathnames.pageNewEmployee,
        component: PageEmployee,
        title: "New Employee",
        authentication: true,
    },
    {
        path: pathnames.pageEditEmployee,
        component: PageEmployee,
        title: "Edit Employee",
        authentication: true,
    },
    {
        path: pathnames.pageCompanyBenefitsPosition,
        component: PageCompanyBenefitsPosition,
        title: "Company Benefits Position",
        authentication: true,
    },
    {
        path: pathnames.pageCompanyBenefitsBenefit,
        component: PageCompanyBenefitsBenefit,
        title: "Company Benefits Benefit",
        authentication: true,
    },
    {
        path: pathnames.pageCreateBenefitPackage,
        component: PageCreateBenefitPackage,
        title: "Create Benefit Package",
        authentication: true,
    },
    {
        path: pathnames.pageEditBenefitPackage,
        component: PageCreateBenefitPackage,
        title: "Edit Benefit Package",
        authentication: true,
    },
    {
        path: pathnames.pageLeaveManagementCalendar,
        component: PageLeaveCalendar,
        title: "Leave Management Calendar",
        authentication: true,
    },
    {
        path: pathnames.pageLeaveDirectory,
        component: PageLeaveDirectory,
        title: "Leave Directory",
        authentication: true,
    },
    {
        path: pathnames.pageLeaveSummary,
        component: PageLeaveSummary,
        title: "Leave Summary",
        authentication: true,
    },
    {
        path: pathnames.pageLeaveHistory,
        component: PageLeaveHistory,
        title: "Leave History",
        authentication: true,
    },
    {
        path: pathnames.pagePendingLeave,
        component: PagePendingLeave,
        title: "Pending Leave",
        authentication: true,
    },
    {
        path: pathnames.pageLeavePendingApproval,
        component: PageLeavePendingApproval,
        title: "Leave Pending Approval",
        authentication: true,
    },
    {
        path: pathnames.pageClaimDirectory,
        component: PageClaimDirectory,
        title: "Claim Directory",
        authentication: true,
    },
    {
        path: pathnames.pageClaimSummary,
        component: PageClaimSummary,
        title: "Claim Summary",
        authentication: true,
    },
    {
        path: pathnames.pageClaimHistory,
        component: PageClaimHistory,
        title: "Claim History",
        authentication: true,
    },
    {
        path: pathnames.pagePendingClaim,
        component: PagePendingClaim,
        title: "Pending Claim",
        authentication: true,
    },
    {
        path: pathnames.pageClaimPendingApproval,
        component: PageClaimPendingApproval,
        title: "Claim Pending Approval",
        authentication: true,
    },
    {
        path: pathnames.pageClaimPendingPayment,
        component: PageClaimPendingPayment,
        title: "Claim Pending Payment",
        authentication: true,
    },
    {
        path: pathnames.pageTimesheetManagement,
        component: PageTimesheetManagement,
        title: "Timesheet Management",
        authentication: true,
    },
    {
        path: pathnames.pagePayslip,
        component: PagePayslip,
        title: "Payslip",
        authentication: true,
    },
    {
        path: pathnames.pageEmployeePayslip,
        component: PageEmployeePayslip,
        title: "Payslip",
        authentication: true,
    },
    {
        path: pathnames.pageEmployeeConfirmation,
        component: PageEmployeeConfirmation,
        title: "Employee Confirmation",
        authentication: true,
    },
    {
        path: pathnames.pageEmployeeConfirmationSendLetter,
        component: PageSendConfirmationLetter,
        title: "Employee Confirmation",
        authentication: true,
    },
    {
        path: pathnames.pageCoachingUpcomingSession,
        component: PageCoachingUpcomingSession,
        title: "Coaching Session",
        authentication: true,
    },
    {
        path: pathnames.pageCoachingHistory,
        component: PageCoachingHistory,
        title: "Coaching Session",
        authentication: true,
    },
];

export default adminRoutes;