// libraries
import React, { useEffect, useState, Fragment, forwardRef, useImperativeHandle } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { useSelector } from 'react-redux';
// components
import AppModal from "components/app-modal";
import AppDropdown from "components/app-dropdown";
import AppButton from "components/app-button";
import appToast from "components/app-toast";
import AppModalConfirmation from "components/app-modal-confirmation";
import AppInputWithLabel from "components/app-input-with-label";
import AppInputUploadAttachment from "components/app-input-upload-attachment";
import AppTextArea from "components/app-text-area";
// service
import api from "services/api";
// common
import { sanitizeError } from "common/utilities";

const newClaimFields = [
    { placeholder: "Claim Type", name: "claimType", type: "dropdown" },
    { placeholder: "Bill Amount (RM)", name: "amount", type: "input" },
    { placeholder: "Attachment", name: "attachment", type: "upload" },
    { placeholder: "Description (Optional)", name: "description", type: "textarea" },
];

const initialValues = {
    claimType: "",
    amount: "",
    attachment: null,
    description: "",
};

const validationSchema = yup.object().shape({
    claimType: yup.string().required("Claim type is required"),
    amount: yup
        .string()
        .required("Amount is required")
        .test("amount", "Invalid amount", (value) => {
            if (value) {
                let arrayValue = value.split(".");
                if (arrayValue.length === 2 && arrayValue[arrayValue.length - 1].length !== 2) {
                    return false;
                } else return true;
            }
        }),
        attachment: yup.string().required("Attachment is required").nullable(),
});

const confirmationBtnInitialValues = { label: "Confirm", disable: false };

const AppEmployeeCreateNewClaim = forwardRef(({ onSuccess }, ref) => {
    const [modalIsOpenSequence, setModalIsOpenSequence] = useState([]);
    const [claimTypeList, getClaimTypeList] = useState([]);
    const [inputValues, setInputValues] = useState({});
    const [confirmationBtn, setConfirmationBtn] = useState(confirmationBtnInitialValues)
    const profile = useSelector(state => state.auth);

    useEffect(() => {
        const getEntitledClaimType = async (profile) => {
            try {
                const response = await api.get.companyBenefitEntitledBenefit(profile.staffID);
                const formattedClaimBenefits = response.data.result.claimBenefits.map((o) => {
                    return {
                        label: o.benefitName,
                        value: o.benefitName,
                    }
                });
                getClaimTypeList(formattedClaimBenefits)
            } catch (error) {
                appToast(sanitizeError(error), false);
            }
        };
        getEntitledClaimType(profile);
    }, [profile])

    useImperativeHandle(ref, () => ({
        createClaimModalIsOpen() {
            setModalIsOpenSequence(["MODAL_CREATE_CLAIM"]);
        },
    }));

    const createClaimOnRequestClose = (resetForm) => {
        setModalIsOpenSequence([]);
        resetForm();
    };

    const dropdownCurrentInputValue = (name, value) => {
        if (name === "claimType" && value) {
            return claimTypeList.filter((e) => e.value === value)[0];
        } else {
            return null;
        }
    };

    const onHandleSubmit = (values) => {
        setInputValues(values);
        setModalIsOpenSequence([...modalIsOpenSequence, "MODAL_CONFIRMATION"]);
    };

    const confirmationModalOnRequestClose = () => {
        setModalIsOpenSequence(modalIsOpenSequence.slice(0, -1));
    };

    const confirmationOnClick = async (resetForm) => {
        setConfirmationBtn({
            label: "Creating..",
            disable: true,
        })
        try {
            let payload = {
                staffId: profile.staffID,
                claimType: inputValues.claimType,
                billAmount: parseFloat(inputValues.amount),
                desc: inputValues.description,
            };
            const formData = new FormData();
            const dataBlob = new Blob([JSON.stringify(payload)], { type: "application/json" });
            formData.append("claim", dataBlob);
            formData.append("attachment", inputValues.attachment);
            const response = await api.post.claimManagementCreateClaim(formData);
            if (response.data.result.status.toLowerCase() === "reject") {
                setConfirmationBtn(confirmationBtnInitialValues)
                appToast(response.data.result.rejectReason, false);
            } else {
                setConfirmationBtn(confirmationBtnInitialValues)
                appToast("Claim has been submitted successfully", true);
                setModalIsOpenSequence([]);
                setInputValues({});
                resetForm();
                onSuccess();
            }
        } catch (error) {
            setConfirmationBtn(confirmationBtnInitialValues)
            appToast(sanitizeError(error), false);
        }
    };

    return (
        <Formik initialValues={initialValues} onSubmit={onHandleSubmit} validationSchema={validationSchema}>
            {({ handleSubmit, values, setFieldValue, errors, touched, setFieldTouched, resetForm }) => {
                return (
                    <Fragment>
                        <AppModalConfirmation
                            details="Confirm to create?"
                            buttonLabel={confirmationBtn.label}
                            isOpenModal={modalIsOpenSequence.slice(-1)[0] === "MODAL_CONFIRMATION"}
                            onRequestClose={confirmationModalOnRequestClose}
                            onClick={() => confirmationOnClick(resetForm)}
                            buttonDisabled={confirmationBtn.disable}
                        />
                        <AppModal title="New Claim" isOpenModal={modalIsOpenSequence.slice(-1)[0] === "MODAL_CREATE_CLAIM"} onRequestClose={() => createClaimOnRequestClose(resetForm)}>
                            <div>
                                {newClaimFields.map((o, i) => {
                                    if (o.type === "dropdown") {
                                        return (
                                            <div key={i} className="app-new-claim__input-wrapper">
                                                <AppDropdown
                                                    placeholder={o.placeholder}
                                                    dropdownOptions={o.name === "claimType" ? claimTypeList : {}}
                                                    error={touched[o.name] && errors[o.name]}
                                                    onChange={(selected) => {
                                                        setFieldTouched(o.name);
                                                        setFieldValue(o.name, selected.value);
                                                    }}
                                                    value={values[o.name]}
                                                    currentInputValue={dropdownCurrentInputValue(o.name, values[o.name])}
                                                />
                                            </div>
                                        );
                                    } else if (o.type === "input") {
                                        return (
                                            <div key={i} className="app-new-claim__input-wrapper">
                                                <AppInputWithLabel
                                                    placeholder={o.placeholder}
                                                    onChange={(e) => {
                                                        setFieldTouched(o.name);
                                                        setFieldValue(o.name, e.target.value);
                                                    }}
                                                    error={touched[o.name] && errors[o.name]}
                                                    value={values[o.name]}
                                                    type="number"
                                                />
                                            </div>
                                        );
                                    } else if (o.type === "upload") {
                                        return (
                                            <div key={i} className="app-new-claim__input-wrapper">
                                                <AppInputUploadAttachment
                                                    accept="application/pdf, .jpg, .png, .jpeg"
                                                    placeholder={o.placeholder}
                                                    onChange={(e) => {
                                                        setFieldValue(o.name, e.target.files[0]);
                                                    }}
                                                    value={values[o.name] ? values[o.name].name : ""}
                                                    error={touched[o.name] && errors[o.name]}
                                                />
                                            </div>
                                        );
                                    } else if (o.type === "textarea") {
                                        return (
                                            <div key={i} className="app-new-claim__input-wrapper">
                                                <AppTextArea
                                                    placeholder={o.placeholder}
                                                    onChange={(e) => {
                                                        setFieldTouched(o.name);
                                                        setFieldValue(o.name, e.target.value);
                                                    }}
                                                    value={values[o.name]}
                                                />
                                            </div>
                                        );
                                    } else return null;
                                })}
                            </div>
                            <div className="app-new-claim__button-row">
                                <div className="app-new-claim__button-wrapper">
                                    <AppButton label="Cancel" buttonType="outline" size="l" onClick={() => createClaimOnRequestClose(resetForm)} />
                                </div>
                                <div className="app-new-claim__button-wrapper">
                                    <AppButton label="Create" size="l" onClick={handleSubmit} />
                                </div>
                            </div>
                        </AppModal>
                    </Fragment>
                );
            }}
        </Formik>
    );
});

export default AppEmployeeCreateNewClaim;
