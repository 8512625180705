// libraries
import React, { useState, useEffect } from "react";
import { BsPlusLg } from "react-icons/bs";
import { useHistory, useLocation, useParams } from "react-router-dom";
// components
import AppPagesLayout from "components/app-pages-layout";
import AppBreadcrumb from "components/app-breadcrumb";
import AppInputWithLabel from "components/app-input-with-label";
import AppMegaDropdown from "components/app-mega-dropdown";
import AppInputNumber from "components/app-input-number";
import AppToggle from "components/app-toggle";
import AppButton from "components/app-button";
import AppInput from "components/app-input";
import AppModal from "components/app-modal";
import appToast from "components/app-toast";
import AppModalConfirmation from "components/app-modal-confirmation";
// hooks
import useIsMount from "hooks/use-is-mount";
// api
import api from "services/api";
// routes
import pathnames from "routes/pathnames";
// common
import { sanitizeError } from "common/utilities";
import CONSTANT from "common/constant";
// assets
import iconDelete from "assets/images/icon-delete.svg";

const createBenefitBreadcrumb = [
    {
        label: "Company Benefits",
        pathname: pathnames.pageCreateBenefitPackage,
    },
    {
        label: "Benefit",
        pathname: pathnames.pageCompanyBenefitsBenefit,
    },
    {
        label: "Create Benefit Package",
    },
];

const editBenefitBreadcrumb = [
    {
        label: "Company Benefits",
        pathname: pathnames.pageCreateBenefitPackage,
    },
    {
        label: "Benefit",
        pathname: pathnames.pageCompanyBenefitsBenefit,
    },
    {
        label: "Edit Benefit Package",
    },
];

const PageCreateBenefitPackage = () => {
    const history = useHistory();
    const [attentionModal, setAttentionModal] = useState(false);
    const [packageName, setPackageName] = useState({
        value: "",
        error: "",
    });
    const [position, setPosition] = useState([]);
    const [leaveBenefitValuesBeforeFilter, setLeaveBenefitValuesBeforeFilter] = useState([
        {
            benefitName: CONSTANT.BE_STATUS.LEAVE_TYPE.ANNUAL_LEAVE,
            benefitType: "leave",
            benefitValue: "",
            approval: 0,
            error: "",
        },
        {
            benefitName: CONSTANT.BE_STATUS.LEAVE_TYPE.COMPASSIONATE_LEAVE,
            benefitType: "leave",
            benefitValue: "",
            approval: 0,
            error: "",
        },
        {
            benefitName: CONSTANT.BE_STATUS.LEAVE_TYPE.MEDICAL_LEAVE,
            benefitType: "leave",
            benefitValue: "",
            approval: 0,
            error: "",
        },
        {
            benefitName: CONSTANT.BE_STATUS.LEAVE_TYPE.PATERNITY_LEAVE,
            benefitType: "leave",
            benefitValue: "",
            approval: 0,
            error: "",
        },
        {
            benefitName: CONSTANT.BE_STATUS.LEAVE_TYPE.CARRY_FORWARD_LEAVE,
            benefitType: "leave",
            benefitValue: "",
            approval: 0,
            error: "",
        },
    ]);
    const [claimBenefitValuesBeforeFilter, setClaimBenefitValuesBeforeFilter] = useState([
        {
            benefitName: CONSTANT.BE_STATUS.CLAIM.CLAIM_TYPE.MEDICAL_CLAIM,
            benefitType: "claim",
            benefitValue: "",
            approval: 0,
            error: "",
        },
        {
            benefitName: CONSTANT.BE_STATUS.CLAIM.CLAIM_TYPE.OTHERS,
            benefitType: "claim",
            benefitValue: "Unlimited",
            approval: 1,
            error: "",
        },
        {
            benefitName: CONSTANT.BE_STATUS.CLAIM.CLAIM_TYPE.BODY_CHECK_OPTICAL_DENTAL,
            benefitType: "claim",
            benefitValue: "",
            approval: 0,
            error: "",
        },
    ]);
    const [newLeave, setNewLeave] = useState([]);
    const [newClaim, setNewClaim] = useState([]);
    const [editPackageId, setEditPackageId] = useState(null);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [createEditBenefitContents, setCreateEditBenefitContents] = useState({
        breadcrumb: [],
        buttonLabel: "",
        buttonDisable: false,
    });
    const location = useLocation();
    const isMount = useIsMount();
    const { pathPackageName } = useParams();
    const [deleteBenefit, setDeleteBenefit] = useState();
    const [existingBenefitId, setExistingBenefitId] = useState([]);

    useEffect(() => {
        if (isMount) {
            if (location.pathname === pathnames.pageEditBenefitPackage.replace(":pathPackageName", pathPackageName)) {
                const getBenefitFromPackageName = async () => {
                    try {
                        let pathPackageNameValue = pathPackageName.replace("value_", "");
                        const response = await api.get.companyBenefitGetBenefit(pathPackageNameValue);
                        let result = response.data.result;
                        const existingLeaveBenefit = [];
                        const newLeaveBenefit = [];
                        const existingClaimBenefit = [];
                        const newClaimBenefit = [];
                        const existingBenefitId = [];
                        result.leaveBenefits.forEach((o, i) => {
                            if (o.benefitName === CONSTANT.BE_STATUS.LEAVE_TYPE.ANNUAL_LEAVE || o.benefitName === CONSTANT.BE_STATUS.LEAVE_TYPE.COMPASSIONATE_LEAVE || o.benefitName === CONSTANT.BE_STATUS.LEAVE_TYPE.MEDICAL_LEAVE || o.benefitName === CONSTANT.BE_STATUS.LEAVE_TYPE.PATERNITY_LEAVE || o.benefitName === CONSTANT.BE_STATUS.LEAVE_TYPE.CARRY_FORWARD_LEAVE) {
                                existingLeaveBenefit.push({ ...o, error: "" });
                            } else {
                                existingBenefitId.push(i);
                                newLeaveBenefit.push({ ...o, id: i, error: { name: "", value: "" } });
                            }
                        });
                        result.claimBenefits.forEach((o, i) => {
                            if (o.benefitName === CONSTANT.BE_STATUS.CLAIM.CLAIM_TYPE.MEDICAL_CLAIM || o.benefitName === CONSTANT.BE_STATUS.CLAIM.CLAIM_TYPE.OTHERS || o.benefitName === CONSTANT.BE_STATUS.CLAIM.CLAIM_TYPE.BODY_CHECK_OPTICAL_DENTAL) {
                                existingClaimBenefit.push({ ...o, error: "" });
                            } else {
                                existingBenefitId.push(i + 1001);
                                newClaimBenefit.push({ ...o, id: i + 1001, error: { name: "", value: "" } });
                            }
                        });
                        setExistingBenefitId(existingBenefitId);
                        setEditPackageId(result.id);
                        setCreateEditBenefitContents({
                            ...createEditBenefitContents,
                            breadcrumb: editBenefitBreadcrumb,
                            buttonLabel: "Save",
                            buttonDisable: true,
                        });

                        setPackageName({
                            error: "",
                            value: result.packageName,
                        });
                        setLeaveBenefitValuesBeforeFilter(existingLeaveBenefit);
                        setClaimBenefitValuesBeforeFilter(existingClaimBenefit);

                        setNewLeave(newLeaveBenefit);
                        setNewClaim(newClaimBenefit);
                        let packagePositionSplitted = result.packagePosition.split(", ").join(",").split("[]").join(",").split(",");
                        setPosition(packagePositionSplitted.filter((e) => e !== ""));
                    } catch (error) {
                        let sanitizedError = sanitizeError(error);
                        appToast(sanitizedError, false, 300);
                        history.push(pathnames.pageCompanyBenefitsBenefit);
                    }
                };
                getBenefitFromPackageName();
            } else {
                setCreateEditBenefitContents({
                    ...createEditBenefitContents,
                    breadcrumb: createBenefitBreadcrumb,
                    buttonLabel: "Create",
                    buttonDisable: false,
                });
                history.push(pathnames.pageCreateBenefitPackage);
            }
        }
    }, [location, history, createEditBenefitContents, isMount, pathPackageName]);

    const addNewLeave = () => {
        if (!newLeave.length) {
            setNewLeave([{ id: 1, benefitName: "", benefitType: "leave", benefitValue: "", approval: 0, error: { name: "", value: "" } }]);
        } else {
            let newLeaveId = newLeave.map((o, i) => o.id);
            let maxId = Math.max(...newLeaveId);
            setNewLeave([...newLeave, { id: maxId + 1, benefitName: "", benefitType: "leave", benefitValue: "", approval: 0, error: { name: "", value: "" } }]);
        }
    };

    const confirmationModalOnClick = async () => {
        if (existingBenefitId.includes(deleteBenefit.id)) {
            try {
                const payload = {
                    benefitName: deleteBenefit.benefitName,
                    packageId: editPackageId,
                };
                const response = await api.get.companyBenefitRemoveBenefit(payload);
                let id = deleteBenefit.id;
                let afterDeleteNewLeave = newLeave.filter((o, i) => o.id !== id);
                setNewLeave([...afterDeleteNewLeave]);

                let afterDeleteNewClaim = newClaim.filter((o, i) => o.id !== id);
                setNewClaim([...afterDeleteNewClaim]);
                setConfirmModalOpen(false);
                appToast(response.data.result + " has been removed", true);
            } catch (error) {
                let sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
                setConfirmModalOpen(false);
            }
        } else {
            let id = deleteBenefit.id;
            let afterDeleteNewLeave = newLeave.filter((o, i) => o.id !== id);
            setNewLeave([...afterDeleteNewLeave]);

            let afterDeleteNewClaim = newClaim.filter((o, i) => o.id !== id);
            setNewClaim([...afterDeleteNewClaim]);
            setConfirmModalOpen(false);
        }
    };

    const addnewClaim = () => {
        if (!newClaim.length) {
            setNewClaim([{ id: 1, benefitName: "", benefitType: "claim", benefitValue: "", approval: 0, error: { name: "", value: "" } }]);
        } else {
            let newClaimId = newClaim.map((o, i) => o.id);
            let maxId = Math.max(...newClaimId);
            setNewClaim([...newClaim, { id: maxId + 1, benefitName: "", benefitType: "claim", benefitValue: "", approval: 0, error: { name: "", value: "" } }]);
        }
    };

    const leaveApprovedForAllChecked = () => {
        let currentLeaveChecked;
        let newLeaveChecked;
        if (leaveBenefitValuesBeforeFilter && leaveBenefitValuesBeforeFilter.length) {
            currentLeaveChecked = leaveBenefitValuesBeforeFilter.every((o) => o.approval === 1);
        }
        if (newLeave && newLeave.length) {
            newLeaveChecked = newLeave.every((o) => o.approval === 1);
        } else {
            newLeaveChecked = true;
        }
        return currentLeaveChecked && newLeaveChecked;
    };

    const claimApprovedForAllChecked = () => {
        let currentClaimChecked;
        let newClaimChecked;
        if (claimBenefitValuesBeforeFilter && claimBenefitValuesBeforeFilter.length) {
            currentClaimChecked = claimBenefitValuesBeforeFilter.every((o) => o.approval === 1);
        }
        if (newClaim && newClaim.length) {
            newClaimChecked = newClaim.every((o) => o.approval === 1);
        } else {
            newClaimChecked = true;
        }
        return currentClaimChecked && newClaimChecked;
    };

    const createHandler = async () => {
        const clonedLeaveBenefitValuesBeforeFilter = leaveBenefitValuesBeforeFilter.map((obj) => {
            if (!obj.benefitValue) {
                return {
                    ...obj,
                    error: obj.benefitName + " is required to be more than 0.",
                };
            } else return obj;
        });
        setLeaveBenefitValuesBeforeFilter(clonedLeaveBenefitValuesBeforeFilter);

        const clonedNewLeave = newLeave.map((obj) => {
            if (!obj.benefitName || !obj.benefitValue) {
                return {
                    ...obj,
                    error: {
                        name: !obj.benefitName ? "Name is required." : "",
                        value: !obj.benefitValue ? "Value is required to be more than 0." : "",
                    },
                };
            } else {
                return obj;
            }
        });
        setNewLeave(clonedNewLeave);

        const clonedClaimBenefitValuesBeforeFilter = claimBenefitValuesBeforeFilter.map((obj) => {
            if (!obj.benefitValue) {
                return {
                    ...obj,
                    error: obj.benefitName + " is required to be more than 0.",
                };
            } else return obj;
        });
        setClaimBenefitValuesBeforeFilter(clonedClaimBenefitValuesBeforeFilter);

        const clonedNewClaim = newClaim.map((obj) => {
            if (!obj.benefitName || !obj.benefitValue) {
                return {
                    ...obj,
                    error: {
                        name: !obj.benefitName ? "Name is required." : "",
                        value: !obj.benefitValue ? "Value is required to be more than 0." : "",
                    },
                };
            } else {
                return obj;
            }
        });
        setNewClaim(clonedNewClaim);

        let packageNameError = !packageName.value;
        let leaveBenefitError = clonedLeaveBenefitValuesBeforeFilter.some((o) => o.error);
        let claimBenefitError = clonedClaimBenefitValuesBeforeFilter.some((o) => o.error);
        let newLeaveNameError = clonedNewLeave.some((o) => o.error.name);
        let newLeaveValueError = clonedNewLeave.some((o) => o.error.value);
        let newClaimNameError = clonedNewClaim.some((o) => o.error.name);
        let newClaimValueError = clonedNewClaim.some((o) => o.error.value);
        if (packageNameError) {
            setPackageName({
                ...packageName,
                error: "Package Name is required",
            });
        }
        if (!packageNameError && !leaveBenefitError && !claimBenefitError && !newLeaveNameError && !newLeaveValueError && !newClaimNameError && !newClaimValueError) {
            setAttentionModal(true);
        }
    };

    const postCreateBenefit = async () => {
        let clonedLeaveBenefitValues = [...leaveBenefitValuesBeforeFilter];
        clonedLeaveBenefitValues.forEach((o, i) => {
            delete o.error;
        });
        let clonedNewLeaveBenefitValues = [...newLeave];
        clonedNewLeaveBenefitValues.forEach((o, i) => {
            delete o.id;
            delete o.error;
        });
        let clonedClaimBenefitValues = [...claimBenefitValuesBeforeFilter];
        clonedClaimBenefitValues.forEach((o, i) => {
            delete o.error;
        });
        let clonedNewClaimBenefitValue = [...newClaim];
        clonedNewClaimBenefitValue.forEach((o, i) => {
            delete o.id;
            delete o.error;
        });

        if (location.pathname.split("value_")[0] === pathnames.pageEditBenefitPackage.split(":")[0]) {
            try {
                let payload = {
                    claimBenefits: [...clonedClaimBenefitValues, ...clonedNewClaimBenefitValue],
                    leaveBenefits: [...clonedLeaveBenefitValues, ...clonedNewLeaveBenefitValues],
                    packageId: editPackageId,
                    packageName: packageName.value,
                    packagePosition: position,
                };
                await api.post.companyBenefitUpdateBenefit(payload);
                appToast("Benefit has been updated successfully", true, 300);
                history.push(pathnames.pageCompanyBenefitsBenefit);
            } catch (error) {
                let sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
                setAttentionModal(false);
            }
        } else {
            try {
                let payload = {
                    claimBenefits: [...clonedClaimBenefitValues, ...clonedNewClaimBenefitValue],
                    leaveBenefits: [...clonedLeaveBenefitValues, ...clonedNewLeaveBenefitValues],
                    packageName: packageName.value,
                    packagePosition: position,
                };
                await api.post.createBenefit(payload);
                appToast("Benefit has been created successfully", true, 300);
                history.push(pathnames.pageCompanyBenefitsBenefit);
            } catch (error) {
                let sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
                setAttentionModal(false);
            }
        }
    };

    const confirmEdit = () => {
        postCreateBenefit();
    };

    return (
        <AppPagesLayout>
            <div className="page-create-benefit">
                <AppModal title="Attention" isOpenModal={attentionModal} onRequestClose={() => setAttentionModal(false)}>
                    <div className="page-create-benefit__attention">
                        <div className="attention__content">Confirm to {createEditBenefitContents.buttonLabel.toLowerCase()}?</div>
                        <div className="attention__button-row">
                            <div className="attention__button-wrapper">
                                <AppButton label="Cancel" buttonType="outline" onClick={() => setAttentionModal(false)} />
                            </div>
                            <div className="attention__button-wrapper">
                                <AppButton label="Confirm" onClick={confirmEdit} />
                            </div>
                        </div>
                    </div>
                </AppModal>
                <AppModalConfirmation
                    isOpenModal={confirmModalOpen}
                    onRequestClose={() => setConfirmModalOpen(false)}
                    details={deleteBenefit ? `${deleteBenefit.benefitName ? `"${deleteBenefit.benefitName}"` : "Benefit"} deleted will be save.` : ""}
                    buttonLabel="Confirm"
                    onClick={() => confirmationModalOnClick()}
                />
                <div className="page-create-benefit__breadcrumb">
                    <AppBreadcrumb list={createEditBenefitContents.breadcrumb} />
                </div>
                <div className="page-create-benefit__input-row">
                    <div className="page-create-benefit__name-input">
                        <AppInputWithLabel
                            placeholder="Benefit Package Name"
                            onChange={(e) => {
                                setPackageName({
                                    error: "",
                                    value: e.target.value,
                                });
                                setCreateEditBenefitContents({
                                    ...createEditBenefitContents,
                                    buttonDisable: false,
                                });
                            }}
                            value={packageName.value}
                            error={packageName.error}
                        />
                    </div>
                </div>
                <div className="page-create-benefit__mega-dropdown-wrapper">
                    <AppMegaDropdown
                        title="Leave Benefit"
                        toggleOnChange={(e) => {
                            let clonedLeaveBenefitValues = [...leaveBenefitValuesBeforeFilter];
                            let clonedNewLeaveBenefitValues = [...newLeave];
                            if (e.target.checked) {
                                clonedLeaveBenefitValues.forEach((o) => (o.approval = 1));
                                setLeaveBenefitValuesBeforeFilter(clonedLeaveBenefitValues);
                                clonedNewLeaveBenefitValues.forEach((o) => (o.approval = 1));
                                setNewLeave(clonedNewLeaveBenefitValues);
                            } else {
                                clonedLeaveBenefitValues.forEach((o) => (o.approval = 0));
                                setLeaveBenefitValuesBeforeFilter(clonedLeaveBenefitValues);
                                clonedNewLeaveBenefitValues.forEach((o) => (o.approval = 0));
                                setNewLeave(clonedNewLeaveBenefitValues);
                            }
                            setCreateEditBenefitContents({
                                ...createEditBenefitContents,
                                buttonDisable: false,
                            });
                        }}
                        toggleChecked={leaveApprovedForAllChecked()}>
                        <div className="page-create-benefit__contents">
                            <div className="page-create-benefit__item-wrapper">
                                {leaveBenefitValuesBeforeFilter.map((item, index) => {
                                    // checkBenefitError(item, leaveBenefitValuesBeforeFilter, setLeaveBenefitValuesBeforeFilter);
                                    return (
                                        <div key={index} className="page-create-benefit__item">
                                            <div className="page-create-benefit__title-wrapper">
                                                <div className="page-create-benefit__title">{item.benefitName}</div>
                                                <AppToggle
                                                    onChange={(e) => {
                                                        let approvalStatus = e.target.checked ? 1 : 0;
                                                        let clonedLeaveBenefitValues = Array.from(leaveBenefitValuesBeforeFilter);
                                                        clonedLeaveBenefitValues.find((o) => o.benefitName === item.benefitName).approval = approvalStatus;
                                                        setLeaveBenefitValuesBeforeFilter(clonedLeaveBenefitValues);
                                                        setCreateEditBenefitContents({
                                                            ...createEditBenefitContents,
                                                            buttonDisable: false,
                                                        });
                                                    }}
                                                    checked={item.approval}
                                                />
                                            </div>
                                            <div className="page-create-benefit__input-wrapper">
                                                <AppInputNumber
                                                    placeholder="Number of days"
                                                    onChange={(v) => {
                                                        let leaveBenefitValueUpdate = [...leaveBenefitValuesBeforeFilter];
                                                        leaveBenefitValueUpdate.forEach((o, i) => {
                                                            if (o.benefitName.toString() === item.benefitName.toString()) {
                                                                o.benefitValue = v.toString();
                                                                o.error = "";
                                                            }
                                                        });
                                                        setLeaveBenefitValuesBeforeFilter([...leaveBenefitValueUpdate]);
                                                        setCreateEditBenefitContents({
                                                            ...createEditBenefitContents,
                                                            buttonDisable: false,
                                                        });
                                                    }}
                                                    value={item.benefitValue}
                                                    error={item.error}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="page-create-benefit__divider"></div>

                            <div className="page-create-benefit__create-new-field">
                                <div className="create-new-field__header">
                                    <div className="create-new-field__title">New Leave</div>
                                    <div className="create-new-field__button-wrapper">
                                        <AppButton size="s" label="Add New" buttonIcon={<BsPlusLg size={11} />} onClick={addNewLeave} />
                                    </div>
                                </div>

                                <div className="create-new-field__body">
                                    {newLeave.map((currentLeave, index) => {
                                        // checkNewBenefitError(currentLeave, newLeave, setNewLeave);
                                        return (
                                            <div key={index} className="create-new-field__body-item">
                                                <div className="create-new-field__toggle-wrapper">
                                                    <AppToggle
                                                        onChange={(e) => {
                                                            let approvalStatus = e.target.checked ? 1 : 0;
                                                            let clonedNewLeaveValues = [...newLeave];
                                                            clonedNewLeaveValues.find((o) => o.id === currentLeave.id).approval = approvalStatus;
                                                            setNewLeave(clonedNewLeaveValues);
                                                            setCreateEditBenefitContents({
                                                                ...createEditBenefitContents,
                                                                buttonDisable: false,
                                                            });
                                                        }}
                                                        checked={currentLeave.approval}
                                                    />
                                                </div>
                                                <div className="create-new-field__input-wrapper">
                                                    <AppInput
                                                        placeholder="Name"
                                                        onChange={(e) => {
                                                            let clonedArray = Array.from(newLeave);
                                                            clonedArray.forEach((o, i) => {
                                                                if (o.id === currentLeave.id) {
                                                                    o.benefitName = e.target.value;
                                                                    o.error.name = "";
                                                                }
                                                            });
                                                            setNewLeave(clonedArray);
                                                            setCreateEditBenefitContents({
                                                                ...createEditBenefitContents,
                                                                buttonDisable: false,
                                                            });
                                                        }}
                                                        value={newLeave.find((o, i) => o.id === currentLeave.id).benefitName}
                                                        error={currentLeave.error ? currentLeave.error.name : ""}
                                                    />
                                                    <button
                                                        className="create-new-field__delete-wrapper"
                                                        onClick={() => {
                                                            setDeleteBenefit(currentLeave);
                                                            setConfirmModalOpen(true);
                                                            setCreateEditBenefitContents({
                                                                ...createEditBenefitContents,
                                                                buttonDisable: false,
                                                            });
                                                        }}>
                                                        <img src={iconDelete} alt="" />
                                                    </button>
                                                </div>
                                                <div className="create-new-field__input-wrapper">
                                                    <AppInputNumber
                                                        placeholder="Number of days"
                                                        onChange={(v) => {
                                                            let newLeaveValueUpdate = [...newLeave];
                                                            newLeaveValueUpdate.forEach((o, i) => {
                                                                if (o.id === currentLeave.id) {
                                                                    o.benefitValue = v.toString();
                                                                    o.error.value = "";
                                                                }
                                                            });
                                                            setNewLeave([...newLeaveValueUpdate]);
                                                            setCreateEditBenefitContents({
                                                                ...createEditBenefitContents,
                                                                buttonDisable: false,
                                                            });
                                                        }}
                                                        value={currentLeave.benefitValue}
                                                        error={currentLeave.error ? currentLeave.error.value : ""}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </AppMegaDropdown>
                </div>

                <div className="page-create-benefit__mega-dropdown-wrapper">
                    <AppMegaDropdown
                        title="Claim Benefit"
                        toggleOnChange={(e) => {
                            let clonedClaimBenefitValues = [...claimBenefitValuesBeforeFilter];
                            let clonedNewClaimBenefitValues = [...newClaim];
                            if (e.target.checked) {
                                clonedClaimBenefitValues.forEach((o) => {
                                    if (o.benefitName !== "Others") {
                                        o.approval = 1;
                                    }
                                });
                                setClaimBenefitValuesBeforeFilter(clonedClaimBenefitValues);
                                clonedNewClaimBenefitValues.forEach((o) => (o.approval = 1));
                                setNewClaim(clonedNewClaimBenefitValues);
                            } else {
                                clonedClaimBenefitValues.forEach((o) => {
                                    if (o.benefitName !== "Others") {
                                        o.approval = 0;
                                    }
                                });
                                setClaimBenefitValuesBeforeFilter(clonedClaimBenefitValues);
                                clonedNewClaimBenefitValues.forEach((o) => (o.approval = 0));
                                setNewClaim(clonedNewClaimBenefitValues);
                            }
                            setCreateEditBenefitContents({
                                ...createEditBenefitContents,
                                buttonDisable: false,
                            });
                        }}
                        toggleChecked={claimApprovedForAllChecked()}>
                        <div className="page-create-benefit__contents">
                            <div className="page-create-benefit__item-wrapper">
                                {claimBenefitValuesBeforeFilter.map((item, index) => {
                                    // checkBenefitError(item, claimBenefitValuesBeforeFilter, setClaimBenefitValuesBeforeFilter);

                                    if (item.benefitName === "Others") {
                                        return (
                                            <div key={index} className="page-create-benefit__item">
                                                <div className="page-create-benefit__title-wrapper">
                                                    <div className="page-create-benefit__title">{item.benefitName}</div>
                                                    <AppToggle checked={item.approval} readOnly={true} />
                                                </div>
                                                <div className="page-create-benefit__input-wrapper">
                                                    <AppInputNumber value="subject to approval" disabled={true} showButtons={false} />
                                                </div>
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <div key={index} className="page-create-benefit__item">
                                                <div className="page-create-benefit__title-wrapper">
                                                    <div className="page-create-benefit__title">{item.benefitName}</div>
                                                    <AppToggle
                                                        onChange={(e) => {
                                                            let approvalStatus = e.target.checked ? 1 : 0;
                                                            let clonedClaimBenefitValues = Array.from(claimBenefitValuesBeforeFilter);
                                                            clonedClaimBenefitValues.find((o) => o.benefitName === item.benefitName).approval = approvalStatus;
                                                            setClaimBenefitValuesBeforeFilter(clonedClaimBenefitValues);
                                                            setCreateEditBenefitContents({
                                                                ...createEditBenefitContents,
                                                                buttonDisable: false,
                                                            });
                                                        }}
                                                        checked={item.approval}
                                                    />
                                                </div>
                                                <div className="page-create-benefit__input-wrapper">
                                                    <AppInputNumber
                                                        placeholder="Amount Yearly (RM)"
                                                        showButtons={false}
                                                        onChange={(v) => {
                                                            let claimBenefitValuesUpdate = [...claimBenefitValuesBeforeFilter];
                                                            claimBenefitValuesUpdate.forEach((o, i) => {
                                                                if (o.benefitName.toString() === item.benefitName.toString()) {
                                                                    o.benefitValue = v.toString();
                                                                    o.error = "";
                                                                }
                                                            });
                                                            setClaimBenefitValuesBeforeFilter([...claimBenefitValuesUpdate]);
                                                            setCreateEditBenefitContents({
                                                                ...createEditBenefitContents,
                                                                buttonDisable: false,
                                                            });
                                                        }}
                                                        value={item.benefitValue}
                                                        error={item.error}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                            <div className="page-create-benefit__divider"></div>

                            <div className="page-create-benefit__create-new-field">
                                <div className="create-new-field__header">
                                    <div className="create-new-field__title">New Benefit</div>
                                    <div className="create-new-field__button-wrapper">
                                        <AppButton size="s" label="Add New" buttonIcon={<BsPlusLg size={11} />} onClick={addnewClaim} />
                                    </div>
                                </div>

                                <div className="create-new-field__body">
                                    {newClaim.map((currentClaim, i) => {
                                        // checkNewBenefitError(currentClaim, newClaim, setNewClaim);
                                        return (
                                            <div key={i} className="create-new-field__body-item">
                                                <div className="create-new-field__toggle-wrapper">
                                                    <AppToggle
                                                        onChange={(e) => {
                                                            let approvalStatus = e.target.checked ? 1 : 0;
                                                            let clonednewClaimValues = [...newClaim];
                                                            clonednewClaimValues.find((o) => o.id === currentClaim.id).approval = approvalStatus;
                                                            setNewClaim(clonednewClaimValues);
                                                            setCreateEditBenefitContents({
                                                                ...createEditBenefitContents,
                                                                buttonDisable: false,
                                                            });
                                                        }}
                                                        checked={currentClaim.approval}
                                                    />
                                                </div>
                                                <div className="create-new-field__input-wrapper">
                                                    <AppInput
                                                        placeholder="Name"
                                                        onChange={(e) => {
                                                            let clonedArray = Array.from(newClaim);
                                                            clonedArray.forEach((o, i) => {
                                                                if (o.id === currentClaim.id) {
                                                                    o.benefitName = e.target.value;
                                                                    o.error.name = "";
                                                                }
                                                            });
                                                            setNewClaim(clonedArray);
                                                            setCreateEditBenefitContents({
                                                                ...createEditBenefitContents,
                                                                buttonDisable: false,
                                                            });
                                                        }}
                                                        value={newClaim.find((o, i) => o.id === currentClaim.id).benefitName}
                                                        error={currentClaim.error ? currentClaim.error.name : ""}
                                                    />
                                                    <button
                                                        className="create-new-field__delete-wrapper"
                                                        onClick={() => {
                                                            setDeleteBenefit(currentClaim);
                                                            setConfirmModalOpen(true);
                                                            setCreateEditBenefitContents({
                                                                ...createEditBenefitContents,
                                                                buttonDisable: false,
                                                            });
                                                        }}>
                                                        <img src={iconDelete} alt="" />
                                                    </button>
                                                </div>
                                                <div className="create-new-field__input-wrapper">
                                                    <AppInputNumber
                                                        placeholder="Amount"
                                                        onChange={(v) => {
                                                            let newClaimValueUpdate = [...newClaim];
                                                            newClaimValueUpdate.forEach((o, i) => {
                                                                if (o.id === currentClaim.id) {
                                                                    o.benefitValue = v.toString();
                                                                    o.error.value = "";
                                                                }
                                                            });
                                                            setNewClaim([...newClaimValueUpdate]);
                                                            setCreateEditBenefitContents({
                                                                ...createEditBenefitContents,
                                                                buttonDisable: false,
                                                            });
                                                        }}
                                                        value={currentClaim.benefitValue}
                                                        error={currentClaim.error ? currentClaim.error.value : ""}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </AppMegaDropdown>
                </div>

                <div className="page-create-benefit__button-row">
                    <div className="page-create-benefit__button-wrapper">
                        <AppButton label="Cancel" buttonType="outline" size="l" onClick={() => history.push(pathnames.pageCompanyBenefitsBenefit)} />
                    </div>
                    <div className="page-create-benefit__button-wrapper">
                        <AppButton label={createEditBenefitContents.buttonLabel} disabled={createEditBenefitContents.buttonDisable} size="l" onClick={createHandler} />
                    </div>
                </div>
            </div>
        </AppPagesLayout>
    );
};

export default PageCreateBenefitPackage;
