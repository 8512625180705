// libraries
import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import _ from "lodash";
// components
import AppPagesLayout from "components/app-pages-layout";
import AppBreadcrumb from "components/app-breadcrumb";
import AppTabLink from "components/app-tab-link";
import AppReactTable from "components/app-react-table";
import AppPaginate from "components/app-paginate";
import appToast from "components/app-toast";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppModalConfirmation from "components/app-modal-confirmation";
// common
import { sanitizeError } from "common/utilities";
// hooks
import usePrevious from "hooks/use-previous";
// services
import api from "services/api";
// routes
import employeePathnames from "routes/employee-pathnames";
// assets
import iconTailedArrowUp from "assets/images/icon-tailed-arrow-up.svg";
import iconCalendar from "assets/images/components/app-button/icon-calendar.svg";
import iconClose from "assets/images/icon-close-black.svg";
// new leave
import AppEmployeeCreateNewLeave from "./new-leave";

const breadcrumb = [
    {
        label: "Leave Management",
    },
    {
        label: "Leave Summary",
    },
];

const PageEmployeeLeaveSummary = () => {
    const [leaveSummaryTable, setLeaveSummaryTable] = useState([]);
    const [tableUpcomingLeaveSearchFilter, setUpcomingLeaveSearchFilter] = useState({
        pageNo: 0,
        pageSize: 5,
        sortBy: "",
        sortDir: "asc",
    });
    const [upcomingLeaveTableData, setUpcomingLeaveTableData] = useState({
        leaveDirList: [],
        last: null,
        pageNo: 0,
        pageSize: 5,
        totalElements: null,
        totalPages: 1,
    });
    const [idRowSelected, setIdRowSelected] = useState([]);
    const prevValueSortHeader = usePrevious({
        sortBy: tableUpcomingLeaveSearchFilter.sortBy,
        sortDir: tableUpcomingLeaveSearchFilter.sortDir,
    });
    const [allId, setAllId] = useState([]);
    const createLeaveModal = useRef(null);
    const profile = useSelector((state) => state.auth);
    const staffId = profile ? profile.staffID : "";
    const [cancelModal, setCancelModal] = useState({
        isOpen: false,
        buttonDisable: false,
    });
    const [selectedCancelItem, setSelectedCancelItem] = useState([]);

    const appTabLink = [
        {
            label: "Leave Calendar",
            pathname: employeePathnames.pageEmployeeLeaveCalendar,
        },
        {
            label: "Leave Summary",
            pathname: employeePathnames.pageEmployeeLeaveSummary,
        },
        {
            label: "Leave History",
            pathname: employeePathnames.pageEmployeeLeaveHistory,
        },
        {
            label: "Pending Approval",
            pathname: employeePathnames.pageEmployeePendingLeave,
        },
    ];

    const getStaffLeaveSummary = async (staffId) => {
        try {
            const response = await api.post.leaveManagementGetLeaveSummary(staffId);
            setLeaveSummaryTable(response.data.result.leaveRemaining);
        } catch (error) {
            let sanitizedError = sanitizeError(error);
            appToast(sanitizedError, false);
        }
    };

    useEffect(() => {
        getStaffLeaveSummary(staffId);
    }, [staffId]);

    const getTableData = async (tableUpcomingLeaveSearchFilter, staffId) => {
        try {
            let payload = {
                ...tableUpcomingLeaveSearchFilter,
                staffId: staffId,
            };
            const response = await api.get.leaveManagementUpcomingLeave(payload);
            setUpcomingLeaveTableData(response.data.result);
            setAllId(response.data.result.leaveIds.map(e => parseInt(e)));
        } catch (error) {
            let sanitizedError = sanitizeError(error);
            appToast(sanitizedError, false);
        }
    };

    useEffect(() => {
        getTableData(tableUpcomingLeaveSearchFilter, staffId);
    }, [tableUpcomingLeaveSearchFilter, staffId]);

    const headerArrowRotate = (active) => {
        const classNames = ["react-table__arrow-icon"];
        if (active && tableUpcomingLeaveSearchFilter.sortDir === "desc") classNames.push("react-table__arrow-icon-down");
        if (active) classNames.push("react-table__arrow-icon--active");

        return classNames.join(" ");
    };

    const headerFilterClick = (header) => {
        let column = header.column;
        if (prevValueSortHeader.sortBy === column.searchFilterValue) {
            if (prevValueSortHeader.sortDir === "asc") {
                setUpcomingLeaveSearchFilter({
                    ...tableUpcomingLeaveSearchFilter,
                    sortBy: column.searchFilterValue,
                    sortDir: "desc",
                    pageNo: 0,
                });
            } else {
                setUpcomingLeaveSearchFilter({
                    ...tableUpcomingLeaveSearchFilter,
                    sortBy: column.searchFilterValue,
                    sortDir: "asc",
                    pageNo: 0,
                });
            }
        } else {
            setUpcomingLeaveSearchFilter({
                ...tableUpcomingLeaveSearchFilter,
                sortBy: column.searchFilterValue,
                sortDir: "asc",
                pageNo: 0,
            });
        }
    };

    const onCrossClick = async (id) => {
        setSelectedCancelItem([id]);
        setCancelModal({
            ...cancelModal,
            isOpen: true,
        });
    };
    
    const multiCancelOnClick = () => {
        setSelectedCancelItem(idRowSelected);
        setCancelModal({
            ...cancelModal,
            isOpen: true,
        });
    }

    const selectAllFunction = (allId) => {
        if (_.isEqual(idRowSelected, allId)) {
            setIdRowSelected([]);
        } else {
            setIdRowSelected(allId);
        }
    };

    const selectRowFunction = (rowData) => {
        if (idRowSelected.includes(rowData.id)) {
            setIdRowSelected([...idRowSelected.filter((ele) => ele !== rowData.id)]);
        } else {
            const sortedIdRowSelected = [...idRowSelected, rowData.id];
            setIdRowSelected(sortedIdRowSelected.sort());
        }
    };

    const tableColumn = [
        {
            id: "leaveType",
            Header: () => {
                return <span className="react-table__leave-type-header">Leave Type</span>;
            },
            accessor: "leaveType",
            Cell: (row) => {
                return <span className="react-table__leave-type-row">{row.row.original.leaveType}</span>;
            },
        },
        {
            id: "entitledDays",
            Header: "Entitled Days",
            accessor: "entitledDays",
            maxWidth: 100,
            Cell: (row) => {
                return <span className="react-table__table-data">{row.row.original.entitledDays}</span>;
            },
        },
        {
            id: "balanceDays",
            Header: "Balance Days",
            accessor: "balanceDays",
            maxWidth: 100,
            Cell: (row) => {
                return <span className="react-table__table-data">{row.row.original.balanceDays}</span>;
            },
        },
    ];

    const tableColumnUpcomingLeave = [
        {
            id: "Id",
            Header: (header) => {
                if (header.data.length) {
                    return (
                        <div className="react-table__header-checkbox-wrapper">
                            <AppCheckbox type="selectAll" onChange={() => selectAllFunction(allId)} checked={_.isEqual(idRowSelected, allId)} />
                        </div>
                    );
                } else {
                    return null;
                }
            },
            Cell: (row) => {
                return (
                    <div className="react-table__row-checkbox-wrapper" onClick={(e) => e.stopPropagation()}>
                        <AppCheckbox onChange={() => selectRowFunction(row.row.original)} checked={idRowSelected.includes(row.row.original.id)} />
                    </div>
                );
            },
            width: 45,
        },
        {
            id: "leaveType",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Leave Type
                        <img className={headerArrowRotate(tableUpcomingLeaveSearchFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            searchFilterValue: "leave_type",
            Cell: (row) => {
                return <span className="">{row.row.original.leaveType}</span>;
            },
        },
        {
            id: "leaveDays",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Days
                        <img className={headerArrowRotate(tableUpcomingLeaveSearchFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            Cell: (row) => {
                return <span className="react-table__days-row">{row.row.original.leaveDays}</span>;
            },
            searchFilterValue: "leave_days",
            maxWidth: 50,
        },
        {
            id: "startDate",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Start Date
                        <img className={headerArrowRotate(tableUpcomingLeaveSearchFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            Cell: (row) => {
                return <span>{moment(row.row.original.startDate).format("DD/MM/YYYY")}</span>;
            },
            searchFilterValue: "start_date",
            maxWidth: 120,
        },
        {
            id: "endDate",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        End Date
                        <img className={headerArrowRotate(tableUpcomingLeaveSearchFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            Cell: (row) => {
                return <span>{moment(row.row.original.endDate).format("DD/MM/YYYY")}</span>;
            },
            searchFilterValue: "end_date",
            maxWidth: 120,
        },
        {
            id: "timeOff",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Time Off
                        <img className={headerArrowRotate(tableUpcomingLeaveSearchFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            searchFilterValue: "time_off",
            accessor: "timeOff",
        },
        {
            id: "cancelButton",
            Cell: (row) => {
                return <img className="react-table__cross-img" src={iconClose} alt="" onClick={() => onCrossClick(row.row.original.id)} />;
            },
            width: 20,
        },
    ];

    const onPageChange = (e) => {
        setUpcomingLeaveSearchFilter({
            ...tableUpcomingLeaveSearchFilter,
            pageNo: e.selected,
        });
    };

    const confirmationModalOnClick = async () => {
        setCancelModal({
            ...cancelModal,
            buttonDisable: true,
        });
        try {
            const payload = {
                id: selectedCancelItem.join(","),
            };
            await api.post.leaveManagementCancelLeave(payload);
            setIdRowSelected([]);
            getTableData(tableUpcomingLeaveSearchFilter, staffId);
            setCancelModal({
                isOpen: false,
                buttonDisable: false,
            });
            appToast("Leave has been cancelled successfully.", true);
        } catch (error) {
            appToast(sanitizeError(error), false);
            setCancelModal({
                ...cancelModal,
                buttonDisable: true,
            });
        }
    };

    return (
        <AppPagesLayout>
            <div className="page-leave-summary">
                <AppEmployeeCreateNewLeave
                    ref={createLeaveModal}
                    onSuccess={() => {
                        getStaffLeaveSummary(staffId);
                        getTableData(tableUpcomingLeaveSearchFilter, staffId);
                    }}
                />
                <AppModalConfirmation
                    isOpenModal={cancelModal.isOpen}
                    onRequestClose={() => {
                        setCancelModal({
                            ...cancelModal,
                            isOpen: false,
                        });
                        setSelectedCancelItem([]);
                    }}
                    details="Confirm to cancel?"
                    buttonDisabled={cancelModal.buttonDisable}
                    buttonLabel="Confirm"
                    onClick={() => confirmationModalOnClick()}
                />
                <div className="page-leave-summary__breadcrumb">
                    <AppBreadcrumb list={breadcrumb} />
                </div>

                <div className="page-leave-summary__button-row">
                    <div className="page-leave-summary__new-button">
                        <AppButton buttonIcon={<img src={iconCalendar} alt="" className="page-leave-summary__calendar-icon" />} size="s" label="New Leave" onClick={() => createLeaveModal.current.createLeaveModalIsOpen()} />
                    </div>
                </div>
                <div className="page-leave-summary__tab-wrapper">
                    <AppTabLink tabList={appTabLink} />
                </div>

                <div className="page-leave-summary__table-wrapper">
                    <AppReactTable columns={tableColumn} data={leaveSummaryTable} customEmptyListMessage="Looks like you don't have any benefits yet , please contact the HR team for further clarification"/>
                </div>
                <div className="page-leave-summary__table-title">Upcoming Leave</div>

                <div className="page-leave-summary__table-wrapper">
                    <AppReactTable
                        columns={tableColumnUpcomingLeave}
                        data={upcomingLeaveTableData.leaveDirList}
                        headerSelectAllChildren={
                            <div className="page-leave-summary__table-header-button-wrapper">
                                <AppButton label="Cancel Leave" size="s" onClick={multiCancelOnClick}/>
                            </div>
                        }
                        idSelectAll={idRowSelected.length}
                        totalItems={allId.length}
                    />
                </div>

                <div className="page-leave-summary__pagination">
                    <AppPaginate onPageChange={onPageChange} pageCount={upcomingLeaveTableData.totalPages} forcePage={upcomingLeaveTableData.pageNo} />
                </div>
            </div>
        </AppPagesLayout>
    );
};

export default PageEmployeeLeaveSummary;
