import React from "react";

const getButtonClassNames = ({ buttonType, size, disabled }) => {
    const classNames = ["app-button"];

    switch (buttonType) {
        case "outline":
            if (disabled) {
                classNames.push("app-button__outline-disabled");
            } else {
                classNames.push("app-button__outline-enable");
            }
            break;
        case "text":
            if (disabled) {
                classNames.push("app-button__text-disabled");
            } else {
                classNames.push("app-button__text-enable");
            }
            break;
        default:
            if (disabled) {
                classNames.push("app-button__solid-disabled");
            } else {
                classNames.push("app-button__solid-enable");
            }
            break;
    }

    switch (size) {
        case "s":
            classNames.push("app-button__small");
            break;
        case "l":
            classNames.push("app-button__large");
            break;
        default:
            classNames.push("app-button__medium");
            break;
    }

    return classNames.join(" ");
};

const getButtonIconClassNames = (disabled) => {
    const classNames = ["app-button__icon"];
    if (disabled) classNames.push("app-button__icon--disabled")
    return classNames.join(" ")
}

const AppButton = ({ label, buttonType, size, disabled, buttonIcon, onClick, type = "button" }) => {
    return (
        <button type={type} onClick={onClick} className={getButtonClassNames({ buttonType, size, disabled })} disabled={disabled}>
            {buttonIcon ? <span className={getButtonIconClassNames(disabled)}>{buttonIcon}</span> : null}
            <span>{label}</span>
        </button>
    );
};

export default AppButton;
