import React, { useContext } from "react";
import AppInputResponsive from "components/app-input-responsive";
import { AppLetterInputContext } from "./send-letter";

const AppLetterWithIncrementTemplate = () => {
    const { letterInput, setLetterInput } = useContext(AppLetterInputContext);

    return (
        <div>
            <div style={{ fontWeight: 700 }}>CONFIRMATION OF APPOINTMENT</div>
            <br />
            <div>
                We are pleased to inform you that your work performance during your probationary period has been satisfactory and that you will be confirmed in your appointment as employment as{" "}
                <span>
                    [
                    <AppInputResponsive
                        className="page-send-letter__input page-send-letter__input--bold"
                        onChange={(e) =>
                            setLetterInput({
                                ...letterInput,
                                position: e.target.value,
                            })
                        }
                        value={letterInput.position}
                    />
                    ]
                </span>{" "}
                with effect from [
                <AppInputResponsive
                    className="page-send-letter__input"
                    onChange={(e) =>
                        setLetterInput({
                            ...letterInput,
                            startDate: e.target.value,
                        })
                    }
                    value={letterInput.startDate}
                />
                ].
            </div>
            <br />
            <div>
                With the confirmation of your appointment and appreciation of your excellence services, your monthly basic salary will be revised from{" "}
                <span style={{ fontWeight: 700 }}>
                    RM [
                    <AppInputResponsive
                        className="page-send-letter__input page-send-letter__input--bold"
                        onChange={(e) =>
                            setLetterInput({
                                ...letterInput,
                                currentSalary: e.target.value,
                            })
                        }
                        value={letterInput.currentSalary}
                    />
                    ]{" "}
                </span>
                to{" "}
                <span style={{ fontWeight: 700 }}>
                    RM [
                    <AppInputResponsive
                        className="page-send-letter__input page-send-letter__input--bold"
                        onChange={(e) =>
                            setLetterInput({
                                ...letterInput,
                                newSalary: e.target.value,
                            })
                        }
                        value={letterInput.newSalary}
                    />
                    ]
                </span>{" "}
                with effect from the [
                <AppInputResponsive
                    className="page-send-letter__input"
                    onChange={(e) =>
                        setLetterInput({
                            ...letterInput,
                            salaryIncrementDate: e.target.value,
                        })
                    }
                    value={letterInput.salaryIncrementDate}
                />
                ]. All other terms and conditions of your employment as specified in your Letter of Offer dated [
                <AppInputResponsive
                    className="page-send-letter__input"
                    onChange={(e) =>
                        setLetterInput({
                            ...letterInput,
                            lastOfferLetter: e.target.value,
                        })
                    }
                    value={letterInput.lastOfferLetter}
                />
                ] remain unchanged.
            </div>
            <br />
            <div>We take this opportunity to acknowledge and to thank you for your dedication, effort and contributions, and we wish you continuous success with Altecflex Sdn. Bhd..</div>
        </div>
    );
};

export default AppLetterWithIncrementTemplate;
