import letterHeaderBase64 from "assets/images/letter-header-base64";
import letterFooterSignatureBase64 from "assets/images/letter-footer-signature";

const letterDefaultTemplate = (letterDetails) => {
    return {
        content: [
            {
                nodeName: "DIV",
                stack: [
                    {
                        nodeName: "IMG",
                        image: letterHeaderBase64,
                        width: 364,
                        style: ["html-img", "html-div", "image"],
                    },
                    {
                        nodeName: "DIV",
                        stack: [
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "PRIVATE & CONFIDENTIAL",
                                nodeName: "STRONG",
                                fontSize: 11,
                                alignment: "justify",
                                bold: true,
                                style: ["html-strong", "html-div"],
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: letterDetails.date,
                                nodeName: "DIV",
                                fontSize: 11,
                                alignment: "justify",
                                style: ["html-div"],
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: letterDetails.name,
                                nodeName: "STRONG",
                                fontSize: 11,
                                alignment: "justify",
                                bold: true,
                                style: ["html-strong", "html-div"],
                            },
                            {
                                text: letterDetails.address1,
                                nodeName: "DIV",
                                fontSize: 11,
                                alignment: "justify",
                                style: ["html-div"],
                            },
                            {
                                text: letterDetails.address2,
                                nodeName: "DIV",
                                fontSize: 11,
                                alignment: "justify",
                                style: ["html-div"],
                            },
                            {
                                text: letterDetails.address3,
                                nodeName: "DIV",
                                fontSize: 11,
                                alignment: "justify",
                                style: ["html-div"],
                            },
                            {
                                text: letterDetails.address4,
                                nodeName: "DIV",
                                fontSize: 11,
                                alignment: "justify",
                                style: ["html-div"],
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "Dear " + letterDetails.dearName + ",",
                                nodeName: "DIV",
                                fontSize: 11,
                                alignment: "justify",
                                style: ["html-div"],
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "CONFIRMATION OF APPOINTMENT",
                                nodeName: "STRONG",
                                fontSize: 11,
                                alignment: "justify",
                                bold: true,
                                style: ["html-strong", "html-div"],
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: [
                                    {
                                        text: "We are pleased to inform you that your work performance during your probationary period has been satisfactory and that you will be confirmed in your appointment as employment as",
                                        fontSize: 11,
                                        alignment: "justify",
                                        style: ["html-div"],
                                    },
                                    {
                                        text: " " + letterDetails.position + " ",
                                        nodeName: "STRONG",
                                        fontSize: 11,
                                        alignment: "justify",
                                        bold: true,
                                        style: ["html-strong", "html-div"],
                                    },
                                    {
                                        text: "with effect from " + letterDetails.startDate + ".",
                                        fontSize: 11,
                                        alignment: "justify",
                                        style: ["html-div"],
                                    },
                                ],
                                nodeName: "DIV",
                                fontSize: 11,
                                alignment: "justify",
                                style: ["html-div"],
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "All other terms and conditions of your employment as specified in your Letter of Offer dated " + letterDetails.lastOfferLetter + " remain unchanged.",
                                nodeName: "DIV",
                                fontSize: 11,
                                alignment: "justify",
                                style: ["html-div"],
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "We take this opportunity to acknowledge and to thank you for your dedication, effort and contributions, and we wish you continuous success with Altecflex Sdn. Bhd.",
                                nodeName: "DIV",
                                fontSize: 11,
                                alignment: "justify",
                                style: ["html-div"],
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "Yours sincerely,",
                                nodeName: "DIV",
                                fontSize: 11,
                                alignment: "justify",
                                style: ["html-div"],
                            },
                            {
                                nodeName: "IMG",
                                image: letterFooterSignatureBase64,
                                width: 104,
                                style: ["html-img", "html-div", "image"],
                            },
                        ],
                    },
                ],
            },
        ],
    };
};

export default letterDefaultTemplate;
