// libraries
import React, { useEffect, useState, useRef, useCallback } from "react";
import Modal from "react-modal";
import moment from "moment";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
// services
import api from "services/api";
// components
import appToast from "components/app-toast";
// common
import { sanitizeError } from "common/utilities";
// assets
import iconClose from "assets/images/icon-close.svg";
// routes
import pathnames from "routes/pathnames";
import employeePathnames from "routes/employee-pathnames";

// status = 1 : claim request
// status = 2 : leave request
// status = 3 : update profile

const getAppHeaderBarClassName = (notificationSlideOpen) => {
    const classNames = ["app-notification"];
    if (notificationSlideOpen) classNames.push("app-notification__open");
    if (!notificationSlideOpen) classNames.push("app-notification__close");
    return classNames.join(" ");
};

const getNotificationOverlayClassName = (notificationSlideOpen) => {
    const classNames = ["app-notification__overlay"];
    if (notificationSlideOpen) classNames.push("app-notification__overlay-open");
    if (!notificationSlideOpen) classNames.push("app-notification__overlay-close");
    return classNames.join(" ");
};

const formatDate = (notificationDate) => {
    const formattedDate = moment(notificationDate).format("ddd DD MMM YY");
    if (formattedDate === moment().format("ddd DD MMM YY")) {
        return "Today";
    } else if (formattedDate === moment().add(-1, "days").format("ddd DD MMM YY")) {
        return "Yesterday";
    } else {
        return formattedDate;
    }
};

const NotificationMessage = ({ message, action, module, staffName, adminName, extra }) => {
    if ((!!staffName || !!adminName) && (action === "applied" || action === "submitted" || action === "cancelled" || action === "approved" || action === "rejected") & !!module) {
        return (
            <div className="app-notification__message">
                <span className="app-notification__message--bold">{staffName || adminName}</span> has {action} a <span className="app-notification__message--bold">{module}</span>
            </div>
        );
    } else if (action === "scheduled") {
        return (
            <div className="app-notification__message">
                You have a coaching session scheduled with <span className="app-notification__message--bold">{staffName || adminName}</span> at <span className="app-notification__message--bold">{extra}</span>
            </div>
        );
    } else if (action === "updated" && module.toLowerCase() === "user profile") {
        return (
            <div className="app-notification__message">
                <span className="app-notification__message--bold">{adminName}</span> has updated <span className="app-notification__message--bold">{staffName}</span>'s profile
            </div>
        );
    } else return <div className="app-notification__message">{message}</div>;
};

const NotificationRead = ({ role, staffId, notificationItem, manualMarkAsRead }) => {
    if (manualMarkAsRead) {
        return null;
    } else {
        if (role.toLowerCase() === "admin") {
            if (notificationItem.adminId && notificationItem.adminId.includes(staffId)) {
                return null;
            } else {
                return <div className="app-notification__notification-dot"></div>;
            }
        }
        if (role.toLowerCase() === "employee") {
            if (notificationItem.read === 1) {
                return null;
            } else {
                return <div className="app-notification__notification-dot"></div>;
            }
        }
    }
};

const NotificationModal = ({ isOpen, onRequestClose, slideOpen, onNotificationClicked }) => {
    const location = useLocation();
    const profile = useSelector((state) => state.auth);
    const [currentNotificationResponse, setCurrentNotificationResponse] = useState({});
    const [notificationList, setNotificationList] = useState([]);
    const listInnerRef = useRef();
    const employeeInitialState = {
        pageNo: 0,
        pageSize: 10,
        staffId: profile.staffID,
    };
    const adminInitialState = {
        pageNo: 0,
        pageSize: 10,
        adminId: profile.staffID,
    };
    const [notificationParams, setNotificationParams] = useState(location.pathname.split("/")[1] === "employee" ? employeeInitialState : adminInitialState);
    const [allowNextPage, setAllowNextPage] = useState(true);
    const history = useHistory();
    const [staffRole, setStaffRole] = useState("");
    const [manualMarkAsRead, setManualMarkAsRead] = useState(false);

    useEffect(() => {
        if ((profile.staffRole.toLowerCase() === "admin owner" || profile.staffRole.toLowerCase() === "admin") && location.pathname.split("/")[1] === "admin") {
            setStaffRole("admin");
        } else if ((profile.staffRole.toLowerCase() === "admin owner" || profile.staffRole.toLowerCase() === "employee") && location.pathname.split("/")[1] === "employee") {
            setStaffRole("employee");
        }
    }, [profile, location]);

    const formatResponseData = (response) => {
        if (response.data.result.content.length) {
            const updateNotificationList = response.data.result.content.map((item) => {
                return {
                    ...item,
                    formattedDate: formatDate(item.notificationDate),
                };
            });
            const groupFormattedDate = _.groupBy(updateNotificationList, "formattedDate");
            const entries = Object.entries(groupFormattedDate);
            const groupFormattedDateKeyValue = entries.map((e) => {
                return {
                    [e[0]]: e[1],
                };
            });
            setNotificationList((prevState) => {
                let prevLength = prevState.length;
                let firstKeyOfNewData = Object.keys(groupFormattedDateKeyValue[0])[0];
                let firstValueOfNewData = Object.values(groupFormattedDateKeyValue[0])[0];
                if (prevState.length && prevState[prevLength - 1][firstKeyOfNewData]) {
                    const combinedArray = prevState.map((obj) => {
                        if (Object.keys(obj)[0] === firstKeyOfNewData) {
                            return {
                                [firstKeyOfNewData]: [...Object.values(obj)[0], ...firstValueOfNewData],
                            };
                        }
                        return obj;
                    });
                    return combinedArray;
                } else {
                    return [...prevState, ...groupFormattedDateKeyValue];
                }
            });
        } else {
            setNotificationList([]);
        }
    };

    const getNotificationList = useCallback(
        async (notificationParams, profile) => {
            setAllowNextPage(false);
            if (staffRole.toLowerCase() === "admin") {
                try {
                    let payload = {
                        adminId: notificationParams.adminId,
                        pageSize: notificationParams.pageSize,
                        pageNo: notificationParams.pageNo,
                    };
                    const response = await api.get.notificationAdmin(payload);

                    formatResponseData(response);
                    setCurrentNotificationResponse(response.data.result);
                    setAllowNextPage(true);
                } catch (error) {
                    appToast(sanitizeError(error), false);
                }
            } else if (staffRole.toLowerCase() === "employee") {
                try {
                    let payload = {
                        staffId: profile.staffID,
                        pageSize: notificationParams.pageSize,
                        pageNo: notificationParams.pageNo,
                    };
                    const response = await api.get.notificationEmployee(payload);

                    formatResponseData(response);
                    setCurrentNotificationResponse(response.data.result);
                    setAllowNextPage(true);
                } catch (error) {
                    appToast(sanitizeError(error), false);
                }
            }
        },
        [staffRole]
    );

    useEffect(() => {
        if (isOpen === true) {
            setManualMarkAsRead(false);
            getNotificationList(notificationParams, profile);
        } else {
            setNotificationList([]);
            if (notificationParams.pageNo !== 0) {
                setNotificationParams({
                    ...notificationParams,
                    pageNo: 0,
                });
            }
        }
    }, [notificationParams, getNotificationList, profile, isOpen]);

    const getNotification = () => {
        if (allowNextPage && !currentNotificationResponse.last) {
            setNotificationParams({
                ...notificationParams,
                pageNo: currentNotificationResponse.pageNo + 1,
            });
        }
    };

    const onScrollHandler = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            if (scrollTop + clientHeight + 150 > scrollHeight) {
                getNotification();
            }
        }
    };

    const markAsReadHandler = async () => {
        if (staffRole.toLowerCase() === "admin") {
            try {
                let payload = {
                    adminId: profile.staffID,
                };
                await api.post.notificationAdminRead(payload);
                setManualMarkAsRead(true);
            } catch (error) {
                appToast(sanitizeError(error), false);
            }
        } else if (staffRole.toLowerCase() === "employee") {
            try {
                let payload = {
                    staffId: profile.staffID,
                };
                await api.post.notificationEmployeeRead(payload);
                setManualMarkAsRead(true);
            } catch (error) {
                appToast(sanitizeError(error), false);
            }
        }
    };

    const notificationClicked = (staffId, module, action) => {
        const lowerCaseModule = module.toLowerCase();
        const lowerCaseAction = action.toLowerCase();
        if (staffRole.toLowerCase() === "admin") {
            if (lowerCaseModule === "leave") {
                if (lowerCaseAction === "applied" || lowerCaseAction === "cancelled") {
                    history.push(pathnames.pageLeaveHistory.replace(":pathStaffId", `value_${staffId}`));
                } else if (lowerCaseAction === "submitted") {
                    history.push(pathnames.pagePendingLeave.replace(":pathStaffId", `value_${staffId}`));
                }
            }
            if (lowerCaseModule === "claim") {
                if (lowerCaseAction === "applied" || lowerCaseAction === "cancelled") {
                    history.push(pathnames.pageClaimHistory.replace(":pathStaffId", `value_${staffId}`));
                } else if (lowerCaseAction === "submitted") {
                    history.push(pathnames.pagePendingClaim.replace(":pathStaffId", `value_${staffId}`));
                }
            }
            if (lowerCaseModule === "coaching session") {
                history.push(pathnames.pageCoachingUpcomingSession);
            }
        } else if (staffRole.toLowerCase() === "employee") {
            if (lowerCaseModule === "leave") {
                history.push(employeePathnames.pageEmployeeLeaveHistory);
            } else if (lowerCaseModule === "claim") {
                history.push(employeePathnames.pageEmployeeClaimHistory);
            } else if (lowerCaseModule === "coaching session") {
                history.push(employeePathnames.pageEmployeeUpcomingSchedule);
            } else if (lowerCaseModule === "user profile") {
                history.push(employeePathnames.pageEmployeeDashboardProfileAndSettings);
            }
        }
        onNotificationClicked();
    };

    return (
        <Modal shouldFocusAfterRender={false} isOpen={isOpen} onRequestClose={onRequestClose} className={getAppHeaderBarClassName(slideOpen)} overlayClassName={getNotificationOverlayClassName(slideOpen)} ariaHideApp={false}>
            <div className="app-notification__contents">
                <div className="app-notification__contents-header">
                    <span>Notifications</span>
                    <div className="app-notification__icon-wrapper" onClick={onRequestClose}>
                        <img src={iconClose} alt="" className="app-notification__close-icon" />
                    </div>
                </div>
                <div className="app-notification__notification-scrolling" onScroll={onScrollHandler} ref={listInnerRef}>
                    {notificationList.length ? (
                        <button onClick={markAsReadHandler} className="app-notification__notification-read">
                            Mark all as read
                        </button>
                    ) : null}
                    {notificationList.length
                        ? notificationList.map((item, index) => (
                              <div key={`${item.date}_${index}`}>
                                  <div className="app-notification__notification-date">{Object.keys(item)}</div>
                                  {Object.values(item)[0].map((notificationItem, index) => {
                                      return (
                                          <div className="app-notification__notification-item" onClick={() => notificationClicked(notificationItem.staffId, notificationItem.module, notificationItem.action)} key={`${notificationItem.name}_${index}`}>
                                              <NotificationRead role={staffRole} staffId={profile.staffID} notificationItem={notificationItem} manualMarkAsRead={manualMarkAsRead} />
                                              {notificationItem.image !== "null" ? (
                                                  <img className="app-notification__notification-image" src={notificationItem.image} alt="" />
                                              ) : (
                                                  <div className="app-notification__notification-image" style={{ backgroundColor: "#eef1f5" }} />
                                              )}
                                              <span className="app-notification__notification-details">
                                                  <NotificationMessage
                                                      message={notificationItem.message}
                                                      action={notificationItem.action}
                                                      module={notificationItem.module}
                                                      staffName={notificationItem.staffName}
                                                      adminName={notificationItem.adminName}
                                                      extra={notificationItem.extra}
                                                  />
                                              </span>
                                              <span className="app-notification__notification-time">
                                                  {notificationItem.formattedDate === "Today" ? moment(notificationItem.notificationDate).fromNow() : moment(notificationItem.notificationDate).format("HH:mm")}
                                              </span>
                                          </div>
                                      );
                                  })}
                              </div>
                          ))
                        : null}
                    <div className="app-notification__footer">- The End -</div>
                </div>
            </div>
        </Modal>
    );
};

export default NotificationModal;
