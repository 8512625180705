import axios from "services/axios";
import CONSTANT from "common/constant";

const api = {
    get: {
        refreshToken: (header) => axios.get(CONSTANT.ENDPOINT_PATH.REFRESH_TOKEN, null, header),
        validateResetPasswordToken: (params) => axios.get(CONSTANT.ENDPOINT_PATH.VALIDATE_RESET_PASSWORD_TOKEN, params),
        userManagementTable: (params) => axios.get(CONSTANT.ENDPOINT_PATH.USER_MANAGEMENT_TABLE, params),
        userManagementTableSearch: (params) => axios.get(CONSTANT.ENDPOINT_PATH.USER_MANAGEMENT_TABLE_SEARCH, params),
        getUsernameDetails: (params) => axios.get(CONSTANT.ENDPOINT_PATH.GET_USERNAME_DETAILS, params),
        getStaffNamePosition: () => axios.get(CONSTANT.ENDPOINT_PATH.GET_STAFF_NAME_POSITION),
        userManagementUploadTemplateVersion: () => axios.get(CONSTANT.ENDPOINT_PATH.USER_MANAGEMENT_UPLOAD_TEMPLATE_VERSION),
        userManagementDownloadTemplateVersion: (urlparams) => axios.get(`${CONSTANT.ENDPOINT_PATH.USER_MANAGEMENT_DOWNLOAD_TEMPLATE}/${urlparams}`),

        companyBenefitBenefitTable: (params) => axios.get(CONSTANT.ENDPOINT_PATH.COMPANY_BENEFIT_BENEFIT_TABLE, params),
        companyBenefitListAllBenefit: () => axios.get(CONSTANT.ENDPOINT_PATH.COMPANY_BENEFIT_LIST_ALL_BENEFIT),
        companyBenefitListAllPosition: () => axios.get(CONSTANT.ENDPOINT_PATH.COMPANY_BENEFIT_LIST_ALL_POSITION),
        companyBenefitListSearchedBenefit: (params) => axios.get(CONSTANT.ENDPOINT_PATH.COMPANY_BENEFIT_LIST_SEARCHED_BENEFIT, params),
        companyBenefitListSearchedPosition: (params) => axios.get(CONSTANT.ENDPOINT_PATH.COMPANY_BENEFIT_LIST_SEARCHED_POSITION, params),
        companyBenefitGetPositionById: (urlParams) => axios.get(`${CONSTANT.ENDPOINT_PATH.COMPANY_BENEFIT_GET_POSITION_BY_ID}/${urlParams}`),
        companyBenefitGetBenefit: (urlParams) => axios.get(`${CONSTANT.ENDPOINT_PATH.COMPANY_BENEFIT_GET_BENEFIT}/${urlParams}`),
        companyBenefitRemoveBenefit: (params) => axios.get(CONSTANT.ENDPOINT_PATH.COMPANY_BENEFIT_REMOVE_BENEFIT, params),
        companyBenefitEntitledBenefit: (urlparams) => axios.get(`${CONSTANT.ENDPOINT_PATH.COMPANY_BENEFIT_ENTITLED_BENEFIT}/${urlparams}`),

        leaveManagementListSearchedLeaveDirectory: (params) => axios.get(CONSTANT.ENDPOINT_PATH.LEAVE_MANAGEMENT_LIST_SEARCHED_LEAVE_DIRECTORY, params),
        leaveManagementUpcomingLeave: (params) => axios.get(CONSTANT.ENDPOINT_PATH.LEAVE_MANAGEMENT_UPCOMING_LEAVE, params),
        leaveManagementListLeaveHistory: (params) => axios.get(CONSTANT.ENDPOINT_PATH.LEAVE_MANAGEMENT_LEAVE_HISTORY, params),
        leaveManagementPendingLeave: (urlparams) => axios.get(`${CONSTANT.ENDPOINT_PATH.LEAVE_MANAGEMENT_PENDING_LEAVE}/${urlparams}`),
        leaveManagementPendingApproval: (params) => axios.get(CONSTANT.ENDPOINT_PATH.LEAVE_MANAGEMENT_PENDING_APPROVAL, params),
        leaveManagementLeaveHistoryDetail: (urlparams) => axios.get(`${CONSTANT.ENDPOINT_PATH.LEAVE_MANAGEMENT_LEAVE_HISTORY_DETAIL}/${urlparams}`),
        leaveManagementLeaveStaffToCalendar: (params) => axios.get(CONSTANT.ENDPOINT_PATH.LEAVE_MANAGEMENT_LEAVE_STAFF_TO_CALENDAR, params),
        leaveManagementPublicHolidayCalendar: (params) => axios.get(CONSTANT.ENDPOINT_PATH.LEAVE_MANAGEMENT_PUBLIC_HOLIDAY_CALENDAR, params),
        leaveManagementListLeaveType: () => axios.get(CONSTANT.ENDPOINT_PATH.LEAVE_MANAGEMENT_LIST_LEAVE_TYPE),
        leaveManagementWorkingStatus: (urlparams) => axios.get(`${CONSTANT.ENDPOINT_PATH.LEAVE_MANAGEMENT_WORKING_STATUS}/${urlparams}`),

        claimManagementClaimDirectory: (params) => axios.get(CONSTANT.ENDPOINT_PATH.CLAIM_MANAGEMENT_CLAIM_DIRECTORY, params),
        claimManagementClaimApproval: (params) => axios.get(CONSTANT.ENDPOINT_PATH.CLAIM_MANAGEMENT_SEARCH_CLAIM_APPROVAL, params),
        claimManagementApproveClaim: (params) => axios.get(CONSTANT.ENDPOINT_PATH.CLAIM_MANAGEMENT_APPROVE_CLAIM, params),
        claimManagementRejectClaim: (params) => axios.get(CONSTANT.ENDPOINT_PATH.CLAIM_MANAGEMENT_REJECT_CLAIM, params), 
        claimManagementClaimHistory: (params) => axios.get(CONSTANT.ENDPOINT_PATH.CLAIM_MANAGEMENT_CLAIM_HISTORY, params),
        claimManagementClaimDetails: (urlparams) => axios.get(`${CONSTANT.ENDPOINT_PATH.CLAIM_MANAGEMENT_CLAIM_DETAILS}/${urlparams}`),
        claimManagementClaimType: () => axios.get(CONSTANT.ENDPOINT_PATH.CLAIM_MANAGEMENT_CLAIM_TYPE),
        claimManagementPendingClaim: (urlparams) => axios.get(`${CONSTANT.ENDPOINT_PATH.CLAIM_MANAGEMENT_PENDING_CLAIM}/${urlparams}`),
        claimManagementPendingClaimDetails: (urlparams) => axios.get(`${CONSTANT.ENDPOINT_PATH.CLAIM_MANAGEMENT_PENDING_CLAIM_DETAIL}/${urlparams}`),
        claimManagementSearchPaymentPending: (params) => axios.get(CONSTANT.ENDPOINT_PATH.CLAIM_MANAGEMENT_SEARCH_PAYMENT_PENDING, params),
        claimManagementIssuePaidStatus: (params) => axios.get(CONSTANT.ENDPOINT_PATH.CLAIM_MANAGEMENT_ISSUE_PAID_STATUS, params), 
        claimManagementClaimPendingPaymentDetail: (urlparams) => axios.get(`${CONSTANT.ENDPOINT_PATH.CLAIM_MANAGEMENT_CLAIM_PENDING_PAYMENT_DETAIL}/${urlparams}`),

        timesheetManagementListTimesheet: (params) => axios.get(CONSTANT.ENDPOINT_PATH.TIMESHEET_MANAGEMENT_LIST_TIMESHEET, params), 

        payslipSearchEmployee: (params) => axios.get(CONSTANT.ENDPOINT_PATH.PAYSLIP_SEARCH_EMPLOYEE, params),
        payslipStaffId: (params) => axios.get(CONSTANT.ENDPOINT_PATH.PAYSLIP_GET_STAFF_ID_SEARCH_PAYSLIP, params),
        payslipValidateFileNames: (params) => axios.get(CONSTANT.ENDPOINT_PATH.PAYSLIP_VALIDATE_FILENAME, params),

        employeeConfirmationPdfDetail: (params) => axios.get(CONSTANT.ENDPOINT_PATH.EMPLOYEE_CONFIRMATION_GET_PDF_DETAILS, params),
        employeeConfirmationSearchEmployee: (params) => axios.get(CONSTANT.ENDPOINT_PATH.EMPLOYEE_CONFIRMATION_SEARCH_EMPLOYEE, params), 

        coachingSessionUpcomingSchedule: (params) => axios.get(CONSTANT.ENDPOINT_PATH.COACHING_SESSION_UPCOMING_LIST, params),
        coachingSessionCoachingHistory: (params) => axios.get(CONSTANT.ENDPOINT_PATH.COACHING_SESSION_COACHING_HISTORY, params),
        coachingSessionSessionDetail: (urlparams) => axios.get(`${CONSTANT.ENDPOINT_PATH.COACHING_SESSION_SESSION_DETAIL}/${urlparams}`),
        coachingSessionUpcomingScheduleAdvanceSearch: (params) => axios.get(CONSTANT.ENDPOINT_PATH.COACHING_SESSION_UPCOMING_LIST_ADVANCE_SEARCH, params),

        dashboardGetOnLeave: () => axios.get(CONSTANT.ENDPOINT_PATH.DASHBOARD_GET_ON_LEAVE),
        dashboardGetTotalEmployee: () => axios.get(CONSTANT.ENDPOINT_PATH.DASHBOARD_GET_TOTAL_EMPLOYEE),
        dashboardGetEmployeeBirthday: () => axios.get(CONSTANT.ENDPOINT_PATH.DASHBOARD_GET_EMPLOYEE_BIRTHDAY),
        dashboardGetPendingClaim: () => axios.get(CONSTANT.ENDPOINT_PATH.DASHBOARD_GET_PENDING_CLAIM),
        dashboardGetPendingLeave: () => axios.get(CONSTANT.ENDPOINT_PATH.DASHBOARD_GET_PENDING_LEAVE),
        dashboardGetPublicHoliday: () => axios.get(CONSTANT.ENDPOINT_PATH.DASHBOARD_GET_PUBLIC_HOLIDAY),
        dashboardGetUpcomingAnnouncement: () => axios.get(CONSTANT.ENDPOINT_PATH.DASHBOARD_UPCOMING_ANNOUNCEMENT),
        dashboardChangePinnedStatus: (urlParams) => axios.get(`${CONSTANT.ENDPOINT_PATH.DASHBOARD_CHANGE_PIN_STATUS}/${urlParams}`),
        dashboardDeleteAnnouncement: (urlParams) => axios.get(`${CONSTANT.ENDPOINT_PATH.DASHBOARD_DELETE_ANNOUNCEMENT}/${urlParams}`),
        dashboardGetPostedAnnouncement: () => axios.get(CONSTANT.ENDPOINT_PATH.DASHBOARD_POSTED_ANNOUNCEMENT),
        dashboardGetNewEmployee: () => axios.get(CONSTANT.ENDPOINT_PATH.DASHBOARD_GET_NEW_EMPLOYEE),
        dashboardGetTurnoverEmployee: () => axios.get(CONSTANT.ENDPOINT_PATH.DASHBOARD_GET_TURNOVER_EMPLOYEE),
        dashboardGetEmployeeUpcomingLeave: (urlParams) => axios.get(`${CONSTANT.ENDPOINT_PATH.DASHBOARD_GET_EMPLOYEE_UPCOMING_LEAVE}/${urlParams}`),
        dashboardGetEmployeeBalanceDays: (urlParams) => axios.get(`${CONSTANT.ENDPOINT_PATH.DASHBOARD_GET_EMPLOYEE_BALANCE_DAY}/${urlParams}`),
        dashboardGetEmployeeUpcomingCoaching: (urlParams) => axios.get(`${CONSTANT.ENDPOINT_PATH.DASHBOARD_GET_EMPLOYEE_UPCOMING_COACHING}/${urlParams}`),
        dashboardGetEmployeePendingClaim: (urlParams) => axios.get(`${CONSTANT.ENDPOINT_PATH.DASHBOARD_GET_EMPLOYEE_PENDING_CLAIM}/${urlParams}`),
        dashboardGetEmployeePendingLeave: (urlParams) => axios.get(`${CONSTANT.ENDPOINT_PATH.DASHBOARD_GET_EMPLOYEE_PENDING_LEAVE}/${urlParams}`),
        dashboardGetEmployeeAllAnnouncement: () => axios.get(CONSTANT.ENDPOINT_PATH.DASHBOARD_GET_EMPLOYEE_ALL_ANNOUNCEMENT),

        notificationAdmin: (params) => axios.get(CONSTANT.ENDPOINT_PATH.NOTIFICATION_ADMIN, params),
        notificationEmployee: (params) => axios.get(CONSTANT.ENDPOINT_PATH.NOTIFICATION_EMPLOYEE, params)
    },
    post: {
        login: (payload) => axios.post(CONSTANT.ENDPOINT_PATH.LOGIN, payload),
        staffLogin: (payload) => axios.post(CONSTANT.ENDPOINT_PATH.STAFF_LOGIN, payload),
        forgotPassword: (payload, params) => axios.post(CONSTANT.ENDPOINT_PATH.FORGOT_PASSWORD, payload, params),
        resetPassword: (payload) => axios.post(CONSTANT.ENDPOINT_PATH.RESET_PASSWORD, payload),
        adminChangePassword: (params) => axios.post(CONSTANT.ENDPOINT_PATH.ADMIN_CHANGE_PASSWORD, null, params),
        
        createNewEmployee: (payload) => axios.post(CONSTANT.ENDPOINT_PATH.CREATE_NEW_EMPLOYEE, payload),
        updateStaffDetails: (payload) => axios.post(CONSTANT.ENDPOINT_PATH.UPDATE_STAFF_DETAILS, payload),
        employeeBulkUpload: (payload) => axios.post(CONSTANT.ENDPOINT_PATH.EMPLOYEE_BULK_UPLOAD, payload),
        employeeExport: (payload) => axios.post(CONSTANT.ENDPOINT_PATH.EMPLOYEE_EXPORT, payload),

        createBenefit: (payload) => axios.post(CONSTANT.ENDPOINT_PATH.CREATE_BENEFIT, payload),
        companyBenefitCreatePosition: (payload) => axios.post(CONSTANT.ENDPOINT_PATH.COMPANY_BENEFIT_CREATE_POSITION, payload),
        companyBenefitUpdatePosition: (payload) => axios.post(CONSTANT.ENDPOINT_PATH.COMPANY_BENEFIT_UPDATE_POSITION, payload),
        companyBenefitUpdateBenefit: (payload) => axios.post(CONSTANT.ENDPOINT_PATH.COMPANY_BENEFIT_UPDATE_BENEFIT, payload),

        leaveManagementUpdateLeave: (payload) => axios.post(CONSTANT.ENDPOINT_PATH.LEAVE_MANAGEMENT_UPDATE_LEAVE, payload),
        leaveManagementCreateLeave: (payload) => axios.post(CONSTANT.ENDPOINT_PATH.LEAVE_MANAGEMENT_CREATE_LEAVE, payload),
        leaveManagementCancelLeave: (params) => axios.post(CONSTANT.ENDPOINT_PATH.LEAVE_MANAGEMENT_CANCEL_LEAVE, null, params),
        leaveManagementGetLeaveSummary: (urlparams) => axios.post(`${CONSTANT.ENDPOINT_PATH.LEAVE_MANAGEMENT_GET_LEAVE_SUMMARY}/${urlparams}`),

        claimManagementCreateClaim: (payload) => axios.post(CONSTANT.ENDPOINT_PATH.CLAIM_MANAGEMENT_CREATE_CLAIM, payload),
        claimManagementClaimSummary: (urlparams) => axios.post(`${CONSTANT.ENDPOINT_PATH.CLAIM_MANAGEMENT_CLAIM_SUMMARY}/${urlparams}`),

        timesheetManagementSaveTimesheet: (payload) => axios.post(CONSTANT.ENDPOINT_PATH.TIMESHEET_MANAGEMENT_SAVE_TIMESHEET, payload),
        timesheetManagementExport: (params, responseType) => axios.post(CONSTANT.ENDPOINT_PATH.TIMESHEET_MANAGEMENT_EXPORT, null, params, responseType),

        payslipdownload: (payload, responseType) => axios.post(CONSTANT.ENDPOINT_PATH.PAYSLIP_DOWNLOAD, payload, null, responseType),
        payslipZipDownload: (payload, responseType) => axios.post(CONSTANT.ENDPOINT_PATH.PAYSLIP_ZIP_DOWNLOAD, payload, null, responseType),
        payslipBulkupload: (payload, params) => axios.post(CONSTANT.ENDPOINT_PATH.PAYSLIP_BULKUPLOAD, payload, params),

        employeeConfirmationSavePdfDetails: (payload) => axios.post(CONSTANT.ENDPOINT_PATH.EMPLOYEE_CONFIRMATION_SAVE_PDF, payload),
        employeeConfirmationPdfToEmail: (payload) => axios.post(CONSTANT.ENDPOINT_PATH.EMPLOYEE_CONFIRMATION_PDF_TO_EMAIL, payload),

        coachingSessionScheduleSession: (payload) => axios.post(CONSTANT.ENDPOINT_PATH.COACHING_SESSION_SCHEDULE_SESSION, payload),
        coachingSessionEditSession: (payload) => axios.post(CONSTANT.ENDPOINT_PATH.COACHING_SESSION_EDIT_SESSION, payload),
    
        dashboardCreateAnnouncement: (payload) => axios.post(CONSTANT.ENDPOINT_PATH.DASHBOARD_CREATE_ANNOUNCEMENT, payload),
        
        notificationAdminRead: (params) => axios.post(CONSTANT.ENDPOINT_PATH.NOTIFICATION_ADMIN_READ, null, params),
        notificationEmployeeRead: (params) => axios.post(CONSTANT.ENDPOINT_PATH.NOTIFICATION_EMPLOYEE_READ, null, params)
    },
};

export default api;
