import React, { useState, useRef, useEffect, Fragment } from "react";

const AppInputResponsive = ({ className, onChange, value }) => {
    const [content, setContent] = useState(value);
    const [width, setWidth] = useState(0);
    const spanRef = useRef();

    useEffect(() => {
        setWidth(spanRef.current.offsetWidth);
        if (content !== value) {
            setContent(value);
        }
    }, [content, value]);

    return (
        <Fragment>
            <span style={{ position: "absolute", opacity: 0, zIndex: -100, whiteSpace: "pre" }} ref={spanRef}>
                {content}
            </span>
            <input
                className={className}
                type="text"
                onChange={(evt) => {
                    setContent(evt.target.value);
                    onChange(evt);
                }}
                value={value}
                style={{ width: width + 5, textAlign: "center" }}
            />
        </Fragment>
    );
};

export default AppInputResponsive;
