import * as axs from "axios";
import _ from "lodash";

const axios = {
    get: async (url, params, header) => {
        const endPointUrl = getEndPointUrl(url);
        const headers = getHeaders(header);
        return axs.get(endPointUrl, { params, ...headers });
    },
    post: async (url, body, params, responseType) => {
        const endPointUrl = getEndPointUrl(url);
        const headers = getHeaders();

        return axs.post(endPointUrl, body, { ...headers, params: params, responseType: responseType ? responseType : "json" });
    },
};

const getHeaders = (header) => {
    let headers = { "Content-Type": "application/json" };

    const token = localStorage.getItem("@storage_token");

    if (token) {
        headers = Object.assign({}, headers, {
            Authorization: "Bearer " + token,
        });
    }
    if (header && !_.isEmpty(header)) {
        headers = Object.assign({}, headers, { ...header });
    };
    return { headers };
};

const getEndPointUrl = (path) => {
    const endpoint = process.env.REACT_APP_API_URL + path;
    return endpoint;
};

export default axios;
