// libraries
import React from "react";
import { useLocation, useHistory } from "react-router-dom";

const getTabItemClassNames = (isActiveTab) => {
    const classNames = ["app-tab-link__item"];
    if (isActiveTab) classNames.push("app-tab-link__active-tab");

    return classNames.join(" ");
};

const AppTabLink = ({ tabList }) => {
    const location = useLocation();
    const history = useHistory();
    return (
        <div className="app-tab-link">
            <div className="app-tab-link__wrapper">
                {tabList.length &&
                    tabList.map((item, index) => {
                        const isActiveTab = item.pathname ? location.pathname.split("value_")[0].split(":")[0] === item.pathname.split(":")[0].split("value_")[0] : null;
                        const onTabSelect = () => {
                            if (isActiveTab) {
                                history.go(0);
                            } else if (item.pathname) {
                                history.push(item.pathname);
                            }
                        };
                        return (
                            <div onClick={() => onTabSelect()} key={index} className={getTabItemClassNames(isActiveTab)}>
                                <span>{item.label}</span>
                            </div>
                        );
                    })}
            </div>
            <div className="app-tab-link--border" />
        </div>
    );
};

export default AppTabLink;
