// libraries
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { HiCheck, HiX } from "react-icons/hi";
import { Formik } from "formik";
import * as yup from "yup";
import { useIdleTimer } from "react-idle-timer";
// components
import NotificationModal from "./app-notification";
import appToast from "components/app-toast";
import AppDropdownMini from "components/app-dropdown-mini";
import AppModal from "components/app-modal";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppModalConfirmation from "components/app-modal-confirmation";
// constant
import CONSTANT from "common/constant";
// common
import { sanitizeError } from "common/utilities";
// service
import api from "services/api";
// pathnames
import pathnames from "routes/pathnames";
import employeePathnames from "routes/employee-pathnames";
// assets
import iconNotification from "assets/images/components/app-header-bar/icon-notification.svg";
import iconArrowDown from "assets/images/icon-arrow-down.svg";
// redux
import { resetProfile } from "redux/slices/auth-slice";

const validationSchema = yup.object().shape({
    currentPassword: yup.string().required("Current password is required"),
    newPassword: yup.string().required("Password is required").min(8, "ERROR_1").matches(CONSTANT.REGEX.atLeastOneAlphabet, "ERROR_2").matches(CONSTANT.REGEX.atLeastOneNumber, "ERROR_3"),
    confirmPassword: yup
        .string()
        .required("Confirm password is required")
        .test("", "Password does not match", function (value) {
            return this.parent.newPassword === value;
        }),
});

const showAllErrorInArray = (schema) => (values) =>
    schema
        .validate(values, { abortEarly: false, strict: false })
        .then(() => ({}))
        .catch(({ inner }) => inner.reduce((memo, { path, message }) => ({ ...memo, [path]: (memo[path] || []).concat(message) }), {}));

const AppHeaderBar = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [notificationIsOpen, setNotificationIsOpen] = useState(false);
    const [notificationSlideOpen, setNotificationSlideOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const profile = useSelector((state) => state.auth);
    const [changePasswordModal, setChangePasswordModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState({
        isOpen: false,
        confirmDisable: false,
    });
    const [changePasswordPayload, setChangePasswordPayload] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
    });
    const [notificationUnread, setNotificationUnread] = useState(0);

    const initialValues = {
        currentPassword: changePasswordPayload.currentPassword,
        newPassword: changePasswordPayload.newPassword,
        confirmPassword: changePasswordPayload.confirmPassword,
    };

    const handleOnIdle = () => {
        localStorage.removeItem("@storage_token");
        history.push(pathnames.pageLogin);
        dispatch({ type: "logout" });
        appToast("Session Timeout. Please login again", true, 300);
    };

    useIdleTimer({
        timeout: 1000 * 60 * 15,
        onIdle: handleOnIdle,
        debounce: 500,
    });

    const openNotification = () => {
        setNotificationIsOpen(true);
        setNotificationSlideOpen(true);
    };

    const logout = () => {
        localStorage.removeItem("@storage_token");
        history.push(pathnames.pageLogin);
        dispatch({ type: "logout" });
        dispatch(resetProfile());
        appToast("You have successfully logged out.", true, 500);
    };

    let checkingStart = false;

    const getIconClassNames = (errorType, currentError, values, touched) => {
        const classNames = [];
        if (touched && values.length) {
            checkingStart = true;
        }
        if (checkingStart) {
            if (currentError && currentError.includes(errorType)) {
                classNames.push("change-password-modal__red");
            } else {
                classNames.push("change-password-modal__green");
            }
        } else {
            classNames.push("change-password-modal__gray");
        }

        return classNames.join(" ");
    };

    const dropdownItemFunction = (staffRole) => {
        if ((staffRole.toLowerCase() === "admin owner" || staffRole.toLowerCase() === "employee") && location.pathname.split("/")[1] === "employee") {
            return [
                {
                    item: "Profile & Settings",
                    onClick: () => history.push(employeePathnames.pageEmployeeDashboardProfileAndSettings),
                },
                {
                    item: "Change password",
                    onClick: () => setChangePasswordModal(true),
                },
                {
                    item: "Logout",
                    onClick: () => logout(),
                },
            ];
        } else if ((staffRole.toLowerCase() === "admin owner" || staffRole.toLowerCase() === "admin") && location.pathname.split("/")[1] === "admin") {
            return [
                {
                    item: "Change password",
                    onClick: () => setChangePasswordModal(true),
                },
                {
                    item: "Logout",
                    onClick: () => logout(),
                },
            ];
        } else {
            return [];
        }
    };

    const onHandleNewPassword = (value) => {
        setConfirmModal({
            isOpen: true,
            confirmDisable: false,
        });
        setChangePasswordPayload(value);
        setChangePasswordModal(false);
    };

    const confirmationModalConfirmed = async () => {
        setConfirmModal({
            isOpen: true,
            confirmDisable: true,
        });
        try {
            let params = {
                confirmPwd: changePasswordPayload.confirmPassword,
                currentPwd: changePasswordPayload.currentPassword,
                newPwd: changePasswordPayload.newPassword,
                staffId: profile ? profile.staffID : "",
            };
            await api.post.adminChangePassword(params);
            appToast("Your password has been changed successfully. Use new password on next login", true);
            setConfirmModal({
                isOpen: false,
                confirmDisable: false,
            });
            setChangePasswordPayload({
                currentPassword: "",
                newPassword: "",
                confirmPassword: "",
            });
        } catch (error) {
            let sanitizedError = sanitizeError(error);
            appToast(sanitizedError, false);
            setConfirmModal({
                isOpen: true,
                confirmDisable: false,
            });
        }
    };

    const getNotificationList = async (profile, location) => {
        if ((profile.staffRole.toLowerCase() === "admin owner" || profile.staffRole.toLowerCase() === "admin") && location.pathname.split("/")[1] === "admin") {
            try {
                let payload = {
                    adminId: profile.staffID,
                };
                const response = await api.get.notificationAdmin(payload);
                setNotificationUnread(response.data.result.unreadNumber);
            } catch (error) {
                appToast(sanitizeError(error), false);
            }
        } else if ((profile.staffRole.toLowerCase() === "admin owner" || profile.staffRole.toLowerCase() === "employee") && location.pathname.split("/")[1] === "employee") {
            try {
                let payload = {
                    staffId: profile.staffID,
                };
                const response = await api.get.notificationEmployee(payload);
                setNotificationUnread(response.data.result.unreadNumber);
            } catch (error) {
                appToast(sanitizeError(error), false);
            }
        }
    };

    useEffect(() => {
        getNotificationList(profile, location);
        const intervalId = setInterval(() => {
            getNotificationList(profile, location);
        }, 180000);
        return () => clearInterval(intervalId);
    }, [profile, location]);

    const closeNotification = () => {
        getNotificationList(profile, location);
        setNotificationSlideOpen(false);
        setTimeout(() => setNotificationIsOpen(false), 500);
    };

    return (
        <div className="app-header-bar">
            <NotificationModal isOpen={notificationIsOpen} onRequestClose={() => closeNotification()} slideOpen={notificationSlideOpen} onNotificationClicked={closeNotification} />
            <div className="app-header-bar__contents">
                <div className="app-header-bar__icon-wrapper" onClick={openNotification}>
                    <img className="app-header-bar__icon" src={iconNotification} alt="" />
                    {notificationUnread ? (
                        <div className="app-header-bar__icon-number">
                            <span>{notificationUnread}</span>
                        </div>
                    ) : null}
                </div>
                <div className="app-header-bar__separator"></div>
                <div className="app-header-bar__dropdown">
                    <AppDropdownMini
                        label={
                            <div>
                                <span>{profile && profile.staffUserName ? profile.staffUserName : ""}</span>
                                <img className="app-header-bar__dropdown-icon" src={iconArrowDown} alt="" />
                            </div>
                        }
                        toggle={() => setDropdownOpen(!dropdownOpen)}
                        isOpen={dropdownOpen}
                        dropdownItem={dropdownItemFunction(profile ? profile.staffRole.toLowerCase() : "")}
                    />
                </div>
            </div>
            <AppModalConfirmation
                isOpenModal={confirmModal.isOpen}
                onRequestClose={() => {
                    setConfirmModal({
                        isOpen: false,
                        confirmDisable: false,
                    });
                    setChangePasswordModal(true);
                }}
                details="Confirm to save?"
                buttonDisabled={confirmModal.confirmDisable}
                buttonLabel="Confirm"
                onClick={confirmationModalConfirmed}
            />
            <AppModal
                title="Change Password"
                isOpenModal={changePasswordModal}
                onRequestClose={() => {
                    setChangePasswordModal(false);
                    setChangePasswordPayload({
                        currentPassword: "",
                        newPassword: "",
                        confirmPassword: "",
                    });
                }}>
                <Formik initialValues={initialValues} onSubmit={onHandleNewPassword} validate={showAllErrorInArray(validationSchema)}>
                    {({ errors, values, handleSubmit, handleChange, touched, setFieldTouched }) => {
                        return (
                            <div className="app-header-bar__change-password-modal">
                                <div className="change-password-modal__input-wrapper-1">
                                    <AppInput
                                        placeholder="Current Password"
                                        type="password"
                                        onChange={(e) => handleChange("currentPassword")(e.target.value)}
                                        value={values.currentPassword}
                                        error={touched.currentPassword && errors.currentPassword ? errors.currentPassword[0] : null}
                                        onFocus={() => setFieldTouched("currentPassword")}
                                    />
                                </div>
                                <div className="change-password-modal__input-wrapper-2">
                                    <AppInput placeholder="New Password" type="password" onChange={(e) => handleChange("newPassword")(e.target.value)} value={values.newPassword} onFocus={() => setFieldTouched("newPassword")} />
                                </div>
                                <div className="change-password-modal__requirement-title">Password must consist of:</div>
                                <div className="change-password-modal__contents">
                                    <span className={getIconClassNames("ERROR_1", errors.newPassword, values.newPassword, touched.newPassword)}>
                                        {errors.newPassword && errors.newPassword.includes("ERROR_1") ? <HiX size={20} /> : <HiCheck size={20} />}
                                    </span>
                                    <span className="change-password-modal__requirement-details">Minimum 8 characters</span>
                                </div>
                                <div className="change-password-modal__contents">
                                    <span className={getIconClassNames("ERROR_2", errors.newPassword, values.newPassword, touched.newPassword)}>
                                        {errors.newPassword && errors.newPassword.includes("ERROR_2") ? <HiX size={20} /> : <HiCheck size={20} />}
                                    </span>
                                    <span className="change-password-modal__requirement-details">At least one letter</span>
                                </div>
                                <div className="change-password-modal__contents">
                                    <span className={getIconClassNames("ERROR_3", errors.newPassword, values.newPassword, touched.newPassword)}>
                                        {errors.newPassword && errors.newPassword.includes("ERROR_3") ? <HiX size={20} /> : <HiCheck size={20} />}
                                    </span>
                                    <span className="change-password-modal__requirement-details">At least one number</span>
                                </div>
                                <div className="change-password-modal__input-wrapper-3">
                                    <AppInput
                                        placeholder="Confirm Password"
                                        type="password"
                                        onChange={(e) => handleChange("confirmPassword")(e.target.value)}
                                        value={values.confirmPassword}
                                        error={touched.confirmPassword && errors.confirmPassword ? errors.confirmPassword[0] : null}
                                        onFocus={() => setFieldTouched("confirmPassword")}
                                    />
                                </div>
                                <div className="change-password-modal__buttons-row">
                                    <div className="change-password-modal__button-wrapper">
                                        <AppButton
                                            size="l"
                                            label="Cancel"
                                            buttonType="outline"
                                            onClick={() => {
                                                setChangePasswordModal(false);
                                                setChangePasswordPayload({
                                                    currentPassword: "",
                                                    newPassword: "",
                                                    confirmPassword: "",
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="change-password-modal__button-wrapper">
                                        <AppButton size="l" label="Confirm" onClick={handleSubmit} />
                                    </div>
                                </div>
                            </div>
                        );
                    }}
                </Formik>
            </AppModal>
        </div>
    );
};

export default AppHeaderBar;
