// libraries
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
// hooks
import useIsMount from "hooks/use-is-mount";
import usePrevious from "hooks/use-previous";
// assets
import iconClose from "assets/images/icon-close-black.svg";

const getModalClassName = (animationOpen) => {
    const classNames = ["app-modal"];
    if (animationOpen) classNames.push("app-modal__open");
    if (!animationOpen) classNames.push("app-modal__close");

    return classNames.join(" ");
};

const getModalOverlayClassName = (animationOpen) => {
    const classNames = ["app-modal__overlay"];
    if (animationOpen) classNames.push("app-modal__overlay-open");
    if (!animationOpen) classNames.push("app-modal__overlay-close");
    return classNames.join(" ");
};

const getHeaderStatusClassNames = (color) => {
    const classNames = ["app-modal__header-status"];
    if (color === "green") classNames.push("app-modal__header-status--green");
    if (color === "red") classNames.push("app-modal__header-status--red");
    if (color === "orange") classNames.push("app-modal__header-status--orange");

    return classNames.join(" ");
};

const getModalBodyClassNames = (bodyPadding) => {
    const classNames = ["app-modal__body"];
    if (bodyPadding) classNames.push("app-modal__body-padding");

    return classNames.join(" ");
};

const AppModal = ({ onRequestClose, children, title, isOpenModal, status, statusColor, emptyModal = false, bodyPadding = true, onClick, headerRight }) => {
    const [modalOpen, setModalOpen] = useState({
        modalOpen: false,
        animationOpen: false,
    });
    const isMount = useIsMount();
    const prevOpenModalState = usePrevious(isOpenModal);

    useEffect(() => {
        if (!isMount && prevOpenModalState !== isOpenModal) {
            if (!isOpenModal) {
                setModalOpen({
                    modalOpen: true,
                    animationOpen: false,
                });
                setTimeout(() => {
                    setModalOpen({
                        modalOpen: false,
                        animationOpen: false,
                    });
                }, 200);
            } else {
                setModalOpen({
                    modalOpen: true,
                    animationOpen: true,
                });
            }
        }
    }, [isOpenModal, isMount, prevOpenModalState]);

    return (
        <Modal isOpen={modalOpen.modalOpen} onRequestClose={onRequestClose} className={getModalClassName(modalOpen.animationOpen)} overlayClassName={getModalOverlayClassName(modalOpen.animationOpen)} ariaHideApp={false}>
            {emptyModal ? (
                <div>{children}</div>
            ) : (
                <div className="app-modal__wrapper" onClick={onClick}>
                    <div className="app-modal__header">
                        <span>{title}</span>
                        <div className="app-modal__header-right">
                            {status ? (
                                <div className={getHeaderStatusClassNames(statusColor)}>
                                    <span>{status}</span>
                                </div>
                            ) : null}
                            <div>{headerRight}</div>
                            <img onClick={onRequestClose} className="app-modal__close-icon" src={iconClose} alt="" />
                        </div>
                    </div>
                    <div className={getModalBodyClassNames(bodyPadding)}>{children}</div>
                </div>
            )}
        </Modal>
    );
};

export default AppModal;
