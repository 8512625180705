// libraries
import React from "react";
import { useHistory } from "react-router-dom";
// components
import AppAuthLayout from "components/app-auth-layout";
import AppButton from "components/app-button";
// assets
import iconEmailSent from "assets/images/pages/authentication/icon-email-sent.svg";
// pathnames
import pathnames from "routes/pathnames";

const PageResetPasswordCheckEmail = (props) => {
    const history = useHistory();

    const { location } = props;
    const email = location?.state?.email;

    return (
        <AppAuthLayout>
            <div className="page-check-email">
                <div className="page-check-email__icon-wrapper">
                    <img src={iconEmailSent} alt="" />
                </div>
                <div className="page-check-email__title">Check your email</div>
                <div className="page-check-email__details">We have sent a confirmation link to</div>
                <div className="page-check-email__details page-check-email__email">{email ? email : null}</div>
                <div className="page-check-email__details">Click on the link to reset your password.</div>
                <div className="page-check-email__button-wrapper">
                    <AppButton label="Back to login" size="l" onClick={() => history.push(pathnames.pageLogin)} />
                </div>
            </div>
        </AppAuthLayout>
    );
};

export default PageResetPasswordCheckEmail;
