// libraries
import React, { useState, useEffect } from "react";
import _ from "lodash";
import { AiOutlinePlus } from "react-icons/ai";
import { BiFilterAlt } from "react-icons/bi";
// components
import AppPagesLayout from "components/app-pages-layout";
import AppBreadcrumb from "components/app-breadcrumb";
import AppTabLink from "components/app-tab-link";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppReactTable from "components/app-react-table";
import AppPaginate from "components/app-paginate";
import appToast from "components/app-toast";
import AppDropdownCheckbox from "components/app-dropdown-checkbox";
import PageCreateCoachingSession from "./create-coaching-session";
import AppDualColumnsModal from "components/app-dual-columns-modal";
import AppInputWithLabel from "components/app-input-with-label";
import AppTextArea from "components/app-text-area";
// service
import api from "services/api";
// common
import { sanitizeError, statusFormat, statusColor } from "common/utilities";
// hooks
import usePrevious from "hooks/use-previous";
// route
import pathnames from "routes/pathnames";
// assets
import iconSearch from "assets/images/components/app-input/icon-search.svg";
import iconTailedArrowUp from "assets/images/icon-tailed-arrow-up.svg";

const appTabLink = [
    {
        label: "Upcoming Schedule",
        pathname: pathnames.pageCoachingUpcomingSession,
    },
    {
        label: "Coaching History",
        pathname: pathnames.pageCoachingHistory,
    },
];

const breadcrumb = [
    {
        label: "Coaching Sessions",
    },
    {
        label: "Upcoming Schedule",
    },
];

const tableStatusFilter = [
    { label: "Completed", name: "completed", value: "completed" },
    { label: "Cancelled", name: "cancelled", value: "cancelled" },
];

let statusToFilter = [];

const getTableStatusClassNames = (status) => {
    const classNames = ["react-table__status-data"];
    if (status.toLowerCase() === "completed") classNames.push("react-table__status--green");
    if (status.toLowerCase() === "cancelled") classNames.push("react-table__status--red");
    if (status.toLowerCase() === "upcoming") classNames.push("react-table__status--orange");

    return classNames.join(" ");
};

const detailsLabel = [
    {
        placeholder: "Employee Username",
        name: "staffUserName",
        type: "input",
    },
    {
        placeholder: "MentorUsername",
        name: "mentorUserName",
        type: "input",
    },
    {
        placeholder: "Remark",
        name: "remark",
        type: "textarea",
    },
    {
        placeholder: "date",
        name: "date",
        type: "input",
    },
    {
        placeholder: "Start Time",
        name: "startTime",
        type: "input",
    },
    {
        placeholder: "End Time",
        name: "endTime",
        type: "input",
    },
];

const PageCoachingHistory = () => {
    const [tableSearchFilter, setTableSearchFilter] = useState({
        pageNo: 0,
        pageSize: 5,
        searchValue: "",
        sortBy: "",
        sortDir: "asc",
        filter: "",
    });
    const [tableData, setTableData] = useState({
        content: [],
        last: null,
        pageNo: 0,
        pageSize: 5,
        totalElements: null,
        totalPages: 1,
    });
    const prevValueSortHeader = usePrevious({
        sortBy: tableSearchFilter.sortBy,
        sortDir: tableSearchFilter.sortDir,
    });
    const [statusFilterOpen, setStatusFilterOpen] = useState(false);
    const [statusFilter, setStatusFilter] = useState({
        completed: false,
        cancelled: false,
    });
    const [largeModalIsOpen, setLargeModalIsOpen] = useState(false);
    const [coachingDetailModalIsOpen, setCoachingDetailModalIsOpen] = useState(false);
    const [detailsValue, setDetailsValue] = useState({
        staffUserName: "",
        mentorUserName: "",
        remark: "",
        date: "",
        startTime: "",
        endTime: "",
        status: "",
    });

    const getTableData = async (payload) => {
        try {
            const response = await api.get.coachingSessionCoachingHistory(payload);
            setTableData(response.data.result);
        } catch (error) {
            let sanitizedError = sanitizeError(error);
            appToast(sanitizedError, false);
        }
    };

    useEffect(() => {
        getTableData(tableSearchFilter);
    }, [tableSearchFilter]);

    const headerArrowRotate = (active) => {
        const classNames = ["react-table__arrow-icon"];
        if (active && tableSearchFilter.sortDir === "desc") classNames.push("react-table__arrow-icon-down");
        if (active) classNames.push("react-table__arrow-icon--active");

        return classNames.join(" ");
    };

    const headerFilterClick = (header) => {
        let column = header.column;
        if (prevValueSortHeader && prevValueSortHeader.sortBy === column.searchFilterValue) {
            if (prevValueSortHeader.sortDir === "asc") {
                setTableSearchFilter({
                    ...tableSearchFilter,
                    sortBy: column.searchFilterValue,
                    sortDir: "desc",
                    pageNo: 0,
                });
            } else {
                setTableSearchFilter({
                    ...tableSearchFilter,
                    sortBy: column.searchFilterValue,
                    sortDir: "asc",
                    pageNo: 0,
                });
            }
        } else {
            setTableSearchFilter({
                ...tableSearchFilter,
                sortBy: column.searchFilterValue,
                sortDir: "asc",
                pageNo: 0,
            });
        }
    };

    const tableColumn = [
        {
            id: "staffUserName",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__employee-username-header react-table__header">
                        Employee Username
                        <img className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            Cell: (row) => {
                return <div className="react-table__employee-username-data">{row.row.original.staffUserName}</div>;
            },
            maxWidth: 140,
            searchFilterValue: "staff_username",
        },
        {
            id: "coachUserName",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Mentor Username
                        <img className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            maxWidth: 140,
            accessor: "coachUserName",
            searchFilterValue: "coach_username",
        },
        {
            id: "coachingDate",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Date
                        <img className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            maxWidth: 140,
            accessor: "coachingDate",
            searchFilterValue: "coaching_date",
        },
        {
            id: "time",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Time
                        <img className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            maxWidth: 140,
            accessor: "time",
            searchFilterValue: "start_time",
        },
        {
            id: "status",
            Header: () => {
                const getStatusClassNames = (statusFilterOpen) => {
                    const classNames = ["react-table__icon-filter"];
                    if (statusFilterOpen) classNames.push("react-table__icon-filter--active");
                    return classNames.join(" ");
                };
                return (
                    <div onClick={(e) => e.stopPropagation()}>
                        <span className="react-table__status-header" onClick={() => setStatusFilterOpen(!statusFilterOpen)}>
                            Status
                            <BiFilterAlt className={getStatusClassNames(statusFilterOpen)} />
                        </span>
                        {statusFilterOpen ? (
                            <div className="react-table__dropdown">
                                <AppDropdownCheckbox
                                    dropdownList={tableStatusFilter}
                                    onChange={(item, value) => {
                                        setStatusFilter({
                                            ...statusFilter,
                                            [item.name]: value,
                                        });
                                        if (value) {
                                            statusToFilter.push(item.value);
                                        } else {
                                            statusToFilter = statusToFilter.filter((e) => e !== item.value);
                                        }
                                        if (statusToFilter.length === 1) {
                                            setTableSearchFilter({
                                                ...tableSearchFilter,
                                                status: statusToFilter[0],
                                                pageNo: 0,
                                            });
                                        } else {
                                            setTableSearchFilter({
                                                ...tableSearchFilter,
                                                status: "",
                                                pageNo: 0,
                                            });
                                        }
                                    }}
                                    state={statusFilter}
                                />
                            </div>
                        ) : null}
                    </div>
                );
            },
            Cell: (row) => {
                return (
                    <div className={getTableStatusClassNames(row.row.original.status)}>
                        <span>{row.row.original.status}</span>
                    </div>
                );
            },
            // searchFilterValue: "status",
            width: 120,
        },
    ];

    const searchKeywordTyped = (value) => {
        setTableSearchFilter({
            pageNo: 0,
            pageSize: 5,
            searchValue: value,
            sortBy: "",
            sortDir: "asc",
            filter: "",
        });
    };

    const searchBarOnChange = (e) => {
        searchKeywordTyped(e.target.value);
    };

    const handleKeypress = (e) => {
        if (e.code === "Enter") {
            searchKeywordTyped(e.target.value);
        }
    };

    const onPageChange = (e) => {
        setTableSearchFilter({
            ...tableSearchFilter,
            pageNo: e.selected,
        });
    };

    const onRowSelect = async (row) => {
        setCoachingDetailModalIsOpen(true);
        try {
            const response = await api.get.coachingSessionSessionDetail(row.id);
            const result = response.data.result;
            setDetailsValue({
                staffUserName: result.staffName,
                mentorUserName: result.mentorName,
                remark: result.remark,
                date: result.scheduleDate,
                startTime: result.startTime,
                endTime: result.endTime,
                status: row.status,
            });
        } catch (error) {
            appToast(sanitizeError(error), false);
        }
    };

    return (
        <AppPagesLayout>
            <div className="page-coaching-history" onClick={() => setStatusFilterOpen(false)}>
                <PageCreateCoachingSession largeModalIsOpen={largeModalIsOpen} onRequestClose={() => setLargeModalIsOpen(false)} onRequestOpen={() => setLargeModalIsOpen(true)}  postSuccess={() => getTableData(tableSearchFilter)}/>
                <AppDualColumnsModal
                    title="Coaching Session Details"
                    isOpenModal={coachingDetailModalIsOpen}
                    onRequestClose={() => setCoachingDetailModalIsOpen(false)}
                    status={statusFormat(detailsValue.status)}
                    statusColor={statusColor(detailsValue.status)}
                    childrenLeft={
                        <div className="page-coaching-history__column-modal-left">
                            {detailsLabel.map((o, i) => {
                                if (i <= 2 && o.type === "input") {
                                    return (
                                        <div key={i} className="column-modal-left__input-wrapper">
                                            <AppInputWithLabel value={detailsValue[o.name]} disabled={true} placeholder={o.placeholder} />
                                        </div>
                                    );
                                } else if (i <= 2 && o.type === "textarea") {
                                    return (
                                        <div key={i} className="column-modal-left__input-wrapper">
                                            <AppTextArea value={detailsValue[o.name]} disabled={true} placeholder={o.placeholder} />
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </div>
                    }
                    childrenRight={
                        <div className="page-coaching-history__column-modal-right">
                            {detailsLabel.map((o, i) => {
                                if (i > 2 && o.type === "input") {
                                    return (
                                        <div key={i} className="column-modal-right__input-wrapper">
                                            <AppInputWithLabel value={detailsValue[o.name]} disabled={true} placeholder={o.placeholder} />
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </div>
                    }
                />
                <div className="page-coaching-history__breadcrumb">
                    <AppBreadcrumb list={breadcrumb} />
                </div>
                <div className="page-coaching-history__tab-wrapper">
                    <AppTabLink tabList={appTabLink} />
                </div>

                <div className="page-coaching-history__row">
                    <div className="page-coaching-history__searchBar-wrapper">
                        <AppInput leftSrc={iconSearch} placeholder="Search keyword" onChange={_.debounce((e) => searchBarOnChange(e), 1000)} onKeyPress={handleKeypress} />
                    </div>
                    <div className="page-coaching-history__button-wrapper">
                        <AppButton size="s" buttonIcon={<AiOutlinePlus size={12} />} label="Schedule Coaching Session" onClick={() => setLargeModalIsOpen(true)} />
                    </div>
                </div>

                <div className="page-coaching-history__table-wrapper">
                    <AppReactTable onClickRow={onRowSelect} columns={tableColumn} data={tableData.content} searchKeyword={tableSearchFilter.searchValue} />
                </div>
                <div className="page-coaching-history__pagination">
                    <AppPaginate onPageChange={onPageChange} pageCount={tableData.totalPages} forcePage={tableSearchFilter.pageNo} />
                </div>
            </div>
        </AppPagesLayout>
    );
};

export default PageCoachingHistory;
