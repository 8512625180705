// libraries
import React, { useState, useLayoutEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import _ from "lodash";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
// constant
import CONSTANT from "common/constant";
// routes
import employeePathnames from "routes/employee-pathnames";
// api
import api from "services/api";
// common
import { sanitizeError } from "common/utilities";
// components
import AppBreadcrumb from "components/app-breadcrumb";
import AppPagesLayout from "components/app-pages-layout";
import AppImageUpload from "components/app-image-upload";
import AppInputDate from "components/app-input-date";
import AppDropdown from "components/app-dropdown";
import AppStatus from "components/app-status";
import AppButton from "components/app-button";
import AppTextArea from "components/app-text-area";
import AppInputWithLabel from "components/app-input-with-label";
import appToast from "components/app-toast";
import AppModalConfirmation from "components/app-modal-confirmation";
// redux
import { updateProfile } from "redux/slices/auth-slice";

const currentDate = moment();

const breadcrumb = [
    {
        label: "Profile & Settings",
    },
];

// edit employee

const editEmployeeInformationFields = [
    { name: "joinedDate", placeholder: "Joined Date", type: "date" },
    { name: "probationEnd", placeholder: "Probation End Date", type: "date" },
    { name: "latestCoachSession", placeholder: "Latest Coaching", type: "date" },
    { name: "position", placeholder: "Position", type: "dropdown" },
    { name: "contractType", placeholder: "Contract Type", type: "dropdown" },
    { name: "reportingManager", placeholder: "Reporting Manager", type: "dropdown" },
];

const editEmployeePersonalInformationFields = [
    { name: "staffID", placeholder: "Employee ID", disabled: true },
    { name: "name", placeholder: "Full Name" },
    { name: "userName", placeholder: "Username", disabled: true },
    { name: "typeOfId", placeholder: "Identification Type", type: "dropdown" },
    { name: "nric", placeholder: "IC No.", type: "ic" },
    { name: "passport", placeholder: "Passport No." },
    { name: "dob", placeholder: "Date of Birth", type: "date" },
    { name: "phoneNumber", placeholder: "Mobile Number", type: "mobile" },
    { name: "email", placeholder: "Work Email", disabled: true },
    { name: "personalEmail", placeholder: "Personal Email" },
    { name: "address", placeholder: "Address", type: "textarea" },
];

const editEmployeeValidationSchema = yup.object().shape({
    position: yup.string().required("Position is required"),
    contractType: yup.string().required("Contract type is required"),
    // reportingManager: yup.string().required("Reporting manager is required"),
    name: yup.string().required("Full name is required"),
    typeOfId: yup.string(),
    nric: yup.string().when(["typeOfId"], (typeOfId, schema) => {
        if (typeOfId === "IC No.") {
            return yup
                .string()
                .required("IC number is required")
                .test("", "Invalid IC number", (value) => {
                    let valueWithoutDash = value && value.split("-").join("");
                    if (value && valueWithoutDash.length === 12) {
                        if (value && valueWithoutDash.length >= 8 && valueWithoutDash.length <= 12) {
                            let date = value.substring(0, 6);
                            let dateChecking = moment(date, "YYMMDD");
                            let location = valueWithoutDash.substring(6, 8);
                            let locationChecking = CONSTANT.STATE_NUMBER.includes(location);
                            if (!dateChecking._isValid || !locationChecking) {
                                return false;
                            } else {
                                return true;
                            }
                        } else {
                            return true;
                        }
                    } else {
                        return false;
                    }
                });
        }
    }),
    passport: yup.string().when(["typeOfId"], (typeOfId, schema) => {
        if (typeOfId === "passport") {
            return yup
                .string()
                .required("Passport no. is required")
                .test("passport", "Only 9 characters are allowed", (value) => value && value.toString().length === 9)
                .matches(/^[a-z0-9]+$/i, "Invalid passport number");
        }
    }),
    dob: yup
        .string()
        .required("Date of birth is required")
        .test("", "Invalid date", (value) => {
            if (value) {
                let valueWithoutSpaces = value.replace(/\s/g, "");
                return "Invaliddate" !== valueWithoutSpaces;
            }
        })
        .nullable(true),
    // phoneNumber: yup.string().required("Mobile number is required").matches(CONSTANT.REGEX.mobileNo, "Invalid mobile number"),
    // personalEmail: yup.string().email("Invalid personal email").required("Personal email is required"),
    // address: yup.string().required("Address is required"),
});

const typeOfIdDropdownOption = [
    { label: "IC No.", value: "ic no." },
    { label: "Passport No.", value: "passport" },
];

const PageEmployeeProfileAndSettings = () => {
    const [pageContentState, setPageContentState] = useState({});
    const [pageDataValues, setPageDataValues] = useState({});
    const [button, setButton] = useState({});
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const profile = useSelector((state) => state.auth);

    useLayoutEffect(() => {
        const apiGetUsername = async () => {
            try {
                let payload = {
                    staffId: profile.staffID,
                };
                const response = await api.get.getUsernameDetails(payload);
                const result = response.data.result[0];
                setPageContentState({
                    breadcrumbLabel: "Edit Employee",
                    initialValues: {
                        image: result.image, // in multipart format
                        joinedDate: result.joinedDate,
                        probationEnd: result.probationEnd,
                        promotionDate: result.promotionDate,
                        latestCoachSession: result.latestCoachSession,
                        lastEmpDate: result.lastEmpDate,
                        position: result.staffPosition,
                        contractType: result.contractType,
                        reportingManager: result.reportingManager,
                        staffID: result.staffID,
                        name: result.staffName,
                        userName: result.staffUserName,
                        nric: result.staffNRIC,
                        dob: result.staffDOB,
                        phoneNumber: result.staffPhoneNumber,
                        email: result.staffEmail,
                        personalEmail: result.staffPersonalEmail,
                        address: result.staffAddress,
                        status: result.status,
                        passport: result.staffNRIC,
                        typeOfId: result.typeOfId.toLowerCase(),
                        role: result.staffRole.toLowerCase(),
                    },
                    status: result.status,
                    validationSchema: editEmployeeValidationSchema,
                    employeeInformationFields: editEmployeeInformationFields,
                    personalInformationFields: editEmployeePersonalInformationFields,
                    modalConfirmDetails: "Confirm to save?",
                });
                setButton({
                    label: "Save",
                    disabled: false,
                });
            } catch (error) {
                const errorMessage = sanitizeError(error);
                appToast(errorMessage, false, 300);
                history.push(employeePathnames.pageEmployeeDashboard);
            }
        };
        apiGetUsername();
    }, [location, history, profile]);

    const buttonDisable = (touched, errors, button) => {
        if (!_.isEmpty(errors)) {
            return true;
        } else {
            if (_.isEmpty(touched)) {
                return true;
            } else {
                if (button.disabled) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    };

    const onHandleSubmit = (values) => {
        setConfirmModalOpen(true);
        setPageDataValues(values);
    };

    const updateConfirmed = async (pageDataValues) => {
        const { image, ...dataExcludeImg } = pageDataValues;
        let inputData;
        let { passport, nric, ...dataExcludeIdentification } = dataExcludeImg;
        if (dataExcludeImg.typeOfId.toLowerCase() === "ic no.") {
            inputData = {
                ...dataExcludeIdentification,
                nricOrPassport: nric,
            };
        } else if (dataExcludeImg.typeOfId.toLowerCase() === "passport") {
            inputData = {
                ...dataExcludeIdentification,
                nricOrPassport: passport,
            };
        }
        setButton({
            label: "Saving",
            disabled: true,
        });
        setPageContentState({
            ...pageContentState,
            modalConfirmDetails: "Saving...",
        });
        try {
            const formData = new FormData();
            const dataBlob = new Blob([JSON.stringify(inputData)], { type: "application/json" });
            let newImage;
            if (typeof image === "object") {
                newImage = image;
            } else {
                newImage = new Blob([""]);
            }
            formData.append("data", dataBlob);
            formData.append("image", newImage);
            const response = await api.post.updateStaffDetails(formData);
            const result = response.data.result;
            if (result.staffID === profile.staffID) {
                dispatch(
                    updateProfile({
                        ...profile,
                        staffName: result.staffName,
                        staffUserName: result.staffUserName,
                    })
                );
            }
            appToast("Profile details has been updated successfully.", true, 300);
            history.push(employeePathnames.pageEmployeeDashboard);
        } catch (error) {
            const sanitizedError = sanitizeError(error);
            appToast(sanitizedError, false);

            setPageContentState({
                ...pageContentState,
                modalConfirmDetails: "Confirm to save?",
            });
            setButton({
                label: "Save",
                disabled: false,
            });
        }
    };

    if (!_.isEmpty(pageContentState) && pageContentState.breadcrumbLabel && pageContentState.initialValues && pageContentState.validationSchema && !_.isEmpty(button)) {
        return (
            <Formik initialValues={pageContentState.initialValues} onSubmit={onHandleSubmit} validationSchema={pageContentState.validationSchema}>
                {({ handleSubmit, values, setFieldValue, errors, touched, setFieldTouched }) => {
                    return (
                        <AppPagesLayout>
                            <div className="page-employee">
                                <AppModalConfirmation
                                    isOpenModal={confirmModalOpen}
                                    onRequestClose={() => setConfirmModalOpen(false)}
                                    details={pageContentState.modalConfirmDetails}
                                    onClick={() => updateConfirmed(pageDataValues)}
                                    cancelDisabled={button.disabled}
                                    buttonDisabled={button.disabled}
                                    buttonLabel={button.label}
                                />
                                <div className="page-employee__breadcrumb">
                                    <AppBreadcrumb list={breadcrumb} />
                                </div>
                                <div className="page-employee__content">
                                    <div className="page-employee__left">
                                        <div className="page-employee__left-content">
                                            <div className="page-employee__image-upload-wrapper">
                                                <AppImageUpload
                                                    onChange={(value) => {
                                                        setFieldTouched("image");
                                                        setFieldValue("image", value[0]);
                                                    }}
                                                    value={values.image}
                                                />
                                            </div>
                                            <div className="page-employee__title">Employment Information</div>

                                            {pageContentState.employeeInformationFields.map((item, index) => {
                                                return (
                                                    <div className="page-employee__input-wrapper" key={index}>
                                                        <AppInputWithLabel disabled={true} placeholder={item.placeholder} value={values[item.name] ? values[item.name] : ""} />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className="page-employee__right">
                                        <div className="page-employee__right-content">
                                            <div className="page-employee__app-status-wrapper">
                                                <AppStatus
                                                    color={pageContentState.status && pageContentState.status.toLowerCase() === "permanent" ? "blue" : pageContentState.status.toLowerCase() === "resigned" ? "red" : "lightBlue"}
                                                    label={pageContentState.status ? pageContentState.status.charAt(0).toUpperCase() + pageContentState.status.slice(1) : ""}
                                                />
                                            </div>
                                            <div className="page-employee__title">Personal Information</div>

                                            {pageContentState.personalInformationFields.map((item, index) => {
                                                if (item.type === "date") {
                                                    return (
                                                        <div key={index} className="page-employee__input-wrapper">
                                                            <AppInputDate
                                                                placeholder="Date of Birth"
                                                                onChange={(value) => {
                                                                    setFieldTouched(item.name);
                                                                    if (value === "") {
                                                                        setFieldValue(item.name, null);
                                                                    } else {
                                                                        setFieldValue(item.name, value);
                                                                    }
                                                                }}
                                                                maxDate="yesterday"
                                                                error={touched[item.name] && errors[item.name]}
                                                                value={values[item.name]}
                                                                disabled={item.name === "dob" && values.typeOfId === "passport" ? false : true}
                                                            />
                                                        </div>
                                                    );
                                                } else if (item.name === "typeOfId") {
                                                    return (
                                                        <div key={index} className="page-employee__input-wrapper">
                                                            <AppDropdown
                                                                placeholder={item.placeholder}
                                                                onChange={(selected) => {
                                                                    setFieldTouched(item.name);
                                                                    setFieldValue(item.name, selected.value);
                                                                    setFieldValue("nric", "");
                                                                    setFieldValue("passport", "");
                                                                    setFieldValue("dob", "");
                                                                }}
                                                                dropdownOptions={typeOfIdDropdownOption}
                                                                error={touched[item.name] && !values[item.name] ? `${item.placeholder} is required` : ""}
                                                                value={values[item.name]}
                                                                currentInputValue={values[item.name] ? typeOfIdDropdownOption.filter((ele) => ele.value.toLowerCase() === values[item.name])[0] : null}
                                                            />
                                                        </div>
                                                    );
                                                } else if (item.type === "ic") {
                                                    if (values.typeOfId === "ic no.") {
                                                        return (
                                                            <div key={index} className="page-employee__input-wrapper">
                                                                <AppInputWithLabel
                                                                    placeholder={item.placeholder}
                                                                    onChange={(e) => {
                                                                        setFieldTouched(item.name);
                                                                        setFieldValue(item.name, e.target.value);
                                                                    }}
                                                                    error={touched[item.name] && errors[item.name]}
                                                                    value={values[item.name] ? values[item.name] : ""}
                                                                    onBlur={() => {
                                                                        let removedDashValue = values.nric.split("-").join("");
                                                                        if (removedDashValue.length >= 12) {
                                                                            let withDashesValue = removedDashValue.substring(0, 6) + "-" + removedDashValue.substring(6, 8) + "-" + removedDashValue.substring(8);
                                                                            setFieldValue(item.name, withDashesValue);
                                                                            let year;
                                                                            let currentYear = currentDate.format("YY");
                                                                            if (parseInt(withDashesValue.substring(0, 2)) < parseInt(currentYear)) {
                                                                                year = `20${withDashesValue.substring(0, 2)}`;
                                                                            } else {
                                                                                year = `19${withDashesValue.substring(0, 2)}`;
                                                                            }
                                                                            let date = `${withDashesValue.substring(4, 6)}/${withDashesValue.substring(2, 4)}/${year}`;
                                                                            setFieldValue("dob", date);
                                                                        } else {
                                                                            setFieldValue("dob", "");
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        );
                                                    } else return null;
                                                } else if (item.type === "mobile") {
                                                    return (
                                                        <div key={index} className="page-employee__input-wrapper">
                                                            <AppInputWithLabel
                                                                placeholder={item.placeholder}
                                                                onChange={(e) => {
                                                                    setFieldTouched(item.name);
                                                                    setFieldValue(item.name, e.target.value);
                                                                }}
                                                                error={touched[item.name] && errors[item.name]}
                                                                value={values[item.name]}
                                                                onBlur={() => {
                                                                    let removedDashValue = values.phoneNumber.split("-").join("");
                                                                    if (removedDashValue >= 10) {
                                                                        let withDashValue = removedDashValue.substring(0, 3) + "-" + removedDashValue.substring(3);
                                                                        setFieldValue(item.name, withDashValue);
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    );
                                                } else if (item.type === "textarea") {
                                                    return (
                                                        <div key={index} className="page-employee__input-wrapper">
                                                            <AppTextArea
                                                                placeholder={item.placeholder}
                                                                onChange={(e) => {
                                                                    setFieldTouched(item.name);
                                                                    setFieldValue(item.name, e.target.value);
                                                                }}
                                                                error={touched[item.name] && errors[item.name]}
                                                                value={values[item.name]}
                                                            />
                                                        </div>
                                                    );
                                                } else if (item.name === "name" || item.name === "userName") {
                                                    return (
                                                        <div key={index} className="page-employee__input-wrapper">
                                                            <AppInputWithLabel
                                                                placeholder={item.placeholder}
                                                                onChange={(e) => {
                                                                    setFieldTouched(item.name);
                                                                    setFieldValue(
                                                                        item.name,
                                                                        e.target.value
                                                                            .toLowerCase()
                                                                            .split(" ")
                                                                            .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
                                                                            .join(" ")
                                                                    );
                                                                }}
                                                                error={touched[item.name] && errors[item.name]}
                                                                value={values[item.name]}
                                                                disabled={item.disabled}
                                                            />
                                                        </div>
                                                    );
                                                } else {
                                                    if (item.name === "passport" && values.typeOfId !== "passport") {
                                                        return null;
                                                    } else {
                                                        return (
                                                            <div key={index} className="page-employee__input-wrapper">
                                                                <AppInputWithLabel
                                                                    placeholder={item.placeholder}
                                                                    onChange={(e) => {
                                                                        setFieldTouched(item.name);
                                                                        setFieldValue(item.name, e.target.value);
                                                                    }}
                                                                    error={touched[item.name] && errors[item.name]}
                                                                    value={values[item.name]}
                                                                    disabled={item.disabled}
                                                                />
                                                            </div>
                                                        );
                                                    }
                                                }
                                            })}

                                            <div className="page-employee__button-row">
                                                <div className="page-employee__button-wrapper">
                                                    <AppButton label="Cancel" buttonType="outline" size="l" onClick={() => history.goBack()} />
                                                </div>
                                                <div className="page-employee__button-wrapper">
                                                    <AppButton disabled={buttonDisable(touched, errors, button)} onClick={handleSubmit} label={button.label} size="l" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </AppPagesLayout>
                    );
                }}
            </Formik>
        );
    } else {
        return null;
    }
};

export default PageEmployeeProfileAndSettings;
