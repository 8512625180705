// libraries
import React, { useEffect, useState, Fragment, forwardRef, useImperativeHandle } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import moment from "moment";
// components
import AppModal from "components/app-modal";
import AppDropdown from "components/app-dropdown";
import AppInputDate from "components/app-input-date";
import AppButton from "components/app-button";
import appToast from "components/app-toast";
import AppModalConfirmation from "components/app-modal-confirmation";
// service
import api from "services/api";
// common
import { sanitizeError } from "common/utilities";
import CONSTANT from "common/constant";

const newLeaveFields = [
    { placeholder: "Leave Type", name: "leaveType", type: "dropdown" },
    { placeholder: "Time Off", name: "timeOff", type: "dropdown" },
    { placeholder: "Start Date", name: "startDate", type: "date" },
    { placeholder: "End Date", name: "endDate", type: "date" },
];

const timeOffList = [
    { label: CONSTANT.BE_STATUS.TIMEOFF.FULL_DAY, value: CONSTANT.BE_STATUS.TIMEOFF.FULL_DAY },
    { label: CONSTANT.BE_STATUS.TIMEOFF.AM, value: CONSTANT.BE_STATUS.TIMEOFF.AM },
    { label: CONSTANT.BE_STATUS.TIMEOFF.PM, value: CONSTANT.BE_STATUS.TIMEOFF.PM },
];

const initialValues = {
    leaveType: "",
    timeOff: "",
    startDate: "",
    endDate: "",
};

const validationSchema = yup.object().shape(
    {
        leaveType: yup.string().required("Leave Type is required"),
        timeOff: yup.string().required("Time off is required"),
        startDate: yup
            .string()
            .required("Start date is required")
            .test("startDate", "Invalid date", (value) => {
                if (value === "Invalid date") {
                    return false;
                } else return true;
            })
            .when(["endDate", "timeOff", "leaveType"], (endDate, timeOff, leaveType, schema) => {
                return schema.test({
                    test: (startDate) => {
                        if (moment(startDate, "DD/MM/YYYY").isAfter(moment(endDate, "DD/MM/YYYY")) && leaveType !== CONSTANT.BE_STATUS.LEAVE_TYPE.PATERNITY_LEAVE) {
                            if (timeOff === CONSTANT.BE_STATUS.TIMEOFF.AM || timeOff === CONSTANT.BE_STATUS.TIMEOFF.PM) {
                                return true;
                            } else {
                                return false;
                            }
                        } else return true;
                    },
                    message: "Date is required to be before end date",
                });
            }),
        endDate: yup
            .string()
            .required("End date is required")
            .test("endDate", "Invalid date", (value) => {
                if (value === "Invalid date") {
                    return false;
                } else return true;
            })
            .when(["startDate", "timeOff"], (startDate, timeOff, schema) => {
                return schema.test({
                    test: (endDate) => {
                        if (moment(endDate, "DD/MM/YYYY").isBefore(moment(startDate, "DD/MM/YYYY"))) {
                            if (timeOff === CONSTANT.BE_STATUS.TIMEOFF.AM || timeOff === CONSTANT.BE_STATUS.TIMEOFF.PM) {
                                return true;
                            } else {
                                return false;
                            }
                        } else return true;
                    },
                    message: "Date is required to be after start date",
                });
            }),
    },
    ["startDate", "endDate"]
);

const confirmationBtnInitialValues = { label: "Confirm", disable: false };

const AppEmployeeCreateNewLeave = forwardRef(({ onSuccess }, ref) => {
    const [leaveTypeList, setLeaveTypeList] = useState([]);
    const [modalIsOpenSequence, setModalIsOpenSequence] = useState([]);
    const [inputValues, setInputValues] = useState({});
    const [confirmationBtn, setConfirmationBtn] = useState(confirmationBtnInitialValues)
    const profile = useSelector((state) => state.auth);

    useEffect(() => {
        const getEntitledLeaveType = async (profile) => {
            try {
                const response = await api.get.companyBenefitEntitledBenefit(profile.staffID);
                const formattedLeaveBenefits = response.data.result.leaveBenefits.map((o) => {
                    return {
                        label: o.benefitName,
                        value: o.benefitName,
                    };
                });
                setLeaveTypeList(formattedLeaveBenefits);
            } catch (error) {
                appToast(sanitizeError(error), false);
            }
        };
        getEntitledLeaveType(profile);
    }, [profile]);

    const dropdownCurrentInputValue = (name, value) => {
        if (name === "leaveType" && value) {
            return leaveTypeList.filter((e) => e.value === value)[0];
        } else if (name === "timeOff") {
            return timeOffList.filter((e) => e.value === value)[0];
        } else {
            return null;
        }
    };

    useImperativeHandle(ref, () => ({
        createLeaveModalIsOpen() {
            setModalIsOpenSequence(["MODAL_CREATE_LEAVE"]);
        },
    }));

    const createLeaveOnRequestClose = (resetForm) => {
        setModalIsOpenSequence(modalIsOpenSequence.slice(0, -1));
        resetForm();
    };

    const confirmationModalOnRequestClose = () => {
        setModalIsOpenSequence(modalIsOpenSequence.slice(0, -1));
    };

    const onHandleSubmit = (values) => {
        setInputValues(values);
        setModalIsOpenSequence([...modalIsOpenSequence, "MODAL_CONFIRMATION"]);
    };

    const dropdownOptions = (name) => {
        if (name === "leaveType" && leaveTypeList.length) {
            return leaveTypeList;
        } else if (name === "timeOff") {
            return timeOffList;
        } else return null;
    };

    const confirmationOnClick = async (resetForm) => {
        setConfirmationBtn({
            label: "Creating..",
            disable: true,
        })
        try {
            let payload = {
                endDate: inputValues.endDate,
                leaveType: inputValues.leaveType,
                staffId: profile ? profile.staffID : "",
                startDate: inputValues.startDate,
                timeOff: inputValues.timeOff,
            };
            await api.post.leaveManagementCreateLeave(payload);
            setConfirmationBtn(confirmationBtnInitialValues)
            appToast("Leave has been submitted successfully.", true);
            setModalIsOpenSequence([]);
            setInputValues({});
            resetForm();
            onSuccess();
        } catch (error) {
            setConfirmationBtn(confirmationBtnInitialValues)
            appToast(sanitizeError(error), false);
        }
    };

    return (
        <Formik initialValues={initialValues} onSubmit={onHandleSubmit} validationSchema={validationSchema}>
            {({ handleSubmit, values, setFieldValue, errors, touched, setFieldTouched, resetForm, setFieldError }) => {
                return (
                    <Fragment>
                        <AppModalConfirmation
                            details="Confirm to create?"
                            buttonLabel={confirmationBtn.label}
                            isOpenModal={modalIsOpenSequence.slice(-1)[0] === "MODAL_CONFIRMATION"}
                            onRequestClose={confirmationModalOnRequestClose}
                            onClick={() => confirmationOnClick(resetForm)}
                            buttonDisabled={confirmationBtn.disable}
                        />
                        <AppModal title="New Leave" isOpenModal={modalIsOpenSequence.slice(-1)[0] === "MODAL_CREATE_LEAVE"} onRequestClose={() => createLeaveOnRequestClose(resetForm)}>
                            <div>
                                <div className="new-leave__contents">
                                    {newLeaveFields.map((o, i) => {
                                        if (o.type === "dropdown") {
                                            return (
                                                <div key={i} className="new-leave__input-wrapper">
                                                    <AppDropdown
                                                        placeholder={o.placeholder}
                                                        onChange={(selected) => {
                                                            if (o.name === "timeOff" && (selected.value === CONSTANT.BE_STATUS.TIMEOFF.AM || selected.value === CONSTANT.BE_STATUS.TIMEOFF.PM) && values.startDate) {
                                                                setFieldValue("endDate", values.startDate);
                                                                setFieldError("endDate", "");
                                                            } else if (selected.value === CONSTANT.BE_STATUS.LEAVE_TYPE.PATERNITY_LEAVE) {
                                                                setFieldValue("timeOff", CONSTANT.BE_STATUS.TIMEOFF.FULL_DAY)
                                                                setFieldError("timeOff", "");
                                                                if (values.startDate) {
                                                                    setFieldValue("endDate", moment(values.startDate, "DD/MM/YYYY").add(59, "days").format("DD/MM/YYYY"));
                                                                    setFieldError("endDate", "");
                                                                }
                                                            }
                                                            setFieldTouched(o.name);
                                                            setFieldValue(o.name, selected.value);
                                                        }}
                                                        isDisabled={o.name === "timeOff" && values.leaveType === CONSTANT.BE_STATUS.LEAVE_TYPE.PATERNITY_LEAVE}
                                                        dropdownOptions={dropdownOptions(o.name)}
                                                        error={touched[o.name] && errors[o.name]}
                                                        value={values[o.name]}
                                                        currentInputValue={dropdownCurrentInputValue(o.name, values[o.name])}
                                                    />
                                                </div>
                                            );
                                        } else if (o.type === "date") {
                                            return (
                                                <div key={i} className="new-leave__input-wrapper">
                                                    <AppInputDate
                                                        placeholder={o.placeholder}
                                                        onChange={(value) => {
                                                            if (values.timeOff === CONSTANT.BE_STATUS.TIMEOFF.AM || values.timeOff === CONSTANT.BE_STATUS.TIMEOFF.PM) {
                                                                setFieldValue("endDate", value);
                                                            } else if (o.name === "startDate" && values.leaveType === CONSTANT.BE_STATUS.LEAVE_TYPE.PATERNITY_LEAVE) {
                                                                setFieldValue("endDate", moment(value, "DD/MM/YYYY").add(59, "days").format("DD/MM/YYYY"));
                                                                setFieldError("endDate", "");
                                                            }
                                                            setFieldError(o.name, "");
                                                            setFieldTouched(o.name);
                                                            setFieldValue(o.name, value);
                                                        }}
                                                        error={touched[o.name] && errors[o.name]}
                                                        value={values[o.name]}
                                                        disabled={o.name === "endDate" && (values.timeOff === CONSTANT.BE_STATUS.TIMEOFF.AM || values.timeOff === CONSTANT.BE_STATUS.TIMEOFF.PM || values.leaveType === CONSTANT.BE_STATUS.LEAVE_TYPE.PATERNITY_LEAVE)}
                                                    />
                                                </div>
                                            );
                                        } else return null;
                                    })}
                                </div>
                                <div className="new-leave__button-row">
                                    <div className="new-leave__button-wrapper">
                                        <AppButton label="Cancel" buttonType="outline" s="l" onClick={() => createLeaveOnRequestClose(resetForm)} />
                                    </div>
                                    <div className="new-leave__button-wrapper">
                                        <AppButton label="Create" s="l" onClick={handleSubmit} />
                                    </div>
                                </div>
                            </div>
                        </AppModal>
                    </Fragment>
                );
            }}
        </Formik>
    );
});

export default AppEmployeeCreateNewLeave;
