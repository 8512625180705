import React from "react";

const AppDropdownCheckbox = ({ onChange, dropdownList, state }) => {
    const onChangeHandler = (item, value) => {
        if (onChange) {
            onChange(item, value);
        }
    };

    return (
        <div className="app-dropdown-checkbox">
            {dropdownList &&
                dropdownList.length &&
                dropdownList.map((item, index) => {
                    return (
                        <div key={index} className="app-dropdown-checkbox__item">
                            <input checked={state[item.name]} onChange={(e) => onChangeHandler(item, e.target.checked)} className="app-dropdown-checkbox__input" id={item.label} type="checkbox" />
                            <label className="app-dropdown-checkbox__label" htmlFor={item.label}>
                                {item.label}
                            </label>
                        </div>
                    );
                })}
        </div>
    );
};

export default AppDropdownCheckbox;
