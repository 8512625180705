import React, { useContext } from "react";
import AppInputResponsive from "components/app-input-responsive";
import { AppLetterInputContext } from "./send-letter";

const AppLetterDefaultTemplate = () => {
    const { letterInput, setLetterInput } = useContext(AppLetterInputContext);
    return (
        <div>
            <div style={{ fontWeight: 700 }}>CONFIRMATION OF APPOINTMENT</div>
            <br />
            <div>
                We are pleased to inform you that your work performance during your probationary period has been satisfactory and that you will be confirmed in your appointment as employment as{" "}
                <span style={{ fontWeight: 700 }}>
                    [
                    <AppInputResponsive
                        className="page-send-letter__input page-send-letter__input--bold"
                        onChange={(evt) => {
                            setLetterInput({
                                ...letterInput,
                                position: evt.target.value,
                            });
                        }}
                        value={letterInput.position}
                    />
                    ]
                </span>{" "}
                with effect from [
                <AppInputResponsive
                    className="page-send-letter__input"
                    onChange={(evt) => {
                        setLetterInput({
                            ...letterInput,
                            startDate: evt.target.value,
                        });
                    }}
                    value={letterInput.startDate}
                />
                ].
            </div>
            <br />
            <div>
                All other terms and conditions of your employment as specified in your Letter of Offer dated [
                <AppInputResponsive
                    className="page-send-letter__input"
                    onChange={(evt) => {
                        setLetterInput({
                            ...letterInput,
                            lastOfferLetter: evt.target.value,
                        });
                    }}
                    value={letterInput.lastOfferLetter}
                />
                ] remain unchanged.
            </div>
            <br />
            <div>We take this opportunity to acknowledge and to thank you for your dedication, effort and contributions, and we wish you continuous success with Altecflex Sdn. Bhd.</div>
        </div>
    );
};

export default AppLetterDefaultTemplate;
