// libraries
import React, { useState, useEffect } from "react";
import _ from "lodash";
import { AiOutlinePlus } from "react-icons/ai";
import { useFormik } from "formik";
import * as yup from "yup";
// components
import AppPagesLayout from "components/app-pages-layout";
import AppBreadcrumb from "components/app-breadcrumb";
import AppTabLink from "components/app-tab-link";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppReactTable from "components/app-react-table";
import AppPaginate from "components/app-paginate";
import AppModal from "components/app-modal";
import AppInputWithLabel from "components/app-input-with-label";
import AppDropdown from "components/app-dropdown";
import AppTextArea from "components/app-text-area";
import appToast from "components/app-toast";
import AppInputDisplay from "components/app-input-display";
// route
import pathnames from "routes/pathnames";
// services
import api from "services/api";
// common
import { sanitizeError } from "common/utilities";
// assets
import iconSearch from "assets/images/components/app-input/icon-search.svg";
import iconEdit from "assets/images/icon-edit.svg";
import iconTailedArrowUp from "assets/images/icon-tailed-arrow-up.svg";
// hooks
import usePrevious from "hooks/use-previous";

const breadcrumb = [
    {
        label: "Company Benefits",
        onClick: null,
    },
    {
        label: "Position",
    },
];

const appTabLink = [
    {
        label: "Position",
        pathname: pathnames.pageCompanyBenefitsPosition,
    },
    {
        label: "Benefit",
        pathname: pathnames.pageCompanyBenefitsBenefit,
    },
];

const validationSchema = yup.object().shape({
    positionName: yup.string().required("Position name is required"),
});

const headerArrowRotate = (active, tableSearchFilter) => {
    const classNames = ["react-table__arrow-icon"];
    if (active && tableSearchFilter.sortDir === "desc") classNames.push("react-table__arrow-icon-down");
    if (active) classNames.push("react-table__arrow-icon--active");

    return classNames.join(" ");
};

const PageCompanyBenefitsPosition = () => {
    const [positionContents, setPositionContents] = useState({
        modalTitle: "",
        modalMainButtonLabel: "",
        modalMainButtonDisable: false,
        selectedRowId: "",
        confirmButton: {
            label: "Confirm",
            disabled: false,
        },
    });
    const [benefitPackageList, setBenefitPackageList] = useState([]);
    const [positionValues, setPositionValues] = useState({
        packageName: "",
        positionDesc: "",
        positionName: "",
    });
    const [tableSearchFilter, setTableSearchFilter] = useState({
        pageNo: 0,
        pageSize: 5,
        searchValue: "",
        sortBy: "",
        sortDir: "asc",
    });
    const [tableData, setTableData] = useState({
        positionList: [],
        last: null,
        pageNo: 0,
        pageSize: 5,
        totalElements: null,
        totalPages: 1,
    });

    const prevValueSortHeader = usePrevious({
        sortBy: tableSearchFilter.sortBy,
        sortDir: tableSearchFilter.sortDir,
    });
    const [modalIsOpenSequence, setModalIsOpenSequence] = useState([]);

    const formik = useFormik({
        initialValues: {
            packageName: positionValues.packageName,
            positionDesc: positionValues.positionDesc,
            positionName: positionValues.positionName,
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: () => {
            onHandleSubmit();
        },
    });

    useEffect(() => {
        const getAllBenefitPackage = async () => {
            try {
                const response = await api.get.companyBenefitListAllBenefit();
                let benefitList = response.data.result.benefitList;
                benefitList.forEach((o, i) => {
                    Object.defineProperty(o, "label", Object.getOwnPropertyDescriptor(o, "value"));
                    delete o["value"];
                });
                benefitList.forEach((o, i) => {
                    Object.defineProperty(o, "value", Object.getOwnPropertyDescriptor(o, "id"));
                    delete o["id"];
                });
                setBenefitPackageList(benefitList);
            } catch (error) {
                let sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
            }
        };
        getAllBenefitPackage();
    }, []);

    const getTableData = async (tableSearchFilter) => {
        try {
            const response = await api.get.companyBenefitListSearchedPosition(tableSearchFilter);
            setTableData(response.data.result);
        } catch (error) {
            let sanitizedError = sanitizeError(error);
            appToast(sanitizedError, false);
        }
    };

    useEffect(() => {
        getTableData(tableSearchFilter);
    }, [tableSearchFilter]);

    const headerFilterClick = (header) => {
        let column = header.column;
        if (prevValueSortHeader && prevValueSortHeader.sortBy === column.searchFilterValue) {
            if (prevValueSortHeader.sortDir === "asc") {
                setTableSearchFilter({
                    ...tableSearchFilter,
                    sortBy: column.searchFilterValue,
                    sortDir: "desc",
                    pageNo: 0,
                });
            } else {
                setTableSearchFilter({
                    ...tableSearchFilter,
                    sortBy: column.searchFilterValue,
                    sortDir: "asc",
                    pageNo: 0,
                });
            }
        } else {
            setTableSearchFilter({
                ...tableSearchFilter,
                sortBy: column.searchFilterValue,
                sortDir: "asc",
                pageNo: 0,
            });
        }
    };

    const searchKeywordTyped = (value) => {
        setTableSearchFilter({
            pageNo: 0,
            pageSize: 5,
            searchValue: value,
            sortBy: "",
            sortDir: "asc",
        });
    };

    const searchBarOnChange = (e) => {
        searchKeywordTyped(e.target.value);
    };

    const handleKeypress = (e) => {
        if (e.code === "Enter") {
            searchKeywordTyped(e.target.value);
        }
    };

    const onPageChange = (e) => {
        setTableSearchFilter({
            ...tableSearchFilter,
            pageNo: e.selected,
        });
    };

    const newPositionOnClick = () => {
        setPositionValues({
            packageName: "",
            positionDesc: "",
            positionName: "",
        });
        formik.resetForm();
        setPositionContents({
            ...positionContents,
            modalTitle: "Create Position",
            modalMainButtonLabel: "Create",
            modalMainButtonDisable: false,
            selectedRowId: "",
        });
        setModalIsOpenSequence(["MODAL_CREATE"]);
    };

    const onRowSelect = async (row) => {
        setPositionValues({
            packageName: "",
            positionDesc: "",
            positionName: "",
        });
        formik.resetForm();
        try {
            const response = await api.get.companyBenefitGetPositionById(row.id);
            setPositionValues(response.data.result);
            setPositionContents({
                ...positionContents,
                modalTitle: "Edit Position",
                modalMainButtonLabel: "Save",
                modalMainButtonDisable: true,
                selectedRowId: row.id,
            });
            setModalIsOpenSequence([...modalIsOpenSequence, "MODAL_EDIT"]);
        } catch (error) {
            let sanitizedError = sanitizeError(error);
            appToast(sanitizedError, false);
        }
    };

    const onHandleSubmit = () => {
        if (positionContents.modalTitle === "Edit Position" && positionValues.packageName !== formik.values.packageName) {
            setModalIsOpenSequence([...modalIsOpenSequence, "MODAL_CONFIRM_BENEFIT"]);
        } else {
            setModalIsOpenSequence([...modalIsOpenSequence, "MODAL_CONFIRMATION"]);
        }
    };

    const confirmationModalOnClick = async () => {
        if (positionContents.modalTitle === "Create Position") {
            setPositionContents({
                ...positionContents,
                confirmButton: {
                    label: "Creating...",
                    disabled: true,
                },
            });
            try {
                await api.post.companyBenefitCreatePosition(formik.values);
                setPositionContents({
                    modalTitle: "",
                    modalMainButtonLabel: "",
                    selectedRowId: "",
                    confirmButton: {
                        label: "Confirm",
                        disabled: false,
                    },
                });
                setModalIsOpenSequence([]);
                appToast("Position has been created successfully", true);
                getTableData(tableSearchFilter);
            } catch (error) {
                let sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
                setPositionContents({
                    ...positionContents,
                    confirmButton: {
                        label: "Confirm",
                        disabled: false,
                    },
                });
            }
        } else {
            try {
                setPositionContents({
                    ...positionContents,
                    confirmButton: {
                        label: "Saving...",
                        disabled: true,
                    },
                });
                let payload = {
                    ...formik.values,
                    id: positionContents.selectedRowId,
                };
                await api.post.companyBenefitUpdatePosition(payload);
                setPositionContents({
                    modalTitle: "",
                    modalMainButtonLabel: "",
                    selectedRowId: "",
                    confirmButton: {
                        label: "Confirm",
                        disabled: false,
                    },
                });
                setModalIsOpenSequence([]);
                getTableData(tableSearchFilter);
                appToast("Position has been updated successfully", true);
            } catch (error) {
                setPositionContents({
                    ...positionContents,
                    confirmButton: {
                        label: "Confirm",
                        disabled: false,
                    },
                });
                let sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
            }
        }
    };

    const tableColumn = [
        {
            id: "position",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__position-header  react-table__header">
                        Position
                        <img className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue, tableSearchFilter)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            searchFilterValue: "position_name",
            Cell: (row) => {
                return <div className="react-table__position-data">{row.row.original.positionName}</div>;
            },
            maxWidth: 80,
        },
        {
            id: "attachedBenefit",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Attached Benefit
                        <img className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue, tableSearchFilter)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            accessor: "packageName",
            searchFilterValue: "package_name",
        },
        {
            id: "button",
            Cell: () => {
                return <img className="react-table__edit-icon" src={iconEdit} alt="" />;
            },
            maxWidth: 10,
        },
    ];

    return (
        <AppPagesLayout>
            <div className="page-benefits-position">
                <AppModal title={positionContents.modalTitle} isOpenModal={modalIsOpenSequence.slice(-1)[0] === "MODAL_CREATE" || modalIsOpenSequence.slice(-1)[0] === "MODAL_EDIT"} onRequestClose={() => setModalIsOpenSequence([])}>
                    <div className="create-position">
                        <div className="create-position__input-wrapper">
                            <AppInputWithLabel
                                placeholder="Position"
                                onChange={(e) => {
                                    formik.setFieldTouched("positionName");
                                    formik.setFieldValue("positionName", e.target.value);
                                    setPositionContents({
                                        ...positionContents,
                                        modalMainButtonDisable: false,
                                    });
                                }}
                                value={formik.values["positionName"]}
                                error={formik.touched["positionName"] && formik.errors["positionName"]}
                            />
                        </div>
                        <div className="create-position__input-wrapper">
                            <AppTextArea
                                placeholder="Description (Optional)"
                                label="Description"
                                onChange={(e) => {
                                    formik.setFieldTouched("positionDesc");
                                    formik.setFieldValue("positionDesc", e.target.value);
                                    setPositionContents({
                                        ...positionContents,
                                        modalMainButtonDisable: false,
                                    });
                                }}
                                value={formik.values.positionDesc}
                            />
                        </div>
                        <div className="create-position__input-wrapper">
                            <AppDropdown
                                placeholder="Benefit Package"
                                dropdownOptions={benefitPackageList}
                                onChange={(selected) => {
                                    formik.setFieldTouched("packageName");
                                    formik.setFieldValue("packageName", selected.label);
                                    setPositionContents({
                                        ...positionContents,
                                        modalMainButtonDisable: false,
                                    });
                                }}
                                currentInputValue={formik.values.packageName ? benefitPackageList.filter((ele) => ele.label === formik.values.packageName)[0] : null}
                                value={formik.values.packageName}
                                error={formik.touched.packageName && formik.errors.packageName}
                            />
                        </div>
                        <div className="create-position__button-row">
                            <div className="create-position__button-wrapper">
                                <AppButton label="Cancel" buttonType="outline" size="l" onClick={() => setModalIsOpenSequence([])} />
                            </div>
                            <div className="create-position__button-wrapper">
                                <AppButton label={positionContents.modalMainButtonLabel} disabled={positionContents.modalMainButtonDisable} size="l" onClick={formik.handleSubmit} />
                            </div>
                        </div>
                    </div>
                </AppModal>

                <AppModal
                    title="Replace Benefit"
                    isOpenModal={modalIsOpenSequence.slice(-1)[0] === "MODAL_CONFIRM_BENEFIT"}
                    onRequestClose={() => {
                        setModalIsOpenSequence(modalIsOpenSequence.slice(0, -1));
                    }}>
                    <div className="page-benefits-position__confirm-benefit">
                        <div className="confirm-benefit__title">Please choose only one benefit.</div>
                        <div>
                            <div className="confirm-benefit__display-wrapper">
                                <AppInputDisplay contents={positionValues.packageName} type="current" />
                            </div>
                            <div className="confirm-benefit__display-wrapper">
                                <AppInputDisplay contents={formik.values.packageName} type="new" />
                            </div>
                        </div>

                        <div className="confirm-benefit__button-row">
                            <div className="confirm-benefit__button-wrapper">
                                <AppButton
                                    label="Cancel"
                                    buttonType="outline"
                                    size="l"
                                    onClick={() => {
                                        setModalIsOpenSequence(modalIsOpenSequence.slice(0, -1));
                                    }}
                                />
                            </div>
                            <div className="confirm-benefit__button-wrapper">
                                <AppButton
                                    label="Confirm"
                                    size="l"
                                    onClick={() => {
                                        setModalIsOpenSequence([...modalIsOpenSequence, "MODAL_CONFIRMATION_DISCLAIMER"]);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </AppModal>

                <AppModal
                    isOpenModal={modalIsOpenSequence.slice(-1)[0] === "MODAL_CONFIRMATION" || modalIsOpenSequence.slice(-1)[0] === "MODAL_CONFIRMATION_DISCLAIMER"}
                    onRequestClose={() => setModalIsOpenSequence(modalIsOpenSequence.slice(0, -1))}
                    title={modalIsOpenSequence.slice(-1)[0] === "MODAL_CONFIRMATION" ? "Confirmation" : modalIsOpenSequence.slice(-1)[0] === "MODAL_CONFIRMATION_DISCLAIMER" ? "Disclaimer" : ""}>
                    <div className="page-benefits-position__disclaimer">
                        <div>
                            {modalIsOpenSequence.slice(-1)[0] === "MODAL_CONFIRMATION"
                                ? "Confirm to save?"
                                : modalIsOpenSequence.slice(-1)[0] === "MODAL_CONFIRMATION_DISCLAIMER"
                                ? "Changing new benefit will affect existing limit/ balance, confirm to continue?"
                                : ""}
                        </div>
                        <div className="disclaimer__button-row">
                            <div className="disclaimer__button-wrapper">
                                <AppButton label="Cancel" buttonType="outline" size="s" disabled={positionContents.confirmButton.disabled} onClick={() => setModalIsOpenSequence(modalIsOpenSequence.slice(0, -1))} />
                            </div>
                            <div className="disclaimer__button-wrapper">
                                <AppButton label={positionContents.confirmButton.label} size="s" disabled={positionContents.confirmButton.disabled} onClick={() => confirmationModalOnClick()} />
                            </div>
                        </div>
                    </div>
                </AppModal>
                <div className="page-benefits-position__breadcrumb">
                    <AppBreadcrumb list={breadcrumb} />
                </div>
                <div className="page-benefits-position__tab-wrapper">
                    <AppTabLink tabList={appTabLink} />
                </div>
                <div className="page-benefits-position__row">
                    <div className="page-benefits-position__searchBar-wrapper">
                        <AppInput leftSrc={iconSearch} placeholder="Search keyword" onChange={_.debounce((e) => searchBarOnChange(e), 1000)} onKeyPress={handleKeypress} />
                    </div>
                    <div className="page-benefits-position__button-wrapper">
                        <AppButton size="s" buttonIcon={<AiOutlinePlus size={12} />} label="New Position" onClick={newPositionOnClick} />
                    </div>
                </div>
                <div className="page-benefits-position__table-wrapper">
                    <AppReactTable onClickRow={onRowSelect} columns={tableColumn} data={tableData.positionList} searchKeyword={tableSearchFilter.searchValue} />
                </div>
                <div className="page-benefits-position__pagination">
                    <AppPaginate onPageChange={onPageChange} pageCount={tableData.totalPages} forcePage={tableSearchFilter.pageNo} />
                </div>
            </div>
        </AppPagesLayout>
    );
};

export default PageCompanyBenefitsPosition;
