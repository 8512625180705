// libraries
import React, { useState, useEffect } from "react";
import Select from "react-select";

const getAppDropdownClassNames = (error) => {
    const classNames = ["app-dropdown__container"];
    if (error) classNames.push("app-dropdown__border-error");
    return classNames.join(" ");
};

const AppDropdown = ({ dropdownOptions, onChange, placeholder, error, onBlur, value, currentInputValue, isOptionDisabled, isDisabled, isClearable }) => {
    const [showLabel, setShowLabel] = useState(true);
    const defaultValue = (dropdownOptions, value) => {
        if (dropdownOptions) {
            const dropdownOptionsKeyList = dropdownOptions.length ? Object.keys(dropdownOptions[0]) : [];
            if (!dropdownOptionsKeyList.includes("label") && dropdownOptionsKeyList.includes("value")) {
                dropdownOptions.forEach((o, i) => {
                    Object.defineProperty(o, "label", Object.getOwnPropertyDescriptor(o, "value"));
                    delete o["value"];
                });
            }
            if (!dropdownOptionsKeyList.includes("value") && dropdownOptionsKeyList.includes("id")) {
                dropdownOptions.forEach((o, i) => {
                    Object.defineProperty(o, "value", Object.getOwnPropertyDescriptor(o, "id"));
                    delete o["id"];
                });
            }
            return dropdownOptions.filter((ele) => ele.label === value);
        } else return null;
    };

    useEffect(() => {
        if (!value) {
            setShowLabel(false);
        }
    }, [value]);

    const inputOnFocus = () => {
        setShowLabel(false);
        let inputValue = document.getElementsByClassName("css-qc6sy-singleValue");
        if (inputValue[0]) {
            inputValue[0].style.width = "100%";
        }
    };

    const inputOnBlur = () => {
        let inputValue = document.getElementsByClassName("css-qc6sy-singleValue");
        if (inputValue[0]) {
            inputValue[0].style.width = "160px";
        }
        if (value && value.length) {
            setShowLabel(true);
        }
        if (onBlur) {
            onBlur();
        }
    };

    return (
        <div className="app-dropdown" onClick={(e) => e.stopPropagation()}>
            <div className="app-dropdown__wrapper">
                <Select
                    value={currentInputValue}
                    onFocus={inputOnFocus}
                    defaultValue={defaultValue(dropdownOptions, value)}
                    onBlur={inputOnBlur}
                    options={dropdownOptions ? dropdownOptions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())): []}
                    onChange={onChange}
                    placeholder={placeholder}
                    className={getAppDropdownClassNames(error)}
                    classNamePrefix="app-dropdown"
                    isOptionDisabled={isOptionDisabled}
                    isDisabled={isDisabled}
                    isClearable={isClearable}
                />
                {showLabel ? <div className="app-dropdown__title">{placeholder}</div> : null}
            </div>
            {error ? <span className="app-dropdown__error-message">{error}</span> : null}
        </div>
    );
};

export default AppDropdown;
