// libraries
import React from "react";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
// components
import appToast from "components/app-toast";
// routes
import pathnames from "routes/pathnames";
import employeePathnames from "routes/employee-pathnames";

const withAuthenticate =
    (WrappedComponent, authenticate, role) =>
    ({ ...props }) => {
        const profile = useSelector((state) => state.auth);
        const token = localStorage.getItem("@storage_token");
        if (!authenticate) {
            if (token && (profile.staffRole.toLowerCase() === "admin" || profile.staffRole.toLowerCase() === "admin owner")) {
                return <Redirect to={pathnames.pageDashboard} />;
            } else if (token && profile.staffRole.toLowerCase() === "employee") {
                return <Redirect to={employeePathnames.pageEmployeeDashboard} />;
            }
        } else {
            if (token && (profile.staffRole.toLowerCase() === "admin" || profile.staffRole.toLowerCase() === "admin owner") && role.toLowerCase() === "admin") {
                return <WrappedComponent {...props} />;
            } else if (token && (profile.staffRole.toLowerCase() === "employee" || profile.staffRole.toLowerCase() === "admin owner") && role.toLowerCase() === "employee") {
                return <WrappedComponent {...props} />;
            } else {
                if (!token && !profile.staffRole) {
                    setTimeout(() => {
                        toast.clearWaitingQueue();
                    }, 2000);
                    appToast("Session Timeout. Please login again", true);
                }
                return <Redirect to={pathnames.pageLogin} />;
            }
        }

        return <WrappedComponent {...props} />;
    };

export default withAuthenticate;
