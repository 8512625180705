// libraries
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import _ from "lodash";
// services
import api from "services/api";
// components
import AppPagesLayout from "components/app-pages-layout";
import AppBreadcrumb from "components/app-breadcrumb";
import appToast from "components/app-toast";
import AppInputWithLabel from "components/app-input-with-label";
// pathnames
import employeePathnames from "routes/employee-pathnames";

import { sanitizeError } from "common/utilities";

const breadcrumb = [
    {
        label: "User Management",
        pathname: employeePathnames.pageEmployeeUserManagement,
    },
    {
        label: "Employee Details",
    },
];

const personalInformationFields = [
    { placeholder: "Employee ID", name: "staffID" },
    { placeholder: "Full Name", name: "staffName" },
    { placeholder: "Username", name: "staffUserName" },
    { placeholder: "Date of Birth", name: "staffDOB" },
    { placeholder: "Mobile Number", name: "staffPhoneNumber" },
    { placeholder: "Work Email", name: "staffEmail" },
];

const employeeInformationFields = [
    { placeholder: "Joined Date", name: "joinedDate" },
    { placeholder: "Position", name: "staffPosition" },
    { placeholder: "Contract Type", name: "contractType" },
    { placeholder: "Reporting Manager", name: "reportingManager" },
];

const PageEmployeeDetails = () => {
    const history = useHistory();
    const { staffId } = useParams();
    const staffIdNumber = staffId.replace("value_", "");
    const [employeeDetails, setEmployeeDetails] = useState({});

    const getStaffIdDetails = async (staffIdNumber, history) => {
        try {
            let payload = {
                staffId: staffIdNumber,
            };
            const response = await api.get.getUsernameDetails(payload);
            setEmployeeDetails(response.data.result[0]);
        } catch (error) {
            appToast(sanitizeError(error), false, 300);
            history.goBack();
        }
    };

    useEffect(() => {
        getStaffIdDetails(staffIdNumber, history);
    }, [staffIdNumber, history]);

    return (
        <AppPagesLayout>
            <div className="page-employee-details">
                <div className="page-employee-details__breadcrumb">
                    <AppBreadcrumb list={breadcrumb} />
                </div>
                <div className="page-employee-details__header">
                    <img className="page-employee-details__profile-image" alt="" src={employeeDetails.image} />
                    <div className="page-employee-details__status-wrapper">
                        <span>{employeeDetails.status}</span>
                    </div>
                </div>
                <div className="page-employee-details__body">
                    <div className="page-employee-details__column-contents">
                        <div className="page-employee-details__column-title">Personal Information</div>
                        {!_.isEmpty(employeeDetails) &&
                            personalInformationFields.map((o, i) => (
                                <div key={i} className="page-employee-details__input-wrapper">
                                    <AppInputWithLabel placeholder={o.placeholder} disabled={true} value={employeeDetails[o.name]} />
                                </div>
                            ))}
                    </div>
                    <div className="page-employee-details__divider" />
                    <div className="page-employee-details__column-contents">
                        <div className="page-employee-details__column-title">Employment Information</div>
                        {!_.isEmpty(employeeDetails) &&
                            employeeInformationFields.map((o, i) => (
                                <div key={i} className="page-employee-details__input-wrapper">
                                    <AppInputWithLabel placeholder={o.placeholder} disabled={true} value={employeeDetails[o.name]} />
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </AppPagesLayout>
    );
};

export default PageEmployeeDetails;
