// libraries
import React from "react";
// components
import AppModal from "components/app-modal";

const AppDualColumnsModal = ({ childrenLeft, childrenRight, childrenBottom, onClick, ...appModalProps }) => {
    return (
        <div className="app-dual-columns-modal" onClick={onClick}>
            <AppModal {...appModalProps}>
                <div className="app-dual-columns-modal__container">
                    <div className="app-dual-columns-modal__left">{childrenLeft}</div>
                    <div className="app-dual-columns-modal__right">{childrenRight}</div>
                </div>
                <div className="app-dual-columns-modal__bottom">
                    <div>{childrenBottom}</div>
                </div>
            </AppModal>
        </div>
    );
};

export default AppDualColumnsModal;
